import {createReducer, on} from "@ngrx/store";
import {
	grassParamsState,
	mapHexLayerFilters,
	HexGridDataMode,
	parameterMap,
	selectableParam, debugModeSelectedParametersActualValues
} from "./types";
import {PhotoInfoType} from "../../models/photo-info/photo-info-type";
import * as config from "../../constants/config";
import {
	demoProjectId,
	DiseaseType,
	DiseaseValue,
	grassQualityParameterNames,
	initialParameters,
	ParameterCategory
} from "../../constants/config";
import {sectorInfo} from "../../models/filtered-objects/sector-info";
import {MapboxFilterUtils} from "../../helpers/mapbox-filter-utils";
import {photoInfo, photoStats} from "../../models/types";
import {grassParamsActions} from "./actions";
import {environment} from "../../../../environments/environment";

const initialState: grassParamsState = {
  expandedCategories: [],
  hexGridDataMode: HexGridDataMode.Parameters,
  isLoadingCount: 0,
  photoInfoTypes: [PhotoInfoType.Fairway, PhotoInfoType.Green, PhotoInfoType.Teebox],
  sectorInfos: [],
  selectableParameters: initialParameters,
  mainSelectedParam: '',
  selectedDiseases: [],
  datesOfPhotos: [],
  errorMessage: '',
  parametersMaps: [],
  hexGridLayerFilters: {
    sectorIds: null,
    type: null,
    diseases: null,
		min: null,
		max: null,
		hexIds: null,
  },
	// todo get from backend
  zoomValue: environment.hexZoomLow,
  trends: [],
  areasEnds: [],
  trendLoadingMapIds: [],
	projectGrassParamsNorms: {
		visualAssessmentNorms: [],
		mineralIngredientsNorms: [],
		vegetationIndicatorNorms: []
	},
	debugModeValues: {},
	measurementsForMaps: {},
	observedHexesSnapshots: [],
	projectDefaultAndLatestGrassParamsNorms: []
}

export const grassParamsReducer = createReducer(
  initialState,
  on(grassParamsActions.getDatesOfPhotos, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
  on(grassParamsActions.getDatesOfPhotosSuccess, (state, {datesOfPhotos}) => ({...state, datesOfPhotos, isLoadingCount: state.isLoadingCount - 1})),
  on(grassParamsActions.handleErrorResponse, (state, {errorMessage}) => ({...state, errorMessage, isLoadingCount: state.isLoadingCount - 1})),
  on(grassParamsActions.changeDateOfPhoto, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
  on(grassParamsActions.changePhotoType, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
  on(grassParamsActions.changeDateOfPhotoOrPhotoTypeSuccess, (state, {date, photoType, mapId, photoInfos, fileLinks, areasEnds, bbox, tilesLinks}) => {
    const isMapCreated = state.parametersMaps.some(x => x.mapId === mapId);

    const hexesDataSources = fileLinks.map(({kind, hexZoom, presignedUrl}) => ({
      kind,
      zoom: hexZoom,
      dataSourceUrl: presignedUrl
    }));

    const parametersMaps: parameterMap[] = isMapCreated ?
      state.parametersMaps.map(paramMap => paramMap.mapId === mapId ?
        {...paramMap,
          mapId,
          selectedDate: date,
          dataSourceType: photoType,
          photoInfos,
					bbox,
					tilesLinks,
          dataSources: hexesDataSources}: paramMap):
        [... state.parametersMaps, {
          mapId,
          selectedDate: date,
          dataSourceType: photoType,
          photoInfos,
					bbox,
					tilesLinks,
          dataSources: hexesDataSources
      }];
    const sectorInfos = getSelectableSectorInfos(parametersMaps);

    const selectedSectorIds = getSelectedSectorIds(parametersMaps, state.selectedSectorIds);

    const hexGridLayerFilters: mapHexLayerFilters = {
      ...state.hexGridLayerFilters,
      type: MapboxFilterUtils.getTypeFilter(state.photoInfoTypes),
      sectorIds: MapboxFilterUtils.getSectorIdsFilter(selectedSectorIds)
    };
    const photoStats = getPhotoParametersStats(parametersMaps);

    const selectableParameters: selectableParam[] = state.selectableParameters.map(param => ({
      ...param,
      disabled: isParamDisabled(param.name, photoStats)
    }));

    return {...state, parametersMaps, isLoadingCount: state.isLoadingCount - 1, hexGridLayerFilters, selectableParameters, selectedSectorIds, sectorInfos,
            areasEnds};
  }),
  on(
    grassParamsActions.toggleSelectedParam,
    (state, { selectedParam }) => {
      if (!canParamBeSelected(state.selectableParameters, selectedParam)) {
        return { ...state };
      }

      const selectableParameters = state.selectableParameters.map((currentParam) =>
        currentParam.name === selectedParam
          ? { ...currentParam, isSelected: !currentParam.isSelected, filterMinValue: !currentParam.isSelected ? currentParam.filterMinValue : undefined, filterMaxValue: !currentParam.isSelected ? currentParam.filterMaxValue : undefined }
          : currentParam
      );

      const parameterSelected = state.selectableParameters.find(({ name }) => name === selectedParam);
      const wasLastSelected = !selectableParameters.find((c) => c.isSelected);

      let mainSelectedParam = parameterSelected!.isSelected && wasLastSelected ? (selectableParameters.find((x) => x.isSelected)?.name ?? "") : selectedParam;

			const stateParamSelected = state.selectableParameters.find(({ name }) => name === mainSelectedParam);

			const min = (stateParamSelected && stateParamSelected.filterMinValue) ? MapboxFilterUtils.getMinFilter(stateParamSelected.filterMinValue, selectedParam) : undefined;
			const max = (stateParamSelected && stateParamSelected.filterMaxValue) ? MapboxFilterUtils.getMaxFilter(stateParamSelected.filterMaxValue, selectedParam) : undefined;

      const isMainSelectedParamSelected = selectableParameters.find((param) => param.name === mainSelectedParam)?.isSelected ?? false;

      if (!isMainSelectedParamSelected) {
        const firstUnselectedParam = selectableParameters.find((param) => param.isSelected);

        if (firstUnselectedParam) {
          mainSelectedParam = firstUnselectedParam.name;

          const hexGridDataMode =
            firstUnselectedParam.category === "Vegetation"
              ? HexGridDataMode.Vegetation
              : HexGridDataMode.Parameters;

          return { ...state, selectableParameters, mainSelectedParam, hexGridDataMode };
        } else {
          mainSelectedParam = "";
        }
      }

      return { ...state, selectableParameters, mainSelectedParam, hexGridDataMode: parameterSelected!.category === "Vegetation" ? HexGridDataMode.Vegetation : HexGridDataMode.Parameters, hexGridLayerFilters: {...state.hexGridLayerFilters, min, max} };
    }
  ),
  on(
    grassParamsActions.selectMainParam,
    (state, { selectedParam }) => {
      const selectedParamConfig = state.selectableParameters.find((param) => param.name === selectedParam);
      const hexGridDataMode = selectedParamConfig?.category === 'Vegetation' ? HexGridDataMode.Vegetation : HexGridDataMode.Parameters;

			const min = selectedParamConfig?.filterMinValue ? MapboxFilterUtils.getMinFilter(selectedParamConfig.filterMinValue, selectedParam) : undefined;
			const max = selectedParamConfig?.filterMaxValue ? MapboxFilterUtils.getMaxFilter(selectedParamConfig.filterMaxValue, selectedParam) : undefined;

      return { ...state, mainSelectedParam: selectedParam, hexGridDataMode, hexGridLayerFilters: {...state.hexGridLayerFilters, min, max} };
    }
  ),
  on(grassParamsActions.switchToDeviationsMode, (state) => ({...state, hexGridDataMode: HexGridDataMode.Deviations})),
  on(grassParamsActions.toggleDisease, (state, {diseaseName}) => {
    const disease = getDiseaseByName(diseaseName);
    const selectedDiseases = state.selectedDiseases.includes(disease) ? state.selectedDiseases.filter(x => x !== disease) : [...state.selectedDiseases, disease];
    const selectableParameters = state.selectableParameters.map(param => param.name === diseaseName ? {...param, isSelected: !param.isSelected}: param);
    return {...state, selectedDiseases, selectableParameters};
  }),
  on(grassParamsActions.removeMap, (state, {mapId}) => ({...state, parametersMaps: state.parametersMaps.filter(x => x.mapId !== mapId)})),
  on(grassParamsActions.reset, () => ({...initialState})),
  on(grassParamsActions.expandCategory, (state, {category}) => ({...state, expandedCategories: [...state.expandedCategories, category]})),
  on(grassParamsActions.hideCategory, (state, {category}) => ({...state, expandedCategories: state.expandedCategories.filter(x => x !== category)})),
  on(grassParamsActions.hideAllCategories, (state) => ({...state, expandedCategories: []})),
  on(grassParamsActions.switchHexGridMode, (state, {hexGridDataMode}) => ({...state, hexGridDataMode})),
  on(grassParamsActions.filterBySectorIds, (state, {sectorIds}) => ({...state, selectedSectorIds: sectorIds, hexGridLayerFilters: {...state.hexGridLayerFilters, sectorIds: MapboxFilterUtils.getSectorIdsFilter(sectorIds)}})),
  on(grassParamsActions.filterByTypes, (state, {typeNames}) => ({...state, photoInfoTypes: typeNames, hexGridLayerFilters: {...state.hexGridLayerFilters, type: MapboxFilterUtils.getTypeFilter(typeNames)}})),
	on(
		grassParamsActions.filterByMinMax,
		(state, { filterMinValue, filterMaxValue }) => {
			const selectableParameters = state.selectableParameters.map((currentParam) =>
				currentParam.name === state.mainSelectedParam
					? { ...currentParam, filterMinValue: filterMinValue, filterMaxValue: filterMaxValue }
					: currentParam
			);

			const min = filterMinValue ? MapboxFilterUtils.getMinFilter(filterMinValue, state.mainSelectedParam) : undefined;
			const max = filterMaxValue ? MapboxFilterUtils.getMaxFilter(filterMaxValue, state.mainSelectedParam) : undefined;

			return { ...state, selectableParameters, hexGridLayerFilters: {...state.hexGridLayerFilters, min, max} };
		}
	),
	on(grassParamsActions.filterByHexIds, (state, {hexIds, selectedParameter}) => {
		const hexIdsFilter = MapboxFilterUtils.getHexIdsFilter(hexIds);

		const hexGridLayerFilters: mapHexLayerFilters = {
			...state.hexGridLayerFilters,
			hexIds: hexIdsFilter,
		};

		return {...state, hexGridLayerFilters };
	}),
	on(grassParamsActions.clearHexIdsFilter, (state) => ({...state, hexGridLayerFilters: {...state.hexGridLayerFilters, hexIds: undefined}})),
  // on(grassParamsActions.filterByZoomValue, (state, {zoomValue}) => ({...state, zoomValue, hexGridLayerFilters: {...state.hexGridLayerFilters, zoom: MapboxFilterUtils.getZoomFilter(zoomValue)}})),
  on(grassParamsActions.getTrendsForHex, (state, {mapId}) => {
    if (!state.trendLoadingMapIds.includes(mapId))
      return {...state, trendLoadingMapIds: [...state.trendLoadingMapIds, mapId ]};
    return state;
  }),
  on(grassParamsActions.getTrendsForHexSuccess, (state, {hexTrends, mapId}) => {
    const newTrendLoadingMapIds = state.trendLoadingMapIds.filter(x => x !== mapId);
    if (hexTrends === undefined)
      return {...state, trendLoadingMapIds: newTrendLoadingMapIds};
    return { ...state, trends: [...state.trends, hexTrends], trendLoadingMapIds: newTrendLoadingMapIds };
  }),
  on(grassParamsActions.changeZoom, (state, {zoomValue}) => ({...state, zoomValue})),
  on(grassParamsActions.clearParameters, (state) => {
    const selectableParameters = state.selectableParameters.map(currentParam => ({...currentParam, isSelected: false}));
    return {...state, selectableParameters, mainSelectedParam: ""};
  }),
	on(grassParamsActions.getParametersNorms, (state) => ({...state, isLoading: true})),
	on(grassParamsActions.getParametersNormsSuccess, (state, {projectGrassParamsNorms}) => ({...state, projectGrassParamsNorms, isLoading: false})),
	on(grassParamsActions.editParameterNorms, (state) => ({...state, isLoading: true})),
	on(grassParamsActions.initSelectableParameters, (state, {projectId}) => {
		if (projectId !== demoProjectId) {
			return {...state, selectableParameters: state.selectableParameters.filter(({name}) => name !== 'humid')}
		}

		return state;
	}),
	on(grassParamsActions.selectHexOnMap, (state, {hexId}) => ({...state, hexId})),
	on(grassParamsActions.resetSelectedHex, (state) => ({...state, hexId: undefined})),
	on(grassParamsActions.setDebugModeValues, (state, {geoJsonProperties}) => {
		if (geoJsonProperties === null) {
			return {...state, debugModeValues: {}};
		}

		const debugModeValues: debugModeSelectedParametersActualValues = {};

		state.selectableParameters.filter(({isSelected}) => isSelected).forEach(({name}) => {
			debugModeValues[name] = geoJsonProperties[name] ?? 'no data';
		})

		return {...state, debugModeValues};
	}),
	on(grassParamsActions.resetDebugModeValues, (state) => ({...state, debugModeValues: {}})),
	on(grassParamsActions.getWeatherAroundDateSuccess, (state, {mapId, measurements}) => ({...state, measurementsForMaps: {...state.measurementsForMaps, [mapId]: measurements}})),
	on(grassParamsActions.getObservedHexesSnapshots, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
	on(grassParamsActions.getObservedHexesSnapshotsSuccess, (state, {observedHexesSnapshots}) => ({...state, isLoadingCount: state.isLoadingCount - 1, observedHexesSnapshots})),
	on(grassParamsActions.saveObservedHexesSnapshot, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
	on(grassParamsActions.saveObservedHexesSnapshotSuccess, (state, {snapshot}) => ({...state, isLoadingCount: state.isLoadingCount - 1, observedHexesSnapshots: [...state.observedHexesSnapshots, snapshot]})),
	on(grassParamsActions.getDefaultAndLatestParametersNorms, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
	on(grassParamsActions.getDefaultAndLatestParametersNormsSuccess, (state, {items}) => ({...state, isLoadingCount: state.isLoadingCount - 1, projectDefaultAndLatestGrassParamsNorms: items})),
	on(grassParamsActions.removeObservedHexesSnapshot, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
	on(grassParamsActions.removeObservedHexesSnapshotSuccess, (state, {snapshotId}) => {
		const observedHexesSnapshots = state.observedHexesSnapshots.filter(({id}) => id !== snapshotId);
		return {...state, isLoadingCount: state.isLoadingCount - 1, observedHexesSnapshots};
	})
);

// utils

const getDiseaseByName = (diseaseName: string) => {
  switch (diseaseName) {
    case DiseaseType.SnowMold:
      return DiseaseValue.SnowMold;
    case DiseaseType.YellowStain:
      return DiseaseValue.YellowStain;
    default:
      return DiseaseValue.AbsenceOfDisease;
  }
}

const canParamBeSelected = (paramsList: selectableParam[], selectedParamName: string) =>
  paramsList.filter(
    param =>
      param.isSelected &&
      param.category !== ParameterCategory.Diseases &&
      param.name !== selectedParamName)
    .length < config.selectedParametersLimit;

const getPhotoParametersStats = (parameterMaps: parameterMap[]): photoStats => {
  const photoStatsList: photoStats[] = parameterMaps.flatMap(({photoInfos}) => photoInfos).flatMap(({photoStats}) => photoStats);

  const initialStats: photoStats = {
    hasSod: false,
    hasGeneral: false,
    hasColor: false,
    hasSlender: false,
    hasDryBiomass: false,
    hasCopper: false,
    hasFall: false,
    hasHumid: false,
    hasSodium: false,
    hasPotassium: false,
    hasZinc: false,
    hasCalcium: false,
    hasNormalizedDifferenceVegetationIndex: false,
    hasFreshBiomass: false,
    HasMagnesium: false,
    hasNitrogen: false,
    hasIron: false,
    hasPhosphorus: false,
    hasManganese: false,
    hasLeafAreaIndexBezp: false,
    hasLeafAreaIndexMean: false
  };

  for (const stats of photoStatsList) {
    for (const key in stats) {
      if (stats[key as keyof photoStats]) {
        initialStats[key as keyof photoStats] = true;
      }
    }
  }

  return initialStats;
}

const getSelectableSectorInfos = (parameterMaps: parameterMap[]): sectorInfo[] => {
  const photoInfos: photoInfo[] = parameterMaps.flatMap(({photoInfos}) => photoInfos);

  return photoInfos.reduce((acc: sectorInfo[], {areaId, name}) => {
    const isDuplicate = acc.some(sectorInfo => sectorInfo.name === name && sectorInfo.id === areaId);

    if (!isDuplicate) {
      acc.push({name, id: areaId});
    }

    return acc;
  }, []);
}

const getSelectedSectorIds = (parametersMaps: parameterMap[], currentSelectedSectorIds?: number[]) => {
  if (currentSelectedSectorIds !== undefined) {
    return currentSelectedSectorIds;
  }

  const sectorIds = parametersMaps.flatMap(({photoInfos}) => photoInfos).map(({areaId}) => areaId);
  return Array.from(new Set(sectorIds.sort()));
}

const isParamDisabled = (paramName: string, photoStats: photoStats) => {
  const isDiseaseType = Object.values(DiseaseType).includes(paramName as DiseaseType);
  const isVegetationIndicator = grassQualityParameterNames
    .filter((param) => param.category === ParameterCategory.VegetationIndicators)
    .some((param) => param.name === paramName);

  const result =
    !(isDiseaseType || isVegetationIndicator) &&
    !photoStats[`has${paramName.charAt(0).toUpperCase() + paramName.slice(1)}` as keyof photoStats];

  return result;
};
