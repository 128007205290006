<ng-container *ngIf="vm$ | async as vm">

  <div class="map-box">
    <div class="app-map-sidebar">
      <app-drawn-areas-sidebar *ngIf="!vm.isLoading"
      [areas]="vm.sidebarAreas"
      (newAreaButtonClicked)="startNewAreaDraw($event)"
      (newElementaryObjectClicked)="startNewElementaryObjectDraw($event)"
      (selectedAreaChanged)="onSelectedAreaOnSidebarChanged($event)"
      (selectElementaryObjectChanged)="onSelectedElementaryObjectOnSidebarChanged($event)" />
    </div>
    <div id="map" class="match-parent map"></div>
  </div>


  <section class="app-map-container-footer">
    <div class="form-group row gap-15">
      <div class="app-map-container-footer-info">
        <div class="icon">
					<svg-icon src="./assets/images/icons/common/question-mark.svg" />
        </div>
        <p class="font-size-10">{{ 'PROJECT.EDIT_HOLES.HELP_INFO' | translate }}</p>
      </div>
    </div>
    <div class="form-group row gap-10 justify-center align-center">
      <p-button *ngIf="shouldShowUpdateFromIGolfButton()" [label]="'PROJECT.EDIT_HOLES.UPDATE_FROM_IGOLF' | translate" styleClass="p-button-outlined p-button-success" class="igolf-button w-195" (onClick)="onUpdateFromIGolf()"></p-button>
      <p-button [label]="'COMMON.SAVE' | translate" styleClass="p-button-success w-195" (onClick)="onSubmit()"></p-button>
    </div>
    <div></div>
  </section>

  <app-area-form-popup [title]="vm.areaFormPopupTitle" [action]="vm.areaFormPopupAction" [currentAreas]="vm.drawnAreas"
                       [areaFormData]="manageAreaFormData" [isAreaFormVisible]="vm.isAddAreaPopupVisible"
                       [isElementaryObjectEnabled]="vm.isElementaryObjectEnabled"
                       (formSubmitted)="onAddAreaSubmit($event)"
                       (areaDeleted)="cancelAreaForm()" (canceled)="cancelAreaForm()" />
  <app-area-form-popup [title]="vm.areaFormPopupTitle" [action]="vm.areaFormPopupAction" [currentAreas]="vm.drawnAreas"
                       [areaFormData]="manageAreaFormData" [isAreaFormVisible]="vm.isEditAreaPopupVisible"
                       [isElementaryObjectEnabled]="vm.isElementaryObjectEnabled"
                       (formSubmitted)="onEditAreaSubmit($event)"
                       (areaDeleted)="deleteArea($event)" />
</ng-container>

