import {AppState} from "../types/app-state";
import {createSelector} from "@ngrx/store";
import {selectUserPermittedFeatures} from "../profile/store";
import {feature, FeatureName} from "../admin/types";
import {isAccessToProjectFeaturePermitted} from "../shared/utils/feature-authorization-utils";
import {organizationIdSelector} from "../project/project-store/selectors";
import {helpContextPaths} from "../shared/components/help-context/help-context-paths";
import {environment} from "../../environments/environment";

export const selectFeatureRouting = (state: AppState) => state.routing;
export const navSidebarRoutingSegmentsSelector = createSelector(selectFeatureRouting, (state) => state?.navSidebarRoutingSegments);
export const loggedInSelector = createSelector(selectFeatureRouting, (state) => state.loggedIn);
export const sidebarExpandedSelector = createSelector(selectFeatureRouting, (state) => state.sidebarExpanded);

export const navSidebarRoutingSegmentsForProject = (projectId: number) => createSelector(navSidebarRoutingSegmentsSelector, selectUserPermittedFeatures, organizationIdSelector, (segments: string[],userFeatures, organizationId ): (string | number)[] => {
	const result = (!segments || segments.length === 0) ? getProjectDefaultSegments(userFeatures, projectId, organizationId!) : segments;
  return ['/', ...result, projectId];
});

const getProjectDefaultSegments = (userFeatures: feature[], projectId: number, organizationId: number) => {
	if (isAccessToProjectFeaturePermitted(projectId, organizationId, FeatureName.ReadUploadPhotoData, userFeatures)) {
		return ["project", 'uploadPhotos'];
	}

	if (isAccessToProjectFeaturePermitted(projectId, organizationId, FeatureName.ReadProjectReport, userFeatures)) {
		return ["project", "report"];
	}

	if (isAccessToProjectFeaturePermitted(projectId, organizationId, FeatureName.ReadMapParamData, userFeatures)) {
		return ["photoParameters"];
	}

	if (isAccessToProjectFeaturePermitted(projectId, organizationId, FeatureName.FindTasks, userFeatures)) {
		return ["tasks"];
	}

	if (isAccessToProjectFeaturePermitted(projectId, organizationId, FeatureName.FindIncidents, userFeatures)) {
		return ["incidents"]
	}

	return ['noAccess'];
}

export const selectContextHelpPathEnd = createSelector(navSidebarRoutingSegmentsSelector, (segments): string => {
	if (segments?.length === 0) {
		return '';
	}

	return helpContextPaths[segments[0]] ?? '';
});

export const selectHelpContextPath = createSelector(selectContextHelpPathEnd, (contextHelpUrlEnd): string | null => {
	if (!contextHelpUrlEnd) {
		return null;
	}

	return `${environment.contextHelpUrl}${contextHelpUrlEnd}`;
})
