import { manageProjectAreaDto } from "src/app/project/DTO/manage-project-area-dto";
import { taskListItem } from "../models/TaskListItem";
import { taskType } from "../models/TaskType";
import { LoadTasksParameters } from "../models/LoadTasksParameters";
import { taskStatusSwitch} from "../models/TaskStatus";
import {taskWithDetails} from "../models/TaskWithDetails";
import { InitAddTaskFromIncidentsModel } from "../models/initAddTaskFromIncidentsModel";
import {changeLog} from "../../shared/model/change-log";
import {ElementaryObject} from "../../project/model/elementary-object";
import {availableTaskUser} from "../models/GetAvailableTaskUsersResponse";
import {
	measurementDto
} from "../../grass-parameters-presentation/components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";

export interface taskState {
  isLoading: boolean;
  tasks: taskListItem[];
  totalRecords: number;
  taskTypes: taskType[];
  areas: manageProjectAreaDto[];
  availableUsers: availableTaskUser[];
	editedTaskBoardItemId?: string;
	taskBoardSwitchConfig?: taskStatusSwitch;
	taskArea?: manageProjectAreaDto;
	taskElementaryObject?: ElementaryObject;
	taskWithDetails?: taskWithDetails;
	selectedIncidents: InitAddTaskFromIncidentsModel[];
	currentTaskBoardFilter: LoadTasksParameters;
	currentCalendarFilter: LoadTasksParameters;
	currentTaskListFilter: LoadTasksParameters;
	taskPresentationMode: TaskPresentationMode;
	changesHistory: changeLog[];
	taskMeasurements: measurementDto[];
}

export enum TaskPresentationMode {
	List,
	Calendar,
	Kannbann
}
