import { createAction, props } from "@ngrx/store";
import { AddATaskCommand } from "../models/AddATaskCommand";
import { UpdateATaskCommand } from "../models/UpdateATaskCommand";
import { LoadTasksParameters } from "../models/LoadTasksParameters";
import { scheduleTaskCommand } from "../models/ScheduleTaskCommand";
import {manageProjectAreaDto} from "../../project/DTO/manage-project-area-dto";
import {taskType} from "../models/TaskType";
import {taskListItem} from "../models/TaskListItem";
import { taskStatus } from "../models/TaskStatus";
import {PhotoInfoType} from "../../grass-parameters-presentation/models/photo-info/photo-info-type";
import {taskWithDetails} from "../models/TaskWithDetails";
import { InitAddTaskFromIncidentsModel } from "../models/initAddTaskFromIncidentsModel";
import {LazyLoadEvent} from "primeng/api";
import {changeLog} from "../../shared/model/change-log";
import {availableTaskUser} from "../models/GetAvailableTaskUsersResponse";
import {
	measurementDto
} from "../../grass-parameters-presentation/components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";

export const taskActions = {
	addTask: createAction('[Task] Add Task', props<AddATaskCommand>()),
	addTaskSuccess: createAction('[Task] Add Task Success'),
	updateTask: createAction('[Task] Edit Task', props<UpdateATaskCommand>()),
	updateTaskSuccess: createAction('[Task] Update Task Success'),
	deleteTask: createAction('[Task] Delete Task', props<{ taskId: string }>()),
	refreshTasks: createAction('[Task] Refresh Tasks'),
	loadTasks: createAction('[Task] Load Tasks', props<LoadTasksParameters>()),
	loadTasksSuccess: createAction('[Task] Load Tasks Success', props<{tasks: taskListItem[], totalRecords: number}>()),
	loadTaskTypes: createAction('[Task] Load Task Types'),
	loadTaskTypesSuccess: createAction('[Task] Load Task Types Success', props<{taskTypes: taskType[]}>()),
	loadAreas: createAction('[Task] Load Areas'),
	loadAreasSuccess: createAction('[Task] Load Areas Success', props<{areas: manageProjectAreaDto[]}>()),
	reset: createAction('[Task] Reset'),
	showSchedulePopup: createAction('[Task] Show Schedule Popup', props<{ taskId: string }>()),
	hideSchedulePopup: createAction('[Task] Hide Schedule Popup', props<{ saveExecuted: boolean }>()),
	scheduleTask: createAction('[Task] Schedule Task', props<scheduleTaskCommand>()),
	getTaskById: createAction('[Task] Get By Id', props<{ taskId: string }>()),
	getTaskByIdSuccess: createAction('[Task] Get By Id Success', props<{task: taskWithDetails}>()),
	addComment: createAction('[Task] Add Comment', props<{taskId: string, comment: string}>()),
	addCommentSuccess: createAction('[Task] Add Comment Success', props<{comment: string, author: string}>()),
	changeTaskArea: createAction('[Task] Change Task Area', props<{areaId?: number, elementaryObjectId?: number}>()),
	changeTaskElementaryObject: createAction('[Task] Change Task Elementary Object', props<{elementaryObjectId: number}>()),
	initAddTaskFromIncidents: createAction('[Task] Init Add Task From Incidents', props<{ incidents: InitAddTaskFromIncidentsModel[]}>()),
	initPresentationPage: createAction('[Task] Init Presentation Page'),
	switchToListMode: createAction('[Task] Switch To List Mode'),
	switchToKannbannMode: createAction('[Task] Switch To Kannbann Mode'),
	switchToCalendarMode: createAction('[Task] Switch To Calendar Mode'),
	getChangesHistory: createAction('[Task] Get Changes History', props<{taskId: string}>()),
	getChangesHistorySuccess: createAction('[Task] Get Changes History Success', props<{changesHistory: changeLog[]}>()),
	getAvailableTaskUsers: createAction('[Task] Get Available Task users'),
	getAvailableTaskUsersSuccess: createAction('[Task] Get Available Task users Success', props<{availableUsers: availableTaskUser[]}>()),
	clearTask: createAction('[Task] Clear Task')
}

export const taskBoardActions = {
	initStatusChange: createAction('[TaskBoard] Init Status Change', props<{taskId: string, taskStatus: taskStatus}>()),
	completeStatusChange: createAction('[TaskBoard] Complete Status Change', props<{dueDate?: Date, assignedUserId?: number}>()),
	completeStatusChangeSuccess: createAction('[TaskBoard] Complete Status Change Success', props<{dueDate?: Date, assignedUser?: {name?: string, id?: number}}>()),
	cancelStatusChange: createAction('[TaskBoard] Cancel Status Change'),
	getTaskBoardTasks: createAction('[TaskBoard] Get Task Board Tasks'),
	filterByAreas: createAction('[TaskBoard] Filter By Areas', props<{areas: manageProjectAreaDto[]}>()),
	filterByElementaryObjectType: createAction('[TaskBoard] Filter By Elementary Object Type', props<{photoInfoType: PhotoInfoType}>()),
	filterByDueDate: createAction('[TaskBoard] Filter By Due Date', props<{dateRange: Date[] | null}>()),
	filterByTaskTypeId: createAction('[TaskBoard] Filter By Task Type Id', props<{taskTypeId?: number}>()),
	filterByTaskTypes: createAction('[TaskList] Filter By Task Types', props<{taskTypes: taskType[]}>()),
	filterByAssignedUserId: createAction('[TaskList] Filter By Assigned User Id', props<{userId?: number}>())
}

export const taskListActions = {
	loadTaskList: createAction('[TaskList] Load Task List', props<{lazyLoadEvent?: LazyLoadEvent}>()),
	filterByAreas: createAction('[TaskList] Filter By Areas', props<{areas: manageProjectAreaDto[]}>()),
	filterByDueDate: createAction('[TaskList] Filter By Due Date', props<{dateRange: Date[] | null}>()),
	filterByTaskTypeId: createAction('[TaskList] Filter By Task Type Id', props<{taskTypeId?: number}>()),
	filterByTaskTypes: createAction('[TaskList] Filter By Task Types', props<{taskTypes: taskType[]}>()),
	filterByStatuses: createAction('[TaskList] Filter By Statuses', props<{statuses: taskStatus[] | undefined}>()),
	filterByElementaryObjectType: createAction('[TaskList] Filter By Elementary Object Type', props<{photoInfoType: PhotoInfoType}>())
}

export const taskCalendarActions = {
	getTaskCalendarTasks: createAction('[TaskCalendar] Get Task Calendar Tasks'),
	filterByAreas: createAction('[TaskCalendar] Filter By Areas', props<{areas: manageProjectAreaDto[]}>()),
	filterByElementaryObjectType: createAction('[TaskCalendar] Filter By Elementary Object Type', props<{photoInfoType: PhotoInfoType}>()),
	filterByTaskTypeId: createAction('[TaskCalendar] Filter By Task Type Id', props<{taskTypeId?: number}>()),
	rescheduleTask: createAction('[TaskCalendar] Reschedule Task', props<{taskId: string, dueDate: Date}>()),
	rescheduleTaskSuccess: createAction('[TaskCalendar] Reschedule Task Success', props<{taskId: string, dueDate: Date}>()),
	rescheduleTaskFailure: createAction('[TaskCalendar] Reschedule Task Failure', props<{errorMessage: string}>()),
	filterByTaskTypes: createAction('[TaskList] Filter By Task Types', props<{taskTypes: taskType[]}>()),
}
