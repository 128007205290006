import { MeasurementSystem } from "../../types/MeasurementSystem";
import {squareFeetToAcres, squareMetersToAcres, squareMetersToHectares} from "./measurement-system-utils";
import {ElementaryObject} from "../../project/model/elementary-object";
import {manageProjectAreaDto} from "../../project/DTO/manage-project-area-dto";

export function displaySize(measurementSystem: MeasurementSystem, sizeMetric?: number | null): string {
	if (sizeMetric == null) {
		return '';
	}

	const suffix = measurementSystem === MeasurementSystem.Metric ? 'ha' : 'ac';
	const value = measurementSystem === MeasurementSystem.Metric ? squareMetersToHectares(sizeMetric).toFixed(2) : squareMetersToAcres(sizeMetric).toFixed(2);
	return `${value} ${suffix}`;
}

export function displayPercentage(totalSizeMetric?: number | null, sizeMetric?: number | null) : string {
	return !totalSizeMetric || !sizeMetric ? '' : `${(100 * sizeMetric / totalSizeMetric).toFixed(2)}%`;
}

export function calculateAreaForTaskOrIncident(measurementSystem: MeasurementSystem, elementaryObject: ElementaryObject | undefined, area: manageProjectAreaDto | undefined): string | undefined {
	const calculatedArea = measurementSystem === MeasurementSystem.Metric ? (elementaryObject?.areaMetric ?? area?.areaMetric) : (elementaryObject?.areaImperial ?? area?.areaImperial);

	if (!calculatedArea) {
		return undefined;
	}

	if (measurementSystem === MeasurementSystem.Metric) {
		return `${squareMetersToHectares(calculatedArea).toFixed(2)} ha`;
	}

	return `${squareFeetToAcres(calculatedArea).toFixed(2)} ac`;
}
