import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {OrganizationInterface} from "../../../profile/types/user-types";
import {Store} from '@ngrx/store';
import {Observable} from "rxjs";
import {AppState} from "../../../types/app-state";
import * as projectSelectors from "../../../project/project-store/selectors";
import {userActions} from "../../../profile/store";

@Component({
  selector: 'app-nav-choose-organization',
  template:
  `
    <div class="choose-organization">
      <div class="title">{{ 'COMMON.ORGANIZATION' | translate }}</div>
      <div class="current-organization-box">
        <select class="current-organization" [ngModel]="selectedOrganization$ | async" (ngModelChange)="onOrganizationChanged($event)">
          <option *ngFor="let organization of organizations$ | async" [ngValue]="organization">
            {{organization.name}}
          </option>
        </select>
        <div class="icon">
					<svg-icon src="./assets/images/icons/nav-top/refresh.svg" />
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavChooseOrganizationComponent {
  private store = inject(Store<AppState>);

  readonly organizations$: Observable<OrganizationInterface[]> = this.store.select(projectSelectors.organizationsSelector);
  readonly selectedOrganization$: Observable<OrganizationInterface> = this.store.select(projectSelectors.selectedOrganizationSelector);

  onOrganizationChanged = (organization: OrganizationInterface) => this.store.dispatch(userActions.changeCurrentUserOrganization({organizationId: organization.id!}));
}
