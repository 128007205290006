import {Routes} from '@angular/router';
import {NotLoggedGuard} from "./shared/guards/not-logged.guard";
import {loggedGuard} from "./shared/guards/logged.guard";
import {DevGuard} from "./shared/guards/dev.guard";
import {UnauthorizedPageComponent} from "./shared/components/unauthorized-page.component";

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'project',
    pathMatch: 'full'
  },
	{
		path: 'request-demo',
		canActivate: [NotLoggedGuard],
		loadChildren: () => import('./request-demo/request-demo.module').then((m) => m.RequestDemoModule),
	},
  {
    path: 'unauthorized',
    component: UnauthorizedPageComponent
  },
  {
    path: 'components',
    canActivate: [DevGuard],
    loadChildren: () => import('./components-page/components-page.module').then(m => m.ComponentsPageModule)
  },
  {
    path: 'login',
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'register',
    canActivate: [NotLoggedGuard],
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'project',
    canActivate: [loggedGuard],
    loadChildren: () => import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'profile',
    canActivate: [loggedGuard],
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'notifications',
    canActivate: [loggedGuard],
    loadChildren: () => import('./notifications/notifications.module').then((m) => m.NotificationsModule),
  },
  {
    path: 'photoParameters',
    canActivate: [loggedGuard],
    loadChildren: () => import('./grass-parameters-presentation/grass-parameters-presentation.module').then((m) => m.GrassParametersPresentationModule)
  },
  {
    path: 'admin',
    canActivate: [loggedGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
  },
  {
    path: 'incidents',
    canActivate: [loggedGuard],
    loadChildren: () => import('./incident/incident.module').then((m) => m.IncidentModule),
  },
  {
    path: 'tasks',
    canActivate: [loggedGuard],
    loadChildren: () => import('./task/task.module').then((m) => m.TaskModule),
  },
	{
		path: 'examinations',
		canActivate: [loggedGuard],
		loadChildren: () => import('./examinations/examinations.module').then((m) => m.ExaminationsModule)
	},
	{
		path: 'golf-club-members',
		canActivate: [loggedGuard],
		loadChildren: () => import('./golf-club-member/golf-club-member.module').then(({GolfClubMemberModule}) => GolfClubMemberModule)
	},
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
