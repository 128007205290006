import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { incidentState } from "./incidentState";
import {selectUserSettings} from "../../profile/store";
import {calculateAreaForTaskOrIncident} from "../../shared/utils/display-size";

export const selectIncidents = (state: AppState) => state.incidents;
export const selectorIsLoading = createSelector(
    selectIncidents,
    (state: incidentState) => state?.isLoading
);
export const selectorIncidents = createSelector(
    selectIncidents,
    (state: incidentState) => state?.incidents
);
export const selectorTotalRecords = createSelector(
    selectIncidents,
    (state: incidentState) => state?.totalRecords
);
export const selectorDiseases = createSelector(
    selectIncidents,
    (state: incidentState) => state?.diseases
);
export const selectorLastLoadIncidentsParameters = createSelector(
    selectIncidents,
    (state: incidentState) => state?.lastLoadIncidentsParameters
);
export const selectorAvailableTasks = createSelector(
    selectIncidents,
    (state: incidentState) => state?.availableTasks
);
export const selectIncidentComments = createSelector(selectIncidents, ({incidentWithDetails}) => incidentWithDetails?.comments || []);
export const selectEditedIncident = createSelector(selectIncidents, ({incidentWithDetails}) => incidentWithDetails);
export const selectEditedIncidentId = createSelector(selectEditedIncident, incident => incident?.id);
export const selectIncidentArea = createSelector(selectIncidents, ({incidentArea}) => incidentArea);
export const selectIncidentElementaryObject = createSelector(selectIncidents, ({incidentElementaryObject}) => incidentElementaryObject);
export const selectChatGptMessages = createSelector(selectIncidents, ({chatGptMessages}) => chatGptMessages);
export const selectAiNoneQuestionsAsked = createSelector(selectChatGptMessages, (messages) => messages.length === 0);
export const selectAiFirstQuestionAsked = createSelector(selectChatGptMessages, (messages) => messages.length === 2);
export const selectAiAllQuestionAsked = createSelector(selectChatGptMessages, (messages) => messages.length > 2);
export const selectAiClarificationNeeded = createSelector(selectIncidents, ({aiClarificationNeeded}) => aiClarificationNeeded);
export const selectAiInteractionFinished = createSelector(selectIncidents, ({aiInteractionFinished}) => aiInteractionFinished);
export const selectChatGptAnswerLoading = createSelector(selectIncidents, ({chatGptAnswerLoading}) => chatGptAnswerLoading);
export const selectChangesHistory = createSelector(selectIncidents, ({changesHistory}) => changesHistory);
export const selectIsEvaluationPopupVisible = createSelector(selectIncidents, ({isEvaluationPopupVisible}) => isEvaluationPopupVisible);
export const selectEvaluationPopupModel = createSelector(selectIncidents, ({evaluationPopupModel}) => evaluationPopupModel);
// todo change naming from area to hole to avoid confusion
export const selectIncidentCalculatedArea = createSelector(selectIncidentArea, selectIncidentElementaryObject, selectUserSettings, (area, elementaryObject, {measurementSystem}): string | undefined => {
	return calculateAreaForTaskOrIncident(measurementSystem!, elementaryObject, area);
});
export const selectStreamedChatMessage = createSelector(selectIncidents, ({streamedChatMessage}) => streamedChatMessage);
