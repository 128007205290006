import {inject, Injectable} from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {catchError, exhaustMap, filter, map, of, tap, withLatestFrom} from "rxjs";
import { TaskService } from "../task.service";
import {taskActions, taskBoardActions, taskCalendarActions, taskListActions} from "./actions";
import { AgroMessageService } from "src/app/shared/services/agro-message.service";
import { TaskDialogService } from "../task-dialog.service";
import { ProjectService, getProjectWithDetailsResponse } from "src/app/project/services/project.service";
import {
	selectCurrentTaskListFilter,
	selectEditedTask,
	selectSelectedIncidents,
	selectTasksPresentationMode
} from "./selectors";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import {
	organizationIdSelector,
	selectedProjectIdSelector
} from "src/app/project/project-store/selectors";
import {selectUserFullName} from "../../profile/store";
import { Router } from "@angular/router";
import {LoadPanelService} from "../../shared/services/load-panel.service";
import {taskStatus} from "../models/TaskStatus";
import {TaskPresentationMode} from "./taskState";
import {LogsApiService} from "../../shared/services/logs.api.service";
import {getAvailableTaskUsersResponse} from "../models/GetAvailableTaskUsersResponse";
import {commonActions} from "../../shared/store/common/actions";

@Injectable()
export class TasksEffects {
	private actions$ = inject(Actions);
	private taskService = inject(TaskService);
	private taskDialogService = inject(TaskDialogService);
	private messageService = inject(AgroMessageService);
	private projectService = inject(ProjectService);
	private store = inject(Store<AppState>);
	private loadPanelService = inject(LoadPanelService);
	private router = inject(Router);
	private logsApiService = inject(LogsApiService);

    addTask$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.addTask),
			withLatestFrom(this.store.select(selectedProjectIdSelector)),
			exhaustMap(([addCommand, projectId]) => {
				return this.taskService.addTask(addCommand, projectId!).pipe(
					map(response => {
						if (response.success) {
							return taskActions.addTaskSuccess()
						}
						return commonActions.handleError({ errorMessage: response.error });
					}),
					catchError(error => of(commonActions.handleError(error)))
				);
			})
    ));

    updateTask$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.updateTask),
				withLatestFrom(this.store.select(selectEditedTask)),
        exhaustMap(([updateCommand, editedTask]) => {
					const currentDueDateTime = editedTask!.dueDate && new Date(editedTask!.dueDate).getTime();
					const editedDueDateTime = updateCommand!.dueDate && new Date(updateCommand!.dueDate).getTime();

					if (currentDueDateTime !== editedDueDateTime) {
						if (editedTask!.state === taskStatus.Scheduled) {
							if (!editedTask!.canReschedule) {
								return of(commonActions.handleError({errorMessage: `Cannot reschedule task with state ${editedTask!.state}`}))
							}
						} else if(!editedTask?.canSchedule) {
							return of(commonActions.handleError({errorMessage: `Cannot schedule task with state ${editedTask!.state}`}))
						}
					}

					return this.taskService.updateTask(updateCommand).pipe(
							map(response => {
								if (response.success) {
									return taskActions.updateTaskSuccess();
								}
								return commonActions.handleError({ errorMessage: response.error });
							}),
							catchError(error => of(commonActions.handleError(error)))
					);
        })
    ));

		addTaskSuccess$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.addTaskSuccess),
			withLatestFrom(this.store.select(selectedProjectIdSelector)),
			tap(([_, projectId]) => {
				void this.router.navigate(['/tasks', projectId]).then(() => {
					this.messageService.displaySuccessMessage('', 'Task was created successfully');
				})
			})
		), {dispatch: false});

		updateTaskSuccess$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.updateTaskSuccess),
			withLatestFrom(this.store.select(selectedProjectIdSelector)),
			tap(([_, projectId]) => {
				void this.router.navigate(['/tasks', projectId]).then(() => {
					this.messageService.displaySuccessMessage('', 'Task was updated successfully');
				})
			})
		), {dispatch: false});

    deleteTask$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.deleteTask),
        exhaustMap(({ taskId }) => {
            return this.taskService.deleteTask(taskId).pipe(
                map(response => {
                    if (response.success) {
                        return taskActions.refreshTasks();
                    }
                    return commonActions.handleError({ errorMessage: response.error });
                }),
                catchError(error => of(commonActions.handleError(error)))
            )
        })
    ));

    handleError$ = createEffect(() => this.actions$.pipe(
        ofType(commonActions.handleError),
        tap(({ errorMessage }) => {
            if (errorMessage)
                this.messageService.displayErrorMessage("Error", errorMessage);
        })
    ), { dispatch: false });

	refreshTasks$ = createEffect(() => this.actions$.pipe(
		ofType(taskActions.refreshTasks),
		withLatestFrom(this.store.select(selectCurrentTaskListFilter)),
		filter(([_, loadPars]) => loadPars !== undefined),
		map(([_, loadParameters]) => {
			return taskActions.loadTasks(loadParameters!);
		})
	));

	loadTasks$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.loadTasks),
			withLatestFrom(
				this.store.select(organizationIdSelector),
				this.store.select(selectedProjectIdSelector)
			),
			exhaustMap(([loadParameters, organizationId, projectId]) =>
				this.taskService.getTaskList(loadParameters, organizationId!, projectId!).pipe(
					map(response => {
						if (response.success) {
							const {tasks, count} = response;
							return taskActions.loadTasksSuccess({tasks, totalRecords: count});
						}

						return commonActions.handleError({errorMessage: response.error});
					}),
					catchError(error => of(commonActions.handleError(error)))
				)
			)
    ));

    loadTaskTypes$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.loadTaskTypes),
        exhaustMap(() => {
            return this.taskService.getTaskTypes().pipe(
				map(response => {
                	if (response.success) {
                    	return taskActions.loadTaskTypesSuccess({taskTypes: response.items});
                    }

					return commonActions.handleError({ errorMessage: response.error });
				}),
                catchError(error => of(commonActions.handleError(error)))
			)
        })
    ));

	getAvailableTaskUsers$ = createEffect(() => this.actions$.pipe(
		ofType(taskActions.getAvailableTaskUsers),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([_, projectId]) => {
            return this.taskService.getAvailableTaskUsers(projectId!).pipe(
				map((response: getAvailableTaskUsersResponse) => {
					if (response.success) {
						return taskActions.getAvailableTaskUsersSuccess({availableUsers: response.users});
                    }

					return commonActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(commonActions.handleError(error)))
			)
        })
	));

    loadAreas$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.loadAreas),
        withLatestFrom(this.store.select(selectedProjectIdSelector)),
        exhaustMap(([_, projectId]) => {
            return this.projectService.getActualProjectAreasAndElementaryObjectsDetails(projectId!).pipe(
				map((response: getProjectWithDetailsResponse) => {
					if (response.success) {
						return taskActions.loadAreasSuccess({areas: response.project.areas || []});
                    }

					return commonActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(commonActions.handleError(error)))
			)
        })
    ));

    showSchedulePopup$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.showSchedulePopup),
        tap(({ taskId }) => {
            this.taskDialogService.showTaskScheduleDialog(taskId);
        })
    ), { dispatch: false });

    hideSchedulePopup$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.hideSchedulePopup),
        tap(({ saveExecuted }) => {
            this.taskDialogService.hideTaskScheduleDialog(saveExecuted);
        })
    ), { dispatch: false });

    scheduleTask$ = createEffect(() => this.actions$.pipe(
        ofType(taskActions.scheduleTask),
        exhaustMap(scheduleCommand => {
            return this.taskService.scheduleTask(scheduleCommand).pipe(
                map(response => {
                    if (response.success) {
                        return taskActions.hideSchedulePopup({ saveExecuted: true });
                    }
                    return commonActions.handleError({ errorMessage: response.error });
                }),
                catchError(error => of(commonActions.handleError(error)))
            );
        })
    ));

		getTaskById$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.getTaskById),
			exhaustMap(({taskId}) =>
				this.taskService.getTaskById(taskId).pipe(
					map(task => taskActions.getTaskByIdSuccess({task})),
					catchError(error => of(commonActions.handleError(error)))
				)
			)
		))

		addComment$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.addComment),
			withLatestFrom(this.store.select(selectUserFullName)),
			exhaustMap(([{taskId, comment}, userFullName]) =>
				this.taskService.addComment(taskId, {comment}).pipe(
					map((response) => {
						if (response.success) {
							return taskActions.addCommentSuccess({comment, author: userFullName});
						}

						return commonActions.handleError({ errorMessage: response.error });
					}),
					catchError(error => of(commonActions.handleError(error)))
				)
			)
		));

		initPresentationPage$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.initPresentationPage),
			withLatestFrom(this.store.select(selectTasksPresentationMode)),
			map(([_, presentationMode]) => {
				if (presentationMode === TaskPresentationMode.Kannbann) {
					return taskBoardActions.getTaskBoardTasks();
				}

				if (presentationMode === TaskPresentationMode.Calendar) {
					return taskCalendarActions.getTaskCalendarTasks();
				}

				return taskListActions.loadTaskList({});
			})
		));

		getChangesHistory$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.getChangesHistory),
			exhaustMap(({taskId}) =>
				this.logsApiService.getTaskLogs(taskId!).pipe(
					map(changesHistory => taskActions.getChangesHistorySuccess({changesHistory})),
					catchError(error => of(commonActions.handleError(error)))
				)
			)
		));

		showLoadingPanel$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.getTaskById, taskActions.loadAreas),
			tap(() => this.loadPanelService.showLoadPanel())
		), {dispatch: false});

		hideLoadingPanel$ = createEffect(() => this.actions$.pipe(
			ofType(taskActions.getTaskByIdSuccess, commonActions.handleError, taskActions.loadAreasSuccess),
			tap(() => this.loadPanelService.hideLoadPanel())
		), {dispatch: false});
}
