import {environment} from "../../../../environments/environment";
import {diseaseFilteredPropNames, paramsFilteredPropNames, vegetationFilteredPropNames, deviationsFilteredPropNames} from "../../store/grass-params/constants";

export enum OtherObjectLayer {
  ObservationPoints = "incidents",
  SprinklersFill = "sprinklers-fill",
  SprinklersLine = "sprinklers-line",
  LaboratoryExaminations = 'laboratoryexaminations'
}

export enum OtherObjectDataSource {
  ObservationPoints = 'incidentsdatasource',
  Sprinklers = 'sprinklersdatasource',
  LaboratoryExaminations = 'laboratoryexaminationsdatasource'
}

export enum OtherObjectType {
	'LaboratoryExamination'= 'LaboratoryExamination',
	'Incident' = 'Incident',
	'Sprinkler' = 'Sprinkler'
}

export enum GrassParametersLayer {
  HexGridParametersFillLayerLowZoom = "hexGridParametersFillLayerLowZoom",
  HexGridParametersFillLayerHighZoom = "hexGridParametersFillLayerHighZoom",
  HexGridParametersBordersLowZoom = "hexGridBordersLowZoom",
  HexGridParametersBordersHighZoom = "hexGridBordersHighZoom",

  HexGridDiseasesFillLayerLowZoom = "hexGridDiseasesFillLayerLowZoom",
  HexGridDiseasesFillLayerHighZoom = "hexGridDiseasesFillLayerHighZoom",
  HexGridDiseasesBordersLowZoom = "hexGridDiseasesBordersLowZoom",
  HexGridDiseasesBordersHighZoom = "hexGridDiseasesBordersHighZoom",

  HexGridVegetationFillLayerLowZoom = "hexGridVegetationFillLayerLowZoom",
  HexGridVegetationFillLayerHighZoom = "hexGridVegetationFillLayerHighZoom",
  HexGridVegetationBordersLowZoom = "hexGridVegetationBordersLowZoom",
  HexGridVegetationBordersHighZoom = "hexGridVegetationBordersHighZoom",

  HexGridDeviationsFillLayerLowZoom = "hexGridDeviationsFillLayerLowZoom",
  HexGridDeviationsFillLayerHighZoom = "hexGridDeviationsFillLayerHighZoom",
  HexGridDeviationsBordersLowZoom = "hexGridDeviationsBordersLowZoom",
  HexGridDeviationsBordersHighZoom = "hexGridDeviationsBordersHighZoom",
}

export enum GrassParametersDataSource {
	ParametersLowZoom = "hexGridParametersLowZoomDataSource",
	ParametersHighZoom = "hexGridParametersHighZoomDataSource",
	DiseasesLowZoom = "hexGridDiseasesLowZoomDataSource",
	DiseasesHighZoom = "hexGridDiseasesHighZoomDataSource",
	VegetationLowZoom = "hexGridVegetationLowZoomDataSource",
	VegetationHighZoom = "hexGridVegetationHighZoomDataSource",
	DeviationsLowZoom = "hexGridDeviationsLowZoomDataSource",
	DeviationsHighZoom = "hexGridDeviationsHighZoomDataSource",
}

export enum HexesDataSourceType {
  Parameters = 'parameters',
  Diseases = 'diseases',
  Vegetation = 'vegetation-indexes',
  Deviations = 'parameters-deviations',
}

export const parametersFillLayers: GrassParametersLayer[] = [GrassParametersLayer.HexGridParametersFillLayerLowZoom, GrassParametersLayer.HexGridParametersFillLayerHighZoom];
export const diseasesFillLayers: GrassParametersLayer[] = [GrassParametersLayer.HexGridDiseasesFillLayerLowZoom, GrassParametersLayer.HexGridDiseasesFillLayerHighZoom];
export const vegetationFillLayers: GrassParametersLayer[] = [GrassParametersLayer.HexGridVegetationFillLayerLowZoom, GrassParametersLayer.HexGridVegetationFillLayerHighZoom];
export const deviationsFillLayers: GrassParametersLayer[] = [GrassParametersLayer.HexGridDeviationsFillLayerLowZoom, GrassParametersLayer.HexGridDeviationsFillLayerHighZoom];

export enum TilesDataSourceType {
	HighResolutionRgb = 'HighResolutionRgb'
}

export enum BackgroundTilesDataSource {
	HighResolutionRgb = 'highResolutionBackgroundTilesSource'
}

export enum BackgroundTilesDataLayer {
	HighResolution = 'highBackgroundTilesDataLayer'
}

export const LoaderLayerId = 'loader-overlay'

export const MapboxSatelliteLayerId = 'satellite'
