import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {AppState} from "../../types/app-state";
import {createSelector, Store} from "@ngrx/store";
import * as routingSelectors from "../../routing-store/selectors";
import {AuthService} from "../../shared/services/auth.service";
import { Message } from 'primeng/api';

@Component({
  selector: 'app-nav',
  template:
  `
		<div id="min-resolution-message">
  			<p-messages [(value)]="messages" [enableService]="false"></p-messages>
		</div>
		<ng-container *ngIf="vm$ | async as vm">
			<div class="app-container" [ngClass]="{'app-container-full': vm.loggedIn, 'menu-expanded': vm.sidebarExpanded}">
				<div class="app-nav-horizontal" *ngIf="vm.loggedIn">
					<app-nav-logo-box />
					<div class="app-nav-horizontal-content h-100">
						<div class="box overflow-hidden">
							<app-nav-selected-project class="overflow-hidden"/>
						</div>
						<div class="box center">
							<app-nav-choose-organization />
						</div>
						<div class="box">
							<app-nav-user-panel class="h-100" />
						</div>
					</div>
				</div>
				<div class="app-container-inner" [ngClass]="{'sidebar-visible': vm.loggedIn}">
					<div class="app-nav" *ngIf="vm.loggedIn">
						<app-nav-sidebar />
					</div>
					<router-outlet></router-outlet>
					<div>
						<ul class="safety-links app-content" *ngIf="vm.loggedIn">
							<li><a href="">{{ 'COMMON.TERMS_OF_USE' | translate }}</a></li>
							<li><a href="">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a></li>
						</ul>
						<app-help-context class="skim-help" />
					</div>
				</div>
			</div>
		</ng-container>
  `,
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent {
  private authService = inject(AuthService);
  private store = inject(Store<AppState>);
  messages: Message[] = [{ severity: 'warn', summary: '', detail: 'Page requires minimum 1280px resolution' }];

  user: string = this.authService.getUserEmail() || '';
	readonly vm$ = this.store.select(navComponentVmSelector);
}

interface navComponentVm {
	loggedIn: boolean;
	sidebarExpanded: boolean;
}

const navComponentVmSelector = createSelector(routingSelectors.loggedInSelector, routingSelectors.sidebarExpandedSelector, (loggedIn, sidebarExpanded) : navComponentVm => ({loggedIn, sidebarExpanded}));
