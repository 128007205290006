export const areDatesEqual = (date1: Date, date2: Date) => date1.getTime() === date2.getTime()

export const isDateAvailable = (date: Date, dates: Date[]) => dates.some(x => x.getTime() === date.getTime())

export const parseDateToString = (dateToParse: Date) => {
  const date = new Date(dateToParse);

  const year = date!.getFullYear();
  const month = String(date!.getMonth() + 1).padStart(2, '0');
  const day = String(date!.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const isDateToday = (date: Date) => {
	const today = new Date();
	return today.getFullYear() === date.getFullYear() && today.getDate() === date.getDate() && today.getMonth() === date.getMonth()
}
