import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'localizedDate',
	pure: false
})
export class LocalizedDatePipe implements PipeTransform {
	private translate = inject(TranslateService);
	private datePipe = inject(DatePipe);

  transform(value: Date, pattern: string = 'mediumDate'): string {
    return this.datePipe.transform(value, pattern, undefined, this.translate.currentLang) || '';
  }
}
