import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {changeUnitProps, laboratoryExaminationListVm, mineralIngredientUnit, selectedParameterVm} from "../types";
import {Subject, takeUntil} from "rxjs";
import {availableUnits} from "../constants";
import { grassQualityParameterNames } from 'src/app/grass-parameters-presentation/constants/config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-laboratory-examination-parameters-form',
  template: `
    <div class="laboratory-examination-popup-parameters-step-wrapper">
      <section class="laboratory-examination-popup-chemical-parameters">
        <p-listbox [filter]="true" [listStyle]="{ 'max-height': '250px' }" [options]="parameters"  optionLabel="displayName" optionValue="name"
                   (onClick)="selectParameter($event)">
          <ng-template let-item pTemplate="item">
            <div class="laboratory-examination-row">
              <p class="laboratory-examination-row-param-name">{{getParamTranslationName(item)}}</p>
              <div class="laboratory-examination-row-param-values">
                <p style="color: #01B072" *ngIf="item.Average">{{item.Average}} {{item.unit}}</p>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </section>
      <section *ngIf="selectedParameter.isFormVisible" class="laboratory-examination-popup-parameters-form">
        <form [formGroup]="form" class="agro-form">
					<div class="form-group gap-30">
						<div class="input-box secondary p-input-secondary label-top input-box-component-full">
							<label>{{selectedParameter.averageLabel}}</label>
							<p-inputNumber mode="decimal" [min]="0" [minFractionDigits]="1" [maxFractionDigits]="2"
                             [suffix]="selectedParameter.unit || ''" [formControl]="averageControl"></p-inputNumber>
						</div>
						<div class="input-box secondary p-input-secondary label-top">
							<label>{{ 'GRASS_PARAMETERS.LAB_EXAM.UNIT' | translate }}</label>
							<p-dropdown [options]="availableUnits" [formControl]="unitControl" appendTo="body" />
						</div>
						<div class="input-box p-input-secondary label-top">
							<label>{{selectedParameter.deviationLabel}}</label>
							<p *ngIf="selectedParameter.deviation" [style]="{'color': selectedParameter.deviationColor}">{{selectedParameter.deviation}}</p>
						</div>
					</div>
        </form>
      </section>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaboratoryExaminationParametersFormComponent implements OnInit, OnDestroy {
  private translate = inject(TranslateService);

  ngOnInit(): void {
    this.averageControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.examinationValueUpdated.emit(value);
    });

		this.unitControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(unit => {
			this.unitChanged.emit({unit: unit!, form: this.form});
		})
  }

  private formBuilder = inject(FormBuilder);
  private destroy$ = new Subject<void>();

  @Input() parameters: laboratoryExaminationListVm[] = [];
  @Input() selectedParameter!: selectedParameterVm;

  @Output() parameterSelected = new EventEmitter<selectLaboratoryExaminationParameterEvent>();
  @Output() examinationValueUpdated = new EventEmitter<number | null>();
	@Output() unitChanged = new EventEmitter<changeUnitProps>();

  averageControl: FormControl<number | null> = this.formBuilder.control({value: null, disabled: false});
	unitControl: FormControl<mineralIngredientUnit | null> = this.formBuilder.control({value: null, disabled: true})

  form = this.formBuilder.group({
    average: this.averageControl,
		unit: this.unitControl
  });

  selectParameter = (event: any) => {
    const selectedParameter = event.value;
    const form = this.form;
    this.parameterSelected.emit({selectedParameter, form});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

	protected readonly availableUnits = availableUnits;

  getParamTranslationName(parameter: laboratoryExaminationListVm): string {
    const qualityParName = grassQualityParameterNames.find(x => x.name === parameter.name);
    if (qualityParName)
      return this.translate.instant(qualityParName.translationKey);
    return parameter.displayName;
  }
}

export interface selectLaboratoryExaminationParameterEvent {
  selectedParameter: string;
  form: FormGroup;
}
