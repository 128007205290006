import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from "@angular/core";
import { AttachmentDTO } from "../../model/attachmentDTO";
import { Observable, Subject, take, takeUntil, throttleTime } from "rxjs";
import { UploadFileService } from "src/app/project/services/upload-file.service";
import { AgroMessageService } from "../../services/agro-message.service";
import { FileUpload } from "primeng/fileupload";
import { ConfirmationService } from "primeng/api";
import { Store, select } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { attachmentActions } from "../../store/attachment/actions";
import { selectorAttachments } from "../../store/attachment/selectors";
import {FeatureName} from "../../../admin/types";

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss'],
})
export class AttachmentsComponent implements OnInit, OnDestroy {
    private store = inject(Store<AppState>);
    private uploadFileService = inject(UploadFileService);
    attachments$: Observable<AttachmentDTO[]> = this.store.pipe(select(selectorAttachments));
    readonly progressValue$: Observable<number> = this.uploadFileService.getFileUploadProgress().pipe(throttleTime(100));
    private messageService = inject(AgroMessageService);
    private destroy$ = new Subject<void>();
    private confirmationService = inject(ConfirmationService);
    @Input() urlBeginning!: string;
    @Input() objectId!: string;
    @ViewChild('attachmentUpload') attachmentUpload!: FileUpload;

    ngOnInit(): void {
        this.store.dispatch(attachmentActions.loadAttachments({ urlBeginning: this.urlBeginning, objectId: this.objectId}));
        this.observeUploadResult();
    }

    ngOnDestroy(): void {
        this.store.dispatch(attachmentActions.reset());
        this.destroy$.next();
    }

    private observeUploadResult = () => {
        this.uploadFileService.getUploadResult()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (uploadSuccessful: Boolean) => {
                    if (uploadSuccessful) {
                        this.attachmentUpload.clear();
                        this.messageService.displaySuccessMessage("Success", "File was uploaded successfully");
                        this.store.dispatch(attachmentActions.refreshAttachments());
                    }
                }
            });
    }

    uploadAttachmentFile = (event: any, currentAttachments: AttachmentDTO[]) => {
        const file = event.files[0] as File;

				const uploadedFileHasUniqueName = !currentAttachments.some(attachment => attachment.fileName === file.name);

				if (!uploadedFileHasUniqueName) {
					this.messageService.displayErrorMessage("", `The file "${file.name}" has already been uploaded`);
					return;
				}

        const uploadUrl = `${this.urlBeginning}/${this.objectId}/attachments/upload`;
        const completeUploadUrl = `${this.urlBeginning}/${this.objectId}/attachments/completeUpload`;
        this.uploadFileService.uploadFile(file, -1, -1, new Date(), '',
            uploadUrl, completeUploadUrl);
    }

    onAttachmentDelete(attachment: AttachmentDTO) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            message: 'Are you sure to delete this attachment?',
            // icon: 'pi pi-exclamation-triangle',
						acceptButtonStyleClass: 'p-button-success hide-icon',
						rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
            accept: () => this.store.dispatch(attachmentActions.deleteAttachment({ attachmentId: attachment.id })),
            reject: () => this.confirmationService.close()
        });
    }

    onOpenAttachment(event: any, attachment: AttachmentDTO) {
        event.preventDefault();
        this.store.dispatch(attachmentActions.getAttachmentUrl({ attachmentId: attachment.id }));
    }

    protected readonly FeatureName = FeatureName;
}
