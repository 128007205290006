<div class="one-atlas-download-container">
    <form class="agro-form" [formGroup]="downloadFileForm">
      <div class="agro-form-property">
        <label>Choose a day from the available ones</label>
        <div class="agro-form-control">
          <p-calendar [disabledDates]="disabledDates"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      (onMonthChange)="onMonthChange($event)"
                      (onSelect)="onDateSelect($event)"
                      [readonlyInput]="true"
                      [showIcon]="true"
                      [inline]="true"
                      formControlName="date">
          </p-calendar>
        </div>
      </div>
    </form>
  </div>
