import {ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../types/app-state";
import {ItemsPopupService} from "../../../services/items-popup.service";
import {MapboxMoveItemsService} from "../../../services/mapbox-move-items.service";
import {LngLat} from "mapbox-gl";
import {laboratoryExaminationActions, mapIncidentActions, sprinklersActions} from "../../../store/other-objects/actions";
import {GrassParametersMapsService} from "../../../services/grass-parameters-maps.service";
import {point} from "../../../../types/point";
import {MenuItem} from "primeng/api";
import {MapboxUtils} from "../../../../shared/utils/mapbox-utils";
import { TranslateService } from '@ngx-translate/core';
import { FeatureName } from 'src/app/admin/types';
import {combineLatest, startWith, switchMap, Observable, map} from 'rxjs';
import { isAccessToProjectFeaturePermitted } from 'src/app/shared/utils/feature-authorization-utils';
import { contextMenuPermissionsSelector } from 'src/app/grass-parameters-presentation/store/other-objects/selectors';
import { PresentationMode } from 'src/app/grass-parameters-presentation/store/grass-params/types';
import { selectPresentationMode } from 'src/app/grass-parameters-presentation/store';
import { Router } from '@angular/router';
import {incidentActions} from "../../../../incident/store/actions";

@Component({
  template: `
    <p-menu *ngIf="menuItems$ | async as contextMenuItems" class="items-context-menu action-menu" [model]="contextMenuItems"></p-menu>
  `,
  styles: [
    `
      .items-context-menu .p-menu {
        border: none;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContextMenuPopupComponent {
  private store = inject(Store<AppState>);
  private translate = inject(TranslateService);

  private itemsPopupService = inject(ItemsPopupService);
  private moveService = inject(MapboxMoveItemsService);
  private mapsService = inject(GrassParametersMapsService);
  private router = inject(Router);

  @Input() itemType?: string;
  @Input() itemId?: number;
  @Input() lngLat?: LngLat;
  @Input() mapId?: string;

	private readonly addPointKey = "GRASS_PARAMETERS.CONTEXT_MENU.ADD_POINT";
	private readonly addSprinklerKey = "GRASS_PARAMETERS.CONTEXT_MENU.ADD_SPRINKLER";
	private readonly addLabExamKey = "GRASS_PARAMETERS.CONTEXT_MENU.ADD_LAB_EXAM";
	private readonly editPointKey = "GRASS_PARAMETERS.CONTEXT_MENU.EDIT_POINT";
	private readonly editSprinklerKey = "GRASS_PARAMETERS.CONTEXT_MENU.EDIT_SPRINKLER";
	private readonly editLabExamKey = "GRASS_PARAMETERS.CONTEXT_MENU.EDIT_LAB_EXAM";
	private readonly moveLabExamKey = "GRASS_PARAMETERS.CONTEXT_MENU.MOVE_LAB_EXAM";
	private readonly removePointKey = "GRASS_PARAMETERS.CONTEXT_MENU.REMOVE_POINT";
	private readonly removeSprinklerKey = "GRASS_PARAMETERS.CONTEXT_MENU.REMOVE_SPRINKLER";
	private readonly removeLabExamKey = "GRASS_PARAMETERS.CONTEXT_MENU.REMOVE_LAB_EXAM";

	private readonly contextMenuTranslationKeys = [
		this.addPointKey,
		this.addSprinklerKey,
		this.addLabExamKey,
		this.editPointKey,
		this.editSprinklerKey,
		this.editLabExamKey,
    	this.moveLabExamKey,
		this.removeLabExamKey,
		this.removePointKey,
		this.removeSprinklerKey,
		this.removeLabExamKey
	];

	menuItems$: Observable<MenuItem[]> = combineLatest([
		this.store.select(selectPresentationMode),
		this.store.select(contextMenuPermissionsSelector),
		this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(this.contextMenuTranslationKeys))
		)
	]).pipe(
		map(([presentationMode, { organizationId, projectId, userFeatures }, translations]) => {
			if (!isAccessToProjectFeaturePermitted(projectId!, organizationId!, FeatureName.ManageMapItems, userFeatures)) {
				return [];
			}

			const areNoItemsSelected: boolean = !this.itemId;
			const isOtherObjectsSelected: boolean = presentationMode === PresentationMode.OtherObjects;

			const isWorkItemSelected: boolean = this.itemId != null && this.itemType === 'Incident';
			const isSprinklerSelected: boolean = this.itemId != null && this.itemType === 'Sprinkler';
			const isLaboratoryExaminationSelected: boolean = this.itemId !== null && this.itemType === 'LaboratoryExamination';

			return [
				{
					label: translations[this.addPointKey],
					icon: 'pi pi-exclamation-circle',
					command: () => {
						this.hidePopup();
						this.itemsPopupService.showAddIncidentPopup({ lat: this.lngLat!.lat, lng: this.lngLat!.lng })
					},
					visible: areNoItemsSelected
				},
				{
					label: translations[this.addSprinklerKey],
					icon: 'pi pi-circle',
					command: () => {
						const gps: point = MapboxUtils.lngLatToPoint(this.lngLat!);
						this.itemsPopupService.showAddSprinklerPopup({ gps: gps });
						this.hidePopup();
					},
					visible: areNoItemsSelected
				},
				{
					label: translations[this.addLabExamKey],
					icon: 'pi pi-info-circle',
					command: () => {
						const gps: point = MapboxUtils.lngLatToPoint(this.lngLat!);
						this.itemsPopupService.showAddLaboratoryExaminationPopup({ gps });
						this.hidePopup();
					},
					visible: areNoItemsSelected
				},
				{
					label: translations[this.editPointKey],
					icon: 'pi pi-pencil',
					command: () => {
						this.itemsPopupService.showEditIncidentPopup({incidentId: this.itemId!});
						this.hidePopup();
					},
					visible: isWorkItemSelected && isOtherObjectsSelected
				},
				{
					label: translations[this.editSprinklerKey],
					icon: 'pi pi-pencil',
					command: () => {
						this.store.dispatch(sprinklersActions.showEditSprinklerPopup({ sprinklerId: this.itemId! }));
						this.hidePopup();
					},
					visible: isSprinklerSelected
				},
				{
					label: translations[this.editLabExamKey],
					icon: 'pi pi-pencil',
					command: () => {
						this.itemsPopupService.showEditLaboratoryExaminationPopup({editedItemId: this.itemId!});
						this.hidePopup();
					},
					visible: isLaboratoryExaminationSelected && isOtherObjectsSelected
				},
				{
					label: translations[this.moveLabExamKey],
					icon: 'pi pi-arrows-alt',
					command: () => {
						this.moveService.moveLaboratoryExamination(this.lngLat!, this.mapId!, this.itemId!);
						this.hidePopup();
					},
					visible: isLaboratoryExaminationSelected && isOtherObjectsSelected
				},
				{
					label: translations[this.removePointKey],
					icon: 'pi pi-trash',
					command: () => {
						this.store.dispatch(incidentActions.deleteIncident( { incidentId: this.itemId!.toString(), deletedFromPopup: true }));
						this.hidePopup();
					},
					visible: isWorkItemSelected && isOtherObjectsSelected
				},
				{
					label: translations[this.removeSprinklerKey],
					icon: 'pi pi-trash',
					command: () => {
						this.store.dispatch(sprinklersActions.removeSprinkler({ sprinklerId: this.itemId! }));
						this.hidePopup();
					},
					visible: isSprinklerSelected
				},
				{
					label: translations[this.removeLabExamKey],
					icon: 'pi pi-trash',
					command: () => {
						this.store.dispatch(laboratoryExaminationActions.remove({id: this.itemId!}));
						this.hidePopup();
					},
					visible: isLaboratoryExaminationSelected && isOtherObjectsSelected
				}
			]
		})
	)

  private hidePopup = () => this.mapsService.hideContextMenu(this.mapId!);
}
