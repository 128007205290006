import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from "@angular/core";
import {AppState} from "../../../../types/app-state";
import {createSelector, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {parameterTrend} from "../../../../parameter-trend-api.service";
import * as grassParamsSelectors from "../../../store/grass-params/selectors";
import {grassParamsActions} from "../../../store/grass-params/actions";
import {selectDebugMode} from "../../../store";

@Component({
  template: `
    <section *ngIf="vm$ | async as vm" class="parameters-trends-popup-wrapper app-off-event-pointer">
      <ng-container *ngIf="!vm.isLoading">
				<div [style]="{display: 'flex'}">
					<h5 class="parameters-trends-title">{{ 'GRASS_PARAMETERS.PARAMETER_VALUES' | translate }}</h5>
					<div class="debug-mode-box" *ngIf="vm.debugMode && parameterDebugModels.length > 0">
						<p>hexId: {{hexId}}</p>
						<p *ngFor="let param of parameterDebugModels">{{param.name | translateGrassParam}}: {{param.actualValue}}</p>
					</div>
				</div>
        <p-table [value]="vm.selectedParamsTrends">
          <ng-template pTemplate="body" let-trend>
            <tr class="parameters-trends-row">
              <td class="trend-param-name">{{trend.paramName | translateGrassParam}}</td>
              <td class="trend-values">
                <app-parameter-trend-section
                    [trendDownNorm]="trend.downBoundary"
                    [trendUpNorm]="trend.upBoundary"
                    [trendPercentageChange]="trend.percentageChange"
                    [trendValue]="trend.currentValue" />
              </td>
              <td>
                <app-parameter-trend-chart
                    [downBoundary]="trend.downBoundary"
                    [upBoundary]="trend.upBoundary"
                    [recentValues]="trend.recentValues" />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>
      <section style="display: flex; align-items: center; justify-content: center;" *ngIf="vm.isLoading">
        <p-progressSpinner animationDuration=".5s" />
      </section>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterTrendsPopupComponent implements OnInit {
	// todo refactor to use debug values from store (Same as app-selected-hex-information)

  private store = inject(Store<AppState>);
  vm$?: Observable<parameterTrendsPopupComponentVm>;

  @Input() hexId!: number;
  @Input() mapId!: string;
	@Input() parameterDebugModels: parameterDebugModel[] = [];

  ngOnInit(): void {
		this.vm$ = this.store.select(parameterTrendsPopupComponentVmSelector(this.mapId));
  }
}

export interface parameterDebugModel {
	name: string,
	actualValue?: number
}

interface parameterTrendsPopupComponentVm {
	isLoading: boolean,
	selectedParamsTrends: parameterTrend[],
	debugMode: boolean
}

const parameterTrendsPopupComponentVmSelector = (mapId: string) => createSelector(grassParamsSelectors.isTrendLoading(mapId), grassParamsSelectors.selectTrendsByHexId(mapId), selectDebugMode, (isLoading, selectedParamsTrends, debugMode): parameterTrendsPopupComponentVm => ({
	isLoading,
	selectedParamsTrends,
	debugMode
}));
