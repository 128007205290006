import {ItemDto} from "../../DTO/item-dto";
import {OtherObjectDataSource, OtherObjectLayer, OtherObjectType} from "../../models/mapbox/types";

export interface otherObjectsState {
  items: ItemDto[];
  editedItem?: ItemDto;
  otherObjectLayers: otherObjectLayerInterface[];
  isLoading: boolean;
	otherObjectMaps: otherObjectMapsDictionary
}

export interface otherObjectMapsDictionary {
	[mapId: string]: otherObjectMapState
}

export interface otherObjectMapState {
	drawItemsRequired: boolean
}

export interface otherObjectLayerInterface {
	type: OtherObjectType,
  displayName: string,
  dataSourceName: OtherObjectDataSource,
  layerNames: OtherObjectLayer[],
  selected: boolean,
  localizedTextKey: string,
}

export enum SprinklerType {
  PopUp = 1,
  Rotary = 2,
}
