import {inject, Injectable} from "@angular/core";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {baseResponse} from "../../../../../grass-parameters-presentation/DTO/base-response";

@Injectable({
	providedIn: 'root'
})
export class AskAiApiService {
	readonly baseUrl = `${environment.projectApiUrl}incidents`;
	private http = inject(HttpClient);

	getIncidentChatMessages = (incidentId: string): Observable<chatGtpMessage[]> => this.http.get<chatGtpMessage[]>(`${this.baseUrl}/${incidentId}/messages`);

	askDefaultQuestion = (incidentId: string): Observable<askAiResponse> => this.http.post<askAiResponse>(`${this.baseUrl}/${incidentId}/askAi`, {});

	askAi = (incidentId: string, question: string): Observable<askAiResponse> => this.http.post<askAiResponse>(`${this.baseUrl}/${incidentId}/askAI`, {question});
}

export interface chatGtpMessage {
	id?: string;
	message: string;
	postedBy: string;
	postedByUserId?: number;
	postedAt: Date;
	incidentId?: string;
}

export interface askAiResponse extends baseResponse {
	message: chatGtpMessage;
}
