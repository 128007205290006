<ul>
  <li *appCheckProjectFeaturePermission="FeatureName.ReadMapParamData"
    [routerLinkActive]="selectedProjectId$ ? 'active' : 'disabled'"
    [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <span class="sidebar-link" [routerLink]="['photoParameters', selectedProjectId$ | async]">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-dashboard.svg" />
      </div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.DASHBOARD' | translate }}</h6>
    </span>
  </li>
  <li routerLinkActive="active" *appCheckProjectFeaturePermission="FeatureName.ReadProjectReport"
    [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <span class="sidebar-link" [routerLink]="['project', 'report', selectedProjectId$ | async]">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-report.svg" />
      </div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.REPORTS' | translate }}</h6>
    </span>
  </li>
  <li *appCheckProjectFeaturePermission="FeatureName.FindIncidents" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
    [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <span class="sidebar-link" [routerLink]="['incidents', selectedProjectId$ | async]">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-alerts.svg" />
      </div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.ALERTS' | translate }}</h6>
    </span>
  </li>
  <li routerLinkActive="active" *appCheckProjectFeaturePermission="FeatureName.FindTasks"
    [routerLinkActiveOptions]="{exact: true}" [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <span class="sidebar-link" [routerLink]="['tasks', selectedProjectId$ | async]">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-tasks.svg" />
			</div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.TASKS' | translate }}</h6>
    </span>
  </li>
    <li *appCheckProjectFeaturePermission="FeatureName.ReadGreenSpeedExaminations" routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}" [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <span class="sidebar-link" [routerLink]="['examinations', selectedProjectId$ | async]">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-examinations.svg" />
			</div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.EXAMINATIONS' | translate }}</h6>
    </span>
  </li>
	<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
		<span class="sidebar-link" [routerLink]="['golf-club-members', selectedProjectId$ | async]">
			<div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-club.svg" />
			</div>
			<h6 *ngIf="(sidebarExpanded$ | async)!">{{'SIDEBAR.CLUB_MEMBERS' | translate}}</h6>
		</span>
	</li>
  <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
    <span class="sidebar-link" [routerLink]="['project']">
      <div class="icon">
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-projects.svg" />
      </div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'SIDEBAR.PROJECTS' | translate }}</h6>
    </span>
  </li>
</ul>
<ul>
  <app-need-help></app-need-help>
  <!-- <li>
      <a class="link-rules-container" href="https://www.skimturf.com/privacy-policy" target="_blank">{{ 'COMMON.PRIVACY_POLICY' | translate }}</a>
  </li>
  <li>
      <a class="link-rules-container" href="https://www.skimturf.com/terms-of-use-skim" target="_blank">{{ 'COMMON.TERMS_AND_CONDITIONS' | translate }}</a>
  </li> -->

  <li routerLinkActive="active" class="with-border" *appCheckProjectFeaturePermission="FeatureName.ReadUploadPhotoData"
    [ngClass]="(selectedProjectId$ | async) ? '' : 'menu-disabled'">
    <p>{{ 'COMMON.IMAGES' | translate }}</p>
    <span class="sidebar-link static-width" [routerLink]="['project', 'uploadPhotos', selectedProjectId$ | async]">
      <div class="absolute circle light-red with-icon top right">
        <div class="icon">
          <!-- sync.svg -->
          <svg-icon src="./assets/images/icons/nav-sidebar/nav-upload-photos-sync.svg" />
        </div>
      </div>
      <div class="icon">
        <!-- satellite.svg -->
        <svg-icon src="./assets/images/icons/nav-sidebar/nav-upload-photos-satellite.svg" />
      </div>
      <h6 *ngIf="(sidebarExpanded$ | async)!">{{ 'COMMON.IMAGES' | translate }}</h6>
    </span>
  </li>



</ul>
<!-- <div class="toggle-sidebar">
  <div class="nav-footer-logo" *ngIf="(sidebarExpanded$ | async)!">
<span>
  <img src="./assets/images/optidata-small-logo.svg" alt="">
  <i class="pi pi-angle-double-left pointer toggle-sidebar-expanded" (click)="toggleSidebar()"></i>
</span>
  </div>
  <div class="expand-sidebar-icon" *ngIf="!(sidebarExpanded$ | async)!">
    <i class="pi pi-angle-double-right pointer toggle-sidebar-hidden" (click)="toggleSidebar()"></i>
  </div>
</div> -->
