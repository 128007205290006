import {inject, Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {baseResponse} from "../grass-parameters-presentation/DTO/base-response";
import {feature} from "./types";

@Injectable({
  providedIn: "root"
})
export class AdminApiService {
	private readonly baseUrl = `${environment.projectApiUrl}authorization`;
	private http = inject(HttpClient);

	assignUserToRole = (organizationId: number, userId: number, roleId: number, projectId?: number): Observable<assignUserToRoleResponse> => {
		const endpoint = `${this.baseUrl}/organizations/${organizationId}/roles/${roleId}/users`;

		const requestBody: {userId: number, projectId?: number} = {
			userId,
			projectId
		};

		return this.http.post<assignUserToRoleResponse>(endpoint, requestBody);
	}

	unassignUserFromRole = (organizationId: number, userId: number, roleId: number): Observable<baseResponse> => {
		const endpoint = `${this.baseUrl}/organizations/${organizationId}/roles/${roleId}/users/${userId}`;
		return this.http.delete<baseResponse>(endpoint);
	}

	inviteUser = (invitedUserModel: userInviteModel, organizationId: number): Observable<baseResponse> => {
		const endpoint = `${this.baseUrl}/organizations/${organizationId}/users`;
		return this.http.post<baseResponse>(endpoint, invitedUserModel);
	}

	getAllFeatures = (): Observable<getAllFeaturesResponse> => this.http.get<getAllFeaturesResponse>(`${this.baseUrl}/features`);

	assignFeatureToOrganizationRole = (organizationId: number, roleId: number, featureId: number): Observable<baseResponse> => this.http.post<baseResponse>(`${this.baseUrl}/organizations/${organizationId}/roles/${roleId}/features/${featureId}`, {});

	unassignFeatureFromOrganizationRole = (organizationId: number, roleId: number, featureId: number): Observable<baseResponse> => this.http.delete<baseResponse>(`${this.baseUrl}/organizations/${organizationId}/roles/${roleId}/features/${featureId}`);
}

export interface assignUserToRoleResponse extends baseResponse {
	organizationUserRoleId: number;
}

export interface getAllFeaturesResponse extends baseResponse {
	features: feature[];
}

export interface userInviteModel {
	email: string;
	firstName?: string;
	lastName?: string;
	projectId?: number;
	roleId?: number;
}
