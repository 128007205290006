import {inject} from '@angular/core';
import { AuthService } from '../services/auth.service';
import {Observable, tap} from "rxjs";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import * as routingSelectors from "../../routing-store/selectors";

export const loggedGuard = ():Observable<boolean> => {
  const store = inject(Store<AppState>);
  const authService = inject(AuthService);
  return store.select(routingSelectors.loggedInSelector).pipe(
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        authService.logout();
      }
    })
  )
}
