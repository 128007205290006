import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {combineLatest, map, Observable, startWith, switchMap} from "rxjs";
import {IncidentPriority} from "../../models/IncidentPriority";
import {taskActions} from "../../../task/store/actions";
import {incidentActions} from "../../store/actions";
import {createSelector, Store} from "@ngrx/store";
import {AppState} from "../../../types/app-state";
import {manageProjectAreaDto} from "../../../project/DTO/manage-project-area-dto";
import {DiseaseDTO} from "../../models/DiseaseDTO";
import {ElementaryObject} from "../../../project/model/elementary-object";
import {selectIncidentArea, selectIncidentCalculatedArea, selectorDiseases} from "../../store/selectors";
import {selectAreas} from "../../../task/store/selectors";
import {areaInfoSelector} from "../../../project/project-store/selectors";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-add-incident-base',
  templateUrl: './add-incident-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIncidentBaseComponent implements OnInit {
	private translate = inject(TranslateService);
	protected store = inject(Store<AppState>);
	private formBuilder = inject(FormBuilder);
	readonly today = new Date();
	readonly vm$ = this.store.select(addIncidentComponentVmSelector);

	private readonly priorityYellowTranslationKey: string = 'INCIDENT.PRIORITY.YELLOW'
	private readonly priorityOrangeTranslationKey: string = 'INCIDENT.PRIORITY.ORANGE'
	private readonly priorityRedTranslationKey: string = 'INCIDENT.PRIORITY.RED'
	private readonly translationKeys: string[] = [this.priorityYellowTranslationKey, this.priorityOrangeTranslationKey, this.priorityRedTranslationKey];

	activeOptions$: Observable<{name: string | null, id: IncidentPriority | null}[]> = combineLatest([
		this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(this.translationKeys))
		)]
	).pipe(
		map(([translations]) => [
			{ name: translations[this.priorityYellowTranslationKey], id: IncidentPriority.Yellow },
			{ name: translations[this.priorityOrangeTranslationKey], id: IncidentPriority.Orange },
			{ name: translations[this.priorityRedTranslationKey], id: IncidentPriority.Red },
			{ name: '', id: null }
		])
	);

	form: FormGroup = this.formBuilder.group({
		name: [null as string | null | undefined],
		description: [''],
		diseaseId: [null as number | null | undefined],
		projectAreaId: [null as number | null | undefined, Validators.required],
		elementaryObjectId: [null as number | null | undefined, Validators.required],
		priority: [null as number | null | undefined]
	});

	isPopup: boolean = false;

	ngOnInit() {
		this.store.dispatch(taskActions.clearTask());
		this.store.dispatch(taskActions.loadAreas());
		this.store.dispatch(incidentActions.loadDiseases());
	}

	onProjectAreaChanged = (e: any) => this.store.dispatch(incidentActions.changeIncidentArea({areaId: e.value}));

	onElementaryObjectChanged = (e: any) => this.store.dispatch(incidentActions.changeIncidentElementaryObject({elementaryObjectId: e.value}));

	submit = () => {}
}

interface addIncidentComponentVm {
	areas: manageProjectAreaDto[],
	diseases: DiseaseDTO[],
	elementaryObjects: ElementaryObject[],
	calculatedArea?: string
}

export const addIncidentComponentVmSelector = createSelector(selectorDiseases, selectAreas, selectIncidentArea, selectIncidentCalculatedArea, areaInfoSelector, (diseases, areas, incidentArea, calculatedArea): addIncidentComponentVm => {
	const elementaryObjects = incidentArea?.elementaryObjects ?? [];
	return {diseases, areas, elementaryObjects, calculatedArea};
})
