export const getWindDirectionDisplayName = (windDirection?: number) => {
  if (windDirection === undefined || windDirection < 0 || windDirection > 360) {
    return 'Invalid';
  }

  const index = Math.floor((windDirection + 11.25) % 360 / 22.5);
  const directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];

  return directions[index];
}

export const convertCelsiusToFahrenheit = (temperatureInC: number) => parseFloat((temperatureInC * 1.8 + 32).toFixed(1));
export const convertFahrenheitToCelsius = (temperatureInF: number) => parseFloat(((temperatureInF - 32) * 5 / 9).toFixed(1));
export const convertKmPerHourToMPerHour = (windInKmPerHour: number) => parseFloat((windInKmPerHour * 0.621371192).toFixed(1));
export const convertMPerHourToKmPerHour = (windInMPerHour: number) => parseFloat((windInMPerHour * 1.609344).toFixed(1));
export const convertMillimetersToInches = (rainInMm: number) => parseFloat((rainInMm * 0.0393700787).toFixed(2));
export const convertInchesToMillimeters = (rainInInches: number) => parseFloat((rainInInches * 25.4).toFixed(2));
export const convertLiterPerSquareMeterToGallonPerFeet = (literPerSquareMeter: number) => parseFloat((literPerSquareMeter * 0.264172).toFixed(2));
export const convertGallonPerFeetToLiterPerSquareMeter = (gallonPerSquareFeet: number) => parseFloat((gallonPerSquareFeet * 3.78541).toFixed(2));

export const convertLiterToGallon = (liter: number | null | undefined): number | null | undefined => {
  if (liter === null || liter === undefined) {
    return liter;
  }

  return parseFloat((liter * 0.2641720524).toFixed(4));
}

export const convertGallonToLiter = (gallon: number | null | undefined): number | null | undefined => {
  if (gallon === null || gallon === undefined) {
    return gallon;
  }

  return parseFloat((gallon * 3.785411784).toFixed(4));
}