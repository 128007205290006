import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, mergeMap, of, withLatestFrom } from "rxjs";
import { HttpErrorService } from "../../shared/services/http-error.service";
import { AgroMessageService } from "../../shared/services/agro-message.service";
import { baseResponse } from "../../grass-parameters-presentation/DTO/base-response";
import { addApplicationUserResponse, AdminApplicationApiService, getApplicationRolesResponse, getApplicationUserResponse, getApplicationUsersResponse } from "../admin-application.api.service";
import { helpDeskActions, selectFilter } from "./application-index";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class HelpDeskEffects {
    private store = inject(Store<AppState>);
    private actions$ = inject(Actions);
    private apiService = inject(AdminApplicationApiService);
    private httpErrorService = inject(HttpErrorService);
	private messageService = inject(AgroMessageService);
	private router = inject(Router);
    private translateService = inject(TranslateService);
    
    loadUsers$ = createEffect(() => this.actions$.pipe(
		ofType(helpDeskActions.loadUsers),
		exhaustMap((args) => { 
			return this.apiService.getUsers(args.filter).pipe(
				map((response: getApplicationUsersResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
						return helpDeskActions.handleError();
					}

					return helpDeskActions.loadUsersSuccess({ users: response.users, totalRecords: response.totalRecords });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(helpDeskActions.handleError());
				})
			)
		})
	));

    refreshUsers$ = createEffect(() => 
        this.actions$.pipe(
            ofType(
                helpDeskActions.refreshUsers, 
                helpDeskActions.filterReset,
                helpDeskActions.filterUsersBySearchText,
                helpDeskActions.filterUsersByActiveState,
                helpDeskActions.filterUsersByDate,
                helpDeskActions.filterUsersByLazyLoad),
            withLatestFrom(this.store.select(selectFilter)),
            map(([_, filter]) => {
                return helpDeskActions.loadUsers({filter});
            })
        ));

    getRoles$ = createEffect(() => this.actions$.pipe(
        ofType(helpDeskActions.getRoles),
        exhaustMap(() => { 
            return this.apiService.getRoles().pipe(
                map((response: getApplicationRolesResponse) => {
                    if (!response.success) {
                        this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                        return helpDeskActions.handleError();
                    }

                    return helpDeskActions.getRolesSuccess({ roles: response.roles });
                }),
                catchError((error) => {
                    this.httpErrorService.handleError(error);
                    return of(helpDeskActions.handleError());
                })
            )
        })
    ));

    addUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.addUser),
            mergeMap(({dto}) =>
                this.apiService.addUser(dto).pipe(
                    map((response: addApplicationUserResponse) => {
                        if (!response.success) {
                            this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                            return helpDeskActions.handleError();
                        }

                        return helpDeskActions.addUserSuccess({dto, userId: response.userId});
                    }),
                    catchError((error) => {
                        this.httpErrorService.handleError(error);
                        return of(helpDeskActions.handleError());
                    })
                ))
    ));

    getUser$ = createEffect(() => this.actions$.pipe(
        ofType(helpDeskActions.getUser),
        exhaustMap(({userId}) => { 
            return this.apiService.getUser(userId).pipe(
                map((response: getApplicationUserResponse) => {
                    if (!response.success) {
                        this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                        return helpDeskActions.handleError();
                    }

                    return helpDeskActions.getUserSuccess({ dto: response.user });
                }),
                catchError((error) => {
                    this.httpErrorService.handleError(error);
                    return of(helpDeskActions.handleError());
                })
            )
        })
    ));

    addUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.addUserSuccess),
            mergeMap(() =>
                this.router.navigate(['admin/application/users']).then(() => {
                  this.messageService.displaySuccessMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_INFO"), this.translateService.instant("APPLICATION_USERS.MESSAGES.ADD_USER_SUCCESS"));
                  this.store.dispatch(helpDeskActions.filterReset());
                })
    )), {dispatch: false});

    changeUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.changeUser),
            mergeMap(({userId, dto}) =>
                this.apiService.changeUser(userId, dto).pipe(
                    map((response: baseResponse) => {
                        if (!response.success) {
                            this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                            return helpDeskActions.handleError();
                        }

                        return helpDeskActions.changeUserSuccess({userId, dto});
                    }),
                    catchError((error) => {
                        this.httpErrorService.handleError(error);
                        return of(helpDeskActions.handleError());
                    })
                ))
    ));

    changeUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.changeUserSuccess),
            mergeMap(({ dto, userId }) =>
                this.router.navigate(['admin/application/users']).then(() => {
                  this.messageService.displaySuccessMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_INFO"), this.translateService.instant("APPLICATION_USERS.MESSAGES.CHANGE_USER_SUCCESS"));
                  this.store.dispatch(helpDeskActions.filterReset());
                })
    )), {dispatch: false});

    lockUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.lockUser),
            mergeMap(({userId}) =>
                this.apiService.lockUser(userId).pipe(
                    map((response: baseResponse) => {
                        if (!response.success) {
                            this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                            return helpDeskActions.handleError();
                        }

                        this.messageService.displaySuccessMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_INFO"), this.translateService.instant("APPLICATION_USERS.MESSAGES.LOCK_USER_SUCCESS"));
                        return helpDeskActions.lockUserSuccess({userId});
                    }),
                    catchError((error) => {
                        this.httpErrorService.handleError(error);
                        return of(helpDeskActions.handleError());
                    })
                ))
        ));

    unlockUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(helpDeskActions.unlockUser),
            mergeMap(({userId}) =>
                this.apiService.unlockUser(userId).pipe(
                    map((response: baseResponse) => {
                        if (!response.success) {
                            this.messageService.displayErrorMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_ERROR"), response.error);
                            return helpDeskActions.handleError();
                        }

                        this.messageService.displaySuccessMessage(this.translateService.instant("APPLICATION_USERS.MESSAGES.SUMMARY_INFO"), this.translateService.instant("APPLICATION_USERS.MESSAGES.UNLOCK_SUSERS_UCCESS"));
                        return helpDeskActions.unlockUserSuccess({userId});
                    }),
                    catchError((error) => {
                        this.httpErrorService.handleError(error);
                        return of(helpDeskActions.handleError());
                    })
                ))
        ));
}