import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {Observable, tap} from "rxjs";
import {AppState} from "../../../types/app-state";
import {select, Store} from "@ngrx/store";
import * as projectSelectors from "../../../project/project-store/selectors";
import * as routingActions from "../../../routing-store/actions";
import * as routingSelectors from "../../../routing-store/selectors";
import {FeatureName} from "../../../admin/types";

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss'],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { 'class': 'app-nav-items' }
})
export class NavSidebarComponent {
  selectedProjectId$: Observable<number | undefined>;
  sidebarExpanded$: Observable<boolean>;

  constructor(private router: Router, private store: Store<AppState>) {
    this.selectedProjectId$ = this.store.pipe(select(projectSelectors.selectedProjectIdSelector));
    this.sidebarExpanded$ = this.store.select(routingSelectors.sidebarExpandedSelector)
    .pipe(
      tap((expanded) => localStorage.setItem('sidebarExpanded', expanded ? 'true' : 'false'))
    );
  }

  toggleSidebar = () => {
    this.store.dispatch(routingActions.toggleSidebar());
  }

  protected readonly FeatureName = FeatureName;
}
