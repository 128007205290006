import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";


if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(() => {
    console.error();
  });
}


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }

 Sentry.init({
  dsn: environment.sentryDsn, environment: environment.name,
  integrations: [
    // new Sentry.Replay({ // Na ten moment wyłaczony replay bo nie nagrywa mapy
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
	release: `agro@${environment.webVersion}`,
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
