import { Injectable, inject } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { AppState } from "../types/app-state";
import { Store } from "@ngrx/store";
import { taskActions } from "./store/actions";
import { TaskScheduleComponent } from "./task-schedule/task-schedule.component";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class TaskDialogService {
    private dialogService = inject(DialogService);
    private store = inject(Store<AppState>);
    private refTaskDialog: DynamicDialogRef | undefined;
    private translate = inject(TranslateService);

    showTaskScheduleDialog(taskId: string) {
        this.refTaskDialog = this.dialogService.open(TaskScheduleComponent, {
            header: this.translate.instant("TASK.TASK_LIST.SCHEDULE"),
            data: taskId,
						height: "550px",
						width: "870px"
        });

        this.refTaskDialog.onClose.subscribe((saveExecuted: boolean) => {
            if (saveExecuted) {
                this.store.dispatch(taskActions.refreshTasks());
            }
            this.refTaskDialog = undefined;
        });
    }

    hideTaskScheduleDialog(saveExecuted: boolean): void {
        if (this.refTaskDialog)
            this.refTaskDialog.close(saveExecuted);
    }

}
