import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CommentDTO} from "../../model/commentDTO";

@Component({
	selector: 'app-comments-section',
	template: `
		<p-panel class="p-panel-comments">
			<div class="comments-panel">
				<div class="comment-container" *ngFor="let comment of comments">
					<div class="comment-content">
						<div class="comment-header">
							<label class="author">{{ comment.author }}</label>
							<label class="date">{{ comment.date | date:'short' }}</label>
						</div>
						<div class="content">{{ comment.content }}</div>
					</div>
				</div>
			</div>
			<div *ngIf="comments && comments.length === 0" class="h-100 form-group align-center justify-center">
				<p class="text-center font-size-13">{{ 'SHARED.COMMENT.NO_COMMENT' | translate }}</p>
			</div>

			<ng-template pTemplate="footer">
				<h4>{{ 'SHARED.COMMENT.TITLE' | translate }}</h4>
				<div class="input-box label-top">
					<label>{{ 'SHARED.COMMENT.ADD_NEW' | translate }}</label>
					<textarea type="text" pInputTextarea [(ngModel)]="newComment" style="width: 100%;"></textarea>
				</div>
				<div class="comment-action">
					<p-button [label]="'SHARED.COMMENT.ADD_COMMENT' | translate" styleClass="p-button-success" (onClick)="addComment()"
										[disabled]="!newComment || newComment === ''"></p-button>
				</div>
			</ng-template>

		</p-panel>
	`
})
export class CommentsSectionComponent {
	@Input() comments: CommentDTO[] = [];

	@Output() commentAdded = new EventEmitter<string>();

	addComment = () => {
		this.commentAdded.emit(this.newComment);
		this.newComment = '';
	}

	newComment: string = '';
}
