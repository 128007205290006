import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: 'translateGrassParam' })
export class TranslateGrassParamPipe implements PipeTransform {
	private translate = inject(TranslateService);

  transform(value: string): string {
		const translationKey = `GRASS_PARAMETERS.PARAM_NAMES.${value}`
    return this.translate.instant(translationKey);
  }
}
