import {environment} from "../../../environments/environment";
import {geoJsonFileSourceLink} from "../models/types";
import {PhotoDataSourceType} from "../models/photo-data-source-type";
import {HexesDataSourceType} from "../models/mapbox/types";

export const getAvailableFileLinks = (fileLinks: geoJsonFileSourceLink[], dataSourceType: PhotoDataSourceType) => {
	if (environment.disableDeviationsParameters) {
		fileLinks = fileLinks.filter(({kind}) => kind !== HexesDataSourceType.Deviations);
	}

	if (dataSourceType === PhotoDataSourceType.Satellite && environment.disableSatelliteHighZoom) {
		fileLinks = fileLinks.filter(({hexZoom}) => hexZoom !== environment.hexZoomHigh);
	}

	return fileLinks;
}
