import {createReducer, on} from "@ngrx/store";
import * as chooseAreaActions from "./actions";
import {chooseAreaState} from "./choose-area-store-types";
import {AreaMapboxModel} from "../../model/area-mapbox-model";
import {State} from "../../_helpers/state";
import {ElementaryObject} from "../../model/elementary-object";
import {v4 as uuid} from "uuid";

const initialState: chooseAreaState = {
  selectedArea: {uuid: undefined},
  drawnAreas: [],
  isAddAreaPopupVisible: false,
  isEditAreaPopupVisible: false,
  isElementaryObjectEnabled: false,
  areaInvalid: false,
  isLoading: false,
  isChanged: false,
  expandedArea: {uuid: undefined},
}

export const chooseAreaReducer = createReducer(
  initialState,
  on(chooseAreaActions.changeSelectedAreaName, (state, action) => ({...state, selectedArea: action.polygonObject, isChanged: true })),
  on(chooseAreaActions.loadProjectAreas, (state) => ({...state, isLoading: true, areaInvalid: false, isElementaryObjectEnabled: false, selectedArea: null!,
                              isEditAreaPopupVisible: false, isAddAreaPopupVisible: false, center: undefined, expandedArea: null!})),
  on(chooseAreaActions.loadProjectAreasSuccess, (state, action) => ({...state, isLoading: false, drawnAreas: action.drawnAreas, isChanged: false})),
  on(chooseAreaActions.addArea, (state, action) => {
    const newArea: AreaMapboxModel = {
      uuid: action.form.uuid,
      name: action.form.name!,
      polygon: action.polygon,
      state: State.Add,
      elementaryObjects: []
    }

    return {
      ...state,
      drawnAreas: [...state.drawnAreas, newArea],
      selectedArea: null!,
      isAddAreaPopupVisible: false,
      areaInvalid: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.addElementaryObject, (state, action) => {
    const drawnAreas = state.drawnAreas.map(area => {
      return area.uuid === action.form.parentUuid ?
        {
          ...area,
          state: area.state === State.Add ? State.Add: State.Edit,
          elementaryObjects: [...area.elementaryObjects!, {
            uuid: action.form.uuid!,
            state: State.Add,
            type: action.form.type!,
            projectAreaId: area!.areaId,
            polygon: action.polygon
          }]
        }
        : area;
    });

    return {
      ...state,
      selectedArea: null!,
      drawnAreas,
      isAddAreaPopupVisible: false,
      isElementaryObjectEnabled: false,
      areaInvalid: false,
      isChanged: true
    }
  }),
  on(chooseAreaActions.editArea, (state, action) => {
    const drawnAreas: AreaMapboxModel[] = state.drawnAreas.map(area => {
      return area.uuid === action.form.uuid ?
        {
          ...area,
          name: action.form.name!,
          polygon: action.polygon,
          state: area.state === (State.Add || undefined) ? State.Add : State.Edit
        }
        : area;
    });

    return {
      ...state,
      selectedArea: null!,
      drawnAreas,
      isEditAreaPopupVisible: false,
      areaInvalid: false,
      isChanged: true
    }
  }),
  on(chooseAreaActions.editElementaryObject, (state, action) => {
    const parent = state.drawnAreas.find((area) => area.elementaryObjects!.find(eo => eo.uuid === action.form.uuid))

    const drawnAreas = state.drawnAreas.map(area => {
      return area.uuid === parent!.uuid ? {
        ...area,
        state: area.state === State.Add ? State.Add : State.Edit,
        elementaryObjects: area.elementaryObjects!.map(eo => {
          return eo.uuid === action.form.uuid ? {
            ...eo,
            state: eo.state === State.Add ? State.Add : State.Edit,
            polygon: action.polygon,
            type: action.form.type!
          } : eo;
        })
      } : area;
    });

    return {
      ...state,
      selectedArea: null!,
      drawnAreas,
      isEditAreaPopupVisible: false,
      areaInvalid: false,
      isElementaryObjectEnabled: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.deleteArea, (state, action) => {
    let drawnAreas: AreaMapboxModel[] = state.drawnAreas.map(area => {
      if (area.uuid === action.form.uuid) {
        if (area.state !== State.Add) {
          let elementaryObjects = area.elementaryObjects!.filter(x => x.state !== State.Add);
          elementaryObjects = elementaryObjects.map(eo => ({...eo, state: State.Delete}));
          return {...area, state: State.Delete, elementaryObjects};
        }
      }

      return area;
    });

    drawnAreas = drawnAreas.filter(x => x.uuid !== action.form.uuid || x.state !== State.Add);

    return {
      ...state,
      selectedArea: null!,
      drawnAreas,
      isEditAreaPopupVisible: false,
      areaInvalid: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.deleteElementaryObject, (state, action) => {
    const parent = state.drawnAreas.find((area) => area.elementaryObjects!.find(eo => eo.uuid === action.form.uuid));
    const elementaryObject = parent!.elementaryObjects!.find(eo => eo.uuid === action.form.uuid);

    let elementaryObjects: ElementaryObject[];

    if (elementaryObject!.state === State.Add) {
      elementaryObjects = parent!.elementaryObjects!.filter(x => x.uuid !== action.form.uuid);
    } else {
      elementaryObjects = parent!.elementaryObjects!.map(eo => {
        return eo.uuid === action.form.uuid ? {
          ...eo,
          state: State.Delete
        } : eo;
      })
    }

    const drawnAreas: AreaMapboxModel[] = state.drawnAreas.map(area => {
      return area.uuid === parent!.uuid ? {
        ...area,
        state: area.state === State.Add ? State.Add : State.Edit,
        elementaryObjects: elementaryObjects
      } : area;
    });

    return {
      ...state,
      drawnAreas,
      selectedArea: null!,
      isEditAreaPopupVisible: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.selectArea, (state, action) => {
    return {
      ...state,
      isElementaryObjectEnabled: false,
      isEditAreaPopupVisible: true,
      isAddAreaPopupVisible: false,
      selectedArea: {uuid: action.areaId},
      expandedArea: {uuid: (action.areaId === state.expandedArea?.uuid) ? null! : action.areaId},
      areaInvalid: false
    };
  }),
  on(chooseAreaActions.expandArea, (state, action) => {
    return {
      ...state,
      expandedArea: {uuid: (action.areaId === state.expandedArea?.uuid) ? null! : action.areaId},
    };
  }),
  on(chooseAreaActions.selectElementaryObject, (state, action) => {
    return {
      ...state,
      isElementaryObjectEnabled: true,
      isEditAreaPopupVisible: true,
      isAddAreaPopupVisible: false,
      selectedArea: {uuid: action.elementaryObjectId},
      areaInvalid: false
    };
  }),
  on(chooseAreaActions.startNewAreaDraw, (state, action) => {
    return {
      ...state,
      isAddAreaPopupVisible: false,
      isEditAreaPopupVisible: false,
      isElementaryObjectEnabled: false,
      selectedArea: {uuid: action.areaId},
      areaInvalid: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.startNewElementaryObjectDraw, (state, action) => {
    return {
      ...state,
      isAddAreaPopupVisible: false,
      isEditAreaPopupVisible: false,
      isElementaryObjectEnabled: true,
      selectedArea: {uuid: action.elementaryObjectId},
      areaInvalid: false,
      isChanged: true
    };
  }),
  on(chooseAreaActions.createDraw, (state, action) => {
    return ({...state, areaInvalid: false, isElementaryObjectEnabled: action.isElementaryObject});
  }),
  on(chooseAreaActions.createDrawSuccess, (state) => ({...state, isAddAreaPopupVisible: true})),
  on(chooseAreaActions.handleValidationFailure, (state) => ({...state, areaInvalid: true})),
  on(chooseAreaActions.unselect, (state) => {
    return {
      ...state,
      isAddAreaPopupVisible: false,
      isEditAreaPopupVisible: false,
      isElementaryObjectEnabled: false,
      areaInvalid: false,
      selectedArea: null!
    }
  }),
  on(chooseAreaActions.editProject, (state) => ({...state, isLoading: true})),
  on(chooseAreaActions.editProjectSuccess, (state) => ({...state, isLoading: false, isChanged: false})),
  on(chooseAreaActions.initAreasForNewProject, (state, action) => {
    const areas: AreaMapboxModel[] = action.newProject.areas?.map((area) => {
      return {
        uuid: uuid(),
        state: State.Add,
        areaId: undefined,
        name: area.name,
        polygon: area.polygon,
        elementaryObjects: area.elementaryObjects!.map((eo) => {
          return {
            uuid: uuid(),
            state:  State.Add,
            type: eo.type,
            name: eo.name,
            projectAreaId: undefined,
            polygon: eo.polygon
          } as ElementaryObject
        })
      };
    }) ?? [];

    return {...state, drawnAreas: areas, isAddAreaPopupVisible: false, isEditAreaPopupVisible: false, isElementaryObjectEnabled: false, areaInvalid: false,
            selectedArea: null!, center: undefined, isChanged: true, expandedArea: null!};
  }),
	on(chooseAreaActions.handleError, (state) => ({...state, isLoading: false}))
);
