import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {selectedAreaSelector, expandedAreaSelector} from "../../choose-area-store/selectors";
import {Observable} from "rxjs";
import {AppState} from "../../../../types/app-state";
import {AreaMapboxModel} from 'src/app/project/model/area-mapbox-model';
import {ElementaryObject} from 'src/app/project/model/elementary-object';
import {PolygonId, AddNewAreaRequestedEvent, AddNewElementaryObjectRequestedEvent, ActiveAreaChangedEvent, ActiveElementaryObjectChangedEvent} from 'src/app/project/model/polygon-id';

@Component({
  selector: 'app-drawn-areas-sidebar',
  templateUrl: './drawn-areas-sidebar.component.html',
  styleUrls: ['./drawn-areas-sidebar.component.scss']
})
export class DrawnAreasSidebarComponent {
  @Input() areas: AreaMapboxModel[] = [];
  @Input() canEdit: boolean = true;
  @Output() newAreaButtonClicked = new EventEmitter<AddNewAreaRequestedEvent>();
  @Output() selectedAreaChanged = new EventEmitter<ActiveAreaChangedEvent>();
  @Output() newElementaryObjectClicked = new EventEmitter<AddNewElementaryObjectRequestedEvent>();
  @Output() selectElementaryObjectChanged = new EventEmitter<ActiveElementaryObjectChangedEvent>();
  selectedArea$: Observable<PolygonId>;
  expandedArea$: Observable<PolygonId>;

  constructor(private store: Store<AppState>) {
    this.selectedArea$ = this.store.pipe(select(selectedAreaSelector));
    this.expandedArea$ = this.store.pipe(select(expandedAreaSelector));
  }

  initNewArea = () => {
    this.newAreaButtonClicked.emit({});
  };

  selectArea = (area: AreaMapboxModel) => {
    this.selectedAreaChanged.emit({ uuid: area.uuid! });
  };

  initNewElementaryObject = (area: AreaMapboxModel) => {
    this.newElementaryObjectClicked.emit({ areaUuid: area.uuid!, areaName: area.name });
  };

  selectElementaryObject = (elementaryObject: ElementaryObject) => {
    this.selectElementaryObjectChanged.emit({ uuid: elementaryObject.uuid, type: elementaryObject.type });
  };
}
