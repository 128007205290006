import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {baseResponse} from "../../DTO/base-response";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ObservedHexesSnapshotsApiService {
	private http = inject(HttpClient);

	getObservedHexesSnapshots = (projectId: number): Observable<getObservedHexesSnapshotsResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/observed-hexes-snapshots`;
		return this.http.get<getObservedHexesSnapshotsResponse>(endpoint);
	}

	saveObservedHexesSnapshot = (projectId: number, title: string, zoom: number, hexIds: number[], selectedParameter: string): Observable<saveObservedHexesSnapshotResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/observed-hexes-snapshots`;
		const command = { zoom, hexIds, title, selectedParameter };
		return this.http.post<saveObservedHexesSnapshotResponse>(endpoint, command);
	}

	removeObservedHexesSnapshot = (projectId: number, snapshotId: number): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/observed-hexes-snapshots/${snapshotId}`;
		return this.http.delete<baseResponse>(endpoint);
	}
}

export interface getObservedHexesSnapshotsResponse extends baseResponse {
	items: observedHexesSnapshot[];
}

export interface observedHexesSnapshot {
	id: number;
	title: string;
	zoom: number;
	selectedParameter: string;
	hexIds: number[];
}

export interface saveObservedHexesSnapshotCommand {
	title: string;
	hexIds: number[];
}

export interface saveObservedHexesSnapshotResponse extends baseResponse {
	snapshotId: number;
}
