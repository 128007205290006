import {Component, inject, Input} from "@angular/core";
import {changeLog} from "../../model/change-log";

@Component({
	template: `
		<p-panel class="p-panel-comments">
			<div class="comments-panel">
				<div class="comment-container" *ngFor="let change of changes">
					<div class="comment-content">
						<div class="comment-header">
							<label class="author">{{change.userEmail}}</label>
							<label class="date">{{change.timestamp | localizedDate: 'short'}}</label>
						</div>
						<div class="content">{{change.changes}}</div>
					</div>
				</div>
			</div>
		</p-panel>
	`,
	selector: `app-changes-history`
})
export class ChangesHistoryComponent {
	@Input() changes: changeLog[] = [];
}
