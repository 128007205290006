import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, filter, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {GrassParametersMapsService} from "../../../services/grass-parameters-maps.service";
import {MapboxItemsService} from "../../../services/mapbox-items.service";
import {getItemsResponse} from "../../../DTO/get-items-response";
import {laboratoryExaminationActions, mapIncidentActions, otherObjectActions, sprinklersActions} from "../actions";
import {ItemsService} from "../../../services/items.service";
import {AgroMessageService} from "../../../../shared/services/agro-message.service";
import {LoadPanelService} from "../../../../shared/services/load-panel.service";
import {ItemsPopupService} from "../../../services/items-popup.service";
import {
	getItemsSelector,
	otherObjectLayersSelector,
} from "../selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../types/app-state";
import {incidentActions} from "../../../../incident/store/actions";
import {selectedProjectIdSelector} from "../../../../project/project-store/selectors";

@Injectable()
export class OtherObjectsEffects {
  private actions$ = inject(Actions);
  private mapsService = inject(GrassParametersMapsService);
  private mapboxItemsService = inject(MapboxItemsService);
  private itemsService = inject(ItemsService);
  private messageService = inject(AgroMessageService);
  private loadPanelService = inject(LoadPanelService);
  private itemsPopupService = inject(ItemsPopupService);
	private store = inject(Store<AppState>);

	refreshItems = createEffect(() => this.actions$.pipe(
		ofType(
			mapIncidentActions.addIncidentSuccess,
			mapIncidentActions.updateIncidentSuccess,
			mapIncidentActions.removeIncidentSuccess,
			sprinklersActions.addSprinklerSuccess,
			sprinklersActions.editSprinklerSuccess,
			sprinklersActions.removeSprinklerSuccess,
			laboratoryExaminationActions.addSuccess,
			laboratoryExaminationActions.editSuccess,
			laboratoryExaminationActions.removeSuccess
		),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		filter(([_, projectId]) => projectId !== undefined),
		map(([_, projectId]) => otherObjectActions.getItems({projectId: projectId!}))
	))

  getItems$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.getItems),
    mergeMap(({projectId, date}) => {
				return this.itemsService.getItems(projectId, date).pipe(
					map((response: getItemsResponse) => {
						if (!response.success) {
							return [otherObjectActions.handleError({errorMessage: response.error})];
						}

						return [
							otherObjectActions.getItemsSuccess({items: response.items}),
							...this.mapsService.maps.map(({mapId}) => mapId).map(mapId => otherObjectActions.drawItems({mapId}))
						];
					}),
					catchError((error) => of([otherObjectActions.handleError(error)]))
				);
			}
		),
		mergeMap(actions => actions)
  ));

	drawItems$ = createEffect(() => this.actions$.pipe(
		ofType(otherObjectActions.drawItems),
		withLatestFrom(
			this.store.select(otherObjectLayersSelector),
			this.store.select(getItemsSelector)
		),
		tap(([{mapId}, otherObjectLayers, items]) => {
			const map = this.mapsService.getMapById(mapId)?.map;
			if (map) {
				otherObjectLayers.forEach(layer => {
					this.mapboxItemsService.loadItemsDataSources(map, items.filter(({itemType}) => itemType === layer.type), layer.dataSourceName);
					this.mapboxItemsService.createLayers(map, layer.layerNames, layer.selected);
				})
			}
		})
	), {dispatch: false})

  selectOtherObject$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.selectOtherObject),
    tap((props) => {
      const otherObject = props.otherObject;
      this.mapsService.maps.forEach(mapDict => {
        otherObject.layerNames.forEach(layerName => this.mapboxItemsService.showLayer(mapDict.map!, layerName));
      })
    })
  ), {dispatch: false});

  unselectOtherObject$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.unselectOtherObject),
    tap((props) => {
      const otherObject = props.otherObject;
      this.mapsService.maps.forEach(mapDict => {
        otherObject.layerNames.forEach(layerName => this.mapboxItemsService.hideLayer(mapDict.map!, layerName));
      })
    })
  ), {dispatch: false});

  showLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.getItems,
      sprinklersActions.getSprinkler,
      sprinklersActions.addSprinkler,
      sprinklersActions.editSprinkler,
      sprinklersActions.removeSprinkler,
      laboratoryExaminationActions.add,
      laboratoryExaminationActions.edit,
      laboratoryExaminationActions.remove),
    tap(() => this.loadPanelService.showLoadPanel())
  ), {dispatch: false});

  hideLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.getItems,
      sprinklersActions.getSprinklerSuccess,
      sprinklersActions.addSprinklerSuccess,
      sprinklersActions.editSprinklerSuccess,
      sprinklersActions.removeSprinklerSuccess,
      otherObjectActions.handleError,
      laboratoryExaminationActions.addSuccess,
      laboratoryExaminationActions.editSuccess,
      laboratoryExaminationActions.removeSuccess),
    tap(() => this.loadPanelService.hideLoadPanel())
  ), {dispatch: false});

  hideItemPopup$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.hideItemPopup),
    tap(() => this.itemsPopupService.destroyDialogIfOpen())
  ), {dispatch: false});

  handleError$ = createEffect(() => this.actions$.pipe(
    ofType(otherObjectActions.handleError),
    tap(({errorMessage}) => this.messageService.displayErrorMessage("Error", errorMessage))
  ), {dispatch: false});
}
