import {ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation} from '@angular/core';
import {EditIncidentBaseComponent} from "./edit-incident-base.component";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {UpdateIncidentCommand} from "../../../incident/models/UpdateIncidentCommand";
import {incidentActions} from "../../../incident/store/actions";

@Component({
  templateUrl: './edit-incident-base.component.html',
	styles: [
		`
			.edit-incident-popup {
				.p-dialog-content {
					padding: 1.5rem;
				}
			}
		`
	],
  // changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None
})
export class EditIncidentPopupComponent extends EditIncidentBaseComponent implements OnInit {
	private dynamicDialogConfig = inject(DynamicDialogConfig);
	private dynamicDialogRef = inject(DynamicDialogRef);

	override ngOnInit() {
		this.incidentId = this.dynamicDialogConfig.data.incidentId;
		this.isPopup = true;
		super.ngOnInit();
	}

	override submit = () => {
		if(!this.form.valid) {
			return;
		}

		const formValue = this.form.value;

		const updateCommand: UpdateIncidentCommand = {
			incidentId: this.incidentId!,
			diseaseId: formValue.diseaseId ?? undefined,
			name: formValue.name ?? undefined,
			description: formValue.description ?? undefined,
			elementaryObjectId: formValue.elementaryObjectId ?? undefined,
			priority: formValue.priority ?? undefined,
		}

		this.store.dispatch(incidentActions.updateIncident({command: updateCommand, editedFromPopup: true}));
		this.dynamicDialogRef.close();
	}
}
