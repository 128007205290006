import {createAction, props} from "@ngrx/store";
import {ItemDto, sprinklerDto, mapIncidentDto} from "../../DTO/item-dto";
import {addSprinklerCommand} from "../../DTO/items/sprinklers/add-sprinkler-command";
import {editSprinklerCommand} from "../../DTO/items/sprinklers/edit-sprinkler-command";
import {moveSprinklerCommand} from "../../DTO/items/sprinklers/move-sprinkler-command";
import { otherObjectLayerInterface } from "./types";
import { addLaboratoryExaminationDto, editLaboratoryExaminationDto} from "../../components/grass-parameters-presentation/laboratory-examination-popup/types";
import {point} from "../../../types/point";
import { Point } from "geojson";

export const otherObjectActions = {
  getItems: createAction('[OtherObjects] Get Items', props<{projectId: number, date?: Date}>()),
	getItemsSuccess: createAction('[OtherObjects] Get Items Success', props<{items: ItemDto[]}>()),
  hideItemPopup: createAction('[OtherObjects] Hide Item Popup'),
  selectOtherObject: createAction('[OtherObjects] Select Other Object', props<{otherObject: otherObjectLayerInterface}>()),
  unselectOtherObject: createAction('[OtherObjects] Unselect Other Object', props<{otherObject: otherObjectLayerInterface}>()),
  handleError: createAction('[OtherObjects] Error Occurred', props<{errorMessage: string}>()),
	drawItems: createAction('[OtherObjects] Draw Items On Map', props<{mapId: string}>()),
  reset: createAction('[OtherObjects] Reset')
}

export const mapIncidentActions = {
  showAddIncidentPage: createAction('[Map Incidents] Add Map incident', props<{lat: number, lng: number}>()),
  showEditIncidentPage: createAction('[Map Incidents] Show edit incident page', props<{itemId: string}>()),
	addIncidentSuccess: createAction('[Map Incidents] Add Incident Success'),
	removeIncidentSuccess: createAction('[Map Incidents] Remove Incident Success'),
	updateIncidentSuccess: createAction('[Map Incidents] Update Incident Success')
}

export const sprinklersActions = {
  getSprinkler: createAction('[Sprinklers] Get Sprinkler', props<{projectId: number, sprinklerId: number}>()),
  getSprinklerSuccess: createAction('[Sprinklers] Get Sprinkler Success', props<sprinklerDto>()),
  addSprinkler: createAction('[Sprinklers] Add Sprinkler', props<addSprinklerCommand>()),
  addSprinklerSuccess: createAction('[Sprinklers] Add Sprinkler Success'),
  editSprinkler: createAction('[Sprinklers] Edit Sprinkler', props<editSprinklerCommand>()),
  editSprinklerSuccess: createAction('[Sprinklers] Edit Sprinkler Success'),
  moveSprinkler: createAction('[Sprinklers] Move Sprinkler', props<moveSprinklerCommand>()),
  removeSprinkler: createAction('[Sprinklers] Remove Sprinkler', props<{sprinklerId: number}>()),
  removeSprinklerSuccess: createAction('[Sprinklers] Remove Sprinkler Success'),
  showEditSprinklerPopup: createAction('[Sprinklers] Show edit sprinkler popup', props<{sprinklerId: number}>()),
}

export const laboratoryExaminationActions = {
  add: createAction('[LaboratoryExaminations] Add Laboratory Examination', props<addLaboratoryExaminationDto>()),
  addSuccess: createAction('[LaboratoryExaminations] Add Laboratory Examination Success'),
  edit: createAction('[LaboratoryExaminations] Edit Laboratory Examination', props<editLaboratoryExaminationDto>()),
  editSuccess: createAction('[LaboratoryExaminations] Edit Laboratory Examination Success'),
  move: createAction('[LaboratoryExaminations] Move Laboratory Examination', props<({id: number, gps: point})>()),
  remove: createAction('[LaboratoryExaminations] Remove Laboratory Examination', props<{id: number}>()),
  removeSuccess: createAction('[LaboratoryExaminations] Remove Laboratory Examination Success'),
}
