export const loadPanelConfig: LoadPanelDefaultConfig = {
  shading: true,
  shadingColor: "rgba(0,0,0,0.8)",
  showIndicator: true,
  message: "Loading...",
  showPane: false,
  cssClass: "load-panel-agro",
  width: "100%",
  height: "100%"
} as const;

export interface LoadPanelDefaultConfig {
  shading: boolean,
  shadingColor: string,
  showIndicator: boolean,
  message: string,
  showPane: boolean,
  cssClass: string,
  width: string,
  height: string
}
