<p-dialog position="right" (onHide)="cancel()" class="area-form-popup-rebuild area-edit-dialog" [header]="getTranslatedTitle()" [(visible)]="isAreaFormVisible">
  <form class="agro-form" [formGroup]="areaPopupForm" (ngSubmit)="submit()">
    <div class="area-edit-dialog-content">
      <div class="input-box secondary p-input-secondary flex style-2">
        <label>{{ 'COMMON.NAME' | translate }}</label>
        <div class="agro-form-control w-100">
          <input type="text" pInputText formControlName="name" [readonly]="isElementaryObjectEnabled" />
          <app-error-message propertyName="name" errorMessage="Name is required" [form]="areaPopupForm" validatorRule="required"></app-error-message>
          <app-error-message propertyName="name" errorMessage="Hole with provided name already exists" [form]="areaPopupForm" validatorRule="nameUnique"></app-error-message>
        </div>
      </div>
      <div *ngIf="isElementaryObjectEnabled" class="input-box p-input-secondary secondary flex style-2">
        <label>{{ 'COMMON.TYPE' | translate }}</label>
        <div class="agro-form-control w-100">
          <p-dropdown [options]="types" optionLabel="name" optionValue="name" formControlName="type" appendTo="body" panelStyleClass="options-font-size-12"></p-dropdown>
          <app-error-message propertyName="type" errorMessage="Type is required" [form]="areaPopupForm" validatorRule="required"></app-error-message>
        </div>
      </div>
    </div>
    <div class="area-edit-dialog-footer">
      <div class="area-form-popup-buttons ">
        <p-button *ngIf="action === 'Add'" (click)="cancel()" [label]="'COMMON.CANCEL' | translate" styleClass="p-button-link"></p-button>
        <p-button *ngIf="action === 'Edit'" (click)="delete()" [label]="'COMMON.DELETE' | translate" icon="pi pi-trash" styleClass="p-button-link" iconPos="right"></p-button>
        <p-button [disabled]="areaPopupForm.invalid" [label]="getTranslatedAction()" styleClass="p-button-success" type="submit"></p-button>
      </div>
    </div>
  </form>
</p-dialog>
