import {AfterViewInit, ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import {createSelector, Store} from '@ngrx/store';
import {take} from "rxjs";
import {MapboxDrawService} from 'src/app/shared/services/mapbox-draw.service';
import {AreaMapboxModel} from 'src/app/project/model/area-mapbox-model';
import {MapboxUtils} from 'src/app/shared/utils/mapbox-utils';
import * as chooseAreaActions from "../../choose-area/choose-area-store/actions";
import {
  ActiveAreaChangedEvent,
  ActiveElementaryObjectChangedEvent,
  AddNewAreaRequestedEvent,
  AddNewElementaryObjectRequestedEvent
} from '../../model/polygon-id';
import {AppState} from 'src/app/types/app-state';
import {environment} from 'src/environments/environment';
import {ProjectAreaProvider, ProjectInterface} from "../../model/project.interface";
import {AreaFormPopupModel} from "../../model/area-form-popup-model"
import {v4 as uuid} from "uuid";
import * as projectUtils from "../../utils/project-utils";
import {ManageAreasService} from "../../services/manage-areas.service";
import {AreaSelectionState} from "../../enums/area-selection-state";
import * as chooseAreaSelectors from "../../choose-area/choose-area-store/selectors";
import {selectFeatureChooseArea} from "../../choose-area/choose-area-store/selectors";
import * as geometryUtils from "../../../shared/utils/geometry-utils";
import {MapboxCenterService} from "../../services/mapbox-center.service";

@Component({
  selector: 'app-edit-areas',
  templateUrl: './edit-areas.component.html',
  styleUrls: ['../../choose-area/choose-area.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { 'class': 'choose-areas-container' },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAreasComponent implements AfterViewInit {
  private store = inject(Store<AppState>);
  private mapboxDrawService = inject(MapboxDrawService);
  private manageAreasService = inject(ManageAreasService);
  private mapboxCenterService = inject(MapboxCenterService);

  @Input()
  project?: ProjectInterface = {};

  manageAreaFormData: AreaFormPopupModel = {};
  map!: mapboxgl.Map;

  readonly vm$ = this.store.select(editAreasComponentVmSelector);

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.map = new mapboxgl.Map({
        accessToken: environment.mapbox.accessToken,
        container: 'map',
        style: environment.mapbox.styles.satelliteV9,
        zoom: 15,
        minZoom: 12,
        maxZoom: 20,
        center: MapboxUtils.getCoordinates(this.project!.gps)
      });

      const nav: mapboxgl.NavigationControl =  new mapboxgl.NavigationControl({
        showCompass: false
      });

      this.map.addControl(nav, "bottom-right");
      this.mapboxDrawService.initDraw();
      this.map.addControl(this.mapboxDrawService.draw);
      this.map.on('draw.create', this.onDrawCreated);
      this.map.on('draw.update', this.onDrawUpdated);
      this.map.on('draw.selectionchange', this.onDrawSelectionChanged);

      this.mapboxCenterService.map = this.map;

      this.store.dispatch(chooseAreaActions.loadProjectAreas({projectId: this.project!.id!}));
    }, 100);
  }

  onSelectedAreaOnSidebarChanged = ($event: ActiveAreaChangedEvent): void => {
    this.store.dispatch(chooseAreaActions.selectArea({areaId: $event.uuid, selectedOnMap: false}));
    this.manageAreaFormData = this.manageAreasService.setFormPropertiesFromSelectedPolygon(this.manageAreaFormData);
  }

  onSelectedElementaryObjectOnSidebarChanged = ($event: ActiveElementaryObjectChangedEvent): void => {
    this.store.dispatch(chooseAreaActions.selectElementaryObject({elementaryObjectId: $event.uuid, selectedOnMap: false}));
    this.manageAreaFormData = this.manageAreasService.setFormPropertiesFromSelectedPolygon(this.manageAreaFormData);
  }

  startNewAreaDraw = ($event: AddNewAreaRequestedEvent): void => {
    const areaId = uuid();
    this.store.dispatch(chooseAreaActions.startNewAreaDraw({areaId}));

    this.manageAreaFormData = {
      uuid: areaId,
      parentUuid: undefined,
      name: undefined,
      type: 'area'
    };
  }

  startNewElementaryObjectDraw = ($event: AddNewElementaryObjectRequestedEvent): void => {
    const elementaryObjectId = uuid();

    this.store.dispatch(chooseAreaActions.startNewElementaryObjectDraw({elementaryObjectId}));

    this.manageAreaFormData = {
      uuid: elementaryObjectId,
      parentUuid: $event.areaUuid!,
      name: $event.areaName,
      type: undefined,
    };
  }

  onAddAreaSubmit(formData: AreaFormPopupModel): void {
    if (formData.type === 'area') {
      this.store.dispatch(chooseAreaActions.addArea({form: {...formData}, polygon: this.mapboxDrawService.getSelectedPolygon()}))
      this.manageAreaFormData = {};
      return;
    }

    if (projectUtils.isKnownElementaryObjectType(formData.type!)) {
      this.store.dispatch(chooseAreaActions.addElementaryObject({form: {...formData}, polygon: this.mapboxDrawService.getSelectedPolygon()}))
      this.manageAreaFormData = {};
      return;
    }
  }

  onEditAreaSubmit(formData: AreaFormPopupModel): void {
    this.manageAreaFormData = formData;

    if (formData.type === 'area') {
      this.store.dispatch(chooseAreaActions.editArea({form: {...formData}, polygon: this.mapboxDrawService.getSelectedPolygon()}));
      return;
    }

    if (projectUtils.isKnownElementaryObjectType(formData.type!)) {
      this.store.dispatch(chooseAreaActions.editElementaryObject({form: {...formData}, polygon: this.mapboxDrawService.getSelectedPolygon()}));
      this.manageAreaFormData = {};
      return;
    }
  }

  deleteArea(formData: AreaFormPopupModel) : void {
    if (formData.type === 'area') {
      this.store.select(chooseAreaSelectors.idsToDelete(formData.uuid!)).pipe(take(1)).subscribe(mainAreaAndElementaryObjectIds => {
        this.store.dispatch(chooseAreaActions.deleteArea(({form: formData, polygon: this.mapboxDrawService.getSelectedPolygon(), idsToDelete: mainAreaAndElementaryObjectIds})));
        this.manageAreaFormData = {};
        return;
      })
    }

    if (projectUtils.isKnownElementaryObjectType(formData.type!)) {
      this.store.dispatch(chooseAreaActions.deleteElementaryObject({form: {...formData}, polygon: this.mapboxDrawService.getSelectedPolygon()}))
      this.manageAreaFormData = {};
      return;
    }
  }

  cancelAreaForm = () => this.store.dispatch(chooseAreaActions.unselect())

  onSubmit = () => this.store.dispatch(chooseAreaActions.editProject({project: this.project!}));

  shouldShowUpdateFromIGolfButton = () => this.project!.provider === ProjectAreaProvider.IGolf && this.project!.providerId !== undefined

  onUpdateFromIGolf = () => this.store.dispatch(chooseAreaActions.updateFromIGolf({organizationId: this.project!.organizationId!, projectId: this.project!.id!}));

  private onDrawSelectionChanged = (e:any) => {
    this.store.select(chooseAreaSelectors.selectionChangedSelector).pipe(take(1))
      .subscribe(selector => {
        const selectionState: AreaSelectionState = this.manageAreasService.getSelectionState(e.features, selector.isAddAreaPopupVisible, selector.isEditAreaPopupVisible);
        switch (selectionState) {
          case AreaSelectionState.Selected: {
            if(selector.areaInvalid) {
              break;
            }

            const isElementaryObject = projectUtils.isKnownElementaryObjectType(this.mapboxDrawService.getSelectedPolygonTypeProperty());
            const id = this.mapboxDrawService.getSelectedPolygonUuidProperty();

            if (isElementaryObject) {
              this.store.dispatch(chooseAreaActions.selectElementaryObject({elementaryObjectId: id, selectedOnMap: true}));
            } else {
              this.store.dispatch(chooseAreaActions.selectArea({areaId: id, selectedOnMap: true}));
            }

            this.manageAreaFormData = this.manageAreasService.setFormPropertiesFromSelectedPolygon(this.manageAreaFormData);
            break;
          }
          case AreaSelectionState.AddingStarted:
            break;
          default: {
            this.store.dispatch(chooseAreaActions.unselect());
            break;
          }
        }
      })
  }

  private onDrawUpdated = (e: any) => {
    if (geometryUtils.arePolygonLinesIntersecting(e.features[0].geometry)) {
      this.store.dispatch(chooseAreaActions.handleValidationFailure());
      return;
    }

    if (this.manageAreaFormData.type === 'area') {
      this.store.dispatch(chooseAreaActions.editAreaWithValidation({form: {...this.manageAreaFormData}, polygon: e.features[0].geometry}))
      return;
    }

    if (this.manageAreaFormData.parentUuid || projectUtils.isKnownElementaryObjectType(this.manageAreaFormData.type!)) {
      this.store.dispatch(chooseAreaActions.editElementaryObjectWithValidation({form: {...this.manageAreaFormData}, polygon: e.features[0].geometry}));
      return;
    }
  }

  private onDrawCreated = (e:any) => {
    if (geometryUtils.arePolygonLinesIntersecting(e.features[0].geometry)) {
      this.store.dispatch(chooseAreaActions.handleValidationFailure());
      return;
    }

    const isElementaryObject = this.manageAreaFormData.parentUuid || projectUtils.isKnownElementaryObjectType(this.manageAreaFormData.type!);
    this.store.dispatch(chooseAreaActions.createDraw({form: {...this.manageAreaFormData}, polygon: e.features[0].geometry, isElementaryObject: isElementaryObject as boolean}));
  }
}

interface editAreasComponentVm {
  sidebarAreas: AreaMapboxModel[],
  isAddAreaPopupVisible: boolean,
  isEditAreaPopupVisible: boolean,
  isElementaryObjectEnabled: boolean,
  drawnAreas: AreaMapboxModel[],
  areaFormPopupTitle: string,
  areaFormPopupAction: string
  isLoading: boolean
}

const editAreasComponentVmSelector = createSelector(chooseAreaSelectors.sidebarAreas, chooseAreaSelectors.areaFormPopupTitle, chooseAreaSelectors.areaFormPopupAction, selectFeatureChooseArea,
  (sidebarAreas, areaFormPopupTitle, areaFormPopupAction, state): editAreasComponentVm => {
    const {isAddAreaPopupVisible, isEditAreaPopupVisible, isElementaryObjectEnabled, drawnAreas, isLoading} = state;

    return {
      drawnAreas,
      isAddAreaPopupVisible,
      isEditAreaPopupVisible,
      isElementaryObjectEnabled,
      sidebarAreas,
      areaFormPopupTitle,
      areaFormPopupAction,
      isLoading
    }
  }
);
