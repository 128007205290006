import {inject, Injectable, Type} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { addItemModel } from "../models/add-item-model";
import { manageItemModel } from '../models/manage-item-model';
import { SprinklerPopupComponent } from '../components/grass-parameters-presentation/sprinkler-popup/sprinkler-popup.component';
import { sprinklerDto} from "../DTO/item-dto";
import {
  LaboratoryExaminationPopupComponent
} from "../components/grass-parameters-presentation/laboratory-examination-popup/laboratory-examination-popup.component";
import {point} from "../../types/point";
import {
  LaboratoryExaminationPopupDetailsComponent
} from "../components/grass-parameters-presentation/laboratory-examination-popup-details/laboratory-examination-popup-details.component";
import {AddIncidentPopupComponent} from "../../incident/components/add-incident/add-incident-popup.component";
import {AddIncidentComponent} from "../../incident/components/add-incident/add-incident.component";
import {TranslateService} from "@ngx-translate/core";
import {EditIncidentPopupComponent} from "../../shared/components/edit-incident/edit-incident-popup.component";

@Injectable({
  providedIn: 'root'
})
export class ItemsPopupService {
	private translate = inject(TranslateService);
  dialog: DynamicDialogRef | null = null;

  private readonly dialogOptions = {
    showHeader: false,
    modal: true,
    width: "900px",
    height: "480px",
    styleClass: "p-dialog-custom"
  };

  private readonly sprinklerDialogOptions = {
    ...this.dialogOptions,
    height: "520px",
    width: "1100px"
  }

  private readonly laboratoryExaminationDialogOptions = {
    ...this.dialogOptions,
    height: "600px",
    width: "1100px"
  }

  private readonly laboratoryExaminationDetailsDialogOptions = {
    ...this.laboratoryExaminationDialogOptions,
    width: '56rem'
  }
  private dialogService = inject(DialogService);

  showAddSprinklerPopup = (model: addItemModel) => {
    const data: manageItemModel = new manageItemModel("add", model);
    this.showPopup(SprinklerPopupComponent, data, this.sprinklerDialogOptions);
  }

  showEditSprinklerPopup = (dto: sprinklerDto) => {
    const data: manageItemModel = new manageItemModel("edit", dto);
    this.showPopup(SprinklerPopupComponent, data, this.sprinklerDialogOptions);
  }

  showAddLaboratoryExaminationPopup = (data: addLaboratoryExaminationPopupData) => this.showPopup(LaboratoryExaminationPopupComponent, data, this.laboratoryExaminationDialogOptions);

  showEditLaboratoryExaminationPopup = (data: editLaboratoryExaminationPopupData) => this.showPopup(LaboratoryExaminationPopupComponent, data, this.laboratoryExaminationDialogOptions);

  showLaboratoryExaminationDetailsPopup = (data: laboratoryExaminationDetailsPopupData) => this.showPopup(LaboratoryExaminationPopupDetailsComponent, data, this.laboratoryExaminationDetailsDialogOptions);

	showAddIncidentPopup = (data: { lng: number; lat: number }) => {
		const header = this.translate.instant('INCIDENT.INCIDENT_ADD.TITLE');

		const addIncidentDialogOptions: DynamicDialogConfig = {
			...this.dialogOptions,
			height: '90vh',
			width: '80vw',
			styleClass: 'add-incident-popup',
			showHeader: true,
			header
		}

		this.showPopup(AddIncidentPopupComponent, data, addIncidentDialogOptions);
	};

	showEditIncidentPopup = (data: {incidentId: number}) => {
		const header = this.translate.instant('INCIDENT.INCIDENT_EDIT.TITLE');

		const editIncidentDialogOptions: DynamicDialogConfig = {
			...this.dialogOptions,
			height: '90vh',
			width: '90vw',
			styleClass: 'edit-incident-popup',
			showHeader: true,
			header
		};

		this.showPopup(EditIncidentPopupComponent, data, editIncidentDialogOptions);
	}

  destroyDialogIfOpen = () => {
    if (this.dialog != null) {
      this.dialog?.destroy();
      this.dialog = null;
    }
  }

  private showPopup(componentType: Type<otherObjectPopupComponent>, data: any, dialogOptions: any) {
    const dialog = this.dialogService.open(componentType, { ...dialogOptions, data: data });
    let dialogRef = this.dialogService.dialogComponentRefMap.get(dialog)!;
    dialogRef.changeDetectorRef.detectChanges();
    this.dialog = dialog;
  }
}

export interface addLaboratoryExaminationPopupData {
  gps: point;
}

export interface editLaboratoryExaminationPopupData {
  editedItemId: number;
}

export interface laboratoryExaminationDetailsPopupData {
  itemId: number;
}

export type otherObjectPopupComponent =
  SprinklerPopupComponent |
  LaboratoryExaminationPopupComponent |
  LaboratoryExaminationPopupDetailsComponent |
	AddIncidentComponent |
	EditIncidentPopupComponent
