import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { baseResponse } from "../grass-parameters-presentation/DTO/base-response";
import { environment } from "../../environments/environment";
import { applicationRole, applicationUser } from "./application-types";
import * as dayjs from "dayjs";

@Injectable({
	providedIn: 'root'
})
export class AdminApplicationApiService {
	private http = inject(HttpClient);
    private readonly baseUrl = environment.projectApiUrl;

    getRoles = (): Observable<getApplicationRolesResponse> => 
        this.http.get<getApplicationRolesResponse>(`${this.baseUrl}authorization/application/roles`);

    lockUser = (userId: number): Observable<baseResponse> => 
        this.http.patch<baseResponse>(`${this.baseUrl}authorization/application/users/${userId}/lock`, {});

	unlockUser = (userId: number): Observable<baseResponse> => 
        this.http.patch<baseResponse>(`${this.baseUrl}authorization/application/users/${userId}/unlock`, {});

    addUser = (command: addApplicationUserCommand): Observable<addApplicationUserResponse> => 
        this.http.post<addApplicationUserResponse>(`${this.baseUrl}authorization/application/users`, command);

    getUsers = (parameters: getApplicationUsersParameters): Observable<getApplicationUsersResponse> => {
        let params = new HttpParams({
			fromObject: {
			  	skip: parameters.skip,
				take: parameters.take,
			}
		});

		if (parameters.sortField && parameters.sortOrder) {
			params = params.append('sortExpression', parameters.sortField + ((parameters.sortOrder > 0) ? '' : ' desc'));
		}

        if (parameters.active != null) {
			params = params.append('active', parameters.active);
		}

        if (parameters.searchText != null && parameters.searchText != '') {
			params = params.append('searchText', parameters.searchText);
		}

        if (parameters.dateRange !== null && parameters.dateRange !== undefined) {
			const dateFrom = parameters.dateRange[0];
			const dateTo = parameters.dateRange[1];
			params = params.append('dateFrom', dateFrom.toISOString());
			params = params.append('dateTo', dayjs(dateTo).add(1, 'day').subtract(1, 'second').toISOString());
		}

        const url = `${this.baseUrl}authorization/application/users`;
        return this.http.get<getApplicationUsersResponse>(url, { params: params });
    }

    getUser = (userId: number): Observable<getApplicationUserResponse> => 
        this.http.get<getApplicationUserResponse>(`${this.baseUrl}authorization/application/users/${userId}`);

    changeUser = (userId: number, dto: changeApplicationUserDto): Observable<baseResponse> => 
        this.http.patch<baseResponse>(`${this.baseUrl}authorization/application/users/${userId}`, dto);
}

export interface getApplicationRolesResponse extends baseResponse {
	roles: applicationRole[];
}

export interface getApplicationUsersParameters {
    take: number;
    skip: number;
	active: boolean | null;
    searchText: string | null;
	dateRange?: Date[] | null;
    sortField?: string;
    sortOrder?: number;
}

export interface getApplicationUsersResponse extends baseResponse {
    totalRecords: number;
    users: applicationUser[];
}

export interface addApplicationUserCommand {
    email: string;
    firstName: string;
    lastName: string;
    password: string,
    roleId: number | null
}

export interface addApplicationUserResponse extends baseResponse {
    userId: number;
}

export interface getApplicationUserResponse extends baseResponse {
    user: applicationUser;
}

export interface changeApplicationUserDto {
    firstName: string;
    lastName: string;
    password: string | null,
    roleId: number | null,
    active: boolean,
}