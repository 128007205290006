import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavComponent} from './components/nav.component';
import {NavSidebarComponent} from './components/nav-sidebar/nav-sidebar.component';
import {NavUserPanelComponent} from './components/nav-user-panel/nav-user-panel.component';
import {NavLogoBoxComponent} from './components/nav-logo-box/nav-logo-box.component';
import {NavChooseOrganizationComponent} from './components/nav-choose-organization/nav-choose-organization.component';
import { NavSelectedProjectComponent } from './components/nav-selected-project/nav-selected-project.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AvatarModule} from "primeng/avatar";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import { UserNotificationsComponent } from './components/nav-user-panel/user-notifications/user-notifications.component';
import { UserActionsComponent } from './components/nav-user-panel/user-actions/user-actions.component';
import {SharedModule} from "../shared/shared.module";
import {AngularSvgIconModule} from "angular-svg-icon";
import { TranslateModule } from '@ngx-translate/core';
import { MessagesModule } from 'primeng/messages';
import { AdminMenuComponent } from './components/nav-user-panel/admin-menu/admin-menu.component';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		RouterLink,
		RouterLinkActive,
		AvatarModule,
		MenuModule,
		NgOptimizedImage,
		ButtonModule,
		SharedModule,
		AngularSvgIconModule,
		TranslateModule,
		MessagesModule,
	],
  declarations: [NavComponent, NavSidebarComponent, NavUserPanelComponent, NavLogoBoxComponent,
				NavChooseOrganizationComponent, NavSelectedProjectComponent, UserNotificationsComponent,
				UserActionsComponent,
    AdminMenuComponent],
  exports: [NavComponent]
})
export class NavModule { }
