import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { attachmentState } from "./attachmentState";

export const selectAttachments = (state: AppState) => state.attachments;

export const selectorIsLoading = createSelector(
    selectAttachments,
    (state: attachmentState) => state?.isLoading
);
export const selectorAttachments = createSelector(
    selectAttachments,
    (state: attachmentState) => state?.attachments
);
export const selectorUrlBeginning = createSelector(
    selectAttachments,
    (state: attachmentState) => state?.urlBeginning
);
export const selectorObjectId = createSelector(
    selectAttachments,
    (state: attachmentState) => state?.objectId
);
