import {inject, Injectable} from '@angular/core';
import {BaseResponse} from "./shared/model/api-responses";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ParameterTrendApiService {
  private httpClient = inject(HttpClient);

  getTrendsForHex = (processingFileVersionId: number, hexId: number, hexZoom: number): Observable<parameterTrendResponse> => {
    const endpoint = `${environment.projectApiUrl}photos/hex-parameters/${processingFileVersionId}/trendline`;

    const params = new HttpParams()
      .set('hexId', hexId)
      .set('hexZoom', hexZoom);

    return this.httpClient.get<parameterTrendResponse>(endpoint, {params});
  }
}

export interface hexTrends {
  fileVersionId: number;
  hexId: number;
  zoomValue: number;
  sod: number[];
  sodSlope?: number;
  sodAbsoluteChange?: number;
  sodPercentageChange?: number;
  general: number[];
  generalSlope?: number;
  generalAbsoluteChange?: number;
  generalPercentageChange?: number;
  color: number[];
  colorSlope?: number;
  colorAbsoluteChange?: number;
  colorPercentageChange?: number;
  slender: number[];
  slenderSlope?: number;
  slenderAbsoluteChange?: number;
  slenderPercentageChange?: number;
  dryBiomass: number[];
  dryBiomassSlope?: number;
  dryBiomassAbsoluteChange?: number;
  dryBiomassPercentageChange?: number;
  copper: number[];
  copperSlope?: number;
  copperAbsoluteChange?: number;
  copperPercentageChange?: number;
  fall: number[];
  fallSlope?: number;
  fallAbsoluteChange?: number;
  fallPercentageChange?: number;
  humid: number[];
  humidSlope?: number;
  humidAbsoluteChange?: number;
  humidPercentageChange?: number;
  sodium: number[];
  sodiumSlope?: number;
  sodiumAbsoluteChange?: number;
  sodiumPercentageChange?: number;
  potassium: number[];
  potassiumSlope?: number;
  potassiumAbsoluteChange?: number;
  potassiumPercentageChange?: number;
  zinc: number[];
  zincSlope?: number;
  zincAbsoluteChange?: number;
  zincPercentageChange?: number;
  calcium: number[];
  calciumSlope?: number;
  calciumAbsoluteChange?: number;
  calciumPercentageChange?: number;
  normalizedDifferenceVegetationIndex: number[];
  normalizedDifferenceVegetationIndexSlope?: number;
  normalizedDifferenceVegetationIndexAbsoluteChange?: number;
  normalizedDifferenceVegetationIndexPercentageChange?: number;
  freshBiomass: number[];
  freshBiomassSlope?: number;
  freshBiomassAbsoluteChange?: number;
  freshBiomassPercentageChange?: number;
  magnesium: number[];
  magnesiumSlope?: number;
  magnesiumAbsoluteChange?: number;
  magnesiumPercentageChange?: number;
  nitrogen: number[];
  nitrogenSlope?: number;
  nitrogenAbsoluteChange?: number;
  nitrogenPercentageChange?: number;
  iron: number[];
  ironSlope?: number;
  ironAbsoluteChange?: number;
  ironPercentageChange?: number;
  phosphorus: number[];
  phosphorusSlope?: number;
  phosphorusAbsoluteChange?: number;
  phosphorusPercentageChange?: number;
  manganese: number[];
  manganeseSlope?: number;
  manganeseAbsoluteChange?: number;
  manganesePercentageChange?: number;
  leafAreaIndexBezp: number[];
  leafAreaIndexBezpSlope?: number;
  leafAreaIndexBezpAbsoluteChange?: number;
  leafAreaIndexBezpPercentageChange?: number;
  leafAreaIndexMean: number[];
  leafAreaIndexMeanSlope?: number;
  leafAreaIndexMeanAbsoluteChange?: number;
  leafAreaIndexMeanPercentageChange?: number;
  ari: number[];
  ariSlope?: number;
  gli: number[];
  glislope?: number;
  lr: number[];
  lrSlope?: number;
  msr: number[];
  msrSlope?: number;
  ndvi: number[];
  ndviSlope?: number;
  ndre: number[];
  ndreSlope?: number;
  bdrvi: number[];
  bdrviSlope?: number;
  mca: number[];
  mcaSlope?: number;
  pvr: number[];
  pvrSlope?: number;
}

export interface parameterTrend {
  paramName: string;
  slope: number;
	percentageChange: number,
  recentValues: number[];
  currentValue: number;
  downBoundary: number,
  upBoundary: number;
}

export interface parameterTrendResponse extends hexTrends, BaseResponse {

}
