import {ChangeDetectionStrategy, Component, inject, Input} from "@angular/core";
import {createSelector, Store} from "@ngrx/store";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgIf} from "@angular/common";
import {Observable} from "rxjs";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {TranslateModule} from "@ngx-translate/core";
import {AppState} from "src/app/types/app-state";
import { SharedModule } from "src/app/shared/shared.module";
import { RatingModule } from 'primeng/rating';
import { incidentActions } from "../../../../incident/store/actions";
import { selectEvaluationPopupModel, selectIsEvaluationPopupVisible } from "../../../../incident/store/selectors";

@Component({
    selector: 'app-incident-evaluation',
    template: `
    <p-dialog *ngIf="(vm$ | async) as vm" [(visible)]="vm.isPopupVisible" [style]="{'width': '30%', height: '30rem'}" [modal]="true" [header]="'INCIDENT.INCIDENT_EVALUATION.TITLE' | translate" (onHide)="close()">
      <div class="agro-form-property">
		  <label>{{ 'INCIDENT.INCIDENT_EVALUATION.EVALUATION' | translate }}</label>
			  <div class="agro-form-control">
				  <span>
            <p-rating [(ngModel)]="evaluation" [stars]="5" [cancel]="false"></p-rating>
				  </span>
			  </div>
      </div>
      <p-button [label]="'COMMON.SAVE' | translate" (onClick)="submit()" />
      <p-button [label]="'INCIDENT.INCIDENT_EVALUATION.SAVE_WITHOUT_RATING' | translate" (onClick)="close()" />
    </p-dialog>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentEvaluationComponent {
    private store = inject(Store<AppState>);
    @Input() visible: boolean = false;

    evaluation: number | null = null;

    readonly vm$: Observable<incidentEvaluationVm> = this.store.select(incidentEvaluationVmSelector);

    submit = () => this.store.dispatch(incidentActions.hideEvaluateIncidentPopupAndChangeState({evaluation: this.evaluation}));
    close = () => this.store.dispatch(incidentActions.hideEvaluateIncidentPopupAndChangeState({evaluation: null}));
}

interface incidentEvaluationVm {
  isPopupVisible: boolean;
}

const incidentEvaluationVmSelector = createSelector(selectIsEvaluationPopupVisible, selectEvaluationPopupModel, (isPopupVisible, model): incidentEvaluationVm => {
  return { isPopupVisible };
});
