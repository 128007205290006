import {ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AddIncidentBaseComponent} from "./add-incident-base.component";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {combineLatest, Subject, takeUntil} from "rxjs";
import {areaInfoSelector} from "../../../project/project-store/selectors";
import {selectAreas} from "../../../task/store/selectors";
import {incidentActions} from "../../store/actions";
import * as projectActions from '../../../project/project-store/actions'
import {AddIncidentCommand} from "../../models/AddIncidentCommand";
import {AgroMessageService} from "../../../shared/services/agro-message.service";
import {MapboxUtils} from "../../../shared/utils/mapbox-utils";
import lngLatToPoint = MapboxUtils.lngLatToPoint;

@Component({
  templateUrl: './add-incident-base.component.html',
	styles: [
		`
			.add-incident-popup {
				.p-dialog-content {
					padding: 1.5rem;
				}
			}
		`
	],
	encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIncidentPopupComponent extends AddIncidentBaseComponent implements OnInit, OnDestroy {
	private dynamicDialogConfig = inject(DynamicDialogConfig);
	private dynamicDialogRef = inject(DynamicDialogRef);
	private messageService = inject(AgroMessageService);
	private destroy$ = new Subject<void>();

	private lat: number | null = null;
	private lng : number | null = null;

	override ngOnInit() {
		super.ngOnInit();
		this.isPopup = true;

		this.lat = this.dynamicDialogConfig.data.lat;
		this.lng = this.dynamicDialogConfig.data.lng;

		if (this.lng && this.lat) {
			this.store.dispatch(projectActions.getAreaInfoByGps({ lat: this.lat, lng: this.lng }));
		}

		this.form.get('projectAreaId')?.disable();
		this.form.get('elementaryObjectId')?.disable();

		combineLatest([
			this.store.select(areaInfoSelector),
			this.store.select(selectAreas)
		]).pipe(takeUntil(this.destroy$))
			.subscribe(([areaInfo, areas]) => {
				if (areaInfo && areas.length > 0) {
					const {projectAreaId, elementaryObjectId} = areaInfo;

					if (projectAreaId === null || elementaryObjectId === null) {
						// todo remove when elementary object will be nullable in version 1.10
						this.messageService.displayInfoMessage('', 'Adding alert outside of a hole/elementary object is currently unavailable');
						this.dynamicDialogRef.close();
						return;
					}

					this.form.patchValue({projectAreaId, elementaryObjectId});

					if (projectAreaId) {
						this.store.dispatch(incidentActions.changeIncidentArea({areaId: projectAreaId, elementaryObjectId: elementaryObjectId ?? undefined}));
					}
				}
			})
	}

	override submit = () => {
		if (!this.form.valid) {
			return;
		}

		const formValue = this.form.getRawValue();

		const addCommand: AddIncidentCommand = {
			name: formValue.name ?? undefined,
			diseaseId: formValue.diseaseId ?? undefined,
			description: formValue.description ?? undefined,
			projectAreaId: formValue.projectAreaId!,
			elementaryObjectId: formValue.elementaryObjectId ?? undefined,
			priority: formValue.priority ?? undefined,
			gps: { x: this.lng!.toString(), y: this.lat!.toString() }
		}

		this.store.dispatch(incidentActions.addIncident({command: addCommand, createdFromPopup: true}));
		this.dynamicDialogRef.close();
	}

	ngOnDestroy() {
		this.destroy$.next();
	}
}
