import {APP_INITIALIZER, ErrorHandler, inject, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutes} from './routes';
import {AuthService} from './shared/services/auth.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {UserOrganizationService} from './shared/services/userOrganization.service';
import {NavModule} from './nav/nav.module';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from "./shared/shared.module";
import {chooseAreaReducer} from "./project/choose-area/choose-area-store/choose-area-reducer";
import {projectReducer} from "./project/project-store/projectReducer";
import {EffectsModule} from "@ngrx/effects";
import {ProjectEffects} from "./project/project-store/effects";
import {ProjectService} from "./project/services/project.service";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthInterceptor} from "./shared/http-interceptors/auth.interceptor";
import {JWT_OPTIONS, JwtHelperService} from "@auth0/angular-jwt";
import {GrassParameterPopupComponent} from "./grass-parameters-presentation/components/grass-parameters-presentation/grass-parameter-popup/grass-parameter-popup.component";
import {ContextMenuPopupComponent} from "./grass-parameters-presentation/components/grass-parameters-presentation/context-menu-popup/context-menu-popup.component";
import {SprinklerPopupComponent} from "./grass-parameters-presentation/components/grass-parameters-presentation/sprinkler-popup/sprinkler-popup.component";
import {createCustomElement} from "@angular/elements";
import {AppInitializerService} from "./app-initializer.service";
import {routingReducer} from "./routing-store/routing-reducer";
import {metaReducers} from "./meta-reducers";
import {MessageService, ConfirmationService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {RoutingEffects} from "./routing-store/effects";
import {ChooseAreaEffects} from "./project/choose-area/choose-area-store/effects";
import {oneAtlasReducer} from "./project/one-atlas-store/reducer";
import {OneAtlasEffects} from './project/one-atlas-store/effects';
import {reportReducer} from "./project/report-store/reducer";
import {ReportEffects} from "./project/report-store/effects";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {usersFeature} from "./profile/store";
import {UserEffects} from "./profile/store/effects";
import {OtherObjectsEffects} from "./grass-parameters-presentation/store/other-objects/effects/effects";
import {
	changePresentationMode$,
	handleCompositionChange$,
	handleMapSync$,
	photoMapViewFeature
} from "./grass-parameters-presentation/store";
import {otherObjectsReducer} from "./grass-parameters-presentation/store/other-objects/reducer";
import {grassParamsReducer} from "./grass-parameters-presentation/store/grass-params/reducer";
import {DialogService} from "primeng/dynamicdialog";
import {MapIncidentsEffects} from "./grass-parameters-presentation/store/other-objects/effects/map-incidents-effects";
import {SprinklerEffects} from "./grass-parameters-presentation/store/other-objects/effects/sprinkler-effects";
import {
  LaboratoryExaminationPopupComponent
} from "./grass-parameters-presentation/components/grass-parameters-presentation/laboratory-examination-popup/laboratory-examination-popup.component";
import {
  LaboratoryExaminationEffects
} from "./grass-parameters-presentation/store/other-objects/effects/laboratory-examination-effects";
import {CheckboxModule} from 'primeng/checkbox';
import {
  ParameterTrendsPopupComponent
} from "./grass-parameters-presentation/components/grass-parameters-presentation/paramter-trends-popup/parameter-trends-popup.component";
import { taskReducer } from './task/store/reducer';
import {AngularSvgIconModule} from "angular-svg-icon";
import {AdminEffects} from "./admin/store/effects";
import {adminFeature} from "./admin/store";
import {TasksEffects} from "./task/store/effects";
import {GrassParamsEffects} from "./grass-parameters-presentation/store/grass-params/effects";
import { incidentReducer } from './incident/store/reducer';
import { IncidentsEffects } from './incident/store/effects';
import {TaskBoardEffects} from "./task/store/task-board.effects";
import {FullCalendarModule} from "@fullcalendar/angular";
import {TaskCalendarEventComponent} from "./task/task-calendar/components/task-calendar-event.component";
import { AttachmentsEffects } from './shared/store/attachment/effects';
import { attachmentReducer } from './shared/store/attachment/reducer';
import { MapLoaderComponent } from "./grass-parameters-presentation/components/grass-parameters-presentation/map-loader.component";
import {AskAiEffects} from "./incident/store/ask-ai.effects";
import {TaskFilterEffects} from "./task/store/task-filter.effects";
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {uploadedPhotoFilesFeature} from "./project/upload-photos/store";
import {UploadedPhotoFilesEffects} from "./project/upload-photos/store/effects";
import {ExaminationsEffects} from "./examinations/store/effects";
import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import {golfClubMemberFeature} from "./golf-club-member/store";
import {GolfClubMemberEffects} from "./golf-club-member/store/effects";
import { examinationFeature } from './examinations/store';
import {CommonEffects} from "./shared/store/common/effects";
import { helpDeskFeature } from './admin/store/application-index';
import { HelpDeskEffects } from './admin/store/application-effects';
import { ApplicationInterceptor } from './shared/http-interceptors/application.interceptor';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localePl);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    NavModule,
    GooglePlaceModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes),
    StoreModule.forRoot({
      projects: projectReducer,
      chooseArea: chooseAreaReducer,
      grassParams: grassParamsReducer,
      otherObjects: otherObjectsReducer,
      oneAtlas: oneAtlasReducer,
      pbiReport: reportReducer,
      routing: routingReducer,
      incidents: incidentReducer,
      tasks: taskReducer,
      attachments: attachmentReducer,
    }, {metaReducers}),
    StoreModule.forFeature(usersFeature),
    StoreModule.forFeature(photoMapViewFeature),
		StoreModule.forFeature(adminFeature),
    StoreModule.forFeature(helpDeskFeature),
		StoreModule.forFeature(uploadedPhotoFilesFeature),
		StoreModule.forFeature(examinationFeature),
		StoreModule.forFeature(golfClubMemberFeature),
    EffectsModule.forFeature([
			{'changePresentationMode': changePresentationMode$},
			{'handleMapsSync': handleMapSync$},
			{'handleCompositionChange': handleCompositionChange$},
		]),
    EffectsModule.forRoot([
			ProjectEffects,
			OneAtlasEffects,
			ReportEffects,
			GrassParamsEffects,
			RoutingEffects,
			ChooseAreaEffects,
			UserEffects,
			AdminEffects,
      HelpDeskEffects,
			OtherObjectsEffects,
			MapIncidentsEffects,
			SprinklerEffects,
			LaboratoryExaminationEffects,
			IncidentsEffects,
			TasksEffects,
			AttachmentsEffects,
			TaskBoardEffects,
			AskAiEffects,
			TaskFilterEffects,
			UploadedPhotoFilesEffects,
			GolfClubMemberEffects,
			ExaminationsEffects,
			CommonEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    SharedModule,
    BrowserAnimationsModule,
    ToastModule,
    ConfirmDialogModule,
    CheckboxModule,
    AngularSvgIconModule.forRoot(),
    FullCalendarModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
  ],
  providers: [
    AuthService,
    UserOrganizationService,
    ProjectService,
    { provide: HTTP_INTERCEPTORS, useClass: ApplicationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: (initializerService: AppInitializerService) => () => initializerService.init(),
      multi: true,
      deps: [AppInitializerService]
    },
    MessageService,
    ConfirmationService,
    DialogService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private injector = inject(Injector);

  constructor() {
    this.defineCustomComponents();
  };

  private defineCustomComponents = () => {
    const injector = this.injector;
    customElements.define("app-grass-parameter-popup", createCustomElement(GrassParameterPopupComponent, { injector }));
    customElements.define("app-context-menu-popup", createCustomElement(ContextMenuPopupComponent, { injector }));
    customElements.define("app-sprinkler-popup", createCustomElement(SprinklerPopupComponent, { injector }));
    customElements.define('app-laboratory-object-popup', createCustomElement(LaboratoryExaminationPopupComponent, { injector }));
    customElements.define('app-parameters-trends-popup', createCustomElement(ParameterTrendsPopupComponent, { injector }));
		customElements.define('app-map-loader', createCustomElement(MapLoaderComponent, { injector }));
    customElements.define('app-task-calendar-event', createCustomElement(TaskCalendarEventComponent, { injector }));
  }
}
