import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {mapIncidentActions, otherObjectActions} from "../actions";
import {catchError, map, of, switchMap, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../types/app-state";
import * as projectSelectors from "../../../../project/project-store/selectors";
import { IncidentAPIService } from "src/app/incident/incidentAPI.service";
import {AgroMessageService} from "../../../../shared/services/agro-message.service";

@Injectable()
export class MapIncidentsEffects {
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);

  showAddIncidentPage$ = createEffect(() => this.actions$.pipe(
    ofType(mapIncidentActions.showAddIncidentPage),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
      switchMap(() => of()),
      catchError((error) => of(otherObjectActions.handleError(error)))
    ))

  showEditIncidentPage$ = createEffect(() => this.actions$.pipe(
    ofType(mapIncidentActions.showEditIncidentPage),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
      switchMap(() => of()),
      catchError((error) => of(otherObjectActions.handleError(error)))
  ))
}
