<ng-container *ngIf="attachments$ | async as attachments">
	<div class="form-group gap-6 row align-center mb-25">
		<p class="font-size-13">{{ 'SHARED.ATTACHMENT.TITLE' | translate }}</p>
		<span class="circle-info">{{ attachments.length }}</span>
	</div>

	<div id="attachments-main-container" class="w-100 form-group row gap-25">
		<div class="upload-files-view-container left w-60 style-2">
			<section class="photo-file-uploader-container">
				<div class="photo-file-uploader" *ngIf="{value: progressValue$ | async} as uploadProgress">
					<ng-container *ngIf="uploadProgress.value !== null">
						<p-fileUpload #attachmentUpload accept="image/*, video/*, .pdf" [customUpload]="true"
													(uploadHandler)="uploadAttachmentFile($event, attachments)" [showCancelButton]="false"
													uploadStyleClass="p-button-success w-195" cancelStyleClass="p-button-success w-195"
													chooseStyleClass="p-button-success p-button-outline w-195" class="file-uploader-component"
													[chooseLabel]="'PROJECT.UPLOAD_PHOTOS.CHOOSE' | translate"
													[uploadLabel]="'PROJECT.UPLOAD_PHOTOS.UPLOAD' | translate"
													[cancelLabel]="'COMMON.CANCEL' | translate"
												styleClass="file-uploader-component">
							<ng-template pTemplate="content" let-files>
								<div class="info">
									<p>{{ 'SHARED.ATTACHMENT.CHOOSE' | translate }}</p>
									<span class="py-3">{{ 'SHARED.ATTACHMENT.SUPPORTED_FORMAT' | translate }}</span>
								</div>
							</ng-template>
							<ng-template let-file pTemplate="file">
								<div class="photo-file-information">{{file.name}}</div>
							</ng-template>
						</p-fileUpload>
					</ng-container>
				</div>
			</section>
		</div>

		<div class="right w-40">
			<div *ngIf="attachments.length > 0" class="attachments-panel">
				<div class="attach-container" *ngFor="let attachment of attachments">
					<div *appCheckProjectFeaturePermission="FeatureName.ReadAttachment">
						<button pButton pRipple type="button" (click)="onAttachmentDelete(attachment)"
									class="p-button-rounded p-button-text" pTooltip="Delete" tooltipPosition="top">
							<svg-icon src="./assets/images/icons/trash.svg" />
						</button>
						<a href="#" (click)="onOpenAttachment($event, attachment)" class="file-name">{{ attachment.fileName }}</a>
					</div>
				</div>
			</div>
			<div *ngIf="attachments.length === 0" class="h-100 form-group align-center justify-center">
				<p class="text-center font-size-13">{{ 'SHARED.ATTACHMENT.NO_ATTACHMENT' | translate }}</p>
			</div>
		</div>
	</div>
</ng-container>
