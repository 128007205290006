import { createSelector } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { taskState } from "./taskState";
import {selectUserSettings} from "../../profile/store";
import {MeasurementSystem} from "../../types/MeasurementSystem";
import {calculateAreaForTaskOrIncident} from "../../shared/utils/display-size";
import {
	convertCelsiusToFahrenheit, convertMillimetersToInches
} from "../../grass-parameters-presentation/components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/utils/weather.utils";

export const selectTaskFeature = (state: AppState) => state.tasks;
export const selectIsLoading = createSelector(selectTaskFeature, (state: taskState) => state?.isLoading);
export const selectTasks = createSelector(selectTaskFeature, (state: taskState) => state?.tasks);
export const selectTotalRecords = createSelector(selectTaskFeature, (state: taskState) => state?.totalRecords);
export const selectTaskTypes = createSelector(selectTaskFeature, (state: taskState) => state?.taskTypes);
export const selectAreas = createSelector(selectTaskFeature, (state: taskState) => state?.areas);

export const selectStatusSwitchConfig = createSelector(selectTaskFeature, (state) => state.taskBoardSwitchConfig);

export const selectStatusSwitchNeedsCompletion = createSelector(selectStatusSwitchConfig, (config) => config?.needsCompletion || false);

export const selectStatusSwitchNeedsConfirmation = createSelector(selectStatusSwitchConfig, (config) => config?.needsConfirmation || false)

export const selectEditedTaskBoardItemId = createSelector(selectTaskFeature, (state) => state.editedTaskBoardItemId);

export const selectStatusSwitchCanBeCompleted = createSelector(selectStatusSwitchConfig, selectEditedTaskBoardItemId, (config, taskId): boolean => {
	return config !== undefined && !config.needsCompletion && !config.needsConfirmation && taskId !== undefined;
})

export const selectCurrentTaskBoardFilter = createSelector(selectTaskFeature, (state) => state.currentTaskBoardFilter);

export const selectCurrentTaskCalendarFilter = createSelector(selectTaskFeature, (state) => state.currentCalendarFilter);

export const selectCurrentTaskListFilter = createSelector(selectTaskFeature, (state) => state.currentTaskListFilter);

export const selectAssignedUsers = createSelector(selectTaskFeature, (state): assignedUserVm[] => {
	const uniqueUsers = state.tasks
		.filter(({ assignedUserName }) => assignedUserName)
		.reduce<Record<string, assignedUserVm>>((acc, { assignedUserName }) => {
			if (!acc[assignedUserName]) {
				acc[assignedUserName] = {
					userName: assignedUserName,
					initials: assignedUserName.split(' ').map(word => word.substring(0, 1).toUpperCase()).join('')
				};
			}
			return acc;
		}, {});

	return Object.values(uniqueUsers);
})

export interface assignedUserVm {
	userName: string;
	initials: string;
}

export const selectTaskArea = createSelector(selectTaskFeature, ({taskArea}) => taskArea);

export const selectTaskElementaryObject = createSelector(selectTaskFeature, ({taskElementaryObject}) => taskElementaryObject);

export const selectEditedTask = createSelector(selectTaskFeature, ({taskWithDetails}) => taskWithDetails);

export const selectTaskComments = createSelector(selectTaskFeature, ({taskWithDetails}) => taskWithDetails?.comments || []);

export const selectSelectedIncidents = createSelector(selectTaskFeature, ({ selectedIncidents }) => selectedIncidents);

export const selectTasksPresentationMode = createSelector(selectTaskFeature, ({taskPresentationMode}) => taskPresentationMode);

export const selectChangesHistory = createSelector(selectTaskFeature, ({changesHistory}) => changesHistory);

export const selectAvailableTaskUsers = createSelector(selectTaskFeature, ({availableUsers}) => availableUsers);

// todo change naming from area to hole to avoid confusion
export const selectTaskCalculatedArea = createSelector(selectTaskArea, selectTaskElementaryObject, selectUserSettings, (area, elementaryObject, {measurementSystem}): string | undefined => {
	return calculateAreaForTaskOrIncident(measurementSystem!, elementaryObject, area);
});

export const selectWeatherForTask = createSelector(selectTaskFeature, (state) => state.taskMeasurements);

export const selectWeatherForTaskByMeasurementSystem = createSelector(selectWeatherForTask, selectUserSettings, (measurements, userSettings) => {
	if (userSettings.measurementSystem === MeasurementSystem.Imperial) {
		return measurements.map(m =>
			({
				...m,
				temperature: convertCelsiusToFahrenheit(m.temperature),
				minimumTemperature: convertCelsiusToFahrenheit(m.minimumTemperature),
				maximumTemperature: convertCelsiusToFahrenheit(m.maximumTemperature),
				precipitation: convertMillimetersToInches(m.precipitation)
			}))
	}

	return measurements;
})
