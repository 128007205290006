import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {changeLog} from "../model/change-log";

@Injectable({
	providedIn: 'root'
})
export class LogsApiService {
	private http = inject(HttpClient);
	private readonly baseUrl = environment.projectApiUrl;

	getTaskLogs = (taskId: string): Observable<changeLog[]> => this.http.get<changeLog[]>(`${this.baseUrl}tasks/${taskId}/logs`);
	getIncidentLogs = (incidentId: string): Observable<changeLog[]> => this.http.get<changeLog[]>(`${this.baseUrl}incidents/${incidentId}/logs`);
}
