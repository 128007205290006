import {Component, inject} from '@angular/core';
import {LoadPanelService} from "../../services/load-panel.service";
import {loadPanelConfig, LoadPanelDefaultConfig} from "../../config/load-panel-default.config";

@Component({
  selector: 'app-load-panel',
  template: `
    <div *ngIf="isVisible$ | async" class="load-panel-wrapper">
      <p-progressSpinner />
    </div>
  `,
  styles: [
    `
      .load-panel-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.4);
        transition-duration: 0.2s;
        z-index: 9999;
      }
    `
  ]
})
export class LoadPanelComponent  {
  // todo display optional message

  private loadPanelService = inject(LoadPanelService);

  isVisible$ = this.loadPanelService.isVisible$;
  message$ = this.loadPanelService.message$;

  config: LoadPanelDefaultConfig = loadPanelConfig;
}
