<div class="login-help">
	<p class="login-help-menu-title">{{ 'COMMON.CONTACT_SUPPORT' | translate }}</p>
  <a [href]="environment.needHelpUrl" target="_blank" class="w-100">
		<div class="flex form-group row justify-space-between align-items-center">
			<span class="need-help-box">
				<img src="./assets/images/caht-question.svg" alt="Need help"/>
				<div i18n>{{ 'COMMON.CONTACT_SUPPORT' | translate }}</div>
			</span>
		</div>
  </a>
</div>
