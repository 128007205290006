import {createAction, props} from "@ngrx/store";
import {PolygonId} from "../../model/polygon-id";
import {AreaFormPopupModel} from "../../model/area-form-popup-model";
import {Geometry} from "geojson";
import {uuid} from "aws-sdk/clients/customerprofiles";
import {AreaMapboxModel} from "../../model/area-mapbox-model";
import {ProjectInterface} from "../../model/project.interface";
import {AddProjectDto} from "../../DTO/add-project-dto";

export const changeSelectedAreaName = createAction("[Choose Area] Change selected area name", props<{polygonObject: PolygonId}>());
export const loadProjectAreas = createAction(("[Choose Area] Load Project Areas"), props<{projectId: number}>());
export const loadProjectAreasSuccess = createAction("[Choose Area] Load Project Areas Success", props<{drawnAreas: AreaMapboxModel[]}>());
export const addArea = createAction("[Choose Area] Add Area", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const addElementaryObject = createAction("[Choose Area] Add Elementary Object", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const editArea = createAction("[Choose Area] Edit Area", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const editAreaWithValidation = createAction("[Choose Area] Edit Area With Validation", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const editElementaryObject = createAction("[Choose Area] Edit Elementary Object", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const deleteArea = createAction("[Choose Area] Delete Area", props<{form: AreaFormPopupModel, polygon: Geometry, idsToDelete: uuid[]}>());
export const deleteElementaryObject = createAction("[Choose Area] Delete Elementary Object", props<{form :AreaFormPopupModel, polygon: Geometry}>());
export const selectArea = createAction("[Choose Area] Select Area On Sidebar", props<{areaId: uuid, selectedOnMap: boolean}>());
export const expandArea = createAction("[Choose Area] Expand Area On Sidebar", props<{areaId: uuid}>());
export const selectElementaryObject = createAction("[Choose Area] Select Elementary Object On Sidebar", props<{elementaryObjectId: uuid, selectedOnMap: boolean}>());
export const startNewAreaDraw = createAction("[Choose Area] Start New Area Draw", props<{areaId: uuid}>());
export const startNewElementaryObjectDraw = createAction("[Choose Area] Start New Elementary Object Draw", props<{elementaryObjectId: uuid}>());
export const editElementaryObjectWithValidation = createAction("[Choose Area] Edit Elementary Object With Validation", props<{form: AreaFormPopupModel, polygon: Geometry}>());
export const handleValidationFailure = createAction("[Choose Area] Handle Validation Failure");
export const createDraw = createAction("[Choose Area] Create Draw", props<{form: AreaFormPopupModel, polygon: Geometry, isElementaryObject: boolean}>());
export const createDrawSuccess = createAction("[Choose Area] Create Draw Success");
export const unselect = createAction('[Choose Area] Unselect');
export const editProject = createAction('[Choose Area] Edit Project', props<{project: ProjectInterface}>());
export const updateFromIGolf = createAction('[Choose] Update from iGolf', props<{organizationId: number, projectId: number}>());
export const editProjectSuccess = createAction('[Choose Area] Edit Project Success' , props<{projectId: number, areasCount: number}>());
export const initAreasForNewProject = createAction('[Choose Area] Init Areas For New Project', props<{newProject: AddProjectDto}>());
export const handleError = createAction('[Choose Area] Handle Error', props<{ errorMessage: string }>());
