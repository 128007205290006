import {inject, Injectable} from "@angular/core";
//@ts-ignore
import Compare from 'mapbox-gl-compare';
import {GrassParametersMapsService} from "./grass-parameters-maps.service";

@Injectable({
	providedIn: 'root'
})
export class MapsInteractiveCompareService {
	compare?: any;
	private mapsService = inject(GrassParametersMapsService);

	addInteractiveCompare = () => {
		this.clearCompare();
		const maps = this.mapsService.maps.map(({map, mapId}) => ({map, mapId}));

		if (!this.compare) {
			const before = maps.find(m => m.mapId === 'map-0')?.map;
			const after = maps.find(m => m.mapId === 'map-1')?.map;

			if (before && after) {
				setTimeout(() => {
					this.compare = new Compare(before, after, '#comparison-container', {});
					before.resize();
					after.resize();
				}, 0)
			}
		}
	}

	clearCompare = () => {
		if (this.compare) {
			this.compare.remove();
			this.compare = undefined;
		}
	}
}
