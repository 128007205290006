import {inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, of, tap, withLatestFrom} from "rxjs";
import {AppState} from "../../types/app-state";
import {selectedProjectIdSelector} from "../../project/project-store/selectors";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import {HttpErrorService} from "../../shared/services/http-error.service";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {examinationsActions, selectExamination, selectFilter} from "./index";
import
{
	getGreenSpeedExaminationResponse,
	addGreenSpeedExaminationResponse,
	ExaminationsApiService,
	getGreenSpeedNormsResponse,
	getGreenSpeedNormResponse,
	changeGreenSpeedNormResponse,
	getExaminationsResponse,
	getHolesResponse,
	getHoleObjectsResponse,
	addLaboratoryExaminationResponse,
	getLaboratoryExaminationResponse,
	addBiomassExaminationResponse,
	getBiomassExaminationResponse,
	addAcidityExaminationResponse,
	getAcidityExaminationResponse
} from "../api/examination.api.service";

@Injectable()
export class ExaminationsEffects {
	private store = inject(Store<AppState>);
	private actions$ = inject(Actions);
	private messageService = inject(AgroMessageService);
	private httpErrorService = inject(HttpErrorService);
	private router = inject(Router);
	private examinationApiService = inject(ExaminationsApiService);

	loadExaminations$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.loadExaminations),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) => {
			return this.examinationApiService.getExaminations(projectId!, args.filter).pipe(
				map((response: getExaminationsResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.loadExaminationsSuccess({ examinations: response.examinations, totalRecords: response.totalRecords });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		})
	));

	refreshExaminations$ = createEffect(() => this.actions$.pipe(
		ofType(
			examinationsActions.refreshExaminations,
			examinationsActions.filterExaminationsByExaminationType,
			examinationsActions.filterExaminationsByHoles,
			examinationsActions.filterExaminationsByHoleObjectType,
			examinationsActions.filterExaminationsByDate,
			examinationsActions.filterExaminationsByLazyLoad),
		withLatestFrom(this.store.select(selectFilter)),
		map(([_, filter]) => {
			return examinationsActions.loadExaminations({filter});
		})
	));

 	getProjectHoles$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getProjectHoles),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getHoles(projectId!).pipe(
				map((response: getHolesResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getProjectHolesSuccess({holes: response.holes});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	getProjectHoleObjects$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getProjectHoleObjects),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) => {
			if (!args.holeId) {
				return of(examinationsActions.getProjectHoleObjectsSuccess({ holeObjects: [] }));
			}

			return this.examinationApiService.getHoleObjects(projectId!, args.holeId).pipe(
				map((response: getHoleObjectsResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getProjectHoleObjectsSuccess({ holeObjects: response.objects });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		})
	));
	addGreenSpeedExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addGreenSpeedExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.addGreenSpeedExamination(projectId!, args.dto).pipe(
				map((response: addGreenSpeedExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.addGreenSpeedExaminationSuccess({ dto: args.dto, id: response.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addGreenSpeedExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addGreenSpeedExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() => this.messageService.displaySuccessMessage('', `Green speed examinations was added successfully`))
		)), {dispatch: false});

	getGreenSpeedExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getGreenSpeedExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getGreenSpeedExamination(projectId!, args.id).pipe(
				map((response: getGreenSpeedExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getGreenSpeedExaminationSuccess({examination: response.examination});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeGreenSpeedExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeGreenSpeedExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.changeGreenSpeedExamination(projectId!, args.id, args.dto).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.changeGreenSpeedExaminationSuccess({ id: args.id, dto: args.dto });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeGreenSpeedExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeGreenSpeedExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['green-speed-examinations', projectId]).then(() =>
				this.messageService.displaySuccessMessage('', `Green speed examinations was changed successfully`))
		)), {dispatch: false});

	removeGreenSpeedExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.removeGreenSpeedExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.removeGreenSpeedExamination(projectId!, args.id).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.removeGreenSpeedExaminationSuccess({ id: args.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addLaboratoryExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.addLaboratoryExamination(projectId!, args.dto).pipe(
				map((response: addLaboratoryExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.addLaboratoryExaminationSuccess({ dto: args.dto, id: response.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addLaboratoryExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addLaboratoryExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() => this.messageService.displaySuccessMessage('', `Laboratory examinations was added successfully`))
		)), {dispatch: false});

	getLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getLaboratoryExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) => {
			return this.examinationApiService.getLaboratoryExamination(projectId!, args.id).pipe(
				map((response: getLaboratoryExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getLaboratoryExaminationSuccess({examination: response.laboratoryExamination});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)}
		)
	));

	changeLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeLaboratoryExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.changeLaboratoryExamination(projectId!, args.id, args.dto).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.changeLaboratoryExaminationSuccess({ id: args.id, dto: args.dto });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeLaboratoryExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeLaboratoryExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() =>
				this.messageService.displaySuccessMessage('', `Laboratory examinations was changed successfully`))
		)), {dispatch: false});

	removeLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.removeLaboratoryExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.removeLaboratoryExamination(projectId!, args.id).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.removeLaboratoryExaminationSuccess({ id: args.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addBiomassExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addBiomassExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.addBiomassExamination(projectId!, args.dto).pipe(
				map((response: addBiomassExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.addBiomassExaminationSuccess({ dto: args.dto, id: response.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addBiomassExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addBiomassExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() => this.messageService.displaySuccessMessage('', `Cutting volume was added successfully`))
		)), {dispatch: false});

	getBiomassExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getBiomassExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getBiomassExamination(projectId!, args.id).pipe(
				map((response: getBiomassExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getBiomassExaminationSuccess({examination: response.examination});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeBiomassExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeBiomassExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.changeBiomassExamination(projectId!, args.id, args.dto).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.changeBiomassExaminationSuccess({ id: args.id, dto: args.dto });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeBiomassExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeBiomassExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() =>
				this.messageService.displaySuccessMessage('', `Cutting volume was changed successfully`))
		)), {dispatch: false});

	removeBiomassExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.removeBiomassExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.removeBiomassExamination(projectId!, args.id).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.removeBiomassExaminationSuccess({ id: args.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));


	addAcidityExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addAcidityExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.addAcidityExamination(projectId!, args.dto).pipe(
				map((response: addAcidityExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.addAcidityExaminationSuccess({ dto: args.dto, id: response.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	addAcidityExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.addAcidityExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() => this.messageService.displaySuccessMessage('', `Acidity examinations was added successfully`))
		)), {dispatch: false});

	getAcidityExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getAcidityExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getAcidityExamination(projectId!, args.id).pipe(
				map((response: getAcidityExaminationResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getAcidityExaminationSuccess({examination: response.examination});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeAcidityExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeAcidityExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.changeAcidityExamination(projectId!, args.id, args.dto).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.changeAcidityExaminationSuccess({ id: args.id, dto: args.dto });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeAcidityExaminationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeAcidityExaminationSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) =>
			this.router.navigate(['examinations', projectId]).then(() =>
				this.messageService.displaySuccessMessage('', `Acidity examinations was changed successfully`))
		)), {dispatch: false});

	removeAcidityExamination$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.removeAcidityExamination),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.removeAcidityExamination(projectId!, args.id).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.removeAcidityExaminationSuccess({ id: args.id });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	getGreenSpeedNorms$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getGreenSpeedNorms),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getGreenSpeedNorms(projectId!).pipe(
				map((response: getGreenSpeedNormsResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getGreenSpeedNormsSuccess({norms: response.norms});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	loadHoleObjectsForExamination$ = createEffect(() =>
		this.actions$.pipe(
			ofType(examinationsActions.getLaboratoryExaminationSuccess),
			withLatestFrom(this.store.select(selectExamination)),
			map(([_, examination]) => examinationsActions.getProjectHoleObjects({holeId: examination?.projectAreaId ?? null}))
		)
	)

	getGreenSpeedNorm$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.getGreenSpeedNorm),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.getGreenSpeedNorm(projectId!, args.greenSpeedNormId).pipe(
				map((response: getGreenSpeedNormResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.getGreenSpeedNormSuccess({norm: response.norm});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeGreenSpeedNorm$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeGreenSpeedNorm),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) =>
			this.examinationApiService.changeGreenSpeedNorm(projectId!, args.dto).pipe(
				map((response: changeGreenSpeedNormResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return examinationsActions.handleError();
					}

					return examinationsActions.changeGreenSpeedNormSuccess({ id: response.greenSpeedNormId });
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(examinationsActions.handleError());
				})
			)
		)
	));

	changeGreenSpeedNormSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(examinationsActions.changeGreenSpeedNormSuccess),
		map((args) => examinationsActions.getGreenSpeedNorm({greenSpeedNormId: args.id }))
	))
}
