import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {taskActions, taskBoardActions, taskCalendarActions, taskListActions} from "./actions";
import {map, withLatestFrom} from "rxjs";
import {selectCurrentTaskBoardFilter, selectCurrentTaskCalendarFilter, selectCurrentTaskListFilter} from "./selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../types/app-state";

@Injectable()
export class TaskFilterEffects {
	private actions$ = inject(Actions);
	private store = inject(Store<AppState>);

	getTaskBoardTasks$ = createEffect(() => this.actions$.pipe(
		ofType(
			taskActions.switchToKannbannMode,
			taskBoardActions.getTaskBoardTasks,
			taskBoardActions.filterByElementaryObjectType,
			taskBoardActions.filterByAreas,
			taskBoardActions.filterByDueDate,
			taskBoardActions.filterByTaskTypes,
			taskBoardActions.filterByAssignedUserId
		),
		withLatestFrom(this.store.select(selectCurrentTaskBoardFilter)),
		map(([_, filter]) => taskActions.loadTasks(filter))
	));

	getCalendarTasks$ = createEffect(() => this.actions$.pipe(
		ofType(
			taskActions.switchToCalendarMode,
			taskCalendarActions.getTaskCalendarTasks,
			taskCalendarActions.filterByTaskTypes,
			taskCalendarActions.filterByAreas,
			taskCalendarActions.filterByElementaryObjectType
		),
		withLatestFrom(this.store.select(selectCurrentTaskCalendarFilter)),
		map(([_, filter]) => taskActions.loadTasks(filter))
	));

	getTaskListTasks$ = createEffect(() => this.actions$.pipe(
		ofType(
			taskActions.switchToListMode,
			taskListActions.loadTaskList
		),
		withLatestFrom(this.store.select(selectCurrentTaskListFilter)),
		map(([_, filter]) => taskActions.loadTasks(filter))
	));
}
