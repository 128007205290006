export enum taskStatus
{
	New = "New",
	Scheduled = "Scheduled",
	InProgress = "InProgress",
	Canceled = "Canceled",
	Suspended = "Suspended",
	Closed = "Closed"
}

export interface taskStatusSwitch {
	currentStatus: taskStatus,
	targetStatus: taskStatus
	needsConfirmation: boolean;
	needsCompletion: boolean;
	warningMessage?: string;
	action: TaskSwitchAction;
}

export enum TaskSwitchAction {
	Schedule = 'Schedule',
	Start = 'Start',
	Cancel = 'Cancel',
	Close = 'Close'
}

export const taskStatusSwitchConfig: taskStatusSwitch[] = [
	{
		currentStatus: taskStatus.New,
		targetStatus: taskStatus.Canceled,
		needsCompletion: false,
		needsConfirmation: true,
		warningMessage: 'Do you really want to cancel this task?',
		action: TaskSwitchAction.Cancel
	},
	{
		currentStatus: taskStatus.New,
		targetStatus: taskStatus.Scheduled,
		needsCompletion: true,
		needsConfirmation: false,
		action: TaskSwitchAction.Schedule
	},
	{
		currentStatus: taskStatus.New,
		targetStatus: taskStatus.InProgress,
		needsCompletion: true,
		needsConfirmation: false,
		action: TaskSwitchAction.Start
	},
	{
		currentStatus: taskStatus.Scheduled,
		targetStatus: taskStatus.Canceled,
		needsCompletion: false,
		needsConfirmation: true,
		warningMessage: 'Do you really want to cancel this task?',
		action: TaskSwitchAction.Cancel
	},
	{
		currentStatus: taskStatus.Scheduled,
		targetStatus: taskStatus.InProgress,
		needsCompletion: true,
		needsConfirmation: false,
		action: TaskSwitchAction.Start
	},
	{
		currentStatus: taskStatus.InProgress,
		targetStatus: taskStatus.Closed,
		needsCompletion: false,
		needsConfirmation: false,
		action: TaskSwitchAction.Close
	}
]
