import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { EtagPartNumber } from '../model/etag-part-number';
import {
  CompleteMultipartUploadInterface,
  GetAttachmentUrlResponse,
  HistoryUploadItem,
  MultipartUploadInterface,
  PreSigneUrlInterface,
  UploadFileInfo
} from '../model/upload.interface';
import { uploadFileInformationDto } from '../model/upload-file-information-dto';
import { HttpErrorService } from 'src/app/shared/services/http-error.service';
import { RepeatProcessingFileUploadDto } from '../DTO/repeat-processing-file-upload-dto';
import { AuthService } from "../../shared/services/auth.service";
import { BaseResponse } from "../../shared/model/api-responses";
import { AgroMessageService } from 'src/app/shared/services/agro-message.service';
import { AttachmentDTO } from 'src/app/shared/model/attachmentDTO';
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  updateFileUploadProgress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private baseUrl: string = environment.projectApiUrl;
  private uploadResult$: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private httpErrorService: HttpErrorService, private authService: AuthService,
    private messageService: AgroMessageService) { }
  getFileUploadProgress = () => this.updateFileUploadProgress$.asObservable();
  getUploadResult = () => this.uploadResult$.asObservable();
  updateUploadResult = (uploadResult: boolean) => this.uploadResult$.next(uploadResult);
  private requestInProgress?: Subscription;
  private uploadCanceled: boolean = false;

  getUploadFiles(projectId: number): Observable<HistoryUploadItem[]> {
    const url = `${this.baseUrl}projects/${projectId}/upload-files`;
    return this.http.get<HistoryUploadItem[]>(url);
  }

  getUploadFileInformation(projectId: number, snapshotId: number): Observable<UploadFileInfo> {
    const url = `${this.baseUrl}projects/${projectId}/versions/${snapshotId}/upload-files-information`;
    return this.http.get<UploadFileInfo>(url);
  }

	getPresignedDownloadUrl = (uploadFileId: number) => {
		const url = `${this.baseUrl}get-uploaded-photo-presigned-download-url/${uploadFileId}`;
		return this.http.get<getUploadedPhotoFilePresignedUrlResponse>(url);
	}

  updateUploadFileInformation = (uploadFileId: number, updateModel: HistoryUploadItem): Observable<baseResponse> => this.http.put<baseResponse>(`${this.baseUrl}uploadFileInformation/${uploadFileId}`, updateModel);

  uploadFile(file: File, organizationId: number, projectId: number, dateOfPhoto: Date, photoType: string,
    uploadUrl: string, completeUploadUrl: string): void {
    this.uploadCanceled = false;
    const fileChunkSize = environment.chunkSize
    const fileSize = file.size;
    const chunksNumber = Math.floor(fileSize / fileChunkSize) + 1;

		let filePath: string;

		if (organizationId > 0 && projectId > 0) {
			filePath = `${organizationId}/${projectId}/${new Date().getTime()}/${file.name}`;
		} else {
			filePath = file.name;
		}

    this.startMultipartUpload(filePath, file.type, chunksNumber, uploadUrl).subscribe({
      next: (result: MultipartUploadInterface) => {
        const uploadFIleInformationDto: uploadFileInformationDto = {
          photoType: photoType,
          dateOfPhoto: dateOfPhoto,
          fileName: file.name,
          fileSize: file.size.toString(),
          uploader: this.authService.getUserEmail() ?? 'unknown',
          filePathUrl: filePath,
          fileType: file.type,
          projectId: projectId,
          organizationId: organizationId
        }
        if (!result.success && result.error) {
          this.messageService.displayErrorMessage('Error', result.error);
          return;
        }
        this.uploadFileInChunks(result, fileChunkSize, chunksNumber, file, uploadFIleInformationDto, completeUploadUrl);
      },
      error: (error: HttpErrorResponse) => {
        this.httpErrorService.handleError(error);
      }
    });
  }

  repeatProcessingUploadFile(projectId: number, uploadFileId: number): Observable<baseResponse> {
    const url = `${this.baseUrl}repeatProcessingUploadFile`;
    const command: RepeatProcessingFileUploadDto = {
      projectId: projectId,
      uploadFileId: uploadFileId
    };
    return this.http.post<baseResponse>(url, command);
  }

	processUploadedFile = (projectId: number, uploadFileId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}process-uploaded-file`;
		return this.http.post<baseResponse>(url, {projectId, uploadFileId})
	}

  removeUploadFile = (projectId: number, projectVersionId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/remove-upload-file/${projectVersionId}`;
		return this.http.delete<baseResponse>(url);
	}

  private uploadFileInChunks(uploadFile: MultipartUploadInterface, fileChunkSize: number, chunksNumber: number, file: File, uploadFileInformationDto: uploadFileInformationDto,
    completeUploadUrl: string): void {
    let uploadPartsArray: EtagPartNumber[] = [];
    let totalBytesUploaded = 0;

    uploadFile.preSigneUrl.map((res: PreSigneUrlInterface) => {
      let start = (res.partNumber - 1) * fileChunkSize;
      let end = (res.partNumber) * fileChunkSize;
      let blob = (res.partNumber < chunksNumber) ? file.slice(start, end) : file.slice(start);

      const headers = new HttpHeaders().set('Content-Type', file.type);

      let req = new HttpRequest('PUT', res.preSigneUrl, blob, {
        reportProgress: true,
        headers: headers,
      });

      let previousBytesUploaded = 0;

      this.requestInProgress = this.http
        .request(req)
        .subscribe({
          next: (event: HttpEvent<any>) => {
            if (this.uploadCanceled) {
              this.updateFileUploadProgress$.next(0);
              return;
            }
            if (event.type === HttpEventType.UploadProgress) {
              const httpProgressEvent = event as HttpProgressEvent;
              const currentBytesUploaded = httpProgressEvent.loaded;
              totalBytesUploaded = totalBytesUploaded - previousBytesUploaded + currentBytesUploaded;
              this.updateFileUploadProgress$.next((totalBytesUploaded / file.size) * 100);
              previousBytesUploaded = currentBytesUploaded;
            }

            if (event instanceof HttpResponse) {
              uploadPartsArray.push({
                etag: event.headers.get('ETag')?.replace(/[|&;$%@"<>()+,]/g, ''),
                partNumber: res.partNumber
              });
              if (uploadPartsArray.length === chunksNumber) {
                this.requestInProgress = this.completeMultipartUpload(uploadFileInformationDto.filePathUrl, file.type, uploadFile.uploadId, uploadPartsArray,
                  completeUploadUrl).subscribe({
                  next: () => {
                    this.saveUploadFileInformation(uploadFileInformationDto);
                  },
                  error: (error: HttpErrorResponse) => {
                    this.httpErrorService.handleError(error);
                    this.updateFileUploadProgress$.next(0);
                  }
                });
              }
            }
          },
          error: (error: HttpErrorResponse) => {
            this.httpErrorService.handleError(error);
            this.updateFileUploadProgress$.next(0);
          }
        });
    }
    )
  }

  private startMultipartUpload(key: string, contentType: string, partNumberCount: number,
    uploadUrl: string): Observable<MultipartUploadInterface> {
    let params = new HttpParams();
    params = params.append('key', key);
    params = params.append('contentType', contentType);
    params = params.append('partNumberCount', partNumberCount);
    return this.http.get<MultipartUploadInterface>(this.baseUrl + uploadUrl, { params: params });
  }

  private completeMultipartUpload(key: string, contentType: string, uploadId: string, etag: EtagPartNumber[],
    completeUploadUrl: string): Observable<CompleteMultipartUploadInterface> {
    let params = new HttpParams();
    params = params.append('key', key);
    params = params.append('contentType', contentType);
    params = params.append('uploadId', uploadId);

    const body = JSON.stringify(etag);

    return this.http.post<CompleteMultipartUploadInterface>(this.baseUrl + completeUploadUrl, body, { params: params });
  }

  private saveUploadFileInformation(uploadFileInformationDto: uploadFileInformationDto): void {
    if (uploadFileInformationDto.projectId < 0 && uploadFileInformationDto.organizationId < 0) {
      this.updateUploadResult(true);
      this.updateFileUploadProgress$.next(0);
      return;
    }
    this.requestInProgress = this.http.post(this.baseUrl + 'uploadFileInformation', uploadFileInformationDto).subscribe({
      next: () => {
        this.updateUploadResult(true);
        this.updateFileUploadProgress$.next(0);
      },
      error: (error: HttpErrorResponse) => {
        this.httpErrorService.handleError(error);
        this.updateFileUploadProgress$.next(0);
      }
    });
  }

  cancelUploadFile() {
    this.uploadCanceled = true;
    if (this.requestInProgress) {
      this.requestInProgress.unsubscribe();
      this.requestInProgress = undefined;
    }
    this.messageService.displayErrorMessage('Error', "Upload canceled");
    this.updateFileUploadProgress$.next(0);
    this.updateUploadResult(false);
  }
}

export interface getUploadedPhotoFilePresignedUrlResponse extends baseResponse {
	url: string;
}
