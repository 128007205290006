import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AppState } from 'src/app/types/app-state';
import { ProjectInterface } from '../model/project.interface';
import * as projectSelectors from '../project-store/selectors';
import * as projectActions from "../project-store/actions";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { FeatureName } from "../../admin/types";
import { TranslateService } from '@ngx-translate/core';
import { EditProjectDto } from '../DTO/edit-project-dto';

@Component({
  selector: 'app-project-settings',
  template: `
      <div class="project-settings-popup">
          <form [formGroup]="changeProjectInformationForm" (ngSubmit)="updateProject()" class="panel-container">
              <div class="panel-left">
                  <div class="form-group h-100 justify-space-between">
                      <div class="form-group">
                          <h3 i18n class="pb-25">{{ 'PROJECT.PROJECT_SETTINGS.TITLE' | translate }}</h3>
                      </div>
                      <div class="control center">
                          <p-button [disabled]="changeProjectInformationForm.invalid" type="submit" [label]="'COMMON.SAVE' | translate"
                                    styleClass="p-button-success w-100"></p-button>
                      </div>
                  </div>
              </div>
              <div class="panel-right">
                  <div class="form-group h-100 justify-space-between">
                      <div class="input-box secondary p-input-secondary label-top">
                          <label>{{ 'PROJECT.PROJECT_SETTINGS.NAME' | translate }}</label>
                          <input type="text" pInputText formControlName="projectName" class="input" placeholder="Enter name here" />
                          <app-error-message propertyName="projectName" [form]="changeProjectInformationForm" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'PROJECT.PROJECT_SETTINGS.NAME' | translate}" validatorRule="required"/>
                      </div>

                      <div *ngIf="currencies$ | async as currencies" class="input-box secondary p-input-secondary label-top">
                          <label>{{ 'PROJECT.PROJECT_SETTINGS.CURRENCY' | translate }}</label>
                          <p-dropdown formControlName="currency" [options]="currencies" optionLabel="name" optionValue="code" appendTo="body" />
                          <app-error-message propertyName="currency" [form]="changeProjectInformationForm" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'PROJECT.PROJECT_SETTINGS.CURRENCY' | translate}" validatorRule="required"/>
                      </div>

                      <div class="input-box secondary p-input-secondary label-top">
												<label>{{ 'PROJECT.PROJECT_SETTINGS.VEGETATIVE_PERIODS' | translate }}</label>
												<div class="vegetative_months">
													<p-checkbox formControlName="vegMonthJan" [label]="('PRIMENG.monthNames' | translate)[0]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthFeb" [label]="('PRIMENG.monthNames' | translate)[1]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthMar" [label]="('PRIMENG.monthNames' | translate)[2]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthApr" [label]="('PRIMENG.monthNames' | translate)[3]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthMaj" [label]="('PRIMENG.monthNames' | translate)[4]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthJun" [label]="('PRIMENG.monthNames' | translate)[5]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthJul" [label]="('PRIMENG.monthNames' | translate)[6]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthAug" [label]="('PRIMENG.monthNames' | translate)[7]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthSep" [label]="('PRIMENG.monthNames' | translate)[8]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthOct" [label]="('PRIMENG.monthNames' | translate)[9]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthNov" [label]="('PRIMENG.monthNames' | translate)[10]" [binary]="true" value="false"></p-checkbox>
													<p-checkbox formControlName="vegMonthDec" [label]="('PRIMENG.monthNames' | translate)[11]" [binary]="true" value="false"></p-checkbox>
												</div>
                      </div>

											<div *ngIf="grassSpecies$ | async as grassSpecies" class="input-box secondary p-input-secondary label-top">
												<label>{{ 'PROJECT.ADD_PROJECT.TURF_TYPE' | translate }}</label>
												<p-dropdown formControlName="selectedGrassSpecies" [options]="grassSpecies" optionLabel="name" optionValue="id" appendTo="body" />
											</div>

                      <div class="input-box secondary p-input-secondary label-top">
                        <label>{{ 'PROJECT.PROJECT_SETTINGS.MESSAGINGPHONENUMBER' | translate }}</label>
                        <input type="text" pInputText formControlName="messagingPhoneNumber" class="input" />
                      </div>

                      <div class="input-box secondary p-input-secondary label-top">
                        <label>{{ 'PROJECT.PROJECT_SETTINGS.MESSAGINGSERVICESID' | translate }}</label>
                        <input type="text" pInputText formControlName="messagingServiceSid" class="input" />
                      </div>

                      <div class="control project-settings-actions" *ngIf="projectId$ | async as projectId">
                          <p-button *appCheckProjectFeaturePermission="FeatureName.RemoveProject" styleClass="p-button-outlined p-button-danger"
                                    icon="pi pi-trash" [label]="'COMMON.DELETE' | translate" (onClick)="removeProject(projectId)"></p-button>
                          <div class="divider"></div>
                          <p-button  *appCheckProjectFeaturePermission="FeatureName.ReadEditAreasData" styleClass="p-button-outlined p-button-success" [label]="'PROJECT.PROJECT_SETTINGS.EDIT_HOLES' | translate"
                                    (onClick)="editAreas()"></p-button>
                      </div>


                  </div>
              </div>
          </form>
      </div>

      <app-edit-areas-popup *ngIf="isEditAreasPopupVisible" [visible]="isEditAreasPopupVisible"
                            (popupClosed)="isEditAreasPopupVisible=false" />
  `,
  styleUrls: ['./project-settings.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
  private store = inject(Store<AppState>);
  private formBuilder = inject(FormBuilder);
  private confirmationService = inject(ConfirmationService);

  isEditAreasPopupVisible: boolean = false;
  destroy$: Subject<void> = new Subject();
	readonly projectId$ = this.store.select(projectSelectors.selectedProjectIdSelector);
	readonly grassSpecies$ = this.store.select(projectSelectors.grassSpecies);
  readonly currencies$ = this.store.select(projectSelectors.currenciesSelector);

  changeProjectInformationForm = this.formBuilder.group({
    projectName: ['', Validators.required],
		selectedGrassSpecies: [0, Validators.required],
    currency: ['PLN', Validators.required],
    messagingPhoneNumber: [undefined as string | null | undefined],
    messagingServiceSid: [undefined as string | null | undefined],
    vegMonthJan: new FormControl<boolean | null>(false),
    vegMonthFeb: new FormControl<boolean | null>(false),
    vegMonthMar: new FormControl<boolean | null>(false),
    vegMonthApr: new FormControl<boolean | null>(false),
    vegMonthMaj: new FormControl<boolean | null>(false),
    vegMonthJun: new FormControl<boolean | null>(false),
    vegMonthJul: new FormControl<boolean | null>(false),
    vegMonthAug: new FormControl<boolean | null>(false),
    vegMonthSep: new FormControl<boolean | null>(false),
    vegMonthOct: new FormControl<boolean | null>(false),
    vegMonthNov: new FormControl<boolean | null>(false),
    vegMonthDec: new FormControl<boolean | null>(false),
  });

  ngOnInit(): void {
		this.store.dispatch(projectActions.getGrassSpecies());
    this.store.dispatch(projectActions.getCurrencies());
    this.store.select(projectSelectors.selectedProjectSelector).pipe(takeUntil(this.destroy$)).subscribe((selectedProject: ProjectInterface) => {
      this.changeProjectInformationForm.patchValue({
        'projectName': selectedProject.projectName,
        selectedGrassSpecies: selectedProject.grassSpeciesId,
        currency: selectedProject.currency,
        messagingPhoneNumber: selectedProject.messagingPhoneNumber,
        messagingServiceSid: selectedProject.messagingServiceSid
       });
      if (selectedProject.vegetativeMonths && selectedProject.vegetativeMonths.length >= 12) {
        this.changeProjectInformationForm.patchValue({ 'vegMonthJan': selectedProject.vegetativeMonths[0] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthFeb': selectedProject.vegetativeMonths[1] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthMar': selectedProject.vegetativeMonths[2] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthApr': selectedProject.vegetativeMonths[3] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthMaj': selectedProject.vegetativeMonths[4] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthJun': selectedProject.vegetativeMonths[5] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthJul': selectedProject.vegetativeMonths[6] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthAug': selectedProject.vegetativeMonths[7] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthSep': selectedProject.vegetativeMonths[8] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthOct': selectedProject.vegetativeMonths[9] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthNov': selectedProject.vegetativeMonths[10] });
        this.changeProjectInformationForm.patchValue({ 'vegMonthDec': selectedProject.vegetativeMonths[11] });
      }
    });
  }

  constructor(private translate: TranslateService) { }

  editAreas = () => this.isEditAreasPopupVisible = true;

  updateProject() {
    const editProject: EditProjectDto = {
      projectName: this.changeProjectInformationForm.value.projectName!,
      provider: 0,
			grassSpeciesId: this.changeProjectInformationForm.value.selectedGrassSpecies!,
      currency: this.changeProjectInformationForm.value.currency!,
      messagingPhoneNumber: this.changeProjectInformationForm.value.messagingPhoneNumber,
      messagingServiceSid: this.changeProjectInformationForm.value.messagingServiceSid,
      vegetativeMonths: [
        this.changeProjectInformationForm.value.vegMonthJan!,
        this.changeProjectInformationForm.value.vegMonthFeb!,
        this.changeProjectInformationForm.value.vegMonthMar!,
        this.changeProjectInformationForm.value.vegMonthApr!,
        this.changeProjectInformationForm.value.vegMonthMaj!,
        this.changeProjectInformationForm.value.vegMonthJun!,
        this.changeProjectInformationForm.value.vegMonthJul!,
        this.changeProjectInformationForm.value.vegMonthAug!,
        this.changeProjectInformationForm.value.vegMonthSep!,
        this.changeProjectInformationForm.value.vegMonthOct!,
        this.changeProjectInformationForm.value.vegMonthNov!,
        this.changeProjectInformationForm.value.vegMonthDec!,
      ],
    };
    this.store.dispatch(projectActions.updateProject({ editProject: editProject }));
  }

  removeProject = (projectId: number) => {
    this.confirmationService.confirm({
      header: this.getTranslatedHeader(),
      message: this.getTranslatedMessage(),
      acceptButtonStyleClass: 'p-button-success hide-icon',
      rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
      accept: () => this.store.dispatch(projectActions.removeProject({ projectId })),
      reject: () => this.confirmationService.close()
    })
  };

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTranslatedHeader(): string {
    return this.translate.instant(`PROJECT.PROJECT_SETTINGS.CONFIRMATION`);
  }

  getTranslatedMessage(): string {
    return this.translate.instant(`PROJECT.PROJECT_SETTINGS.DELETE_MESSAGE`);
  }

  protected readonly FeatureName = FeatureName;
}
