import {inject, Injectable} from "@angular/core";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import * as dayjs from "dayjs";

@Injectable({
	providedIn: 'root'
})
export class GolfClubMembersApiService {
	private http = inject(HttpClient);

	getGolfClubMembers = (projectId: number, parameters: golfClubMembersGridParameters): Observable<getGolfClubMembersResponse> => {
		let params = new HttpParams({
			fromObject: {
			  	skip: parameters.skip,
				take: parameters.take,
			}
		});
		
		if (parameters.dateRange !== null && parameters.dateRange !== undefined) {
			const dateFrom = parameters.dateRange[0];
			const dateTo = parameters.dateRange[1];
			params = params.append('dateFrom', dateFrom.toISOString());
			params = params.append('dateTo', dayjs(dateTo).add(1, 'day').subtract(1, 'second').toISOString());
		}
		
		if (parameters.sortField && parameters.sortExpression) {
			params = params.append('sortExpression', parameters.sortField + ((parameters.sortExpression > 0) ? '' : ' desc'));
		}
		
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members`;
		return this.http.get<getGolfClubMembersResponse>(endpoint, { params: params });
	}

	getGolfClubMemberById = (projectId: number, memberId: number): Observable<getGolfClubByIdResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members/${memberId}`;
		return this.http.get<getGolfClubByIdResponse>(endpoint);
	}

	getMembersFromOtherProjects = (organizationId: number, projectId: number): Observable<getGolfClubMembersResponse> => {
		const endpoint = `${environment.projectApiUrl}organizations/${organizationId}/members-from-other-projects/${projectId}`;
		return this.http.get<getGolfClubMembersResponse>(endpoint);
	}

	addGolfClubMember = (projectId: number, command: addGolfClubMemberCommand): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members`;
		return this.http.post<baseResponse>(endpoint, command);
	}

	assignMemberToProject = (projectId: number, memberId: number): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}golf-club-members/${memberId}/assignToProject/${projectId}`;
		return this.http.post<baseResponse>(endpoint, {});
	}

	unassignMemberFromProject = (projectId: number, memberId: number): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members/${memberId}/unassign`;
		return this.http.post<baseResponse>(endpoint, {});
	}

	updateGolfClubMember = (projectId: number, memberId: number, command: updateGolfClubMemberCommand): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members/${memberId}`;
		return this.http.patch<baseResponse>(endpoint, command);
	}

	downloadTemplate = (projectId: number): Observable<Blob> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members/download-template`;
		return this.http.get(endpoint, { responseType: 'blob' });
	}

	importFromExcel = (projectId: number, file: File) : Observable<importGolfClubMembersFromExcelResponse> => {
		const blob = new Blob([file], { type: file.type });
		const headers = new HttpHeaders();
		headers.append('Content-Type', 'application/octet-stream');
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/golf-club-members/import-members`;
		return this.http.post<importGolfClubMembersFromExcelResponse>(endpoint, blob, {headers: headers});
	}
}

export interface getGolfClubMembersResponse extends baseResponse {
	golfClubMembers: golfClubMember[],
	totalRecords: number
}

export interface getGolfClubByIdResponse extends baseResponse {
	id: number,
	firstName: string,
	lastName: string,
	phoneNumber: string,
	countryCode: string,
	dialCode: string
}

export interface golfClubMember {
	id: number,
	firstName: string,
	lastName: string,
	phoneNumber: string,
	countryCode: string,
	dialCode: string,
	allIncidentsCount: number,
	acceptedIncidentsCount: number
}

export interface addGolfClubMemberCommand {
	firstName: string,
	lastName: string,
	phoneNumber: string,
	countryCode: string,
	dialCode: string
}

export interface updateGolfClubMemberCommand {
	firstName: string,
	lastName: string,
	phoneNumber: string,
	countryCode: string,
	dialCode: string
}

export interface importGolfClubMembersFromExcelResponse extends baseResponse {
	invalidPhoneNumbersCount: number;
}

export interface golfClubMembersGridParameters {
    take: number;
    skip: number;
	dateRange?: Date[] | null;
    sortField?: string;
    sortExpression?: number;
}