import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { incidentActions } from "./actions";
import { catchError, exhaustMap, map, of, tap, withLatestFrom } from "rxjs";
import { AgroMessageService } from "src/app/shared/services/agro-message.service";
import { IncidentAPIService } from "../incidentAPI.service";
import { selectEditedIncident, selectEvaluationPopupModel, selectorLastLoadIncidentsParameters } from "./selectors";
import { TaskService } from "src/app/task/task.service";
import {
	organizationIdSelector,
	selectedProjectIdSelector,
	selectedProjectSelector
} from "src/app/project/project-store/selectors";
import {selectUserFullName} from "../../profile/store";
import { Router } from "@angular/router";
import { IncidentState } from "../models/IncidentState";
import { LogsApiService } from "src/app/shared/services/logs.api.service";
import { EvaluationPopupModel } from "../models/EvaluationPopupModel";
import {mapIncidentActions, otherObjectActions} from "../../grass-parameters-presentation/store/other-objects/actions";
import {LoadPanelService} from "../../shared/services/load-panel.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class IncidentsEffects {
	private actions$ = inject(Actions);
	private incidentAPIService = inject(IncidentAPIService);
	private messageService = inject(AgroMessageService);
	private store = inject(Store<AppState>);
	private taskAPIService = inject(TaskService);
	private router = inject(Router);
	private logsApiService = inject(LogsApiService);
	private loadPanelService = inject(LoadPanelService);
	private translateService = inject(TranslateService);

	private readonly messagesTranslationKey = 'GRASS_PARAMETERS.OTHER_OBJECTS.MESSAGES.ALERTS';

	addIncident$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.addIncident),
		exhaustMap(({command, createdFromPopup}) => {
			return this.incidentAPIService.addIncident(command).pipe(
				map(response => {
					if (response.success) {
						this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.ADD_SUCCESS`));
						if (createdFromPopup) {
							return mapIncidentActions.addIncidentSuccess();
						} else {
							return incidentActions.addIncidentSuccess();
						}
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			);
		})
	));

	updateIncident$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.updateIncident),
		exhaustMap(({command, editedFromPopup}) => {
			return this.incidentAPIService.updateIncident(command).pipe(
				map(response => {
					if (response.success) {
						this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.UPDATE_SUCCESS`));
						if (editedFromPopup) {
							return mapIncidentActions.updateIncidentSuccess();
						} else {
							return incidentActions.updateIncidentSuccess();
						}
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			);
		})
	));

	addIncidentSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.addIncidentSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) => {
			void this.router.navigate(['/incidents', projectId]);
		})
	), {dispatch: false});

	updateIncidentSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.updateIncidentSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) => {
			void this.router.navigate(['/incidents', projectId]);
		})
	), {dispatch: false});

	deleteIncident$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.deleteIncident),
		exhaustMap(({ incidentId, deletedFromPopup }) => {
			return this.incidentAPIService.deleteIncident(incidentId).pipe(
				map(response => {
					if (response.success) {
						if (deletedFromPopup) {
							this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.REMOVE_SUCCESS`));
							return mapIncidentActions.removeIncidentSuccess()
						}

						return incidentActions.refreshIncidents();
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			)
		})
	));

	handleError$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.handleError),
		tap(({ errorMessage }) => {
			this.messageService.displayErrorMessage("Error", errorMessage);
		})
	), { dispatch: false });

	refreshIncidents$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.refreshIncidents),
		withLatestFrom(this.store.select(selectorLastLoadIncidentsParameters)),
		map(([_, loadParameters]) => {
			return incidentActions.loadIncidents(loadParameters!);
		})
	));

	loadIncidents$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.loadIncidents),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([loadParameters, projectId]) => {
			return this.incidentAPIService.getIncidentList({...loadParameters, projectId: projectId!}).pipe(
				map(response => {
					if (response.success) {
						return incidentActions.loadIncidentsSuccess(response);
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			)
		})
	));

	loadDiseases$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.loadDiseases),
		exhaustMap(() => {
			return this.incidentAPIService.getDiseases().pipe(
				map(response => {
					if (response.success) {
						return incidentActions.loadDiseasesSuccess(response);
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			)
		})
	));

	addTaskToIncident$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.addTaskToIncident),
		exhaustMap(command => {
			return this.incidentAPIService.addTaskToIncident(command).pipe(
				map(response => {
					if (response.success) {
						return incidentActions.addTaskToIncidentSuccess(response);
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			);
		})
	));

	removeTaskFromIncident$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.removeTaskFromIncident),
		exhaustMap(({ incidentId, taskId }) => {
			return this.incidentAPIService.removeTaskFromIncident(incidentId, taskId).pipe(
				map(response => {
					if (response.success) {
						return incidentActions.getIncidentById({ incidentId: incidentId });
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			);
		})
	));

	loadAvailableTasks$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.loadAvailableTasks),
		withLatestFrom(
			this.store.select(organizationIdSelector),
			this.store.select(selectedProjectIdSelector)
		),
		exhaustMap(([loadParameters, organizationId, projectId]) => {
			return this.taskAPIService.getTaskList(loadParameters, organizationId!, projectId!).pipe(
				map(response => {
					if (response.success) {
						return incidentActions.loadAvailableTasksSuccess(response);
					}

					return incidentActions.handleError({ errorMessage: response.error });
				}),
				catchError(error => of(incidentActions.handleError(error)))
			)
		})
	));

	getIncidentById$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.getIncidentById),
		exhaustMap(({ incidentId }) =>
			this.incidentAPIService.getIncidentById(incidentId).pipe(
				map(incident => incidentActions.getIncidentByIdSuccess({incident})),
				catchError(error => of(incidentActions.handleError(error)))
			))
	));

	addComment$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.addComment),
		withLatestFrom(this.store.select(selectUserFullName)),
		exhaustMap(([{incidentId, comment}, userFullName]) =>
			this.incidentAPIService.addComment(incidentId, {comment}).pipe(
				map((response) => {
					if (response.success) {
						return incidentActions.addCommentSuccess({comment, author: userFullName});
					}

					return incidentActions.handleError({errorMessage: response.error});
				}),
				catchError(error => of(incidentActions.handleError(error)))
			))
	));

	changeState$ = createEffect(() => this.actions$.pipe(
			ofType(incidentActions.changeState),
			withLatestFrom(this.store.select(selectEditedIncident)),
			exhaustMap(([{ incidentId, state }, incidentWithDetails]) => {
				const oldState: IncidentState = incidentWithDetails?.state as IncidentState;
				if (state === IncidentState.New) {
					return of(incidentActions.changeStateEnd({stateToUpdate: oldState, errorMessage: `Cannot change state to New`}));
				}

				if (state == IncidentState.Closed || state == IncidentState.Rejected) {
					const model: EvaluationPopupModel = { incidentId, state, stateToUpdate: oldState, evaluation: null };
					return of(incidentActions.showEvaluateIncidentPopup({model}));
				}

				return this.incidentAPIService.changeState(incidentId, state, null).pipe(
					map(response => {
						if (response.success) {
							this.messageService.displaySuccessMessage("", "State was updated successfully");
							return incidentActions.getIncidentById({ incidentId: incidentId });
						}

						return incidentActions.changeStateEnd({stateToUpdate: oldState, errorMessage: response.error});
					}),
					catchError(error => of(incidentActions.handleError(error)))
				);
			})
	));

	hideEvaluateIncidentPopupAndChangeState$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.hideEvaluateIncidentPopupAndChangeState),
		withLatestFrom(this.store.select(selectEvaluationPopupModel)),
		exhaustMap(([{evaluation}, popupModel]) => {
			const model: EvaluationPopupModel = popupModel!;
			return this.incidentAPIService.changeState(model.incidentId, model.state, evaluation).pipe(
				map(response => {
					if (response.success) {
						this.messageService.displaySuccessMessage("", "State was updated successfully");
						return incidentActions.getIncidentById({ incidentId: model.incidentId });
					}

					return incidentActions.changeStateEnd({stateToUpdate: model.stateToUpdate, errorMessage: response.error});
				}),
				catchError(error => of(incidentActions.handleError(error)))
			);
		})
	));

	changeStateEnd$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.changeStateEnd),
		tap(({ stateToUpdate, errorMessage }) => {
			if (errorMessage)
				this.messageService.displayErrorMessage("Error", errorMessage);
		})
	), { dispatch: false });

	getChangesHistory$ = createEffect(() => this.actions$.pipe(
		ofType(incidentActions.getChangesHistory),
		exhaustMap(({ incidentId }) =>
			this.logsApiService.getIncidentLogs(incidentId!).pipe(
				map(changesHistory => incidentActions.getChangesHistorySuccess({changesHistory})),
				catchError(error => of(incidentActions.handleError(error)))
			)
		)
	));

	showLoadingPanel$ = createEffect(() => this.actions$.pipe(
		ofType(
			incidentActions.getIncidentById,
			incidentActions.addIncident,
			incidentActions.updateIncident
		),
		tap(() => this.loadPanelService.showLoadPanel())
	), {dispatch: false})

	hideLoadingPanel$ = createEffect(() => this.actions$.pipe(
		ofType(
			incidentActions.getIncidentByIdSuccess,
			incidentActions.handleError,
			incidentActions.addIncidentSuccess,
			mapIncidentActions.addIncidentSuccess,
			incidentActions.updateIncidentSuccess,
			mapIncidentActions.updateIncidentSuccess
		),
		tap(() => this.loadPanelService.hideLoadPanel())
	), {dispatch: false})
}
