import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as chooseAreaActions from "./actions";
import {catchError, exhaustMap, map, mergeMap, of, take, tap, withLatestFrom} from "rxjs";
import {MapboxDrawService} from "../../../shared/services/mapbox-draw.service";
import {AgroMessageService} from "../../../shared/services/agro-message.service";
import {ManageAreasService} from "../../services/manage-areas.service";
import {LoadPanelService} from "../../../shared/services/load-panel.service";
import {ProjectService, getProjectWithDetailsResponse} from "../../services/project.service";
import {ManageProjectDto} from "../../DTO/manage-project-dto";
import {State} from "../../_helpers/state";
import {v4 as uuid} from "uuid";
import * as chooseAreaSelectors from "./selectors";
import {AppState} from "../../../types/app-state";
import {Store} from "@ngrx/store";
import {Polygon} from "geojson";
import {MapboxCenterService} from "../../services/mapbox-center.service";
import {manageProjectAreaDto} from "../../DTO/manage-project-area-dto";
import {EditProjectDto} from "../../DTO/edit-project-dto";
import { IGolfService } from "src/app/shared/services/i-golf.service";
import { GetProjectWithIGolfChangesResponse } from "../../DTO/getProjectWithIGolfChangesResponse";

@Injectable()
export class ChooseAreaEffects {
  constructor(private mapboxDrawService: MapboxDrawService,
              private messageService: AgroMessageService,
              private actions$: Actions,
              private manageAreasService: ManageAreasService,
              private loadPanelService: LoadPanelService,
              private projectService: ProjectService,
              private iGolfService: IGolfService,
              private store: Store<AppState>,
              private mapboxService: MapboxCenterService) {}

  loadProjectAreas$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.loadProjectAreas),
      exhaustMap(props => {
        return this.projectService.getActualProjectAreasAndElementaryObjectsDetails(props.projectId).pipe(
          map((response: getProjectWithDetailsResponse) => {
            if (!response.success) {
              return chooseAreaActions.handleError({ errorMessage: response.error });
            }
            const project = response.project;

            const drawnAreas = project.areas!.map((area) => {
              area.elementaryObjects = area.elementaryObjects!.map((eo) => {
                return ({
                  ...eo,
                  state: State.Ignore,
                  uuid: uuid()
                });
              });

              return {...area, state: State.Ignore, uuid: uuid()};
            });

            this.mapboxDrawService.addAreas([...drawnAreas]);
            this.mapboxDrawService.updateCurrentSelection();
            return chooseAreaActions.loadProjectAreasSuccess({drawnAreas});
          }),
          catchError(error => of(chooseAreaActions.handleError({errorMessage: error.message})))
        )
      })
    )
  });

  updateFromIGolf$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.updateFromIGolf),
      mergeMap(props => {
        return this.iGolfService.getProjectWithIGolfChanges(props.organizationId, props.projectId).pipe(
          map((response: GetProjectWithIGolfChangesResponse) => {
            if (!response.success) {
              return chooseAreaActions.handleError({ errorMessage: response.error });
            }
            const project = response.project;
            const drawnAreas = project.areas!.map((area) => {
              area.elementaryObjects = area.elementaryObjects!.map((eo) => {
                return ({ ...eo, uuid: uuid()});
              });

              return {...area, uuid: uuid()};
            });

            this.mapboxDrawService.resetDrawToPreviousState();
            this.mapboxDrawService.addAreas([...drawnAreas]);
            this.mapboxDrawService.updateCurrentSelection();
            return chooseAreaActions.loadProjectAreasSuccess({drawnAreas});
          }),
          catchError(error => of(chooseAreaActions.handleError({errorMessage: error.message})))
        );
      })
    )
  });

  initAreasForNewProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.initAreasForNewProject),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      tap(([props, drawnAreas]) => {
        this.mapboxDrawService.addAreas([...drawnAreas]);
        this.mapboxDrawService.updateCurrentSelection();
      })
    )
  }, {dispatch: false});

  addArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.addArea),
      tap((props) => {
        this.manageAreasService.setSelectedPolygonProperties(props.form);
        this.mapboxDrawService.updateCurrentSelection();
        this.mapboxDrawService.changeToSelectMode();
        this.messageService.displaySuccessMessage("Success", "New hole was added successfully");
      })
    )
  }, { dispatch: false });

  addElementaryObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.addElementaryObject),
      tap((props) => {
        this.manageAreasService.setSelectedPolygonProperties(props.form);
        this.mapboxDrawService.updateCurrentSelection();
        this.messageService.displaySuccessMessage("Success", "Type was changed");
        this.mapboxDrawService.changeToSelectMode();
      })
    )
  }, {dispatch: false})

  editArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editArea),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      tap(([props, drawnAreas]) => {
        const elementaryObjectIds = drawnAreas.find(x => x.uuid === props.form.uuid!)!.elementaryObjects!.map(eo => eo.uuid)
        this.mapboxDrawService.setSelectedPolygonNameProperty(props.form.name!, elementaryObjectIds);
        this.mapboxDrawService.updateCurrentSelection();
        this.messageService.displaySuccessMessage("Success", `Hole ${props.form.name!} was changed`);
        this.mapboxDrawService.changeToSelectMode();
      })
    )
  }, {dispatch: false});

  editElementaryObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editElementaryObject),
      tap((props) => {
        this.mapboxDrawService.updateCurrentSelection();
        this.mapboxDrawService.changeToSelectMode();
        this.messageService.displaySuccessMessage("Success", `Type was changed`);
      })
    )
  }, {dispatch: false});

  deleteArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.deleteArea),
      tap((props) => {
        props.idsToDelete.forEach(x => this.mapboxDrawService.deletePolygonByUuid(x));
        this.mapboxDrawService.updateCurrentSelection();
        this.mapboxDrawService.changeToSelectMode();
        this.messageService.displaySuccessMessage("Success", `Hole ${props.form.name!} was removed`);
      })
    )
  }, {dispatch: false});

  deleteElementaryObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.deleteElementaryObject),
      tap((props) => {
        this.mapboxDrawService.deletePolygonByUuid(props.form.uuid!);
        this.messageService.displaySuccessMessage("Success", `Type was removed`);
        // todo possibly other side effects
      })
    )
  }, {dispatch: false});

  selectArea$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.selectArea),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      tap(([props, drawnAreas]) => {
        if (!props.selectedOnMap) {
          this.mapboxDrawService.selectPolygonByUuid(props.areaId);
        }

        const polygon = drawnAreas.find(x => x.uuid === props.areaId)!.polygon as Polygon;
        this.mapboxService.setCenter(polygon);
      })
    )
  }, {dispatch: false});

  selectElementaryObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.selectElementaryObject),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      tap(([props, drawnAreas]) => {
        if (!props.selectedOnMap) {
          this.mapboxDrawService.selectPolygonByUuid(props.elementaryObjectId);
        }

        const parent = drawnAreas.find(x => x.elementaryObjects!.some(eo => eo.uuid === props.elementaryObjectId));
        const polygon = parent!.elementaryObjects!.find(x => x.uuid === props.elementaryObjectId)!.polygon as Polygon;
        this.mapboxService.setCenter(polygon);
      })
    )
  }, {dispatch: false});

  startNewDraw$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.startNewAreaDraw, chooseAreaActions.startNewElementaryObjectDraw),
      tap(() => {
        this.mapboxDrawService.changeToDrawPolygonMode();
      })
    )
  }, {dispatch: false});

  editAreaWithValidation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editAreaWithValidation),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      map(([props, drawnAreas]) => {
        if (!this.manageAreasService.validateAreaPolygon(props.form, drawnAreas, props.polygon)) {
          this.mapboxDrawService.selectPolygonByUuid(props.form.uuid!);
          return chooseAreaActions.handleValidationFailure();
        }

        return chooseAreaActions.editArea({ form: props.form, polygon: props.polygon });
      })
    )
  });

  editElementaryObjectWithValidation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editElementaryObjectWithValidation),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      map(([props, drawnAreas]) => {
        if (!this.manageAreasService.validateElementaryObjectPolygon(props.form, drawnAreas, props.polygon)) {
          this.mapboxDrawService.selectPolygonByUuid(props.form.uuid!);
          return chooseAreaActions.handleValidationFailure();
        }

        return chooseAreaActions.editElementaryObject({ form: props.form, polygon: props.polygon });
      })
    )
  });

  validationFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.handleValidationFailure),
      tap(() => {
        this.mapboxDrawService.resetDrawToPreviousState();
        this.messageService.displayErrorMessage("Error", "Validation Failed");
      })
    )
  }, {dispatch: false})

  createDraw$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.createDraw),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      map(([props, drawnAreas]) => {
        if (!this.manageAreasService.validateElementaryObjectPolygon(props.form, drawnAreas, props.polygon)) {
          return chooseAreaActions.handleValidationFailure();
        }

        return chooseAreaActions.createDrawSuccess();
      })
    )
  });

  editProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editProject),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      mergeMap(([props, drawnAreas]) => {
        const areasDtos: manageProjectAreaDto[] = drawnAreas.filter(x => x.state).map(x => ({
          state: x.state!,
          name: x.name,
          areaId: x.areaId!,
          polygon: x.polygon,
          elementaryObjects: x.elementaryObjects!.map(y => ({
            ...y,
            state: y.state ? y.state : State.Ignore
          }))
        }));

        const areasWithoutDeleteState = areasDtos.filter(area => area.state !== State.Delete);

        const editProjectDto: EditProjectDto = {
          projectId: props.project.id,
          projectName: props.project.projectName,
          provider: props.project.provider!,
          providerId: props.project.providerId,
          gps: props.project.gps,
          organizationId: props.project.organizationId,
          projectCategoryId: props.project.projectCategoryId,
          grassSpeciesId: props.project.grassSpeciesId,
          area: props.project.area,
          areas: areasDtos,
          vegetativeMonths: [],
          currency: props.project.currency
        };

        return this.projectService.editProject(props.project.id!, editProjectDto).pipe(
          map(response => {
            if (response.success) {
              return chooseAreaActions.editProjectSuccess({ projectId: props.project.id!, areasCount: areasWithoutDeleteState.length });
            }
            return chooseAreaActions.handleError({ errorMessage: response.error });
          }),
          catchError(error => of(chooseAreaActions.handleError({errorMessage: error.message})))
        )
      })
    )
  })

  editProjectSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.editProjectSuccess),
      tap(() => {
        this.messageService.displaySuccessMessage("Success", "Project was updated successfully");
      })
    )
  }, {dispatch: false});

  unselect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.unselect),
      tap(() => {
        this.mapboxDrawService.deleteOrphanPolygons();
      })
    )
  }, {dispatch: false})

  showLoadingPanel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.loadProjectAreas, chooseAreaActions.editProject, chooseAreaActions.updateFromIGolf),
      tap(() => this.loadPanelService.showLoadPanel())
    )
  }, {dispatch: false});

  hideLoadingPanel = createEffect(() => {
    return this.actions$.pipe(
      ofType(chooseAreaActions.loadProjectAreasSuccess, chooseAreaActions.editProjectSuccess, chooseAreaActions.handleError),
      tap(() => this.loadPanelService.hideLoadPanel())
    )
  }, {dispatch: false});

  handleError$ = createEffect(() => this.actions$.pipe(
    ofType(chooseAreaActions.handleError),
    tap(({ errorMessage }) => {
        this.messageService.displayErrorMessage("Error", errorMessage);
    })
  ), { dispatch: false });

}
