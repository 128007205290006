import {createActionGroup, createFeature, createReducer, emptyProps, on, props} from "@ngrx/store";
import { ExaminationType, acidityExaminationFormModel, addAcidityExaminationDto, addBiomassExaminationDto, addGreenSpeedExaminationDto, addLaboratoryExaminationDto, biomassExaminationFormModel, changeAcidityExaminationDto, changeBiomassExaminationDto, changeGreenSpeedExaminationDto, changeGreenSpeedNormDto, changeLaboratoryExaminationDto, examinationFormModel, examinationGridModel, examinationGridParameters, greenSpeedExaminationFormModel, greenSpeedNormGridModel, holeDropDownModel, holeObjectDropDownModel, laboratoryExaminationFormModel} from "../types";
import { LazyLoadEvent } from "primeng/api";

export interface examinationsState {
	holes: holeDropDownModel[],
	holeObjects: holeObjectDropDownModel[],
	filter: examinationGridParameters,
	examinations: examinationGridModel[],
	totalRecords: number,
	examination: examinationFormModel | null,
	norms: greenSpeedNormGridModel[],
	isChangeNormPopupVisible: boolean,
	isLoading: boolean
}

const initialState: examinationsState = {
	holes: [],	
	holeObjects: [],
	filter: { take: 10, skip: 0, examinationType: null, holeIds: [], holeObjectTypeIds: [] } as examinationGridParameters,
	examinations: [],
	totalRecords: 0,
	norms: [],
	examination: null,
	isChangeNormPopupVisible: false,
	isLoading: false
}

export const examinationsActions = createActionGroup(({
	source: 'Examination',
	events: {
		'Refresh Examinations': emptyProps(),
		'Load Examinations': props<{filter: examinationGridParameters}>(),
		'Load Examinations Success': props<{examinations: examinationGridModel[], totalRecords: number}>(),
		'Filter examinations by Examination Type': props<{examinationType: ExaminationType | null}>(),
		'Filter examinations by Holes': props<{holeIds: number[] | null}>(),
		'Filter examinations by Hole Object Type': props<{holeObjectTypeIds: string[] | null }>(),
		'Filter examinations by Lazy load': props<{ lazyLoadEvent: LazyLoadEvent }>(),
		'Filter examinations by Date': props<{dateRange: Date[] | null}>(),
		'Get Green Speed Norms': emptyProps(),
		'Get Green Speed Norms Success': props<{norms: greenSpeedNormGridModel[]}>(),
		'Get Green Speed Norm': props<{greenSpeedNormId: number}>(),
		'Get Green Speed Norm Success': props<{norm: greenSpeedNormGridModel}>(),
		'Change Green Speed Norm': props<{ dto: changeGreenSpeedNormDto }>(),
		'Change Green Speed Norm Success': props<{id: number}>(),
		'Show Change Norm Popup': emptyProps(),
		'Hide Change Norm Popup': emptyProps(),
		'Get Project Holes': emptyProps(),
		'Get Project Holes Success': props<{ holes: holeDropDownModel[] }>(),
		'Get Project Hole Objects': props<{ holeId: number | null }>(),
		'Get Project Hole Objects Success': props<{ holeObjects: holeObjectDropDownModel[] }>(),
		'Add Green Speed Examination': props<{dto: addGreenSpeedExaminationDto}>(), 
		'Add Green Speed Examination Success': props<{dto: addGreenSpeedExaminationDto, id: number}>(), 
		'Get Green Speed Examination': props<{id: number}>(),
		'Get Green Speed Examination Success': props<{examination: greenSpeedExaminationFormModel}>(),
		'Change Green Speed Examination': props<{id: number, dto: changeGreenSpeedExaminationDto}>(),
		'Change Green Speed Examination Success': props<{id: number, dto: changeGreenSpeedExaminationDto}>(),
		'Remove Green Speed Examination': props<{id: number}>(),
		'Remove Green Speed Examination Success': props<{id: number}>(),
		'Add Biomass Examination': props<{dto: addBiomassExaminationDto}>(), 
		'Add Biomass Examination Success': props<{dto: addBiomassExaminationDto, id: number}>(), 
		'Get Biomass Examination': props<{id: number}>(),
		'Get Biomass Examination Success': props<{examination: biomassExaminationFormModel}>(),
		'Change Biomass Examination': props<{id: number, dto: changeBiomassExaminationDto}>(),
		'Change Biomass Examination Success': props<{id: number, dto: changeBiomassExaminationDto}>(),
		'Remove Biomass Examination': props<{id: number}>(),
		'Remove Biomass Examination Success': props<{id: number}>(),
		'Add Acidity Examination': props<{dto: addAcidityExaminationDto}>(), 
		'Add Acidity Examination Success': props<{dto: addAcidityExaminationDto, id: number}>(), 
		'Get Acidity Examination': props<{id: number}>(),
		'Get Acidity Examination Success': props<{examination: acidityExaminationFormModel}>(),
		'Change Acidity Examination': props<{id: number, dto: changeAcidityExaminationDto}>(),
		'Change Acidity Examination Success': props<{id: number, dto: changeAcidityExaminationDto}>(),
		'Remove Acidity Examination': props<{id: number}>(),
		'Remove Acidity Examination Success': props<{id: number}>(),
		'Add Laboratory Examination': props<{dto: addLaboratoryExaminationDto}>(), 
		'Add Laboratory Examination Success': props<{dto: addLaboratoryExaminationDto, id: number}>(), 
		'Get Laboratory Examination': props<{id: number}>(),
		'Get Laboratory Examination Success': props<{examination: laboratoryExaminationFormModel}>(),
		'Change Laboratory Examination': props<{id: number, dto: changeLaboratoryExaminationDto}>(),
		'Change Laboratory Examination Success': props<{id: number, dto: changeLaboratoryExaminationDto}>(),
		'Remove Laboratory Examination': props<{id: number}>(),
		'Remove Laboratory Examination Success': props<{id: number}>(),
		'Handle Error': emptyProps()
	}
}));

export const examinationFeature = createFeature({
	name: 'Examination',
	reducer: createReducer(
		initialState,
		on(examinationsActions.loadExaminations, (state, {filter}) => ({...state, filter, isLoading: true})),
		on(examinationsActions.loadExaminationsSuccess, (state, {examinations, totalRecords}) => ({ ...state, examinations, totalRecords, isLoading: false })),
		on(examinationsActions.filterExaminationsByExaminationType, (state, {examinationType}) => ({...state, filter: {...state.filter, examinationType: examinationType }})),
		on(examinationsActions.filterExaminationsByHoles, (state, {holeIds}) => ({...state, filter: {...state.filter, holeIds: holeIds }})),
		on(examinationsActions.filterExaminationsByHoleObjectType, (state, {holeObjectTypeIds}) => ({...state, filter: {...state.filter, holeObjectTypeIds: holeObjectTypeIds }})),
		on(examinationsActions.filterExaminationsByDate, (state, {dateRange}) => ({...state, filter: {...state.filter, dateRange: dateRange }})),
		on(examinationsActions.filterExaminationsByLazyLoad, (state, {lazyLoadEvent}) => {
			if (!lazyLoadEvent) {
				return state;
			}
	
			return {
				...state,
				filter: {
					...state.filter,
					take: lazyLoadEvent.rows!,
					skip: lazyLoadEvent.first!,
					sortField: lazyLoadEvent.sortField,
					sortOrder: lazyLoadEvent.sortOrder
				}
			}
		}),
		on(examinationsActions.addGreenSpeedExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.addGreenSpeedExaminationSuccess, (state, ) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.getGreenSpeedExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.getGreenSpeedExaminationSuccess, (state, {examination}) => ({...state, examination, isLoading: false})),
		on(examinationsActions.changeGreenSpeedExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.changeGreenSpeedExaminationSuccess, (state) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.removeGreenSpeedExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.removeGreenSpeedExaminationSuccess, (state, {id}) => ({...state, examinations: state.examinations.filter(e => e.id !== id), isLoading: false})),
		on(examinationsActions.addBiomassExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.addBiomassExaminationSuccess, (state, ) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.getBiomassExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.getBiomassExaminationSuccess, (state, {examination}) => ({...state, examination, isLoading: false})),
		on(examinationsActions.changeBiomassExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.changeBiomassExaminationSuccess, (state) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.removeBiomassExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.removeBiomassExaminationSuccess, (state, {id}) => ({...state, examinations: state.examinations.filter(e => e.id !== id), isLoading: false})),
		on(examinationsActions.addAcidityExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.addAcidityExaminationSuccess, (state, ) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.getAcidityExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.getAcidityExaminationSuccess, (state, {examination}) => ({...state, examination, isLoading: false})),
		on(examinationsActions.changeAcidityExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.changeAcidityExaminationSuccess, (state) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.removeAcidityExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.removeAcidityExaminationSuccess, (state, {id}) => ({...state, examinations: state.examinations.filter(e => e.id !== id), isLoading: false})),
		on(examinationsActions.addLaboratoryExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.addLaboratoryExaminationSuccess, (state, ) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.getLaboratoryExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.getLaboratoryExaminationSuccess, (state, {examination}) => ({...state, examination, isLoading: false})),
		on(examinationsActions.changeLaboratoryExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.changeLaboratoryExaminationSuccess, (state) => ({...state, examination: null, isLoading: false})),
		on(examinationsActions.removeLaboratoryExamination, (state) => ({...state, isLoading: true})),
		on(examinationsActions.removeLaboratoryExaminationSuccess, (state, {id}) => ({...state, examinations: state.examinations.filter(e => e.id !== id), isLoading: false})),
		on(examinationsActions.getProjectHoles, (state) => ({...state, isLoading: true})),
		on(examinationsActions.getProjectHolesSuccess, (state, { holes }) => ({ ...state, holes, holeObjects: [], isLoading: false })),
		on(examinationsActions.getProjectHoleObjects, (state) => ({ ...state, isLoading: true })),
		on(examinationsActions.getProjectHoleObjectsSuccess, (state, { holeObjects }) => ({ ...state, holeObjects: holeObjects, isLoading: false })),
		on(examinationsActions.getGreenSpeedNorms, (state) => ({...state, isLoading: true })),
		on(examinationsActions.getGreenSpeedNormsSuccess, (state, {norms}) => ({...state, norms: norms, isLoading: false })),
		on(examinationsActions.getGreenSpeedNorm, (state) => ({...state, isLoading: true })),
		on(examinationsActions.getGreenSpeedNormSuccess, (state, {norm}) => ({...state, norms: state.norms.map(c => c.id === norm.id ? norm : c), isLoading: false })),
		on(examinationsActions.changeGreenSpeedNorm, (state) => ({...state, isLoading: true })),
		on(examinationsActions.changeGreenSpeedNormSuccess, (state, {id}) => ({...state, isChangeNormPopupVisible: false, norms: [...state.norms, {id: id} as greenSpeedNormGridModel], isLoading: false })),
		on(examinationsActions.showChangeNormPopup, (state) => ({...state, isChangeNormPopupVisible: true })),
		on(examinationsActions.hideChangeNormPopup, (state) => ({...state, isChangeNormPopupVisible: false })),
		on(examinationsActions.handleError, (state) => ({...state, isLoading: false}))
	)
})

export const {selectHoles, selectHoleObjects, selectFilter, selectExaminations, selectExamination, selectNorms, selectIsChangeNormPopupVisible, selectTotalRecords, selectIsLoading} = examinationFeature
