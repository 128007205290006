import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {adminActions} from "./index";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {AppState} from "../../types/app-state";
import * as projectSelectors from "../../project/project-store/selectors";
import {Store} from "@ngrx/store";
import {AdminApiService, assignUserToRoleResponse, getAllFeaturesResponse} from "../admin.api.service";
import {HttpErrorService} from "../../shared/services/http-error.service";
import {
	addOrganizationResponse,
	AdminOrganizationsApiService,
	getAvailableOrganizationsResponse,
	getDefaultRolesResponse
} from "../admin-organizations.api.service";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {organizationIdSelector} from "../../project/project-store/selectors";
import {organizationDetails} from "../types";
import {Router} from "@angular/router";

@Injectable()
export class AdminEffects {
    private store = inject(Store<AppState>);
    private adminService = inject(AdminApiService);
		private adminOrganizationsApiService = inject(AdminOrganizationsApiService);
    private actions$ = inject(Actions);
    private httpErrorService = inject(HttpErrorService);
		private messageService = inject(AgroMessageService);
		private router = inject(Router);

		getOrganization$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.getManagedOrganization),
				mergeMap(({organizationId}) =>
					this.adminOrganizationsApiService.getManagedOrganization(organizationId).pipe(
						map(response => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							const organizationUsers = this.adminOrganizationsApiService.transformUserOrganizationsApiResponseToViewModel(response);
							const availableProjects = response.availableOrganizationProjects;
							const availableRoles = response.availableOrganizationRoles;

							const { accountId: awsAccountId, name, subscription } = response;

							const organizationDetails: organizationDetails = {
								awsAccountId,
								name,
								...(subscription && {
									activationDate: subscription.activationDate,
									automaticRenewal: subscription.automaticRenewal,
									subscriptionStatus: subscription.subscriptionStatus,
								}),
							};

							return adminActions.getManagedOrganizationSuccess({organizationUsers, availableRoles, availableProjects, organizationDetails});
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
			)
		)

    assignRoleToUser$ = createEffect(() =>
      this.actions$.pipe(
        ofType(adminActions.assignUserToRole),
        withLatestFrom(this.store.select(projectSelectors.organizationIdSelector)),
        mergeMap(([{userId, roleId, roleName, projectId, projectName}, organizationId]) =>
          this.adminService.assignUserToRole(organizationId!, userId, roleId, projectId).pipe(
            map((response: assignUserToRoleResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return adminActions.handleError();
				}

				this.messageService.displaySuccessMessage('User was successfully assigned to role', 'User was successfully assigned to role');
				return adminActions.assignUserToRoleSuccess({userId, role: {roleId, roleName, projectId, projectName, organizationUserRoleId: response.organizationUserRoleId}});
			}),
            catchError((error) => {
              this.httpErrorService.handleError(error);
              return of(adminActions.handleError());
            })
          )
        )
      )
    );

		unassignUserFromRole$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.unassignUserFromRole),
				withLatestFrom(this.store.select(projectSelectors.organizationIdSelector)),
				mergeMap(([{userId, roleId}, organizationId]) =>
					this.adminService.unassignUserFromRole(organizationId!, userId, roleId).pipe(
						map((response: baseResponse) => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							this.messageService.displaySuccessMessage('User was successfully unassigned from role', 'User was successfully unassigned from role');
							return adminActions.unassignUserFromRoleSuccess({userId, roleId});
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
			)
		);

		getAvailableUsers$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.getAvailableUsers),
				withLatestFrom(this.store.select(projectSelectors.organizationIdSelector)),
				mergeMap(([_, organizationId]) =>
					this.adminOrganizationsApiService.getAvailableUsers(organizationId!).pipe(
						map((response: getAvailableOrganizationsResponse) => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							return adminActions.getAvailableUsersSuccess({availableUsers: response.users})
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
		));

		addOrganization$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.addOrganization),
				mergeMap(({name, accountId, productCode}) =>
					this.adminOrganizationsApiService.addOrganization(name, accountId, productCode).pipe(
						map((response: addOrganizationResponse) => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							return adminActions.addOrganizationSuccess({organization: {name, id: response.organizationId, accountId, productCode, removable: true}});
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
			)
		);

		addOrganizationSuccess$ = createEffect(() => this.actions$.pipe(
			ofType(adminActions.addOrganizationSuccess),
			tap(({organization}) =>
				this.router.navigate(['/admin/organizations']).then(() =>
					this.messageService.displaySuccessMessage('', `Organization ${organization.name} was added successfully`)
				))
		), {dispatch: false});

		editOrganization$ = createEffect(() => this.actions$.pipe(
			ofType(adminActions.editOrganization),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{name, accountId, productCode}, organizationId]) =>
				this.adminOrganizationsApiService.editOrganization(organizationId!, name, accountId, productCode).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						return adminActions.editOrganizationSuccess({organizationId: organizationId!, name, accountId, productCode});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				),
			)
		));

	editOrganizationSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(adminActions.editOrganizationSuccess),
		tap(() =>
			this.router.navigate(['/admin/organizations']).then(() =>
				this.messageService.displaySuccessMessage('', `Organization was successfully updated`)
			))
	), {dispatch: false});

		removeOrganization$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.removeOrganization),
				mergeMap(({organizationId}) =>
					this.adminOrganizationsApiService.deleteOrganization(organizationId).pipe(
						map((response: baseResponse) => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							this.messageService.displaySuccessMessage('', `Organization was removed successfully`);
							return adminActions.removeOrganizationSuccess({organizationId});
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
			)
		)

		lockUser$ = createEffect(() =>
			this.actions$.pipe(
				ofType(adminActions.lockUser),
				withLatestFrom(this.store.select(organizationIdSelector)),
				mergeMap(([{userId}, organizationId]) =>
					this.adminOrganizationsApiService.lockOrganizationUser(organizationId!, userId).pipe(
						map((response: baseResponse) => {
							if (!response.success) {
								this.messageService.displayErrorMessage('Error', response.error);
								return adminActions.handleError();
							}

							this.messageService.displaySuccessMessage('', `User was locked`);
							return adminActions.lockUserSuccess({userId});
						}),
						catchError((error) => {
							this.httpErrorService.handleError(error);
							return of(adminActions.handleError());
						})
					)
				)
			)
		);

	unlockUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.unlockUser),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{userId}, organizationId]) =>
				this.adminOrganizationsApiService.unlockOrganizationUser(organizationId!, userId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage('', `User was unlocked`);
						return adminActions.unlockUserSuccess({userId});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			)
		)
	);

	getAllFeatures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.getAllFeatures),
			mergeMap(() =>
				this.adminService.getAllFeatures().pipe(
					map((response: getAllFeaturesResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						return adminActions.getAllFeaturesSuccess({allFeatures: response.features});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			)
		)
	);

	assignRoleToFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.assignFeatureToOrganizationRole),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{roleId, featureId}, organizationId]) =>
				this.adminService.assignFeatureToOrganizationRole(organizationId!, roleId, featureId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage('', `New feature was assigned to role`);
						return adminActions.assignFeatureToOrganizationRoleSuccess({featureId, roleId});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			)
		)
	);

	unassignRoleToFeature$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.unassignFeatureFromOrganizationRole),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{roleId, featureId}, organizationId]) =>
				this.adminService.unassignFeatureFromOrganizationRole(organizationId!, roleId, featureId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage('', `Feature was unassigned from role`);
						return adminActions.unassignFeatureFromOrganizationRoleSuccess({featureId, roleId});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			)
		)
	);

	inviteToOrganization$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.inviteToOrganization),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{inviteModel}, organizationId]) =>
				this.adminService.inviteUser(inviteModel, organizationId!).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage('New user was invited to organization', 'New user was invited to organization');
						return adminActions.getManagedOrganization({organizationId: organizationId!});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			)
		)
	)

	getDefaultRoles$ = createEffect(() =>
	  this.actions$.pipe(
		ofType(adminActions.getDefaultRolesForSelectedOrganization),
		mergeMap(({organizationId}) => {
		  return this.adminOrganizationsApiService.getDefaultOrganizationRoles(organizationId).pipe(
				map((response: getDefaultRolesResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return adminActions.handleError();
					}

					return adminActions.getDefaultRolesForSelectedOrganizationSuccess({defaultRoles: response.roles});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(adminActions.handleError());
				})
		  )}
	  	)
	  )
	)

	resetOrganizationRoleToPredefinedRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.resetOrganizationRoleToPredefinedRole),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{roleId, defaultRoleId}, organizationId]) => {
				return this.adminOrganizationsApiService.resetToDefaultOrganizationRole(organizationId!, roleId, defaultRoleId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage("Role was successfully reset", "Role was successfully reset");
						return adminActions.getManagedOrganization({organizationId: organizationId!});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			})
		));

	addOrganizationRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.addOrganizationRole),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{addRoleModel}, organizationId]) => {
				return this.adminOrganizationsApiService.addOrganizationRole(organizationId!, addRoleModel).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						return adminActions.addOrganizationRoleSuccess();
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			})
		)
	);

	addOrganizationRoleSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.addOrganizationRoleSuccess),
			withLatestFrom(this.store.select(organizationIdSelector)),
			tap(([_, organizationId]) =>
				this.router.navigate(['/admin/organizations', organizationId, 'details']).then(
					() => this.messageService.displaySuccessMessage('New role was successfully added to organization', 'New role was successfully added to organization')
				)
			)
		)
	, {dispatch: false});

	removeOrganizationRole$ = createEffect(() =>
		this.actions$.pipe(
			ofType(adminActions.removeOrganizationRole),
			withLatestFrom(this.store.select(organizationIdSelector)),
			mergeMap(([{roleId}, organizationId]) => {
				return this.adminOrganizationsApiService.removeOrganizationRole(organizationId!, roleId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
							this.messageService.displayErrorMessage('Error', response.error);
							return adminActions.handleError();
						}

						this.messageService.displaySuccessMessage('Role was successfully removed from the organization', 'Role was successfully removed from the organization');
						return adminActions.getManagedOrganization({organizationId: organizationId!});
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(adminActions.handleError());
					})
				)
			})
		)
	);
}


