import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-error-message',
  template: `<p-message severity="error" *ngIf="showErrorMessage()" [text]="errorMessage"></p-message>`
})
export class ErrorMessageComponent {
  @Input() form!: FormGroup;
  @Input() propertyName: string = "";
  @Input() errorMessage: string = "";
  @Input() validatorRule: string = "";

  showErrorMessage = (): boolean => {
    const propertyFormControl = this.form.get(this.propertyName);
    return propertyFormControl?.errors?.[this.validatorRule] && propertyFormControl?.dirty;
  }
}
