import {createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props} from "@ngrx/store";
import {MeasurementSystem, Units} from "../../types/MeasurementSystem";
import {userSettings} from "../update-current-user-settings/update-current-user-settings-types";
import {feature, role, RoleName} from "../../admin/types";

export interface userState {
  userSettings: userSettings,
  userRoles: RoleName[],
  userPermittedFeatures: feature[],
  isLoadingCount: number,
	userFullName: string,
	userEmail: string,
	isHelpDeskAdmin: boolean,
	active: boolean
}

export const initialState: userState = {
  userSettings: { measurementSystem: MeasurementSystem.Imperial, acceptNotifications: true },
  userRoles: [],
  userPermittedFeatures: [],
  isLoadingCount: 0,
	userFullName: '',
	isHelpDeskAdmin: false,
	userEmail: '',
	active: true
};

export const userActions = createActionGroup({
  source: 'Users',
  events: {
    'Update Current User Settings': props<{measurementSystem: MeasurementSystem, acceptNotifications: boolean}>(),
    'Update Current User Settings Success': props<{measurementSystem: MeasurementSystem, acceptNotifications: boolean}>(),
    'Get Current User Settings': emptyProps(),
    'Get Current User Settings Success': props<{measurementSystem: MeasurementSystem, acceptNotifications: boolean}>(),
    'Get Current User Data': emptyProps(),
    'Change Current User Organization': props<{organizationId: number}>(),
    'Change User Organization Success': props<{organizationId: number}>(),
    'Load Current User Info': props<{roles: role[], userFullName: string, isHelpDeskAdmin: boolean, active: boolean}>(),
		'Set Current User Email': props<{userEmail: string}>(),
    'Handle Error': props<{errorMessage: string}>(),
  }
});

export const usersFeature = createFeature({
  name: 'users',
  reducer: createReducer(
    initialState,
    on(userActions.updateCurrentUserSettings, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
    on(userActions.updateCurrentUserSettingsSuccess, (state, action) => ({...state, isLoadingCount: state.isLoadingCount - 1, userSettings: {...state, acceptNotifications: action.acceptNotifications, measurementSystem: action.measurementSystem}})),
    on(userActions.getCurrentUserSettings, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
    on(userActions.getCurrentUserSettingsSuccess, (state, action) => ({...state, isLoadingCount: state.isLoadingCount - 1, userSettings: {...state.userSettings, acceptNotifications: action.acceptNotifications, measurementSystem: action.measurementSystem}})),
    on(userActions.getCurrentUserData, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
    on(userActions.changeCurrentUserOrganization, (state) => ({...state, isLoadingCount: state.isLoadingCount + 1})),
    on(userActions.loadCurrentUserInfo, (state, {roles, userFullName, isHelpDeskAdmin, active}) => {
      const userRoles = roles.map(({name}) => name);
      const userPermittedFeatures = roles.flatMap(({features}) => features);
      return { ...state, userRoles, userPermittedFeatures, isLoadingCount: state.isLoadingCount - 1, userFullName, isHelpDeskAdmin, active };
    }),
		on(userActions.setCurrentUserEmail, (state, {userEmail}) => ({...state, userEmail})),
    on(userActions.handleError, (state) => ({...state, isLoadingCount: state.isLoadingCount - 1})),
  ),
  extraSelectors: ({selectUserSettings, selectIsLoadingCount}) => ({
    selectTemperatureUnit: createSelector(selectUserSettings, ({measurementSystem}) => measurementSystem === MeasurementSystem.Metric ? Units.Celsius : Units.Fahrenheit),
    selectWindUnit: createSelector(selectUserSettings, ({measurementSystem}) => measurementSystem === MeasurementSystem.Metric ? Units.KilometersPerHour : Units.MilesPerHour),
    selectMeasurementUnit: createSelector(selectUserSettings, ({measurementSystem}) => measurementSystem === MeasurementSystem.Metric ? Units.Meters : Units.Feet),
    selectIrrigationUnit: createSelector(selectUserSettings, ({measurementSystem}) => measurementSystem === MeasurementSystem.Metric ? Units.Liter : Units.Gallon),
		selectPrecipitationUnit: createSelector(selectUserSettings, ({measurementSystem}) => measurementSystem === MeasurementSystem.Metric ? Units.MillimetersShort : Units.InchesShort),
    selectIsLoading: createSelector(selectIsLoadingCount, (isLoadingCount) => isLoadingCount > 0)
  })}
);

export const {
  selectUserSettings,
  selectTemperatureUnit,
  selectWindUnit,
  selectMeasurementUnit,
  selectIrrigationUnit,
  selectIsLoading,
	selectUserPermittedFeatures,
	selectUserFullName,
	selectIsHelpDeskAdmin,
	selectUserEmail,
	selectPrecipitationUnit
} = usersFeature;
