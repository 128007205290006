import {Injectable} from '@angular/core';
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import {MapboxStyles} from "../../project/_helpers/mapbox.styles";
import {FeatureCollection, GeoJsonProperties, Geometry} from "geojson";
import * as turf from "@turf/turf";
import {AreaMapboxModel} from "../../project/model/area-mapbox-model";

@Injectable({
  providedIn: 'root'
})
export class MapboxDrawService {
  draw!: MapboxDraw;
  currentSelection!: FeatureCollection<Geometry, GeoJsonProperties>;

  constructor() { }

  initDraw = () => {
    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: false
      },
      defaultMode: "simple_select",
      styles: MapboxStyles.returnStyles()
    });
  }

  resetDrawToPreviousState = () => {
    this.draw.deleteAll();
    this.draw.set(this.currentSelection);
  }

  updateCurrentSelection = () => this.currentSelection = this.draw.getAll();

  getSelectedPolygon = (): Geometry => this.draw.getSelected().features[0].geometry;

  getSelectedPolygonUuidProperty = ():string => this.draw.getSelected().features[0].properties!['uuid'];

  getSelectedPolygonNameProperty = ():string => this.draw.getSelected().features[0].properties!['name']

  getSelectedPolygonTypeProperty = ():string => this.draw.getSelected().features[0].properties!['type']

  setSelectedPolygonUuidProperty = (value: string) =>
    this.draw.setFeatureProperty(this.draw.getSelected().features[0].id!.toString(), 'uuid', value);

  setSelectedPolygonNameProperty = (value: string, elementaryObjectUuids: string[] | undefined = undefined) => {
    this.draw.setFeatureProperty(this.draw.getSelected().features[0].id!.toString(), 'name', value);

    if (elementaryObjectUuids) {
      const allFeatures: FeatureCollection = this.draw.getAll();

      elementaryObjectUuids.forEach(id => {
        for(let i = 0; i<allFeatures.features.length; i++) {
          const feature = allFeatures.features[i];
          if (feature.properties && feature.properties['uuid'] === id) {
            this.draw.setFeatureProperty(feature.id!.toString(), 'name', value);
          }
        }
      });
    }
  };

  setSelectedPolygonTypeProperty = (value: string) =>
    this.draw.setFeatureProperty(this.draw.getSelected().features[0].id!.toString(), 'type', value);

  setSelectedPolygonNameAndTypeProperty = (value: string, type: string) => {
    this.draw.setFeatureProperty(this.draw.getSelected().features[0].id!.toString(), 'name', value)
    this.draw.setFeatureProperty(this.draw.getSelected().features[0].id!.toString(), 'type', type)
  };

  selectPolygonByUuid = (uuid: string) => {
    const featuresIds = this.draw.getAll().features.filter(x => x.properties!['uuid'] === uuid).map(x => x.id!.toString());
    this.draw.changeMode("simple_select", { featureIds: featuresIds });
  }
  changeToSelectMode = () => this.draw.changeMode("simple_select");

  changeToDrawPolygonMode = () => this.draw.changeMode("draw_polygon");

  deleteSelectedPolygon = () => {
    const selectedFeaturesIds = this.draw.getSelected().features.map(x => x.id!.toString());
    this.draw.delete(selectedFeaturesIds);
  }

  // Deprecated
  deleteOrphanPolygons = () => {
    const orphanedFeaturesIds = this.draw.getAll().features.filter(x => !x.properties || !x.properties['name']).map(x => x.id!.toString());
    this.draw.delete(orphanedFeaturesIds);
  }

  deletePolygonByUuid = (uuid: string) => {
    const featuresIds = this.draw.getAll().features.filter(x => x.properties!['uuid'] === uuid).map(x => x.id!.toString());
    this.draw.delete(featuresIds);
  }

  addAreas = (areas: AreaMapboxModel[]) => {
    areas.forEach(area => {
      const mainPolygonFeature = turf.feature(area.polygon, {uuid: area.uuid, name: area.name, type: 'area'})
      this.draw.add(mainPolygonFeature);
      area.elementaryObjects?.forEach(elementaryObject => {
        const elementaryObjectFeature = turf.feature(elementaryObject.polygon, {uuid: elementaryObject.uuid, name: area.name, type: elementaryObject.type});
        this.draw.add(elementaryObjectFeature);
      });
    });
  };
}
