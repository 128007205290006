import {createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props} from "@ngrx/store";
import {
	ActionContext,
	defaultRole,
	feature,
	manageOrganizationUser,
	organizationDetails,
	organizationUserGridViewModel,
	organizationUserRoleGridViewModel,
	projectListItem,
	role
} from "../types";
import {OrganizationInterface} from "../../profile/types/user-types";
import {userInviteModel} from "../admin.api.service";
import {getFeaturesForRole} from "../../shared/utils/feature-authorization-utils";
import {addOrganizationRoleModel} from "../admin-organizations.api.service";
import * as projectActions from "../../project/project-store/actions";

export interface adminState {
	organizationUsers: organizationUserGridViewModel[]
	organizationDetails: organizationDetails | undefined,
	availableRoles: role[],
	availableProjects: projectListItem[],
	availableUsers: manageOrganizationUser[],
	managedUser: manageOrganizationUser,
	isAssignUserToRolePopupVisible: boolean,
	allFeatures: feature[],
	defaultRoles: defaultRole[],
	isLoading: boolean,
	featureIdsFilter: number[]
}

export const initialState: adminState = {
	organizationUsers: [],
	availableRoles: [],
	availableProjects: [],
	availableUsers: [],
	managedUser: {},
	isLoading: false,
	isAssignUserToRolePopupVisible: false,
	organizationDetails: undefined,
	allFeatures: [],
	defaultRoles: [],
	featureIdsFilter: []
}

export const adminActions = createActionGroup({
	source: 'Admin',
	events: {
		'Get Managed Organization': props<{organizationId: number}>(),
		'Get Managed Organization Success': props<{organizationUsers: organizationUserGridViewModel[], availableProjects: projectListItem[], availableRoles: role[], organizationDetails: organizationDetails}>(),
		'Show Assign User To Role Popup': props<{userId: number, email: string}>(),
		'Close Assign User To Role Popup': emptyProps(),
		'Assign User To Role': props<{userId: number, roleId: number, roleName: string, projectId?: number, projectName?: string}>(),
		'Assign User To Role Success': props<{userId: number, role: organizationUserRoleGridViewModel}>(),
		'Unassign User From Role': props<{userId: number, roleId: number}>(),
		'Unassign User From Role Success': props<{userId: number, roleId: number}>(),
		'Get Available Users': emptyProps(),
		'Get Available Users Success': props<{availableUsers: manageOrganizationUser[]}>(),
		'Add Organization': props<{name: string, accountId: string, productCode: string}>(),
		'Add Organization Success': props<{organization: OrganizationInterface}>(),
		'Edit Organization': props<{name: string, accountId: string, productCode: string}>(),
		'Edit Organization Success': props<{organizationId: number, name: string, accountId: string, productCode: string}>(),
		'Remove Organization': props<{organizationId: number}>(),
		'Remove Organization Success': props<{organizationId: number}>(),
		'Lock User': props<{userId: number}>(),
		'Lock User Success': props<{userId: number}>(),
		'Unlock User': props<{userId: number}>(),
		'Unlock User Success': props<{userId: number}>(),
		'Get All Features': emptyProps(),
		'Get All Features Success': props<{allFeatures: feature[]}>(),
		'Assign Feature To Organization Role': props<{roleId: number, featureId: number}>(),
		'Assign Feature To Organization Role Success': props<{roleId: number, featureId: number}>(),
		'Unassign Feature From Organization Role': props<{roleId: number, featureId: number}>(),
		'Unassign Feature From Organization Role Success': props<{roleId: number, featureId: number}>(),
		'Invite To Organization': props<{inviteModel: userInviteModel}>(),
		'Invite To Organization Success': emptyProps(),
		'Get Default Roles For Selected Organization': props<{organizationId: number}>(),
		'Get Default Roles For Selected Organization Success': props<{defaultRoles: defaultRole[]}>(),
		'Reset Organization Role To Predefined Role': props<{roleId: number, defaultRoleId: number}>(),
		'Add Organization Role': props<{addRoleModel: addOrganizationRoleModel}>(),
		'Add Organization Role Success': emptyProps(),
		'Remove Organization Role': props<{roleId: number}>(),
		'Filter Roles By Features': props<{featureIdsFilter: number[]}>(),
		'Handle Error': emptyProps()
	}
});

export const adminFeature = createFeature({
	name: 'admin',
	reducer: createReducer(
		initialState,
		on(adminActions.getManagedOrganization, (state) => ({...state, isLoading: true})),
		on(adminActions.getManagedOrganizationSuccess, (state, {organizationUsers, availableProjects, availableRoles, organizationDetails}) => ({...state, organizationUsers, availableProjects, availableRoles, organizationDetails, isLoading: false})),
		on(adminActions.showAssignUserToRolePopup, (state, {userId, email}) => ({...state, isAssignUserToRolePopupVisible: true, managedUser: {id: userId, email}})),
		on(adminActions.closeAssignUserToRolePopup, (state) => ({...state, isAssignUserToRolePopupVisible: false, managedUser: {}})),
		on(adminActions.assignUserToRole, (state) => ({...state, isLoading: true})),
		on(adminActions.assignUserToRoleSuccess, (state, {userId, role}) => {
			const organizationUsers: organizationUserGridViewModel[] = state.organizationUsers.map(ou => {
				return ou.id === userId ? {
					...ou, roles: [...ou.roles, role!]
				} : ou
			});

			return {...state, organizationUsers, isLoading: false, isAssignUserToRolePopupVisible: false, managedUser: {}}
		}),
		on(adminActions.unassignUserFromRole, (state) => ({...state, isLoading: true})),
		on(adminActions.unassignUserFromRoleSuccess, (state, {userId, roleId}) => {
			const organizationUsers: organizationUserGridViewModel[] = state.organizationUsers.map(user => {
				return user.id === userId ? {
					...user,
					roles: user.roles.filter(role => role.organizationUserRoleId !== roleId)
				} : user
			});

			return {...state, organizationUsers, isLoading: false};
		}),
		on(adminActions.getAvailableUsersSuccess, (state, {availableUsers}) => ({...state, availableUsers})),
		on(adminActions.handleError, (state) => ({...state, isLoading: false, isAssignUserToRolePopupVisible: false, managedUser: {}})),
		on(adminActions.lockUserSuccess, (state, {userId}) => ({...state, organizationUsers: state.organizationUsers.map(user => user.id === userId ? {...user, active: false} : user)})),
		on(adminActions.unlockUserSuccess, (state, {userId}) => ({...state, organizationUsers: state.organizationUsers.map(user => user.id === userId ? {...user, active: true} : user)})),
		on(adminActions.getAllFeaturesSuccess, (state, {allFeatures}) => ({...state, allFeatures})),
		on(adminActions.assignFeatureToOrganizationRoleSuccess, (state, {roleId, featureId}) => {
			const availableRoles = state.availableRoles.map(role =>
				role.id === roleId ? {
					...role,
					featureIds: [...role.featureIds, featureId]
				} : role);

			return {...state, availableRoles};
		}),
		on(adminActions.unassignFeatureFromOrganizationRoleSuccess, (state, {roleId, featureId}) => {
			const availableRoles = state.availableRoles.map(role =>
				role.id === roleId ? {
					...role,
					featureIds: role.featureIds.filter(id => id !== featureId)
				} : role);

			return {...state, availableRoles};
		}),
		on(adminActions.getDefaultRolesForSelectedOrganizationSuccess, (state, {defaultRoles}) => ({...state, defaultRoles})),
		on(projectActions.createNewProjectSuccess, (state, { newProject }) => ({...state, availableProjects: [...state.availableProjects, {id: newProject.id!, name: newProject.projectName!}]})),
		on(adminActions.filterRolesByFeatures, (state, {featureIdsFilter}) => ({...state, featureIdsFilter}))
	),
	extraSelectors: ({selectAllFeatures, selectDefaultRoles, selectAvailableRoles}) => ({
		selectFeaturesForContext: (actionContext: ActionContext) => createSelector(selectAllFeatures, (allFeatures): feature[] => getFeaturesForRole(actionContext, allFeatures)),
		selectDefaultFeaturesForContext: (actionContext: ActionContext) => createSelector(selectDefaultRoles, (defaultRoles): defaultRole[] => defaultRoles.filter(({context}) => context === actionContext)),
		selectUnassignedFeatures: createSelector(selectAllFeatures, selectAvailableRoles, (allFeatures, availableRoles): feature[] => {
			const featureIdsAssignedToRoles = availableRoles.flatMap(({featureIds}) => featureIds);
			return allFeatures.filter(({id, context}) => context !== ActionContext.Application && !featureIdsAssignedToRoles.includes(id));
		})
	})
});

export const { selectOrganizationUsers, selectIsLoading, selectAvailableRoles, selectAvailableProjects, selectIsAssignUserToRolePopupVisible, selectManagedUser, selectOrganizationDetails, selectAllFeatures, selectDefaultRoles, selectFeaturesForContext, selectDefaultFeaturesForContext, selectUnassignedFeatures, selectFeatureIdsFilter } = adminFeature
