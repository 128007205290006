import {point} from "../../../../types/point";
import {FormGroup} from "@angular/forms";
import {PrimeIcons} from "primeng/api";

export interface manageLaboratoryExaminationDto {
  // dryBiomassAverage?: number;
  // dryBiomassStandardDeviation?: number;
  // dryBiomassVariation?: number;
  laboratoryExaminationType?: number;

  nitrogenAverage?: number;
  // nitrogenStandardDeviation?: number;
  // nitrogenVariation?: number;

  potassiumAverage?: number;
  // potassiumStandardDeviation?: number;
  // potassiumVariation?: number;

  sodiumAverage?: number;
  // sodiumStandardDeviation?: number;
  // SodiumVariation?: number;

  calciumAverage?: number;
  // calciumStandardDeviation?: number;
  // calciumVariation?: number;

  magnesiumAverage?: number;
  // magnesiumStandardDeviation?: number;
  // magnesiumVariation?: number;

  manganeseAverage?: number;
  // manganeseStandardDeviation?: number;
  // manganeseVariation?: number;

  ironAverage?: number;
  // ironStandardDeviation?: number;
  // ironVariation?: number;

  zincAverage?: number;
  // zincStandardDeviation?: number;
  // zincVariation?: number;

  copperAverage?: number;
  // copperStandardDeviation?: number;
  // copperVariation?: number;

  phosphorusAverage?: number;
  // phosphorusStandardDeviation?: number;
  // phosphorusVariation?: number;
}

export interface addLaboratoryExaminationDto extends manageLaboratoryExaminationDto {
  gps: point;
  examinationDate: Date;
}

export interface editLaboratoryExaminationDto extends manageLaboratoryExaminationDto {
  id: number;
  gps?: point;
}

export interface selectedParameterVm {
  averageLabel?: string;
  deviationLabel?: string;
	deviation?: string;
	deviationColor?: string;
  unit?: string;
	isFormVisible: boolean
}

export type mineralIngredientUnit = '%' | 'ppm'

export interface initEditFormProps {
  editedLaboratoryExamination: manageLaboratoryExaminationDto;
  examinationDate: Date;
  examinationType: number;
}

export interface selectParameterProps {
  selectedParameter: string;
  form: FormGroup;
}

export interface changeUnitProps {
	unit: mineralIngredientUnit;
	form: FormGroup;
}

export interface LaboratoryObjectPopupState {
  chemicalParametersList: laboratoryExaminationListVm[];
  manageLaboratoryExaminationDto: manageLaboratoryExaminationDto;
  selectedParameter?: string;
  currentStep: laboratoryExaminationPopupStep;
  examinationDate?: Date;
  examinationType?: number;
  editMode: boolean;
}

export interface laboratoryExaminationListVm {
  name: string,
  displayName: string,
  Average?: number,
  // StandardDeviation?: number,
  // Variation?: number,
  unit?: mineralIngredientUnit
}

// export interface updateParameterProps {
//   value: number | null;
//   propertyName: string;
// }

export enum laboratoryExaminationPopupStep {
  LaboratoryExaminationType,
  ExaminationParameters,
  DateOfExamination
}
