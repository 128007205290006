import {inject, Injectable} from '@angular/core';
import {GrassParametersMapsService} from "./grass-parameters-maps.service";
import {HexesDataSourceType} from "../models/mapbox/types";
import {PresentationMode} from "../store/grass-params/types";
import {
	getAvailableHexesLayersConfigs,
	hexesLayerConfig
} from "../models/mapbox/hexes-layers.config";

@Injectable({
  providedIn: 'root'
})
export class MapboxParamsDisplayService {
  private mapsService = inject(GrassParametersMapsService);

  switchHexesSourcesVisibility = (config: hexesLayerConfig) => {
		const hexesLayersToHide = getAvailableHexesLayersConfigs().filter(c =>  c.dataSourceName !== config.dataSourceName);
		hexesLayersToHide.forEach(l => this.hideHexesLayers(l));
		this.showHexesLayers(config);
  }

  changePresentationMode = (presentationMode: PresentationMode, config: hexesLayerConfig) => {
    presentationMode === PresentationMode.Parameters ? this.showHexesLayers(config) : this.hideHexesLayers(config);
  }

	hideHexesLayers = (config: hexesLayerConfig) => {
		this.mapsService.maps.forEach(({map}) => {
			const bordersVisibility = map.getLayoutProperty(config.bordersLayer, 'visibility');
			const fillVisibility = map.getLayoutProperty(config.fillLayer, 'visibility');

			if (bordersVisibility !== 'none') {
				map.setLayoutProperty(config.bordersLayer, 'visibility', 'none');
			}
			if (fillVisibility !== 'none') {
				map.setLayoutProperty(config.fillLayer, 'visibility', 'none');
			}
		})
	}

  showHexesLayers = (config: hexesLayerConfig) => {
    this.mapsService.maps.forEach(({map}) => {
				map.setLayoutProperty(config.bordersLayer, 'visibility', 'visible');
				map.setLayoutProperty(config.fillLayer, 'visibility', 'visible');
    })
  }

  private updateVisibility = (currentConfig: hexesLayerConfig, previousConfig: hexesLayerConfig) => {
    this.mapsService.maps.forEach(({map}) => {
			[currentConfig.fillLayer, currentConfig.bordersLayer].forEach(layer => map.setLayoutProperty(layer, 'visibility', 'visible'));
			[previousConfig.fillLayer, previousConfig.bordersLayer].forEach(layer => map.setLayoutProperty(layer, 'visibility', 'none'));
    })
  }
}
