import {inject, Injectable, OnDestroy} from '@angular/core';
import {AuthService} from "./shared/services/auth.service";
import {AppState} from "./types/app-state";
import {Store} from "@ngrx/store";
import {selectIsLoading, userActions} from "./profile/store";
import * as routingActions from "./routing-store/actions";
import {Subject, takeUntil, tap} from "rxjs";
import {SignalrService} from "./shared/services/signalr.service";

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService implements OnDestroy {
  private store = inject(Store<AppState>);
  private authService = inject(AuthService);
	private signalrService = inject(SignalrService);

  private destroy$ = new Subject<void>();

  init = ():Promise<any> => {
    return new Promise((resolve) => {
      const userEmail = this.authService.getUserEmail();
      if (!userEmail) {
        resolve('');
        return;
      }

			this.store.dispatch(userActions.setCurrentUserEmail({userEmail}));
      this.store.dispatch(userActions.getCurrentUserData());
      this.store.dispatch(userActions.getCurrentUserSettings());
      this.store.dispatch(routingActions.login());

			this.signalrService.startConnection();

      this.store.select(selectIsLoading).pipe(takeUntil(this.destroy$))
          .subscribe((isLoading) => {
            if (!isLoading) {
              resolve('');
              return;
            }
      });
    });
  }

    ngOnDestroy(): void {
      this.destroy$.next();
    }
}
