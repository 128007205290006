import {Injectable} from '@angular/core';
import {NgElement, WithProperties} from '@angular/elements';
import {LngLat, Map, Point, Popup} from "mapbox-gl";
import {OtherObjectLayer} from "../models/mapbox/types";

@Injectable({
    providedIn: 'root'
})
export class ContextMenuPopupService {
  private itemsLayers = [
    OtherObjectLayer.ObservationPoints,
    OtherObjectLayer.SprinklersFill,
    OtherObjectLayer.SprinklersLine,
    OtherObjectLayer.LaboratoryExaminations
  ];

  showContextMenu = (popup: Popup, lngLat: LngLat, mapId: string, options?: {
    itemType?: string,
    itemId?: number
  }): Popup => {
    const contextMenuPopupComponent = document.createElement("app-context-menu-popup") as NgElement & WithProperties<{
      itemType?: string,
      itemId?: number,
      lngLat?: LngLat,
      mapId?: string
    }>;
    contextMenuPopupComponent.itemType = options?.itemType;
    contextMenuPopupComponent.itemId = options?.itemId;
    contextMenuPopupComponent.lngLat = lngLat;
    contextMenuPopupComponent.mapId = mapId;

    popup.setLngLat(lngLat);
    return popup.setDOMContent(contextMenuPopupComponent)
  }

  getItemFeaturesInPoint = (point: Point, map: Map) => map.queryRenderedFeatures(point, {layers: this.itemsLayers})
}
