import {Geometry, Polygon} from "geojson";
import {booleanContains, feature, kinks} from "@turf/turf";

export const isGeometryContained = (geometry1: Geometry, geometry2: Geometry) => {
  const feature1 = feature(geometry1);
  const feature2 = feature(geometry2);

  return booleanContains(feature1, feature2);
};

export const arePolygonLinesIntersecting = (geometry: Geometry) => {
  const intersections = kinks(geometry as Polygon);
  return intersections.features.length > 0;
}
