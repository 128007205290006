// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// @ts-ignore
import config from '../../package.json';
export const environment = {
  production: true,
	name: 'prod',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiYW5heC1vcHRpZGF0YSIsImEiOiJjbG11bHRobHgwZzdwMmltcXkxMWJ6aWMxIn0.wv4dwdr3ip9qpY5HVAR2rA',
    styles: {
      satelliteV9: 'mapbox://styles/mapbox/satellite-v9'
    },
		minZoom: 15,
		maxZoom: 22.5
  },
	sentryDsn: 'https://26e7a7831533d721ebee20911505136e@o4506598167543808.ingest.sentry.io/4506598168461312',
	webVersion: config.version,
	userApiUrl: 'https://api.skimturf.com/auth/api/',
	projectApiUrl: 'https://api.skimturf.com/project/api/',
	notificationApiUrl: 'https://api.skimturf.com/notification/api/',
	weatherApiUrl: 'https://api.skimturf.com/weather/api/',
	emailApiUrl: 'https://lapi.skimturf.com/email/api/',
	loggerApiUrl: 'https://api.skimturf.com/logger/api/',
  chunkSize: 100000000, // 1GB
  hubUrl: 'https://api.skimturf.com/notification/hubs/',
  hexZoomLow: 15,
  hexZoomHigh: 10,
  reportRefreshToken: 1800000,
	gridRowsPerPageOptions: [10, 20],
  needHelpUrl: 'https://optidataservicedesk.atlassian.net/',
	contextHelpUrl: 'https://app.demoboost.com/demo/',
	helpPathGrassParams: 'lsejtsiv',
	helpPathIncidents: 'lsejuc72',
	helpPathTasks: 'lsejuhgk',
  defaultLang: 'en',
	disableDeviationsParameters: true,
	disableSatelliteHighZoom: true
};
