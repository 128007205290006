import {Component, Input} from '@angular/core';
import {GeoJsonProperties} from "geojson";
import { selectableParam } from '../../../store/grass-params/types';

@Component({
  templateUrl: './grass-parameter-popup.component.html',
  styleUrls: ['./grass-parameter-popup.component.css']
})
export class GrassParameterPopupComponent {
  @Input() properties!: GeoJsonProperties;
  @Input() selectedParams: selectableParam[] = [];

  getParamDisplayValue = (param: selectableParam) => {
    if (!param) {
      return '';
    }

    const parameterValue = parseFloat(this.properties![`${param.name}`].toFixed(2));
    return parameterValue > -32766 ? parameterValue.toString() : "-";
  }

  isParamInNorm = (param: selectableParam) => {
    const deviationValue = this.getParamDeviationValuePercentage(param);
    return !deviationValue || deviationValue === 0;
  }

  getParamDeviationValuePercentage = (param: selectableParam): number => this.properties![`${param.name}Deviation`] * 100;

  getParamDeviationDisplayValue = (param: selectableParam) => {
    const deviationValue = this.getParamDeviationValuePercentage(param);
    return deviationValue > 0 ? `+${deviationValue.toFixed(0)}%` : `${deviationValue.toFixed(0)}%`;
  }
}
