import { BaseResponse } from "src/app/shared/model/api-responses";
import {point} from "../../types/point";
import * as mapboxgl from "mapbox-gl";

export interface Project {
    organizationId: number;
    selectAreaCategoriesId: number;
    address: AddressInterface;
    areaCoordinates: mapboxgl.LngLat[];
}

export interface ProjectInterface {
    id?: number;
    provider?: ProjectAreaProvider;
    providerId?: string;
    projectName?: string;
    area?: string;
    organizationId?: number;
    gps?: point;
    projectCategoryId?: number;
    grassSpeciesId?: number,
    areasCount?: number
    vegetativeMonths?: boolean[];
    currency?: string;
    messagingPhoneNumber?: string | null | undefined;
    messagingServiceSid?: string | null | undefined;
}

export enum ProjectAreaProvider {
  Manual,
  IGolf
}

export interface ProjectDetailsInterface {
  id?: number;
  projectName?: string;
  area?: string;
  gpsX?: string;
  gpsY?: string;
  organizationId?: number;
  projectCategoryId?: number;
  grassSpecies?: number;
  projectAreas?: any;
}

export interface ProjectCategoryInterface {
    id: number;
    name: string;
    translationKey: string;
}

export interface GrassSpeciesInterface {
    id: number;
    name: string;
}

export interface CurrencyInterface {
    code: string;
    name: string;
}

export interface AddressInterface {
    gps?: point
}

export interface AddProjectInterface extends BaseResponse {
    projectId: number;
}

export interface EditProjectInterface extends BaseResponse {
}

export interface EditAreasInterface {
    success: boolean;
    error: string;
}

export interface GetAreaInfoByGpsInterface extends BaseResponse {
    projectAreaId: number | null;
    elementaryObjectId: number | null;
    elementaryObjectType: string | null;
}