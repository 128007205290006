import { createAction, props } from "@ngrx/store";
import { AddIncidentCommand } from "../models/AddIncidentCommand"
import { UpdateIncidentCommand } from "../models/UpdateIncidentCommand"
import { LoadIncidentsParameters } from "../models/LoadIncidentsParameters"
import { GetIncidentsResponse } from "../models/GetIncidentsResponse"
import { GetDiseasesResponse } from "../models/GetDiseasesResponse";
import { AddTaskToIncidentCommand } from "../models/AddTaskToIncidentCommand";
import { LoadTasksParameters } from "src/app/task/models/LoadTasksParameters";
import { GetATasksResponse } from "src/app/task/models/GetATasksResponse";
import { IncidentWithDetailsDTO } from "../models/IncidentWithDetailsDTO";
import {chatGtpMessage} from "../../shared/components/edit-incident/tab-view/ai/ask-ai-api.service";
import { IncidentState } from "../models/IncidentState";
import { changeLog } from "src/app/shared/model/change-log";
import { baseResponse } from "src/app/grass-parameters-presentation/DTO/base-response";
import { EvaluationPopupModel } from "../models/EvaluationPopupModel";

export const incidentActions = {
	addIncident: createAction('[Incident] Add Incident', props<{command: AddIncidentCommand, createdFromPopup: boolean}>()),
	addIncidentSuccess: createAction('[Incident] Add Incident Success'),
	updateIncident: createAction('[Incident] Edit Incident', props<{command: UpdateIncidentCommand, editedFromPopup: boolean}>()),
	updateIncidentSuccess: createAction('[Incident] Update Incident Success'),
	deleteIncident: createAction('[Incident] Delete Incident', props<{ incidentId: string, deletedFromPopup: boolean }>()),
	handleError: createAction('[Incident] Error Occurred', props<{ errorMessage: string }>()),
	refreshIncidents: createAction('[Incident] Refresh Incidents'),
	loadIncidents: createAction('[Incident] Load Incidents', props<LoadIncidentsParameters>()),
	loadIncidentsSuccess: createAction('[Incident] Load Incidents End', props<GetIncidentsResponse>()),
	loadDiseases: createAction('[Incident] Load Diseases'),
	loadDiseasesSuccess: createAction('[Incident] Load Diseases End', props<GetDiseasesResponse>()),
	reset: createAction('[Incident] Reset'),
	loadAvailableTasks: createAction('[Incident] Load Available Tasks', props<LoadTasksParameters>()),
	loadAvailableTasksSuccess: createAction('[Incident] Load Available Tasks End', props<GetATasksResponse>()),
	addTaskToIncident: createAction('[Incident] Add Task To Incident', props<AddTaskToIncidentCommand>()),
	addTaskToIncidentSuccess: createAction('[Incident] Add Task To Incident End', props<baseResponse>()),
	removeTaskFromIncident: createAction('[Incident] Remove Task From Incident', props<{ incidentId: string, taskId: string }>()),
	getIncidentById: createAction('[Incident] Get Incident By Id', props<{ incidentId: string }>()),
	getIncidentByIdSuccess: createAction('[Incident] Get Incident By Id Success', props<{incident: IncidentWithDetailsDTO}>()),
	addComment: createAction('[Incident] Add Comment', props<{incidentId: string, comment: string}>()),
	addCommentSuccess: createAction('[Incident] Add Comment Success', props<{comment: string, author: string}>()),
	changeIncidentArea: createAction('[Incident] Change Incident Area', props<{areaId?: number, elementaryObjectId?: number}>()),
	changeIncidentElementaryObject: createAction('[Incident] Change Incident Elementary Object', props<{elementaryObjectId: number}>()),
	changeState: createAction('[Incident] Change State', props<{ incidentId: string, state: IncidentState }>()),
	changeStateEnd: createAction('[Incident] Change State End', props<{ stateToUpdate: IncidentState, errorMessage: string }>()),
	showEvaluateIncidentPopup: createAction('[Incident] Show Evaluate incident', props<{ model: EvaluationPopupModel }>()),
	hideEvaluateIncidentPopupAndChangeState: createAction('[Incident] Hide Evaluate incident', props<{ evaluation: number | null }>()),
	getChangesHistory: createAction('[Incident] Get Changes History', props<{ incidentId: string }>()),
	clearIncident: createAction('[Incident] Clear Incident'),
	getChangesHistorySuccess: createAction('[Incident] Get Changes History Success', props<{ changesHistory: changeLog[] }>()),
	updateStreamedMessage: createAction('[Incident] Update streamed message', props<{chunk: string}>()),
}

export const askAiActions = {
	getChatHistory: createAction('[Incident] Get Incident Ask AI History', props<{incidentId: string}>()),
	getChatHistorySuccess: createAction('[Incident] Get Incident Ask AI History Success', props<{messages: chatGtpMessage[]}>()),
	askAI: createAction('[Incident] Ask AI', props<{question: string, author?: string}>()),
	askAISuccess: createAction('[Incident] Ask AI Success', props<{message?: chatGtpMessage}>()),
	startAiInteraction: createAction('[Incident] Start AI Interaction', props<{author?: string}>()),
	finishAiInteraction: createAction('[Incident] Finish AI Interaction'),
	clarificationNeeded: createAction('[Incident] AI Clarification needed')
}
