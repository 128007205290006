import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	inject,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from "primeng/api";
import {Observable, combineLatest, map, switchMap, Subject, takeUntil, startWith} from "rxjs";
import {AppState} from "../../../../types/app-state";
import {Store} from "@ngrx/store";
import {selectUserEmail} from "../../../../profile/store";

@Component({
  selector: 'app-user-actions',
  template: `
    <div *ngIf="userActionsItems$ | async as userActionsItems" class="app-nav-user-box">
      <p-menu #userActions [popup]="true" [model]="userActionsItems"></p-menu>
      <p-avatar shape="circle" (click)="userActions.toggle($event)"></p-avatar>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActionsComponent implements OnInit, OnDestroy {
  @Output() userLoggedOut = new EventEmitter();

  private translateService = inject(TranslateService);
	private store = inject(Store<AppState>);

	private destroy$ = new Subject<void>();

	userActionsItems$?: Observable<MenuItem[]>;

	private readonly myProfileTranslationKey = 'NAV.USER_PANEL.MY_PROFILE';
	private readonly logoutTranslationKey = 'NAV.USER_PANEL.LOG_OUT';

  ngOnInit(): void {
		this.userActionsItems$ = combineLatest([
			this.translateService.onLangChange.pipe(
				startWith({lang: this.translateService.currentLang}),
				switchMap(() => this.translateService.get([this.myProfileTranslationKey, this.logoutTranslationKey]))
			),
			this.store.select(selectUserEmail)
		]).pipe(
			takeUntil(this.destroy$),
			map(([translations, userEmail]) => {
				return [
					{
						label: userEmail,
						styleClass: 'static-info',
						items: [
							{
								label: translations[this.myProfileTranslationKey],
								routerLink: ['/profile'],
								icon: 'pi pi-user'
							},
							{
								label: translations[this.logoutTranslationKey],
								command: () => this.userLoggedOut.emit(),
								icon: 'pi pi-sign-out'
							}
						]
					}
				]
			})
		)
  }

	ngOnDestroy() {
		this.destroy$.next();
	}
}


