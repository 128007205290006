import {createSelector} from "@ngrx/store";
import {selectUserSettings} from "../../../profile/store";
import {PhotoDataSourceType} from "../../models/photo-data-source-type";
import {AppState} from "../../../types/app-state";
import {dateOfPhotoMeasurements, HexGridDataMode, projectGrassParamsNorms, propsForMapChange} from "./types";
import {ParameterCategory} from "../../constants/config";
import {parameterTrend} from "../../../parameter-trend-api.service";
import {HexesDataSourceType} from "../../models/mapbox/types";
import {photoInfo, photoInfoArea} from "../../models/types";
import {displayPercentage, displaySize} from "../../../shared/utils/display-size";
import {
	getParameterMapboxNorms,
	parameterMapboxNorm
} from "../../models/parameter-norms/parameter-mapbox-norm";
import {availableElementaryObjectTypes} from "src/app/task/config";
import {PhotoInfoType} from "../../models/photo-info/photo-info-type";
import {
	getAvailableHexesLayersConfigs,
	hexesLayerConfig
} from "../../models/mapbox/hexes-layers.config";
import {MeasurementSystem} from "../../../types/MeasurementSystem";
import {
	convertCelsiusToFahrenheit, convertMillimetersToInches
} from "../../components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/utils/weather.utils";

export const selectFeatureGrassParams = (state: AppState) => state.grassParams;

export const selectIsLoading = createSelector(selectFeatureGrassParams, ({isLoadingCount}): boolean => isLoadingCount > 0);

export const isTrendLoading = (mapId: string) => createSelector(selectFeatureGrassParams, ({trendLoadingMapIds}) => {
  if (mapId)
    return trendLoadingMapIds.includes(mapId);
  return false;
});

export const selectMostRecentDateOfPhoto = createSelector(selectFeatureGrassParams, ({datesOfPhotos}) => new Date(Math.max(...datesOfPhotos.map(photo => new Date(photo.date).getTime()))))

export const selectPropsForDateChange = (date?: Date) => createSelector(selectMostRecentDateOfPhoto, selectFeatureGrassParams, (mostRecentDateOfPhoto, {datesOfPhotos}): propsForMapChange => {
  const selectedDate = date ?? mostRecentDateOfPhoto;
  const photoType = datesOfPhotos.find(x => x.date.getTime() === selectedDate.getTime())!.photoType;
  const photoId = datesOfPhotos.find(x => x.date.getTime() === selectedDate?.getTime() && x.photoType === photoType)!.processingFileVersionId;

  return { selectedDate, photoType, photoId };
});

export const selectPropsForPhotoTypeChange = (mapId: string, photoType: PhotoDataSourceType) => createSelector(selectFeatureGrassParams, ({datesOfPhotos, parametersMaps}): propsForMapChange => {
  const selectedDate = parametersMaps.find(x => x.mapId === mapId)!.selectedDate;
  const photoId = datesOfPhotos.find(x => x.date.getTime() === selectedDate.getTime() && x.photoType === photoType)!.processingFileVersionId;

  return { selectedDate, photoType, photoId };
});

export const selectHexGridDataMode = createSelector(selectFeatureGrassParams, ({hexGridDataMode}) => hexGridDataMode);

export const selectSelectedParams = createSelector(selectFeatureGrassParams, ({selectableParameters}) => selectableParameters.filter(x => x.isSelected && x.category !== ParameterCategory.Diseases));

export const selectDiseasesParams = createSelector(selectFeatureGrassParams, ({selectableParameters}) => selectableParameters.filter(x => x.category === ParameterCategory.Diseases));

export const selectMainSelectedParam = createSelector(selectFeatureGrassParams, ({mainSelectedParam}) => mainSelectedParam);

export const selectHexGridLayersFilters = createSelector(selectFeatureGrassParams, ({hexGridLayerFilters}) => hexGridLayerFilters);

export const selectAreasEnds = createSelector(selectFeatureGrassParams, ({areasEnds}) => areasEnds);

export const selectAllParams = createSelector(selectFeatureGrassParams, ({selectableParameters}) => selectableParameters);

export const selectCurrentGrassParam = createSelector(selectFeatureGrassParams, ({selectableParameters, mainSelectedParam}) => selectableParameters.find(({name}) => name === mainSelectedParam));

export const selectExpandedParamsCategories = createSelector(selectFeatureGrassParams, ({expandedCategories}) => expandedCategories);

export const selectSectorInfos = createSelector(selectFeatureGrassParams, ({sectorInfos}) => sectorInfos);

export const selectSectorIds = createSelector(selectFeatureGrassParams, ({selectedSectorIds}) => selectedSectorIds);

export const selectPhotoInfoTypes = createSelector(selectFeatureGrassParams, ({photoInfoTypes}) => photoInfoTypes);

export const selectArea = createSelector(selectFeatureGrassParams, selectUserSettings, ({parametersMaps, photoInfoTypes, selectedSectorIds}, {measurementSystem}): photoInfoArea => {
	if (parametersMaps.length === 0) {
		return {total: 0, healthy: 0, stressed: 0, critical: 0};
	}

  const photoInfos: photoInfo[] = parametersMaps[0].photoInfos.filter(pi =>
		photoInfoTypes.includes(pi.type as PhotoInfoType) &&
		pi.type !== PhotoInfoType.All &&
    availableElementaryObjectTypes.includes(pi.type as PhotoInfoType) &&
    (selectedSectorIds ? selectedSectorIds.includes(pi.areaId) : true)
  );

  return photoInfos.reduce((accumulator: photoInfoArea, photoInfo: photoInfo) => {
		return {
			...accumulator,
			critical: accumulator.critical + photoInfo.criticalAreaMetric,
			healthy: accumulator.healthy + photoInfo.healthyAreaMetric,
			total: accumulator.total + photoInfo.areaMetric,
			stressed: accumulator.stressed + photoInfo.stressedAreaMetric,
		};
	}, {total: 0, healthy: 0, stressed: 0, critical: 0});
});

export const selectTotalAreaDisplayValue = createSelector(selectArea, selectUserSettings,  ({total}, {measurementSystem}) =>
	displaySize(measurementSystem, total));

export const selectHealthyAreaDisplayValue = createSelector(selectArea, selectUserSettings, ({total, healthy}, {measurementSystem}) => !total || !healthy
	? `${displaySize(measurementSystem, healthy)}`
	: `${displaySize(measurementSystem, healthy)} (${displayPercentage(total, healthy)})`);

export const selectCriticalAreaDisplayValue = createSelector(selectArea, selectUserSettings, ({total, critical}, {measurementSystem}) => !total || !critical
	? `${displaySize(measurementSystem, critical)}`
	: `${displaySize(measurementSystem, critical)} (${displayPercentage(total, critical)})`);

export const selectHasCriticalArea = createSelector(selectArea, ({critical}) => critical > 0);

export const selectedStressedAreaDisplayValue = createSelector(selectArea, selectUserSettings, ({total, stressed}, {measurementSystem}) => !total || !stressed
	? `${displaySize(measurementSystem, stressed)}`
	: `${displaySize(measurementSystem, stressed)} (${displayPercentage(total, stressed)})`);

export const selectHasStressedArea = createSelector(selectArea, ({stressed}) => stressed > 0);

export const selectDatesOfPhotosAndIsLoading = createSelector(selectFeatureGrassParams, selectIsLoading, ({datesOfPhotos}, isLoading) => ({datesOfPhotos, isLoading}))

export const selectZoomValue = createSelector(selectFeatureGrassParams, ({zoomValue}) => zoomValue);

export const selectTrends = createSelector(selectFeatureGrassParams, ({trends}) => trends);

export const selectGrassParamsNorms = createSelector(selectFeatureGrassParams, ({projectGrassParamsNorms}) => projectGrassParamsNorms);

export const selectDefaultAndLatestNorms = createSelector(selectFeatureGrassParams, ({projectDefaultAndLatestGrassParamsNorms}) => projectDefaultAndLatestGrassParamsNorms);

export const selectParameterNorms = createSelector(selectGrassParamsNorms, (norms): parameterMapboxNorm[] => {
	if (!norms) {
		return [];
	}

	return getParameterMapboxNorms(norms);
})

export const selectTrendsByHexId = (mapId: string) => createSelector(selectFeatureGrassParams, selectSelectedParams, selectProcessingFileVersionId(mapId), selectGrassParamsNorms, ({zoomValue, trends, hexId}, selectedParams, processingFileVersionId, projectGrassParamsNorms): parameterTrend[] => {
	const hexTrends = trends.find(x => x.fileVersionId === processingFileVersionId && x.hexId === hexId && x.zoomValue === zoomValue);

	if (!hexTrends || !projectGrassParamsNorms) {
		return [];
	}

	return selectedParams.map(({name, displayName}) => {
		const paramName = name;
		const slope = hexTrends[`${name}Slope` as keyof typeof hexTrends] as number;
		let recentValues = hexTrends[name as keyof typeof hexTrends] as number[];
		const percentageChange = hexTrends[`${name}PercentageChange` as keyof typeof hexTrends] as number;

		if (recentValues.length === 1) {
			recentValues = [recentValues[0], recentValues[0]];
		}

		let currentValue = 0;

		if (recentValues.length > 0) {
			currentValue = +recentValues[recentValues.length-1].toFixed(2);
		}

		const {min, max} = getParamMinAndMax(name, projectGrassParamsNorms);


		return {paramName, slope, percentageChange, recentValues, currentValue, downBoundary: min, upBoundary: max};
	});
});

export const selectProcessingFileVersionId = (mapId: string) => createSelector(selectFeatureGrassParams, ({datesOfPhotos, parametersMaps}) => {
  const {selectedDate, dataSourceType} = parametersMaps.find(x => x.mapId === mapId) || {};

	if (!selectedDate) {
		return undefined;
	}

  return datesOfPhotos.find(x => x.date.getTime() === selectedDate.getTime() && x.photoType === dataSourceType)?.processingFileVersionId;
});

export const selectHexesLayersConfig = createSelector(selectHexGridDataMode, selectZoomValue, (dataMode, zoom): hexesLayerConfig => {
  let type: HexesDataSourceType;
  switch(dataMode){
    // case HexGridDataMode.Diseases: type = HexesDataSourceType.Diseases; break;
    case HexGridDataMode.Vegetation: type = HexesDataSourceType.Vegetation; break;
    case HexGridDataMode.Parameters: type = HexesDataSourceType.Parameters; break;
    case HexGridDataMode.Deviations: type = HexesDataSourceType.Deviations; break;
    default: throw 'unknown data mode';
  }

  return getAvailableHexesLayersConfigs().find(x => x.type === type && x.zoom === zoom)!;
})

export const selectParametersMap = createSelector(selectFeatureGrassParams, ({parametersMaps}) => parametersMaps);

export const selectWeatherMeasurementsForMapIds = createSelector(selectFeatureGrassParams, selectUserSettings, ({measurementsForMaps}, {measurementSystem}): dateOfPhotoMeasurements => {
	if (measurementSystem === MeasurementSystem.Imperial) {
		return Object.keys(measurementsForMaps).reduce((updatedMeasurements: dateOfPhotoMeasurements, mapId: string) => {
			updatedMeasurements[mapId] = measurementsForMaps[mapId].map(measurementMetric => ({
				...measurementMetric,
				temperature: convertCelsiusToFahrenheit(measurementMetric.temperature),
				minimumTemperature: convertCelsiusToFahrenheit(measurementMetric.minimumTemperature),
				maximumTemperature: convertCelsiusToFahrenheit(measurementMetric.maximumTemperature),
				precipitation: convertMillimetersToInches(measurementMetric.precipitation)
			}));

			return updatedMeasurements;
		}, {})
	}

	return measurementsForMaps;
});

export const selectDebugModeValues = createSelector(selectFeatureGrassParams, ({debugModeValues}) => debugModeValues);

export const selectHexId = createSelector(selectFeatureGrassParams, ({hexId}) => hexId);

export const selectObservedHexesSnapshots = createSelector(selectFeatureGrassParams, ({observedHexesSnapshots}) => observedHexesSnapshots);

// utils

const getParamMinAndMax = (paramName: string, norms: projectGrassParamsNorms): {min: number, max: number} => {
  const mineralIngredient = norms.mineralIngredientsNorms.find(x => x.paramName === paramName);

  if (mineralIngredient !== undefined) {
    return {
      min: mineralIngredient!.normDownBoundary,
      max: mineralIngredient!.normUpBoundary
    }
  }

  const visualAssessment = norms.visualAssessmentNorms.find(x => x.paramName === paramName);

  if (visualAssessment !== undefined) {
    return {
      min: visualAssessment!.badValue,
      max: visualAssessment!.goodValue
    }
  }

  const vegetationIndicator = norms.vegetationIndicatorNorms.find(x => x.paramName === paramName);

  return {
    min: vegetationIndicator!.badValue + 2,
    max: vegetationIndicator!.goodValue
  }
}
