import { Injectable } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import {FeatureCollection, GeoJSON, GeoJsonProperties, Geometry, Polygon} from "geojson";
import * as turf from '@turf/turf';
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import * as chooseAreaActions from "../choose-area/choose-area-store/actions";
import {AllGeoJSON} from "@turf/turf";

@Injectable({
    providedIn: 'root',  })
export class MapboxCenterService {
    map!: mapboxgl.Map;

    setCenter(polygon: Polygon): void {
        if (polygon == null) {
            return;
        }

        const center = this.calculateCenter(polygon);
        this.map.setCenter(center);
    }

  private calculateCenter = (polygon: Polygon) => {
    const feature = turf.polygon(polygon.coordinates);
    const centerPoint = turf.center(feature);
    const centerPointCoordinates = turf.getCoords(centerPoint);
    return new mapboxgl.LngLat(centerPointCoordinates[0], centerPointCoordinates[1]);
  }
}
