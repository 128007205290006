import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from "@ngrx/store";
import { applicationRole, applicationUser } from "../application-types";
import { LazyLoadEvent } from "primeng/api";
import { addApplicationUserCommand, changeApplicationUserDto, getApplicationUsersParameters } from "../admin-application.api.service";

export interface applicationState {
	roles: applicationRole[],
	filter: getApplicationUsersParameters,
	users: applicationUser[],
	totalRecords: number,
	details: applicationUser | null,
	isLoading: boolean
}

const initialState: applicationState = {
	roles: [],	
	filter: { take: 10, skip: 0 } as getApplicationUsersParameters,
	users: [],
	totalRecords: 0,
	details: null,
	isLoading: false
}

export const helpDeskActions = createActionGroup(({
	source: 'HelpDesk',
    events: {
		'Refresh Users': emptyProps(),
		'Load Users': props<{filter: getApplicationUsersParameters}>(),
		'Load Users Success': props<{users: applicationUser[], totalRecords: number}>(),
		'Filter Users By Active State': props<{active: boolean | null}>(),
		'Filter Users By Search Text': props<{ searchText: string | null }>(),
        'Filter Users By Lazy Load': props<{ lazyLoadEvent: LazyLoadEvent }>(),
		'Filter Users By Date': props<{dateRange: Date[] | null}>(),
		'Filter Reset': emptyProps(),
		'Add User': props<{dto: addApplicationUserCommand}>(),
		'Add User Success': props<{dto: addApplicationUserCommand, userId: number}>(),
		'Get User': props<{userId: number}>(),
		'Get User Success': props<{dto: applicationUser}>(),
		'Change User': props<{userId: number, dto: changeApplicationUserDto}>(),
		'Change User Success': props<{userId: number, dto: changeApplicationUserDto}>(),
        'Lock User': props<{userId: number}>(),
		'Lock User Success': props<{userId: number}>(),
		'Unlock User': props<{userId: number}>(),
		'Unlock User Success': props<{userId: number}>(),
		'Get Roles': emptyProps(),
		'Get Roles Success': props<{roles: applicationRole[]}>(),
		'Handle Error': emptyProps()
    }
}));

export const helpDeskFeature = createFeature({
	name: 'HelpDesk',
	reducer: createReducer(
		initialState,
		on(helpDeskActions.getRoles, (state) => ({...state, isLoading: true})),
        on(helpDeskActions.getRolesSuccess, (state, {roles}) => ({...state, roles})),
        on(helpDeskActions.loadUsers, (state, {filter}) => ({...state, filter, isLoading: true})),
		on(helpDeskActions.loadUsersSuccess, (state, {users, totalRecords}) => ({ ...state, users, totalRecords, isLoading: false })),
		on(helpDeskActions.filterUsersByActiveState, (state, {active}) => ({...state, filter: {...state.filter, active }})),
		on(helpDeskActions.filterUsersBySearchText, (state, {searchText}) => ({...state, filter: {...state.filter, searchText }})),
		on(helpDeskActions.filterUsersByDate, (state, {dateRange}) => ({...state, filter: {...state.filter, dateRange }})),
		on(helpDeskActions.filterUsersByLazyLoad, (state, {lazyLoadEvent}) => {
			if (!lazyLoadEvent) {
				return state;
			}

			return {
				...state,
				filter: {
					...state.filter,
					take: lazyLoadEvent.rows!,
					skip: lazyLoadEvent.first!,
					sortField: lazyLoadEvent.sortField,
					sortOrder: lazyLoadEvent.sortOrder
				}
			}
		}),
		on(helpDeskActions.filterReset, (state) => ({...state, filter: { take: 10, skip: 0 } as getApplicationUsersParameters })),
		on(helpDeskActions.addUser, (state) => ({...state, isLoading: true})),
		on(helpDeskActions.addUserSuccess, (state) => ({...state, isLoading: false})),
		on(helpDeskActions.getUser, (state) => ({...state, isLoading: true})),
		on(helpDeskActions.getUserSuccess, (state, {dto}) => ({...state, details: dto, isLoading: false})),
        on(helpDeskActions.lockUser, (state) => ({...state, isLoading: true})),
		on(helpDeskActions.lockUserSuccess, (state, {userId}) => ({...state, users: state.users.map(user => user.id === userId ? {...user, active: false} : user), isLoading: false})),
        on(helpDeskActions.unlockUser, (state) => ({...state, isLoading: true})),
		on(helpDeskActions.unlockUserSuccess, (state, {userId}) => ({...state, users: state.users.map(user => user.id === userId ? {...user, active: true} : user), isLoading: false})),
        on(helpDeskActions.changeUser, (state) => ({...state, isLoading: true})),
        on(helpDeskActions.changeUserSuccess, (state, {userId, dto}) => ({...state, details: null, users: state.users.map(user => user.id === userId ? {...user, active: dto.active, firstName: dto.firstName, lastName: dto.lastName, applicationRoleId: dto.roleId} : user), isLoading: false}))
    )
});

export const {selectRoles, selectFilter, selectUsers, selectTotalRecords, selectDetails, selectIsLoading} = helpDeskFeature