<div class="one-atlas-prediction-container p-confirm-dialog-message">
	<div class="form-group gap-25">
    <div *ngIf="shouldShowNoDataInfo() === true">
        <p class="color-gray-2 font-size-12">No images found for the selected location.</p>
    </div>
    <div *ngIf="shouldShowYearInfo() === true">
        <p class="color-gray-2 font-size-12">You can receive about {{this.yearSum}} images per year for the selected location.</p>
    </div>
    <div *ngIf="shouldShowMonthInfo() === true">
        <p class="color-gray-2 font-size-12">You can receive about {{this.monthAvg}} images per month for the selected location.</p>
    </div>
    <p class="font-size-10">* The calculation was made based on the number of available images from last year.</p>
	</div>
<div>
