import { HttpClient } from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import {BehaviorSubject, catchError, combineLatest, map, of} from "rxjs";

@Injectable({providedIn: 'root'})
export class ApplicationService {
  private http = inject(HttpClient);
  getWebVersion = (): Observable<appVersionResponse> => new BehaviorSubject<appVersionResponse>({
		version: environment.webVersion,
		error: '',
		success: true
	});
  getProjectApiVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.projectApiUrl}application/version`);
  getUserApiVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.userApiUrl}application/version`);
  getNotificationApiVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.notificationApiUrl}application/version`);
  getWeatherVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.weatherApiUrl}application/version`);
  getEmailVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.emailApiUrl}application/version`);
  getLoggerApiVersion = (): Observable<appVersionResponse> => this.http.get<appVersionResponse>(`${environment.loggerApiUrl}application/version`);
  getPublicKey = (): Observable<appPublicKeyResponse> => this.http.get<appPublicKeyResponse>(`${environment.userApiUrl}application/publicKey`);

	getAppVersions(): Observable<appVersions> {
		return combineLatest([
			this.getWebVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getUserApiVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getProjectApiVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getWeatherVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getEmailVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getNotificationApiVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			),
			this.getLoggerApiVersion().pipe(
				map(v => v.version),
				catchError(() => of(''))
			)
		]).pipe(
			map(([webVersion, apiAuthVersion, apiProjectVersion, apiWeatherVersion, apiEmailVersion, apiNotificationVersion, apiLoggerVersion]) => {
				return ({
					webVersion,
					apiAuthVersion,
					apiProjectVersion,
					apiWeatherVersion,
					apiEmailVersion,
					apiNotificationVersion,
					apiLoggerVersion
				});
			})
		);
	}
}

interface appVersionResponse {
  version: string,
  error: string,
  success: boolean
}

export interface appPublicKeyResponse {
	publicKey: string,
	error: string,
	success: boolean
  }

export interface appVersions {
	webVersion: string;
	apiAuthVersion: string;
	apiProjectVersion: string;
	apiWeatherVersion: string;
	apiEmailVersion: string;
	apiNotificationVersion: string;
}
