import { Component, OnDestroy, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LngLat } from "mapbox-gl";
import { Subject, takeUntil } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { addSprinklerCommand } from 'src/app/grass-parameters-presentation/DTO/items/sprinklers/add-sprinkler-command';
import { editSprinklerCommand } from 'src/app/grass-parameters-presentation/DTO/items/sprinklers/edit-sprinkler-command';
import {otherObjectActions, sprinklersActions} from "../../../store/other-objects/actions";
import { AppState } from 'src/app/types/app-state';
import { selectIrrigationUnit, selectMeasurementUnit, selectUserSettings } from 'src/app/profile/store';
import { TranslateService } from '@ngx-translate/core';
import { SprinklerType } from 'src/app/grass-parameters-presentation/store/other-objects/types';
import { MeasurementSystem, Units } from "src/app/types/MeasurementSystem";
import { convertGallonToLiter, convertLiterToGallon } from '../photo-general-information/general-info-weather-panel/utils/weather.utils';
import { point } from 'src/app/types/point';

@Component({
  templateUrl: './sprinkler-popup.component.html',
  styleUrls: ['./sprinkler-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SprinklerPopupComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  readonly measurementUnit$ = this.store.select(selectMeasurementUnit);
  readonly irrigationUnit$ = this.store.select(selectIrrigationUnit);
  private formBuilder = inject(FormBuilder);
  private translate = inject(TranslateService);
  private data: ManageSprinklerModel;

  public form: FormGroup;


  sprinklerTypes: any[] = [
		{ id: SprinklerType.PopUp, name: this.translate.instant('GRASS_PARAMETERS.SPRINKLER.SPRINKLERTYPE_POPUP') },
		{ id: SprinklerType.Rotary, name: this.translate.instant('GRASS_PARAMETERS.SPRINKLER.SPRINKLERTYPE_ROTARY') },
	];

  measurementSystem: MeasurementSystem = MeasurementSystem.Metric;

  constructor(private config: DynamicDialogConfig, private store: Store<AppState>) {
    this.data = config.data;
    this.form = this.formBuilder.group({
      number: [{value: null, disabled: true }],
      sprinklerType: [{ value: null, disabled: this.data.action != 'add' }, [Validators.required]],
      radius: [null, [Validators.required]],
      irrigation: [null, [Validators.min(0), Validators.required]],
      name: [null, [Validators.maxLength(200)]],
      producer: [null, [Validators.maxLength(200)]],
      projectAreaName: [{ value: null, disabled: true }],
      elementaryObjectName: [{ value: null, disabled: true }]
    });
  }

  ngOnInit(): void {
    this.store.select(selectUserSettings).pipe(takeUntil(this.destroy$))
      .subscribe((vm) => {
        if (!vm) {
          return;
        }

        this.measurementSystem = vm?.measurementSystem || MeasurementSystem.Metric;
        const model = this.data.model;

        this.form.patchValue({
          number: model.number,
          sprinklerType: model.sprinklerType,
          radius: model.radius,
          irrigation: this.convertToUserMeasurementSystem(model.irrigation),
          name: model.name,
          producer: model.producer,
          projectAreaName: model.projectAreaName,
          elementaryObjectName: model.elementaryObjectName
				});
      });
	}

  public onClose(event: any) : void {
    event.preventDefault();
    this.store.dispatch(otherObjectActions.hideItemPopup());
  }

  public onSubmit(): void {
    switch(this.data.action) {
      case "add": return this.onSubmitAdd();
      case "edit": return this.onSubmitEdit();
      default: throw `Unrecognized action ${this.data.action}`;
    }
  }

  private onSubmitAdd(): void {
    const model: AddSprinklerModel = this.data.model;

    const command: addSprinklerCommand = {
      gps: model.gps,
      radius: this.form.controls['radius'].value,
      name: this.form.controls['name'].value,
      sprinklerType: this.form.controls['sprinklerType'].value,
      producer: this.form.controls['producer'].value,
      irrigation: this.convertToMetricMeasurementSystem(this.form.controls['irrigation'].value),
    };

    this.store.dispatch(sprinklersActions.addSprinkler(command));
    this.store.dispatch(otherObjectActions.hideItemPopup());
  }

  private onSubmitEdit(): void {
    const command: editSprinklerCommand = {
      sprinklerId: this.data.model.sprinklerId,
      gps: this.data.model.gps,
      name: this.form.controls['name'].value,
      radius: this.form.controls['radius'].value,
      irrigation: this.convertToMetricMeasurementSystem(this.form.controls['irrigation'].value),
    };

    this.store.dispatch(sprinklersActions.editSprinkler(command));
    this.store.dispatch(otherObjectActions.hideItemPopup());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public submitButtonTitle(): string {
    return this.translate.instant(this.data.action == 'add' ? "COMMON.CREATE" : "COMMON.SAVE");
  }

  public shouldShowAreaName(): boolean {
    return this.data.action !== 'add';
  }

  convertToMetricMeasurementSystem(value: number | null | undefined): number | null | undefined {
    return this.measurementSystem == MeasurementSystem.Metric ? value : convertGallonToLiter(value);
  }

  convertToUserMeasurementSystem(value: number | null | undefined): number | null | undefined {
    return this.measurementSystem == MeasurementSystem.Metric ? value : convertLiterToGallon(value);
  }
}

export interface AddSprinklerModel {
  gps: point,
}

export class ManageSprinklerModel {
  constructor(public action: string, public model: any) {
  }
}
