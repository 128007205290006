import {Component, Input, inject} from "@angular/core";
import { Store } from "@ngrx/store";
import { selectChangesHistory } from "src/app/incident/store/selectors";
import { AppState } from "src/app/types/app-state";

@Component({
	selector: 'app-incident-tab-view',
	template: `
		<p-tabView *ngIf="incidentId" class="w-100 h-100 buttons-auto-width">
			<p-tabPanel [header]="'INCIDENT.INCIDENT_EDIT.TAB_VIEW.COMMENT' | translate">
				<app-incident-comments-section [incidentId]="incidentId" />
			</p-tabPanel>
			<p-tabPanel [header]="'INCIDENT.INCIDENT_EDIT.TAB_VIEW.HISTORY' | translate">
				<app-changes-history [changes]="(changesHistory$ | async)!" />
			</p-tabPanel>
			<p-tabPanel [header]="'INCIDENT.INCIDENT_EDIT.TAB_VIEW.ASK_AI' | translate">
				<app-ask-ai [incidentId]="incidentId" />
			</p-tabPanel>
		</p-tabView>
	`
})
export class IncidentTabViewComponent {
	@Input() incidentId?: string;
	private store = inject(Store<AppState>);
	readonly changesHistory$ = this.store.select(selectChangesHistory);
}
