export interface manageOrganizationUser {
  id?: number;
  name?: string;
	email?: string;
}

export interface role {
  id: number;
  name: RoleName;
  features: feature[];
	context: ActionContext;
	featureIds: number[];
}

export interface defaultRole {
	id: number;
	name: RoleName;
	context: ActionContext;
	features: FeatureName[];
}

export interface feature {
	id: number;
  name: FeatureName;
  displayName: string;
	context: ActionContext;
	projectId?: number;
	organizationId?: number;
}

export enum ActionContext {
	Application,
	Organization,
	Project
}

export enum RoleName {
  'Owner'='Owner',
  'Admin' = 'Admin',
  'FieldManager' = 'Field Manager',
  'Visitor' = 'Visitor',
	'DroneOperator' = 'Drone Operator',
	'DemoUser' = 'Demo User'
}

export enum FeatureName {
  'ReadManageApplicationData' = 'ReadManageApplicationData',
  'ManageApplicationAccess' = 'ManageApplicationAccess',
  'ManageApplicationUserRoles' = 'ManageApplicationUserRoles',
  'ManageApplicationUsers' = 'ManageApplicationUsers',
  'ManageOrganizations' = 'ManageOrganizations',
  'ManageOrganizationRoles' = 'ManageOrganizationRoles',
  'ManageOrganizationRoleFeatures' = 'ManageOrganizationRoleFeatures',
  'ManageOrganizationUsers' = 'ManageOrganizationUsers',
  'ManageOrganizationUserRoles' = 'ManageOrganizationUserRoles',
  'ReadManageOrganizationData' = 'ReadManageOrganizationData',
  'ManageOrganizationAccess'='ManageOrganizationAccess',
  'ManuallyEditAreas' = 'ManuallyEditAreas',
  'ReadEditAreasData' = 'ReadEditAreasData',
  'ReadGolfData' = 'ReadGolfData',
  'ReadMapParamData' = 'ReadMapParamData',
  'ReadMapItemsData' = 'ReadMapItemsData',
  'ManageMapItems' = 'ManageMapItems',
  'ReadGrassNormsData' = 'ReadGrassNormsData',
  'ManageGrassNorms' = 'ManageGrassNorms',
  'CheckOneAtlasStatus' = 'CheckOneAtlasStatus',
  'ReadOneAtlasData' = 'ReadOneAtlasData',
  'MakeOneAtlasOrder' = 'MakeOneAtlasOrder',
  'RepeatPhotoVerificationProcess' = 'RepeatPhotoVerificationProcess',
  'AddNewProject' = 'AddNewProject',
  'ManageProject' = 'ManageProject',
  'RemoveProject' = 'RemoveProject',
  'ReadProjectData' = 'ReadProjectData',
  'ReadCategoriesData' = 'ReadCategoriesData',
  'UploadPhoto' = 'UploadPhoto',
  'ReadUploadPhotoData' = 'ReadUploadPhotoData',
  'RandomFeature' = 'RandomFeature',
	'ReadTask' = 'ReadTask',
	'StartTask'= 'StartTask',
	'AddAttachmentToTask'= 'AddAttachmentToTask',
	'ReadTaskAttachment' = 'ReadTaskAttachment',
	'AddTask'='AddTask',
	'AddTaskComment'='AddTaskComment',
	'AssignTask'='AssignTask',
	'CancelTask'='CancelTask',
	'CloseTask'='CloseTask',
	'FindTasks'='FindTasks',
	'EditTask'='EditTask',
	'RemoveTask'='RemoveTask',
	'ScheduleTask'='ScheduleTask',
	'ReadProjectReport'='ReadProjectReport',
	'FindIncidents'='FindIncidents',
	'EditIncident'='EditIncident',
	'AddIncident'='AddIncident',
	'RemoveIncident'='RemoveIncident',
	'ReadAttachment'='ReadAttachment',
	'ProcessUploadedPhoto'='ProcessUploadedPhoto',
	'DownloadUploadedPhoto'='DownloadUploadedPhoto',
	'ManageGreenSpeedExaminations' = 'ManageGreenSpeedExaminations',
	'ReadGreenSpeedExaminations' = 'ReadGreenSpeedExaminations',
	'ManageGreenSpeedNorms' = 'ManageGreenSpeedNorms',
	'ReadGreenSpeedNorms' = 'ReadGreenSpeedNorms',
	'ReadWeather' = 'ReadWeather',
	'ReadGolfClubMembers' = 'ReadGolfClubMembers',
	'AddGolfClubMember' = 'AddGolfClubMember',
	'UpdateGolfClubMember' = 'UpdateGolfClubMember',
	'RemoveGolfClubMember' = 'RemoveGolfClubMember',
	'ReadOrganizationGolfClubMembers' = 'ReadOrganizationGolfClubMembers',
	'AssignMembersToProjects' = 'AssignMembersToProjects',
	'RemoveUploadPhoto' = 'RemoveUploadPhoto'
}

export interface organizationUserGridViewModel {
	email: string,
	id: number,
	roles: organizationUserRoleGridViewModel[],
	active: boolean
}

export interface organizationUserRoleGridViewModel {
	organizationUserRoleId: number,
	roleId: number,
	roleName: string,
	projectId?: number,
	projectName?: string
}

export interface organizationUserListItem {
	id: number,
	email: string,
	fullName: string,
	active: boolean,
	firstActivityDateTime: Date | null,
	lastActivityDateTime: Date | null,
	accessVia: number | null
}

export interface organizationUserRoleListItem {
	userId: number,
	roleId: number,
	organizationUserRoleId: number,
	projectId?: number
}

export interface projectListItem {
	id: number,
	name: string
}

export interface organizationDetails {
	name: string,
	awsAccountId: string,
	// todo add to backend
	// licenceType: LicenseType,
	activationDate?: Date,
	// expirationDate: Date, // missing in awsSubscriptionManager?
	automaticRenewal?: boolean,
	subscriptionStatus?: string
}

export enum LicenseType {
	Basic='9Holes',
	Standard='18Holes',
	Premium='76Holes'
}
