import {inject, Injectable} from "@angular/core";
import {TaskService} from "../../task.service";
import {TaskSwitchAction} from "../../models/TaskStatus";
import {Observable} from "rxjs";
import {baseResponse} from "../../../grass-parameters-presentation/DTO/base-response";

@Injectable({
	providedIn: 'root'
})
export class TaskStatusChangeStrategyService {
	private tasksApiService = inject(TaskService);

	completeStatusChange = (action: TaskSwitchAction, taskId: string, dueDate?: Date, assignedUserId?: number): Observable<baseResponse> => {
		switch (action) {
			case TaskSwitchAction.Cancel:
				return this.tasksApiService.cancelTask(taskId);
			case TaskSwitchAction.Close:
				return this.tasksApiService.closeTask(taskId);
			case TaskSwitchAction.Schedule:
				return this.tasksApiService.scheduleTask({taskId, dueDate: dueDate!, assignedUserId});
			default:
				return this.tasksApiService.startTask({taskId, dueDate: dueDate!, assignedUserId});
		}
	}
}
