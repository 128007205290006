import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {NgIf} from "@angular/common";
import {TrendsLineChartDirective} from "./trends-line-chart.directive";

@Component({
    selector: 'app-parameter-trend-chart',
    template: `
        <canvas class="trends-canvas"
                *ngIf="recentValues" appTrendsLineChart
                [trendData]="recentValues"
                [downBoundary]="downBoundary"
                [upBoundary]="upBoundary">
        </canvas>
    `,
    standalone: true,
    imports: [
        NgIf,
        TrendsLineChartDirective
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterTrendChartComponent {
    @Input() recentValues: number[] = [];
    @Input() upBoundary!: number;
    @Input() downBoundary!: number;
}
