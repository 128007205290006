import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, groupBy, map, mergeMap, of, switchMap, take, tap, withLatestFrom } from "rxjs";
import { PhotosService } from "../../services/api/photos.service";
import { dateOfPhotoResponse } from "../../models/date-of-photo/date-of-photo-response";
import { HttpErrorService } from "../../../shared/services/http-error.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "../../../types/app-state";
import { LoadPanelService } from "../../../shared/services/load-panel.service";
import { MapboxHexGridService } from "../../services/mapbox-hex-grid.service";
import { GrassParametersMapsService } from "../../services/grass-parameters-maps.service";
import {HexGridDataMode, PresentationMode, projectGrassParamsNorms, propsForMapChange} from "./types";
import { MapboxFilterUtils } from "../../helpers/mapbox-filter-utils";
import { MapboxParamsDisplayService } from "../../services/mapbox-params-display.service";
import {
	diseasesFillLayers,
	parametersFillLayers,
	vegetationFillLayers
} from "../../models/mapbox/types";
import * as config from "../../constants/config";
import { AgroMessageService } from "../../../shared/services/agro-message.service";
import { grassParamsActions } from "./actions";
import * as grassParamsSelectors from "./selectors";
import {
	selectHexesLayersConfig,
	selectMainSelectedParam,
	selectMostRecentDateOfPhoto
} from "./selectors";
import { hexTrends, ParameterTrendApiService, parameterTrendResponse } from "../../../parameter-trend-api.service";
import { PhotoParametersApiService } from "../../services/api/photo-parameters.api.service";
import { MapboxUtils } from "../../../shared/utils/mapbox-utils";
import {selectComposition, selectPresentationMode} from "../index";
import {getAvailableHexesLayersConfigs} from "../../models/mapbox/hexes-layers.config";
import {getAvailableFileLinks} from "../../helpers/file-links.utils";
import {
	getDefaultAndLatestMineralIngredientNormsResponse,
	getProjectGrassParamsLatestNormsResponse,
	ProjectGrassParamsNormsApiService
} from "../../services/api/project-grass-params-norms-api.service";
import {vegetationIndicatorNorms, visualAssessmentsNorms} from "../../models/parameter-norms/parameter-mapbox-norm";
import {selectedProjectIdSelector} from "../../../project/project-store/selectors";
import {baseResponse} from "../../DTO/base-response";
import {
	getWeatherForTaskResponse, WeatherApiService
} from "../../components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";
import {commonActions} from "../../../shared/store/common/actions";
import {FitBoundsOptions, LngLat, LngLatBoundsLike} from "mapbox-gl";
import {
	getObservedHexesSnapshotsResponse, observedHexesSnapshot,
	ObservedHexesSnapshotsApiService, saveObservedHexesSnapshotResponse
} from "../../services/api/observed-hexes-snapshots.api.service";
import {ObservedHexesService} from "../../services/observed-hexes.service";
import {environment} from "../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {tilesLayersConfig} from "../../models/mapbox/tiles-layers.config";
import {mapTilesFilesDtoToTilesFiles} from "../../models/types";

@Injectable()
export class GrassParamsEffects {
  private actions$ = inject(Actions);
  private photosApiService = inject(PhotosService);
  private trendsApiService = inject(ParameterTrendApiService);
  private httpErrorService = inject(HttpErrorService);
  private store = inject(Store<AppState>);
  private loadPanelService = inject(LoadPanelService);
  private mapboxHexGridService = inject(MapboxHexGridService);
  private mapsService = inject(GrassParametersMapsService);
  private layersDisplayService = inject(MapboxParamsDisplayService);
  private messageService = inject(AgroMessageService);
  private photoParametersApiService = inject(PhotoParametersApiService);
	private projectGrassParamsNormsApiService = inject(ProjectGrassParamsNormsApiService);
	private weatherApiService = inject(WeatherApiService);
	private observedHexesSnapshotsApiService = inject(ObservedHexesSnapshotsApiService);
	private observedHexesService = inject(ObservedHexesService);
	private translateService = inject(TranslateService);

  getDatesOfPhotos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(grassParamsActions.getDatesOfPhotos),
      exhaustMap(({ projectId }) => this.photosApiService.getProjectDateOfPhotos(projectId).pipe(
        map((response: dateOfPhotoResponse) => {
          if (!response.success) {
            this.httpErrorService.handleErrorOkResponse(response);
            return grassParamsActions.handleErrorResponse({ errorMessage: response.error });
          }

          return grassParamsActions.getDatesOfPhotosSuccess({ datesOfPhotos: response.items.map(dateOfPhoto => ({ ...dateOfPhoto, date: new Date(new Date(dateOfPhoto.date).toDateString()) })) });
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          this.httpErrorService.handleError(errorResponse);
          return of(grassParamsActions.handleErrorResponse({ errorMessage: errorResponse.error }));
        })
      ))
    )
  );

  changeDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(grassParamsActions.changeDateOfPhoto),
      mergeMap(({ date, mapId }) =>
        this.store.select(grassParamsSelectors.selectPropsForDateChange(date)).pipe(
          take(1),
          exhaustMap((propsForMapChange) =>
            this.handleDateOrPhotoTypeChange(propsForMapChange, mapId)
          )
        )
      )
    )
  );

  changePhotoType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(grassParamsActions.changePhotoType),
      mergeMap(({ photoType, mapId }) =>
        this.store.select(grassParamsSelectors.selectPropsForPhotoTypeChange(mapId, photoType)).pipe(
          take(1),
          exhaustMap((propsForMapChange) =>
            this.handleDateOrPhotoTypeChange(propsForMapChange, mapId)
          )
        )
      )
    )
  );

  private handleDateOrPhotoTypeChange = (propsForMapChange: propsForMapChange, mapId: string) => {
    const { photoType, photoId, selectedDate } = propsForMapChange

    return this.photoParametersApiService.getProjectPhotoHexParameters(photoId).pipe(
      map(response => {
        if (!response.success) {
          this.httpErrorService.handleErrorOkResponse(response);
          return grassParamsActions.handleErrorResponse({ errorMessage: response.error });
        }

        let { photoInfos, fileLinks, gps, areasEnds, bbox, tilesLinks  } = response;

				fileLinks = getAvailableFileLinks(fileLinks, photoType);



        return grassParamsActions.changeDateOfPhotoOrPhotoTypeSuccess({
          date: selectedDate,
          photoType,
          mapId,
          photoInfos,
          fileLinks,
          gps,
          areasEnds,
					tilesLinks: tilesLinks.map(t => mapTilesFilesDtoToTilesFiles(t)),
					bbox,
        });
      })
    );
  }

  changeDataSourceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(grassParamsActions.changeDateOfPhotoOrPhotoTypeSuccess),
      withLatestFrom(
        this.store.select(grassParamsSelectors.selectHexesLayersConfig),
        this.store.select(grassParamsSelectors.selectSelectedParams),
        this.store.select(grassParamsSelectors.selectMainSelectedParam),
        this.store.select(grassParamsSelectors.selectHexGridLayersFilters),
        this.store.select(grassParamsSelectors.selectHexGridDataMode),
        this.store.select(grassParamsSelectors.selectCurrentGrassParam),
				this.store.select(grassParamsSelectors.selectParameterNorms),
				this.store.select(selectComposition),
				this.store.select(selectPresentationMode)
      ),
      tap(([{ mapId, fileLinks, gps, photoType, bbox, tilesLinks }, currentConfig, selectedParams, mainSelectedParam, hexGridLayerFilters, dataMode, currentGrassParam, parametersNorms, composition, presentationMode]) => {
				const mapConfig = this.mapsService.getMapById(mapId);

        if (mapConfig === undefined) {
          return;
        }

				const map = mapConfig.map

				const center: LngLat = map.getCenter();

				if (center.lat === 0 && center.lng === 0) {
					map.setCenter(MapboxUtils.getCoordinates(gps));
				}

				tilesLinks.forEach(link => {
					const config =  tilesLayersConfig.find(c => c.type == link.kind)
					if(!config){
						return;
					}

					this.mapsService.setBackgroundStyle(map,bbox,link.url, config);
				})



        fileLinks.forEach(link => {
          const config = getAvailableHexesLayersConfigs().find(c => c.type === link.kind && c.zoom === link.hexZoom);

					if(!config) {
						return;
					}

					const isVisible = presentationMode === PresentationMode.Parameters && config.type === currentConfig.type && config.zoom === currentConfig.zoom;

          this.mapboxHexGridService.setHexesLayer(map, link.presignedUrl, config, isVisible, mapConfig.loaderMarker!, composition);
        })

        switch (dataMode) {
          case HexGridDataMode.Deviations:
            parametersFillLayers.forEach(layer => this.mapboxHexGridService.paintHexesLayerMultiParams(selectedParams.map(x => x.name), layer, map, parametersNorms))
            break;

          case HexGridDataMode.Diseases:
            diseasesFillLayers.forEach(layer => this.mapboxHexGridService.paintDiseasesLayer(map, layer))
            break;

          case HexGridDataMode.Vegetation:
            vegetationFillLayers.forEach(layer => this.mapboxHexGridService.paintHexesLayerSingleParam(mainSelectedParam, layer, map, currentGrassParam!, parametersNorms))
            break;

          default:
            parametersFillLayers.forEach(layer => this.mapboxHexGridService.paintHexesLayerSingleParam(mainSelectedParam, layer, map, currentGrassParam!, parametersNorms));
        }

        getAvailableHexesLayersConfigs().forEach(config => MapboxFilterUtils.filterHexGridLayers(map, config, hexGridLayerFilters));

        this.mapsService.handleZoomGranulations(photoType);
        this.mapsService.bindParameterPopupEventsAllMaps(selectedParams);
      })
    ), { dispatch: false })

  changeZoom$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.changeZoom),
    withLatestFrom(
      this.store.select(grassParamsSelectors.selectHexesLayersConfig),
      this.store.select(selectPresentationMode)
    ),
    tap(([_, config, presentationMode]) => {
      if (presentationMode === PresentationMode.Parameters) {
        this.layersDisplayService.switchHexesSourcesVisibility(config);
      }
    })
  ), { dispatch: false })

  toggleDisease$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.toggleDisease),
    withLatestFrom(
      this.store.select(grassParamsSelectors.selectHexGridLayersFilters),
      this.store.select(grassParamsSelectors.selectHexesLayersConfig)
    ),
    tap(([_, filters, layersConfig]) => {
      this.mapsService.maps.forEach(({ map }) => {
        MapboxFilterUtils.filterHexGridLayers(map, layersConfig, filters);
        diseasesFillLayers.forEach(layer => this.mapboxHexGridService.paintDiseasesLayer(map, layer));
      });

      this.layersDisplayService.switchHexesSourcesVisibility(layersConfig);
    })
  ), { dispatch: false });

  switchToDeviationsMode$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.switchToDeviationsMode),
    withLatestFrom(
			this.store.select(grassParamsSelectors.selectHexesLayersConfig),
			this.store.select(grassParamsSelectors.selectParameterNorms)
		),
    tap(([{ paramNames }, layersConfig, parametersNorms]) => this.mapsService.maps.forEach(({ map }) => {
      this.mapboxHexGridService.paintHexesLayerMultiParams(paramNames, layersConfig.fillLayer, map, parametersNorms);
      this.layersDisplayService.switchHexesSourcesVisibility(layersConfig);
    }))
  ), { dispatch: false });

  switchHexGridMode$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.switchHexGridMode),
    withLatestFrom(this.store.select(selectHexesLayersConfig)),
    tap(([_, layersConfig]) => {
      this.layersDisplayService.switchHexesSourcesVisibility(layersConfig);
    })
  ), { dispatch: false });

  filterHexGridLayers$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.filterBySectorIds, grassParamsActions.filterByTypes, grassParamsActions.filterByMinMax, grassParamsActions.toggleSelectedParam, grassParamsActions.selectMainParam, grassParamsActions.filterByHexIds, grassParamsActions.clearHexIdsFilter),
    withLatestFrom(this.store.select(grassParamsSelectors.selectHexGridLayersFilters)),
    tap(([_, filters]) => {
      this.mapsService.maps.forEach(({ map }) => {
				getAvailableHexesLayersConfigs().forEach(config => MapboxFilterUtils.filterHexGridLayers(map, config, filters));
      });
    })
  ), { dispatch: false });

	filterByHexIds$ = createEffect(() =>
		this.actions$.pipe(
			ofType(grassParamsActions.filterByHexIds),
			withLatestFrom(
				this.store.select(grassParamsSelectors.selectSelectedParams),
				this.store.select(grassParamsSelectors.selectZoomValue)
			),
			map(([{selectedParameter, zoom}, params, currentZoom]) => {
				if (zoom === environment.hexZoomHigh && currentZoom === environment.hexZoomLow) {
					this.messageService.displayInfoMessage('', 'Current map zoom is different than in saved snapshot. Please zoom in to see the results.');
				}

				if (zoom === environment.hexZoomLow && currentZoom === environment.hexZoomHigh) {
					this.messageService.displayInfoMessage('', 'Current map zoom is different than in saved snapshot. Please zoom out to see the results.');
				}

				if (params.find(p => p.name === selectedParameter)?.isSelected) {
					return grassParamsActions.selectMainParam({selectedParam: selectedParameter});
				} else {
					return grassParamsActions.toggleSelectedParam({selectedParam: selectedParameter});
				}
			})
		))

	fitBounds$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.filterBySectorIds),
		withLatestFrom(this.store.select(grassParamsSelectors.selectAreasEnds)),
		tap(([{sectorIds}, areasEnds]) => {
			const selectedAreaEnds = areasEnds.filter(area => sectorIds.includes(area.areaId));
			const minX = Math.min(...selectedAreaEnds.map(area => area.minX));
			const minY = Math.min(...selectedAreaEnds.map(area => area.minY));
			const maxX = Math.max(...selectedAreaEnds.map(area => area.maxX));
			const maxY = Math.max(...selectedAreaEnds.map(area => area.maxY));

			const bounds: LngLatBoundsLike = [
				[minX, minY], // southwestern corner of the bounds
				[maxX, maxY] // northeastern corner of the bounds
			];
			const options: FitBoundsOptions = { padding: { top: 0, bottom: 0, left: 290, right: 290 } };
			this.mapsService.maps.forEach(({ map }) => {
				map.fitBounds(bounds, options);
			});
		})
	), {dispatch: false})

  toggleSelectedParam$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.toggleSelectedParam),
    withLatestFrom(
      this.store.select(grassParamsSelectors.selectSelectedParams),
      this.store.select(grassParamsSelectors.selectHexGridDataMode),
      this.store.select(grassParamsSelectors.selectMainSelectedParam),
      this.store.select(grassParamsSelectors.selectHexesLayersConfig),
      this.store.select(grassParamsSelectors.selectCurrentGrassParam),
			this.store.select(grassParamsSelectors.selectParameterNorms)
    ),
    tap(([{ selectedParam }, selectedParams, hexGridMode, mainSelectedParam, layersConfig, currentGrassParam, parametersNorms]) => {
      if (!selectedParams.some(x => x.name === selectedParam) && selectedParams.length === config.selectedParametersLimit) {
        this.messageService.displayErrorMessage(`Please select maximum ${config.selectedParametersLimit} parameters`, '');
        return;
      }

      this.mapsService.maps.forEach(({ map }) => {
        if (hexGridMode === HexGridDataMode.Deviations && selectedParams.length > 0) {
          parametersFillLayers.forEach(paramLayer => this.mapboxHexGridService.paintHexesLayerMultiParams(selectedParams.map(({ name }) => name), paramLayer, map, parametersNorms));
        } else if (hexGridMode === HexGridDataMode.Parameters) {
          parametersFillLayers.forEach(paramLayer => this.mapboxHexGridService.paintHexesLayerSingleParam(mainSelectedParam, paramLayer, map, currentGrassParam!, parametersNorms));
        }
        else if (hexGridMode === HexGridDataMode.Vegetation) {
          vegetationFillLayers.forEach(paramLayer => this.mapboxHexGridService.paintHexesLayerSingleParam(mainSelectedParam, paramLayer, map, currentGrassParam!, parametersNorms));
        }
      });

      this.mapsService.bindParameterPopupEventsAllMaps(selectedParams);
      this.layersDisplayService.switchHexesSourcesVisibility(layersConfig);
    })
  ), { dispatch: false });

  changeMainParam$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.selectMainParam),
    withLatestFrom(
      this.store.select(grassParamsSelectors.selectHexesLayersConfig),
      this.store.select(grassParamsSelectors.selectHexGridDataMode),
      this.store.select(grassParamsSelectors.selectCurrentGrassParam),
			this.store.select(grassParamsSelectors.selectParameterNorms)
		),
    tap(([{ selectedParam }, layersConfig, hexGridMode, currentGrassParam, parameterNorms]) => {
      this.mapsService.maps.forEach(({ map }) => {
        if (hexGridMode === HexGridDataMode.Parameters) {
          parametersFillLayers.forEach(paramLayer => this.mapboxHexGridService.paintHexesLayerSingleParam(selectedParam, paramLayer, map,
            currentGrassParam!, parameterNorms));
        }
        else if (hexGridMode === HexGridDataMode.Vegetation) {
          vegetationFillLayers.forEach(paramLayer => this.mapboxHexGridService.paintHexesLayerSingleParam(selectedParam, paramLayer, map,
            currentGrassParam!, parameterNorms));
        }
      });
      this.layersDisplayService.switchHexesSourcesVisibility(layersConfig);
    })
  ), { dispatch: false });

	selectHexOnMap$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.selectHexOnMap),
		map(({hexId}) => this.mapsService.maps.map(({mapId}) => grassParamsActions.getTrendsForHex({hexId, mapId}))),
		mergeMap(actions => actions)
	))

  getTrendByHexId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(grassParamsActions.getTrendsForHex),
      groupBy(action => action.mapId),
      mergeMap(group$ =>
        group$.pipe(
          withLatestFrom(
            this.store.select(grassParamsSelectors.selectZoomValue),
            this.store.select(grassParamsSelectors.selectTrends),
          ),
          switchMap(([{ hexId, mapId }, zoomValue, trends]) =>
            this.store.select(grassParamsSelectors.selectProcessingFileVersionId(mapId)).pipe(
              exhaustMap((processingFileVersionId) => {
                const trendCached = trends.find(x => x.fileVersionId === processingFileVersionId && x.hexId === hexId && x.zoomValue === zoomValue);

                if (trendCached || processingFileVersionId === undefined) {
                  return of(grassParamsActions.getTrendsForHexSuccess({ hexTrends: undefined, mapId }));
                }

                return this.trendsApiService.getTrendsForHex(processingFileVersionId, hexId, zoomValue).pipe(
                  map((response: parameterTrendResponse) => {
                    if (!response.success) {
                      this.httpErrorService.handleErrorOkResponse(response);
                      return grassParamsActions.handleErrorResponse({ errorMessage: response.error });
                    }

                    const hexTrends: hexTrends = {
                      ...response,
                      fileVersionId: processingFileVersionId,
                      hexId,
                      zoomValue
                    };

                    return grassParamsActions.getTrendsForHexSuccess({ hexTrends, mapId });
                  }),
                  catchError((errorResponse: HttpErrorResponse) => {
                    this.httpErrorService.handleError(errorResponse);
                    return of(grassParamsActions.handleErrorResponse({ errorMessage: errorResponse.error }));
                  })
                );
              })
            ))
        )
      ),
    )
  );

	getParametersNorms$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.getParametersNorms),
		exhaustMap(({projectId}) =>
			this.projectGrassParamsNormsApiService.getLatestNorms(projectId).pipe(
				map((response: getProjectGrassParamsLatestNormsResponse) => {
					if (!response.success) {
						this.httpErrorService.handleErrorOkResponse(response);
						return grassParamsActions.handleErrorResponse({ errorMessage: response.error });
					}

					const projectGrassParamsNorms: projectGrassParamsNorms = {
						mineralIngredientsNorms: response.norms,
						vegetationIndicatorNorms: vegetationIndicatorNorms,
						visualAssessmentNorms: visualAssessmentsNorms
					}

					return grassParamsActions.getParametersNormsSuccess({projectGrassParamsNorms});
				}),
				catchError((errorResponse: HttpErrorResponse) => {
					this.httpErrorService.handleError(errorResponse);
					return of(grassParamsActions.handleErrorResponse({ errorMessage: errorResponse.error }));
				})
			)
		)
	));

	getDefaultAndLatestParameterNorms$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.getDefaultAndLatestParametersNorms),
		exhaustMap(({projectId}) =>
			this.projectGrassParamsNormsApiService.getDefaultAndLatestNorms(projectId).pipe(
				map((response: getDefaultAndLatestMineralIngredientNormsResponse) => {
					if (!response.success) {
						this.httpErrorService.handleErrorOkResponse(response);
						return grassParamsActions.handleErrorResponse({ errorMessage: response.error });
					}

					return grassParamsActions.getDefaultAndLatestParametersNormsSuccess({items: response.items});
				}),
				catchError((errorResponse: HttpErrorResponse) => {
					this.httpErrorService.handleError(errorResponse);
					return of(grassParamsActions.handleErrorResponse({ errorMessage: errorResponse.error }));
				})
			)
		)
	));

	editParametersNorms$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.editParameterNorms),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{command}, projectId]) =>
			this.projectGrassParamsNormsApiService.editNorm(projectId!, command).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.httpErrorService.handleErrorOkResponse(response);
						return grassParamsActions.handleErrorResponse({errorMessage: response.error});
					}

					this.messageService.displaySuccessMessage("Success", "Parameters norms was updated successfully");
					return grassParamsActions.getParametersNorms({projectId: projectId!})
				}),
				catchError((errorResponse: HttpErrorResponse) => {
					this.httpErrorService.handleError(errorResponse);
					return of(grassParamsActions.handleErrorResponse({errorMessage: errorResponse.error}));
				})
			))
	))

	getWeatherAroundDate$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.getWeatherAroundDate),
		withLatestFrom(
			this.store.select(selectedProjectIdSelector),
			this.store.select(selectMostRecentDateOfPhoto)
		),
		exhaustMap(([{date, mapId}, projectId, mostRecentDateOfPhoto]) => this.weatherApiService.getWeatherAroundDate(projectId!, date || mostRecentDateOfPhoto)
			.pipe(
				map((response: getWeatherForTaskResponse) => {
					if (!response.success) {
						return commonActions.handleError({ errorMessage: response.error });
					}

					return grassParamsActions.getWeatherAroundDateSuccess({mapId, measurements: response.measurements.map((m) => ({...m, measurementDateTime: new Date(m.measurementDateTime)}))});
				}),
				catchError(error => of(commonActions.handleError(error)))
			)
		)
	));

	getObservedHexesSnapshots$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.getObservedHexesSnapshots),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		mergeMap(([_, projectId]) => this.observedHexesSnapshotsApiService.getObservedHexesSnapshots(projectId!).pipe(
			map((response: getObservedHexesSnapshotsResponse) => {
				if (!response.success) {
					return commonActions.handleError({errorMessage: response.error});
				}

				return grassParamsActions.getObservedHexesSnapshotsSuccess({observedHexesSnapshots: response.items});
			}),
			catchError(error => of(commonActions.handleError(error)))
		))
	));

	saveObservedHexesSnapshot$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.saveObservedHexesSnapshot),
		withLatestFrom(
			this.store.select(selectedProjectIdSelector),
			this.store.select(selectHexesLayersConfig),
			this.store.select(selectMainSelectedParam)
		),
		mergeMap(([{title}, projectId, hexesLayerConfig, selectedParam]) => {
			const mapObj = this.mapsService.getMapById('map-0')?.map;

			if (!mapObj) {
				return of(commonActions.handleError({errorMessage: 'Map object is not available'}));
			}

			const hexIds: number[] = this.observedHexesService.getFilteredHexesIds(mapObj, hexesLayerConfig.fillLayer);

			return this.observedHexesSnapshotsApiService.saveObservedHexesSnapshot(projectId!, title, hexesLayerConfig.zoom, hexIds, selectedParam).pipe(
				map((response: saveObservedHexesSnapshotResponse) => {
					if (!response.success) {
						return commonActions.handleError({errorMessage: response.error});
					}

					const snapshot: observedHexesSnapshot = {title, id: response.snapshotId, zoom: hexesLayerConfig.zoom, hexIds, selectedParameter: selectedParam};
					return grassParamsActions.saveObservedHexesSnapshotSuccess({snapshot});
				}),
				catchError(error => of(commonActions.handleError(error)))
			);
		})
	));

	removeObservedHexesSnapshot$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.removeObservedHexesSnapshot),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{snapshotId}, projectId]) =>
			this.observedHexesSnapshotsApiService.removeObservedHexesSnapshot(projectId!, snapshotId).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						return [commonActions.handleError({errorMessage: response.error})];
					}

					this.messageService.displaySuccessMessage('', this.translateService.instant('GRASS_PARAMETERS.OBSERVED_HEXES.MESSAGES.REMOVE_SUCCESSFUL'));

					return [
						grassParamsActions.removeObservedHexesSnapshotSuccess({snapshotId}),
						grassParamsActions.clearHexIdsFilter()
					];
				}),
				catchError(error => of([commonActions.handleError(error)]))
			)
		),
		mergeMap(actions => actions)
	));

	saveObservedHexesSnapshotSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(grassParamsActions.saveObservedHexesSnapshotSuccess),
		tap(() => this.messageService.displaySuccessMessage('', this.translateService.instant('GRASS_PARAMETERS.OBSERVED_HEXES.MESSAGES.SAVE_SUCCESSFUL')))
	), {dispatch: false})

  showLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.getDatesOfPhotos, grassParamsActions.changeDateOfPhoto, grassParamsActions.changePhotoType, grassParamsActions.getParametersNorms, grassParamsActions.editParameterNorms, grassParamsActions.saveObservedHexesSnapshot, grassParamsActions.removeObservedHexesSnapshot),
    tap(() => this.loadPanelService.showLoadPanel())
  ), { dispatch: false });

  hideLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.getDatesOfPhotosSuccess, grassParamsActions.handleErrorResponse, grassParamsActions.changeDateOfPhotoOrPhotoTypeSuccess, grassParamsActions.getParametersNormsSuccess, grassParamsActions.saveObservedHexesSnapshotSuccess, grassParamsActions.removeObservedHexesSnapshotSuccess),
    withLatestFrom(this.store.select(grassParamsSelectors.selectIsLoading)),
    tap(([_, isLoading]) => {
      if (!isLoading) this.loadPanelService.hideLoadPanel();
    })
  ), { dispatch: false });

  clearParameters$ = createEffect(() => this.actions$.pipe(
    ofType(grassParamsActions.clearParameters),
    withLatestFrom(this.store.select(grassParamsSelectors.selectHexesLayersConfig)),
    tap(() => {
      this.mapsService.maps.forEach(({ map }) => {
        parametersFillLayers.forEach(layer => this.mapboxHexGridService.removeHexesParamsPaint(layer, map));
        vegetationFillLayers.forEach(layer => this.mapboxHexGridService.removeHexesParamsPaint(layer, map));
      });
    })
  ), { dispatch: false });
}
