export enum VerificationStatus {
	Uploaded = 'Uploaded',
  Processing = 'Processing',
  Success = 'Success',
  Error = 'Error',
  NoHexGrid = 'NoHexGrid'
}

export enum UploadFileStatus {
  Archive = 'Archive',
  Actual = 'Actual'
}

export const getVerificationSeverity = (verificationStatus: VerificationStatus) => {
  switch(verificationStatus) {
    case VerificationStatus.Processing:
      return 'info';
    case VerificationStatus.Error:
      return 'danger';
    default:
      return 'success';
  }
}

export const getUploadFileStatusSeverity = (uploadFileStatus: UploadFileStatus) => {
  switch(uploadFileStatus) {
    case UploadFileStatus.Actual:
      return 'success';
    default:
      return 'warning';
  }
}
