import { LazyLoadEvent } from "primeng/api";
import {addGolfClubMemberCommand, golfClubMember, golfClubMembersGridParameters, updateGolfClubMemberCommand} from "../api/golf-club-members.api.service";
import {createActionGroup, createFeature, createReducer, emptyProps, on, props} from "@ngrx/store";

export interface golfClubMemberState {
	filter: golfClubMembersGridParameters,
	golfClubMembers: golfClubMember[],
	totalRecords: number,
	currentGolfClubMember: golfClubMember,
	isLoading: boolean,
	currentGolfClubMemberAssignedFromOtherProject: boolean,
	golfClubMembersFromOtherProjects: golfClubMember[]
}

const initialState: golfClubMemberState = {
	filter: { take: 10, skip: 0 } as golfClubMembersGridParameters,
	golfClubMembers: [],
	totalRecords: 0,
	//@ts-ignore
	currentGolfClubMember: {},
	isLoading: false,
	currentGolfClubMemberAssignedFromOtherProject: false,
	golfClubMembersFromOtherProjects: []
}

export const golfClubMemberActions = createActionGroup({
	source: 'GolfClubMember',
	events: {
		'Get Golf Club Members': props<{filter: golfClubMembersGridParameters}>(),
		'Get Golf Club Members Success': props<{golfClubMembers: golfClubMember[], totalRecords: number}>(),
		'Filter Golf Club Members by Lazy load': props<{ lazyLoadEvent: LazyLoadEvent }>(),
		'Filter Golf Club Members by Date': props<{dateRange: Date[] | null}>(),
		'Add Golf Club Member': props<{projectId: number, command: addGolfClubMemberCommand}>(),
		'Add Golf Club Member Success': emptyProps(),
		'Assign Member To Project': props<{projectId: number, memberId: number}>(),
		'Assign Member To Project Success': emptyProps(),
		'Start Adding Golf Club Member Manually': emptyProps(),
		'Start Adding Golf Club Member From Other Project': emptyProps(),
		'Get Members From Other Projects': emptyProps(),
		'Get Members From Other Projects Success': props<{golfClubMembers: golfClubMember[]}>(),
		'Unassign Member From Project': props<{projectId: number, memberId: number}>(),
		'Unassign Member From Project Success': props<{memberId: number}>(),
		'Get Member By Id': props<{memberId: number}>(),
		'Get Member By Id Success': props<{member: golfClubMember}>(),
		'Update Golf Club Member': props<{memberId: number, command: updateGolfClubMemberCommand}>(),
		'Update Golf Club Member Success': emptyProps(),
		'Download Template': emptyProps(),
		'Download Template Success': props<{bytes: Blob}>(),
		'Upload Golf Club Members': props<{file: File}>(),
		'Handle Error': emptyProps()
	}
});

export const golfClubMemberFeature = createFeature({
	name: 'golfClubMember',
	reducer: createReducer(
		initialState,
		on(golfClubMemberActions.getGolfClubMembers, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.getGolfClubMembersSuccess, (state, {golfClubMembers, totalRecords}) => ({...state, golfClubMembers, totalRecords, isLoading: false})),
		on(golfClubMemberActions.filterGolfClubMembersByDate, (state, {dateRange}) => ({...state, filter: {...state.filter, dateRange: dateRange }})),
		on(golfClubMemberActions.filterGolfClubMembersByLazyLoad, (state, {lazyLoadEvent}) => {
			if (!lazyLoadEvent) {
				return state;
			}
	
			return {
				...state,
				filter: {
					...state.filter,
					take: lazyLoadEvent.rows!,
					skip: lazyLoadEvent.first!,
					sortField: lazyLoadEvent.sortField,
					sortExpression: lazyLoadEvent.sortOrder
				}
			}
		}),
		on(golfClubMemberActions.addGolfClubMember, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.addGolfClubMemberSuccess, (state) => ({...state, isLoading: false})),
		on(golfClubMemberActions.assignMemberToProject, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.assignMemberToProjectSuccess, (state) => ({...state, isLoading: false, currentGolfClubMemberAssignedFromOtherProject: false})),
		on(golfClubMemberActions.startAddingGolfClubMemberFromOtherProject, (state) => ({...state, currentGolfClubMemberAssignedFromOtherProject: true})),
		on(golfClubMemberActions.startAddingGolfClubMemberManually, (state) => ({...state, currentGolfClubMemberAssignedFromOtherProject: false})),
		on(golfClubMemberActions.getMembersFromOtherProjects, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.getMembersFromOtherProjectsSuccess, (state, {golfClubMembers}) => ({...state, golfClubMembersFromOtherProjects: golfClubMembers, isLoading: false})),
		on(golfClubMemberActions.unassignMemberFromProject, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.unassignMemberFromProjectSuccess, (state, {memberId}) => ({...state, isLoading: false, golfClubMembers: state.golfClubMembers.filter(p => p.id !== memberId)})),
		on(golfClubMemberActions.downloadTemplate, (state) => ({...state, isLoading: true })),
		on(golfClubMemberActions.downloadTemplateSuccess, (state, {bytes}) => ({...state, isLoading: false })),
		on(golfClubMemberActions.getMemberById, (state) => ({...state, isLoading: true})),
		on(golfClubMemberActions.getMemberByIdSuccess, (state, {member}) => ({...state, currentGolfClubMember: member, isLoading: false}))
	)
});

export const {selectFilter, selectGolfClubMembers, selectTotalRecords, selectIsLoading, selectCurrentGolfClubMemberAssignedFromOtherProject, selectGolfClubMembersFromOtherProjects, selectCurrentGolfClubMember} = golfClubMemberFeature;
