import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {PrimeIcons} from "primeng/api";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-parameter-trend-section',
    template: `
        <p>{{trendValue}}</p>
        <p class="trend-slope" [ngClass]="getPercentageChangeClass(trendDownNorm, trendUpNorm, trendValue)">
            {{getPercentageChangeValue(trendPercentageChange)}}
            <span style="font-size: 0.5rem;" [class]="getPercentageChangeIcon(trendPercentageChange)"></span>
        </p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        NgClass
    ],
    standalone: true
})
export class ParameterTrendSectionComponent {
  @Input() trendValue!: number;
  @Input() trendDownNorm!: number;
  @Input() trendUpNorm!: number;
  @Input() trendPercentageChange!: number;

  getPercentageChangeValue = (percentage: number): string => {

    if (percentage === 0 || !percentage) { return '0'; }

    const slopePercentage = `${(percentage).toFixed(1)}%`;
    return percentage > 0 ? `+${slopePercentage}` : slopePercentage;
  }

  getPercentageChangeIcon = (trendSlope: number): string => {
    if (trendSlope === 0 || !trendSlope) { return PrimeIcons.ANGLE_DOUBLE_RIGHT; }

    return trendSlope > 0 ? PrimeIcons.ANGLE_DOUBLE_UP : PrimeIcons.ANGLE_DOUBLE_DOWN;
  }

  getPercentageChangeClass = (paramNormMin: number, paramNormMax: number, paramValue: number): string => (paramValue < paramNormMin || paramValue > paramNormMax) ? 'trend-out-of-norm' : 'trend-in-norm'
}
