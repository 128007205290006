import {laboratoryExaminationListVm, mineralIngredientUnit} from "./types";
import {mineralIngredientNorm} from "../../../services/api/project-grass-params-norms-api.service";

export const laboratoryExaminationPopupParameterListInitialValue: laboratoryExaminationListVm[] = [
  // { name: 'dryBiomass', displayName: 'Dry biomass', unit: 'g/100g d.m.' },
  { name: 'nitrogen', displayName: 'Nitrogen (N)', unit: "%" },
  { name: 'potassium', displayName: 'Potassium (K)', unit: '%' },
  { name: 'sodium', displayName: 'Sodium (Na)', unit: 'ppm' },
  { name: 'calcium', displayName: 'Calcium (Ca)', unit: '%' },
  { name: 'magnesium', displayName: 'Magnesium (Mg)', unit: '%' },
  { name: 'manganese', displayName: 'Manganese (Mn)', unit: "ppm" },
//  { name: 'iron', displayName: 'Iron (Fe)', unit: 'mg/kg d.m.' },
  { name: 'zinc', displayName: 'Zinc (Zn)', unit: 'ppm' },
  { name: 'copper', displayName: 'Copper (Cu)', unit: 'ppm' },
  { name: 'phosphorus', displayName: 'Phosphorus (P)', unit: '%' }
];

export const availableUnits: mineralIngredientUnit[] = ["ppm", "%"];

export const convertAverage = (currentValue: number | undefined, unit: mineralIngredientUnit): number | undefined => {
	if (!currentValue) {
		return undefined;
	}

	if (unit === "%") {
		return currentValue / 10000;
	}

	return currentValue * 10000;
}

// When value is displayed to user it has to be converted to whatever unit they choose
export const calculateAverageInChosenUnit = (currentValue: number | undefined, unit: mineralIngredientUnit) => {
	if (!currentValue || unit === "%") {
		return currentValue;
	}

	return currentValue * 10000;
}

// Value is stored in standard unit (g/kg d.m.) so data in different unit has to be converted to it
export const calculateAverageInStandardUnit = (currentValue: number | undefined, unit: mineralIngredientUnit) => {
	if (!currentValue || unit === "%") {
		return currentValue;
	}

	return currentValue / 10000;
}

export const calculateDeviation = (paramName: string, paramValueInStandardUnit: number, mineralIngredientsNorms: mineralIngredientNorm[]): number => {
	const norm = mineralIngredientsNorms.find(norm => norm.paramName === paramName)!;

	let paramValue = paramValueInStandardUnit;

	if (norm.unit !== "%") {
		paramValue = convertAverage(paramValueInStandardUnit, norm.unit!)!;
	}

	const {normDownBoundary, normUpBoundary} = norm;

	if (normDownBoundary <= paramValue && paramValue <= normUpBoundary) {
		return 0;
	}

	if (normDownBoundary > paramValue) {
		return -Math.abs((normDownBoundary - paramValue) / normDownBoundary);
	}

	return Math.abs((paramValue - normUpBoundary) / normUpBoundary);
}
