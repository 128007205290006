import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {apiResponse} from "../model/api-responses";
import {AgroMessageService} from "./agro-message.service";

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(private messageService: AgroMessageService) {}

  handleError = (response: HttpErrorResponse) : void => {
    const errorMessage = this.getErrorMessage(response);
    this.messageService.displayErrorMessage('Error', errorMessage);
  }

  getErrorMessage = (response: HttpErrorResponse): string => {
    return response.status === 0 ? "Something went wrong" : response.error || response.message;
  }

  // Temporary solution
  // Will be removed after backend refactoring
  handleErrorOkResponse = (response: apiResponse) => {
    if (response.failures && response.failures.length > 0) {
      this.messageService.displayErrorMessages(response.failures.map(x => x.errorMessage));
    } else {
      this.messageService.displayErrorMessage('Error', response.error ?? 'Something went wrong');
    }
  }
}
