import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../types/app-state";
import * as routingSelectors from "../../../routing-store/selectors";
import * as routingActions from "../../../routing-store/actions";
import {Observable} from "rxjs";

@Component({
  selector: 'app-nav-logo-box',
  template:
  `
    <div class="app-nav-logo" [ngClass]="(sidebarExpanded$ | async)! ? 'expanded' : ''">
      <div [routerLink]="['project']" class="image-container">
        <img *ngIf="(sidebarExpanded$ | async)!" ngSrc="./assets/images/skim_logo.svg" alt="Logo" height="42" width="55">
        <img *ngIf="!(sidebarExpanded$ | async)!" ngSrc="./assets/images/skim_logo_sygnet.svg" alt="Logo" height="42" width="55">
      </div>
      <button class="hamburger hamburger--arrow" type="button" (click)="toggleSidebar()">
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavLogoBoxComponent  {
  private store = inject(Store<AppState>);
  readonly sidebarExpanded$: Observable<boolean> = this.store.select(routingSelectors.sidebarExpandedSelector);

	toggleSidebar = () => this.store.dispatch(routingActions.toggleSidebar());
}
