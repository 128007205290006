import {HexesDataSourceType, TilesDataSourceType} from "./mapbox/types";
import {ex} from "@fullcalendar/core/internal-common";
import {TilesFiles} from "./mapbox/tiles-layers.config";

export interface photoInfo {
  areaId: number;
  type: string;
  name: string;
  areaImperial: number;
  areaMetric: number;
	healthyAreaMetric: number;
  stressedAreaMetric: number;
	criticalAreaMetric: number;
  zoom: number;
  photoStats: photoStats[]
}

export interface photoStats {
  hasSod: boolean;
  hasGeneral: boolean;
  hasColor: boolean;
  hasSlender: boolean;
  hasDryBiomass: boolean;
  hasCopper: boolean;
  hasFall: boolean;
  hasHumid: boolean;
  hasSodium: boolean;
  hasPotassium: boolean;
  hasZinc: boolean;
  hasCalcium: boolean;
  hasNormalizedDifferenceVegetationIndex: boolean;
  hasFreshBiomass: boolean;
  HasMagnesium: boolean;
  hasNitrogen: boolean;
  hasIron: boolean;
  hasPhosphorus: boolean;
  hasManganese: boolean;
  hasLeafAreaIndexBezp: boolean;
  hasLeafAreaIndexMean: boolean;
}

export interface geoJsonFileSourceLink {
  kind: HexesDataSourceType,
  hexZoom: number,
  presignedUrl: string
}

export interface areaEnds {
  areaId: number;
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface photoInfoArea {
	total: number,
	healthy: number,
	critical: number,
  stressed: number,
}

export interface tilesFilesDto {
	kind: TilesDataSourceTypeDto;
	url: string;
}

export enum TilesDataSourceTypeDto {
	HighResolutionRgb = 0,
}

export const mapTilesFilesDtoToTilesFiles = (model: tilesFilesDto): TilesFiles => ({
	kind: mapTilesDataKindFromDtoToModel(model.kind),
	url: model.url,
});

export const mapTilesDataKindFromDtoToModel = (kind: TilesDataSourceTypeDto): TilesDataSourceType=> {
	switch (kind) {
		case TilesDataSourceTypeDto.HighResolutionRgb:
			return TilesDataSourceType.HighResolutionRgb;
	}

}
