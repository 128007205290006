import {environment} from "../../../../environments/environment";
import {
	deviationsFilteredPropNames,
	paramsFilteredPropNames,
	vegetationFilteredPropNames
} from "../../store/grass-params/constants";
import {GrassParametersDataSource, GrassParametersLayer, HexesDataSourceType} from "./types";

export interface hexesLayerConfig {
	type: HexesDataSourceType;
	dataSourceName: GrassParametersDataSource;
	fillLayer: GrassParametersLayer;
	bordersLayer: GrassParametersLayer;
	zoom: number;
	filteredProps: string[];
}

export const hexesLayersConfigs: hexesLayerConfig[] = [
	{
		type: HexesDataSourceType.Parameters,
		zoom: environment.hexZoomLow,
		dataSourceName: GrassParametersDataSource.ParametersLowZoom,
		fillLayer: GrassParametersLayer.HexGridParametersFillLayerLowZoom,
		bordersLayer: GrassParametersLayer.HexGridParametersBordersLowZoom,
		filteredProps: paramsFilteredPropNames
	},
	{
		type: HexesDataSourceType.Parameters,
		zoom: environment.hexZoomHigh,
		dataSourceName: GrassParametersDataSource.ParametersHighZoom,
		fillLayer: GrassParametersLayer.HexGridParametersFillLayerHighZoom,
		bordersLayer: GrassParametersLayer.HexGridParametersBordersHighZoom,
		filteredProps: paramsFilteredPropNames
	},
	// {
	// 	type: HexesDataSourceType.Diseases,
	// 	zoom: environment.hexZoomLow,
	// 	dataSourceName: GrassParametersDataSource.DiseasesLowZoom,
	// 	fillLayer: GrassParametersLayer.HexGridDiseasesFillLayerLowZoom,
	// 	bordersLayer: GrassParametersLayer.HexGridDiseasesBordersLowZoom,
	// 	filteredProps: diseaseFilteredPropNames
	// },
	// {
	// 	type: HexesDataSourceType.Diseases,
	// 	zoom: environment.hexZoomHigh,
	// 	dataSourceName: GrassParametersDataSource.DiseasesHighZoom,
	// 	fillLayer: GrassParametersLayer.HexGridDiseasesFillLayerHighZoom,
	// 	bordersLayer: GrassParametersLayer.HexGridDiseasesBordersHighZoom,
	// 	filteredProps: diseaseFilteredPropNames
	// },
	{
		type: HexesDataSourceType.Vegetation,
		zoom: environment.hexZoomLow,
		dataSourceName: GrassParametersDataSource.VegetationLowZoom,
		fillLayer: GrassParametersLayer.HexGridVegetationFillLayerLowZoom,
		bordersLayer: GrassParametersLayer.HexGridVegetationBordersLowZoom,
		filteredProps: vegetationFilteredPropNames
	},
	{
		type: HexesDataSourceType.Vegetation,
		zoom: environment.hexZoomHigh,
		dataSourceName: GrassParametersDataSource.VegetationHighZoom,
		fillLayer: GrassParametersLayer.HexGridVegetationFillLayerHighZoom,
		bordersLayer: GrassParametersLayer.HexGridVegetationBordersHighZoom,
		filteredProps: vegetationFilteredPropNames
	},
	{
		type: HexesDataSourceType.Deviations,
		zoom: environment.hexZoomLow,
		dataSourceName: GrassParametersDataSource.DeviationsLowZoom,
		fillLayer: GrassParametersLayer.HexGridDeviationsFillLayerLowZoom,
		bordersLayer: GrassParametersLayer.HexGridDeviationsBordersLowZoom,
		filteredProps: deviationsFilteredPropNames
	},
	{
		type: HexesDataSourceType.Deviations,
		zoom: environment.hexZoomHigh,
		dataSourceName: GrassParametersDataSource.DeviationsHighZoom,
		fillLayer: GrassParametersLayer.HexGridDeviationsFillLayerHighZoom,
		bordersLayer: GrassParametersLayer.HexGridDeviationsBordersHighZoom,
		filteredProps: deviationsFilteredPropNames
	}
];

export const getAvailableHexesLayersConfigs = () => {
	let result = hexesLayersConfigs;

	if (environment.disableDeviationsParameters) {
		result = result.filter(({type}) => type !== HexesDataSourceType.Deviations)
	}

	return result;
}
