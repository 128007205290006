import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import {catchError, Observable, switchMap, throwError} from 'rxjs';
import {AuthService} from "../services/auth.service";
import {LoginResponse, refreshTokenResponse} from "../../login/models/login-types";
import {AgroMessageService} from "../services/agro-message.service";
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private messageService: AgroMessageService) {}

  private readonly whitelisted_endpoints = [`${environment.userApiUrl}changePassword`];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.whitelisted_endpoints.some(whitelistedEndpoint => request.url === whitelistedEndpoint)) {
      return next.handle(request);
    }

    const token = this.authService.getIdToken();

    if (token && token !== '' && !request.url.match('^(https?:\\/\\/)?([a-zA-Z0-9-]+\\.s3[\\.-](?:[a-z0-9-]+\\.)?amazonaws\\.com)\\/.+')) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === HttpStatusCode.Unauthorized) {
            return this.handleUnauthorizedError(request, next);
          }
        }
        return throwError(() => new Error("Something went wrong"));
      })
    );
  }

  private handleUnauthorizedError = (request: HttpRequest<any>, next: HttpHandler) => {
    const refreshToken = this.authService.getRefreshToken()!;
    return this.authService.refreshToken(refreshToken).
      pipe(
        switchMap((response: refreshTokenResponse) => {
          this.authService.setIdToken(response.idToken);
          request = request.clone({
            setHeaders: {Authorization: `Bearer ${response.idToken}`}
          })
          return next.handle(request);
        }),
        catchError((error) => {
            this.messageService.displayWarningMessage("Token is expired, please login again", "");
            this.authService.logout();
            return throwError(error);
        })
      )
  }
}
