import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddProjectDto } from '../DTO/add-project-dto';
import { EditAreasDto } from '../DTO/edit-areas-dto';
import { EditProjectDto } from '../DTO/edit-project-dto';
import { ManageProjectDto } from '../DTO/manage-project-dto';
import {
  AddProjectInterface,
  CurrencyInterface,
  EditAreasInterface,
  EditProjectInterface,
  GetAreaInfoByGpsInterface,
  GrassSpeciesInterface,
  ProjectCategoryInterface,
  ProjectDetailsInterface,
  ProjectInterface
} from '../model/project.interface';
import { baseResponse } from "../../grass-parameters-presentation/DTO/base-response";
import { manageProjectAreaDto } from "../DTO/manage-project-area-dto";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjectSettingsComponent } from '../project-settings/project-settings.component';
import { Currency } from 'aws-sdk/clients/lightsail';

@Injectable()
export class ProjectService {
  private baseUrl = environment.projectApiUrl;
  private dialogService = inject(DialogService);
  private projectSettingsPopupRef: DynamicDialogRef | undefined;

  constructor(private http: HttpClient) { }

  getProjects = (organizationId?: number): Observable<ProjectInterface[]> => this.http.get<ProjectInterface[]>(this.baseUrl + 'projects/' + organizationId);
  getProjectCategories = (): Observable<ProjectCategoryInterface[]> => this.http.get<ProjectCategoryInterface[]>(this.baseUrl + 'projectCategories/');
  getGrassSpecies = (): Observable<GrassSpeciesInterface[]> =>
  this.http.get<{ items: GrassSpeciesInterface[] }>(this.baseUrl + 'dictionaries/grass-species').pipe(
    map(response => response.items)
  );
  getCurrencies = (): Observable<CurrencyInterface[]> =>
    this.http.get<{ currencies: CurrencyInterface[] }>(this.baseUrl + 'dictionaries/currencies').pipe(
      map(response => response.currencies)
  );
  addProject = (requestBody: AddProjectDto): Observable<AddProjectInterface> => this.http.post<AddProjectInterface>(this.baseUrl + 'project', requestBody);
  editAreas = (requestBody: EditAreasDto): Observable<EditAreasInterface> => this.http.post<EditAreasInterface>(this.baseUrl + 'editProjectAreas', requestBody);
  getSnapshotProjectAreasAndElementaryObjectsDetails = (projectId: number, snapshotId?: number): Observable<ManageProjectDto> => {
    const url = `${this.baseUrl}project/${projectId}/versions/${snapshotId}`;
    return this.http.get<ManageProjectDto>(url);
  }
  getActualProjectAreasAndElementaryObjectsDetails = (projectId: number): Observable<getProjectWithDetailsResponse> => {
    const url = `${this.baseUrl}project/${projectId}`;
    return this.http.get<getProjectWithDetailsResponse>(url);
  }
  editProject = (projectId: number, requestBody: EditProjectDto): Observable<EditProjectInterface> => {
    const url = `${this.baseUrl}projects/${projectId}`;
    return this.http.put<EditProjectInterface>(url, requestBody);
  }
  removeProject = (projectId: number): Observable<baseResponse> => this.http.delete<baseResponse>(`${this.baseUrl}projects/${projectId}`);

  getAreaInfoByGps = (projectId: number, lat: number, lng: number): Observable<GetAreaInfoByGpsInterface> => {
    const url = `${this.baseUrl}project/${projectId}/area-info-by-gps?lat=${lat}&lng=${lng}`;
    return this.http.get<GetAreaInfoByGpsInterface>(url);
  }

  showProjectSettingsPopup() {
    this.projectSettingsPopupRef = this.dialogService.open(ProjectSettingsComponent, {
      showHeader: true,
      modal: true,
      width: "900px",
      height: "480px",
      styleClass: "p-dialog-custom"
    });
    this.projectSettingsPopupRef.onClose.subscribe(() => {
      this.projectSettingsPopupRef = undefined;
    });
  }
  closeProjectSettingsPopup() {
    this.projectSettingsPopupRef?.close();
  }

  generateHexGrid = (projectId: number, uploadFileId: number): Observable<baseResponse> => {
    const url = `${this.baseUrl}project/${projectId}/hex?uploadFileId=${uploadFileId}`;
    return this.http.post<baseResponse>(url, {});
  }
}

export interface getProjectWithDetailsResponse extends baseResponse {
  project: ManageProjectDto;
}
