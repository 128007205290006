import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorService {
    public numberToRgb(rgb: number): string {
			return `rgb(${(rgb >> 16) & 0xff},${(rgb >> 8) & 0xff},${rgb & 0xff})`;
    }

    public rgbToNumber(rgb: string): number {
			const array: string[] = rgb.replace(/[^\d,]/g, '').split(',');
			const value = ((parseInt(array[0]) & 0xff) << 16) + ((parseInt(array[1]) & 0xff) << 8) + (parseInt(array[2]) & 0xff);
			return value;
    }
}
