import {Pipe, PipeTransform, inject} from "@angular/core";
import { IncidentState } from "../../incident/models/IncidentState";
import { TranslateService } from "@ngx-translate/core";
import {Observable, startWith, switchMap} from "rxjs";

@Pipe({
	name: 'TranslateIncidentStateAsyncPipe'
})
export class TranslateIncidentStateAsyncPipePipe implements PipeTransform {
	private translate = inject(TranslateService);

	transform(value: IncidentState): Observable<string> {
		return this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(`INCIDENT.STATUS.${value.toUpperCase()}`))
		)
	}
}
