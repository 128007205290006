import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { AppState } from 'src/app/types/app-state';
import * as dayjs from 'dayjs'
import { ConfirmationService } from 'primeng/api';
import * as objectSupport from 'dayjs/plugin/objectSupport';
import { ProjectInterface } from '../../model/project.interface';
import { OneAtlasOrderInterface, OneAtlasSearchInterface } from '../../one-atlas-store/types';
import * as projectSelectors from "../../project-store/selectors";
import * as oneAtlasSelectors from "../../one-atlas-store/selectors";
import * as oneAtlasActions from "../../one-atlas-store/actions";

@Component({
  selector: 'app-one-atlas-download',
  templateUrl: './one-atlas-download.component.html',
  styleUrls: ['./one-atlas-download.component.scss']
})
export class OneAtlasDownloadComponent implements OnInit, OnDestroy {
    project!: ProjectInterface;
    minDate: Date;
    maxDate: Date;
    disabledDates: Date[] = [];

    @Output() selected = new EventEmitter();

    downloadFileForm = this.formBuilder.group({
        date: [null, Validators.required]
      });

    private subscriptions: Subscription[] = [];

    constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private confirmationService: ConfirmationService) {
        dayjs.extend(objectSupport);
        const now: Date = new Date();
        this.minDate = new Date(now.getFullYear(), now.getMonth(), 1);
        this.maxDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.store.select(projectSelectors.selectedProjectSelector)
            .subscribe((selectedProject: ProjectInterface) => {
                this.project = selectedProject;
            }));

        this.subscriptions.push(this.store.select(oneAtlasSelectors.oneAtlasOrdersSelector)
            .subscribe((orders: OneAtlasOrderInterface[]) => {
                if (orders.length == 0) {
                    return;
                }

                const order: OneAtlasOrderInterface = orders[0];
                const date = dayjs(order.day);
                this.confirmationService.confirm({
                    header: 'Confirmation',
                    // icon: 'pi pi-question-circle',
                    message: `Images for the day ${date.format('YYYY-MM-DD')} were priced at ${order.priceAmount}${order.priceAmountUnit}. Further processing of the order requires acceptance of this price. Continue this purchase?`,
										acceptButtonStyleClass: 'p-button-success hide-icon',
										rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
                    accept: () => this.acceptOrderPrice(order.projectId, order.processId),
                    reject: () => this.rejectOrderPrice(order.projectId, order.processId),
                  });
            }));

        this.subscriptions.push(this.store.select(oneAtlasSelectors.oneAtlasSearchSelector)
            .subscribe((searchResult?: OneAtlasSearchInterface) => {
                if (searchResult == null) {
                    this.minDate = dayjs().startOf('month').toDate();
                    this.maxDate = dayjs().endOf('month').toDate();
                    this.disabledDates = this.calculateDisabledDays(this.minDate, this.maxDate, [], []);
                } else {
                    this.minDate = dayjs(searchResult.fromDate).toDate();
                    this.maxDate = dayjs(searchResult.toDate).add(1, 'day').toDate();
                    const images: Date[] = searchResult.images.map(c => c.date) || [];
                    const orders: Date[] = searchResult.orders.map(c => c.day) || [];
                    this.disabledDates = this.calculateDisabledDays(this.minDate, this.maxDate, images, orders);
                }
            })
        );

        const now = dayjs();
        const monthIndex = now.month();
        const monthNumber = monthIndex + 1;
        this.store.dispatch(oneAtlasActions.getOneAtlasPhotoInfos({ projectId: this.project.id!, searchRange: { year: now.year(), month: monthNumber } }));
        this.store.dispatch(oneAtlasActions.findOneAtlasCreatedOrders({ projectId: this.project.id! }));
    }

    acceptOrderPrice(projectId: number, processId: string) {
        this.store.dispatch(oneAtlasActions.acceptOneAtlasOrderPrice({processId, projectId}));
    }

    rejectOrderPrice(projectId: number, processId: string) {
        this.store.dispatch(oneAtlasActions.rejectOneAtlasOrderPrice({projectId, processId}));
    }

    onMonthChange($event: any): void {
        const monthNumber = $event.month;
        this.store.dispatch(oneAtlasActions.getOneAtlasPhotoInfos({ projectId: this.project.id!, searchRange: { year: $event.year, month: monthNumber } }));
    }

    onDateSelect($event: Date): void {
        this.selected.emit();
        const day = dayjs($event);
        this.confirmationService.confirm({
            header: 'Confirmation',
            // icon: 'pi pi-question-circle',
            message: `Do you want to create an order for satellite images available for the selected day '${day.format('YYYY-MM-DD')}'?`,
						acceptButtonStyleClass: 'p-button-success hide-icon',
						rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
            accept: () => this.prepareOrder($event),
            reject: () => {},
          });
    }

    private prepareOrder(day: Date) {
        this.store.dispatch(oneAtlasActions.prepareOneAtlasOrder({ projectId: this.project.id!, day: day }));
    }

    private calculateDisabledDays(from: Date, to: Date, availableImages: Date[], purchasedImages: Date[]): Date[] {
        const e = dayjs(to);
        const days: Date[] = [];
        for (let day = dayjs(from); day.isBefore(e); day = day.add(1, 'day')) {
            days.push(day.toDate());
        }

        const availableImagesDays: Date[] = availableImages.map(d => dayjs(d).startOf('day').toDate());
        const possibleToBuyImages: Date[] = availableImagesDays.filter(ai => !purchasedImages.some(pi => dayjs(ai).diff(dayjs(pi).startOf('day')) === 0));
        return days.filter(d => !possibleToBuyImages.some(ptb => dayjs(ptb).diff(dayjs(d).startOf('day'), 'day') === 0));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(x => x.unsubscribe());
    }
}
