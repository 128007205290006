import { Injectable } from '@angular/core';
import { GetATasksResponse } from './models/GetATasksResponse';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AddATaskCommand } from './models/AddATaskCommand';
import { AddATaskResponse } from './models/AddATaskResponse';
import { baseResponse } from '../grass-parameters-presentation/DTO/base-response';
import { RemoveATaskCommand } from './models/RemoveATaskCommand';
import { GetTaskTypesResponse } from './models/GetTaskTypesResponse';
import { taskWithDetails } from './models/TaskWithDetails';
import { UpdateATaskCommand } from './models/UpdateATaskCommand';
import { LoadTasksParameters } from './models/LoadTasksParameters';
import {scheduleTaskCommand, startTaskCommand} from './models/ScheduleTaskCommand';
import { AddCommentCommand } from '../shared/model/addCommentCommand';
import { getAvailableTaskUsersResponse } from './models/GetAvailableTaskUsersResponse';
import * as dayjs from 'dayjs';


@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private tasksUrl = environment.projectApiUrl + 'tasks';  // URL to web api
  private taskTypesUrl = environment.projectApiUrl + 'dictionaries/task-types';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    private httpClient: HttpClient
  ) { }

  getTaskList(loadTasksParameters: LoadTasksParameters, organizationId: number, projectId: number): Observable<GetATasksResponse> {
    let params = new HttpParams({
      fromObject: {
        organizationId,
        projectId,
        skip: loadTasksParameters.skip,
        take: loadTasksParameters.take,
      }
    });

    if (loadTasksParameters.areas && loadTasksParameters.areas.length > 0) {
      loadTasksParameters.areas.forEach(area => {
        params = params.append('ProjectAreaIds', area.areaId);
      });
      if (loadTasksParameters.elementaryObject)
        params = params.append('ElementaryObjectId', loadTasksParameters.elementaryObject.id!);
    }
    if (loadTasksParameters.elementaryObjectType)
      params = params.append('ElementaryObjectType', loadTasksParameters.elementaryObjectType);

    if (loadTasksParameters.states && loadTasksParameters.states.length > 0) {
      loadTasksParameters.states.forEach(state => {
        params = params.append('states', state);
      });
    }

    if (loadTasksParameters.dueDateFrom && loadTasksParameters.dueDateTo) {
      params = params.append('DueDateFrom', loadTasksParameters.dueDateFrom.toISOString());
      let dateTo = dayjs(loadTasksParameters.dueDateTo).add(1, 'day').subtract(1, 'second');
      params = params.append('DueDateTo', dateTo.toISOString());
    }

    if (loadTasksParameters.taskTypes) {
      loadTasksParameters.taskTypes.forEach(taskType => {
        params = params.append('TaskTypeIds', taskType.id);
      });
    }

		if (loadTasksParameters.assignedUserId) {
			params = params.append('AssignedUserId', loadTasksParameters.assignedUserId);
		}

    if (loadTasksParameters.sortField && loadTasksParameters.sortOrder) {
      params = params.append('sortOrder', loadTasksParameters.sortField + ((loadTasksParameters.sortOrder > 0) ? '' : ' desc'));
    }

    return this.httpClient.get<GetATasksResponse>(this.tasksUrl, { params: params })
  }

  getTaskById(taskId: string): Observable<taskWithDetails> {
    let url: string = `${this.tasksUrl}/${taskId}`;
    return this.httpClient.get<taskWithDetails>(url);
  }

  getTaskTypes(): Observable<GetTaskTypesResponse> {
    return this.httpClient.get<GetTaskTypesResponse>(this.taskTypesUrl);
  }

  getAvailableTaskUsers(projectId: number): Observable<getAvailableTaskUsersResponse> {
    let url: string = `${this.tasksUrl}/available-task-users`;
    const params = new HttpParams().set('projectId', projectId);
    return this.httpClient.get<getAvailableTaskUsersResponse>(url, {params: params});
  }

  addTask(addCommand: AddATaskCommand, projectId: number): Observable<AddATaskResponse> {
		const taskTypeIds = [];
		if (addCommand.taskTypeId) {
			taskTypeIds.push(addCommand.taskTypeId);
		}

		const body = {...addCommand, taskTypeIds, projectId};
    return this.httpClient.post<AddATaskResponse>(this.tasksUrl, body, this.httpOptions);
  }

  updateTask(updateCommand: UpdateATaskCommand): Observable<baseResponse> {
    let url: string = `${this.tasksUrl}/${updateCommand.taskId}`;
    return this.httpClient.put<baseResponse>(url, {...updateCommand, taskTypeIds: [updateCommand.taskTypeId]}, this.httpOptions);
  }

  deleteTask(id: string): Observable<baseResponse> {
    const removeCommand: RemoveATaskCommand = {
      commandId: id
    }
    const url = `${this.tasksUrl}/${id}`;
    const httpOptionsDelete = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: removeCommand
    };

    return this.httpClient.delete<baseResponse>(url, httpOptionsDelete);
  }

  scheduleTask(scheduleCommand: scheduleTaskCommand): Observable<baseResponse> {
    const url: string = `${this.tasksUrl}/${scheduleCommand.taskId}/schedule`;
    return this.httpClient.post<baseResponse>(url, scheduleCommand, this.httpOptions);
  }

	rescheduleTask(taskId: string, dueDate: Date): Observable<baseResponse> {
		const url: string = `${this.tasksUrl}/${taskId}/reschedule`;
		return this.httpClient.post<baseResponse>(url, {dueDate})
	}

	startTask(startCommand: startTaskCommand): Observable<baseResponse> {
		const url: string = `${this.tasksUrl}/${startCommand.taskId}/start`;
		return this.httpClient.post<baseResponse>(url, startCommand);
	}

	cancelTask(taskId: string): Observable<baseResponse> {
		return this.httpClient.post<baseResponse>(`${this.tasksUrl}/${taskId}/cancel`, {});
	}

	closeTask(taskId: string): Observable<baseResponse> {
		return this.httpClient.post<baseResponse>(`${this.tasksUrl}/${taskId}/close`, {});
	}

  addComment(taskId: string, command: AddCommentCommand): Observable<baseResponse> {
    let url: string = `${this.tasksUrl}/${taskId}/comments`;
    return this.httpClient.post<baseResponse>(url, command, this.httpOptions);
  }

	isExpiredTask(dueDate: Date | undefined): boolean {
		if (!dueDate) {
			return false;
		}

		const today = new Date();
		return new Date(dueDate).getTime() < today.getTime();
	}

}
