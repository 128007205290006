import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AppState} from "../../../../types/app-state";
import {Store} from "@ngrx/store";
import {laboratoryExaminationActions, otherObjectActions} from "../actions";
import {catchError, exhaustMap, map, of, tap, withLatestFrom} from "rxjs";
import {LaboratoryExaminationsApiService} from "../../../services/api/laboratory-examinations.api.service";
import * as projectSelectors from "../../../../project/project-store/selectors";
import * as otherObjectSelectors from "../selectors";
import {AgroMessageService} from "../../../../shared/services/agro-message.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class LaboratoryExaminationEffects {
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);
  private apiService = inject(LaboratoryExaminationsApiService);
	private messageService = inject(AgroMessageService);
	private translateService = inject(TranslateService);

	private readonly messagesTranslationKey = 'GRASS_PARAMETERS.OTHER_OBJECTS.MESSAGES.EXAMINATIONS';

  addLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
    ofType(laboratoryExaminationActions.add),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
    exhaustMap(([addLaboratoryExaminationDto, projectId]) => {
      return this.apiService.add(addLaboratoryExaminationDto, projectId!).pipe(
        map((addLaboratoryExaminationResponse) => {
          if (!addLaboratoryExaminationResponse.success) {
            return otherObjectActions.handleError({errorMessage: addLaboratoryExaminationResponse.error});
          }

					this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.ADD_SUCCESS`));
					return laboratoryExaminationActions.addSuccess();
        }),
        catchError((error) => of(otherObjectActions.handleError(error)))
      );
    })
  ));

  editLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
    ofType(laboratoryExaminationActions.edit),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
    exhaustMap(([editLaboratoryObjectDto, projectId]) => {
      return this.apiService.edit(editLaboratoryObjectDto, editLaboratoryObjectDto.id, projectId!).pipe(
        map((editLaboratoryExaminationResponse) => {
          if (!editLaboratoryExaminationResponse.success) {
            return otherObjectActions.handleError({errorMessage: editLaboratoryExaminationResponse.error});
          }

					this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.UPDATE_SUCCESS`));
					return laboratoryExaminationActions.editSuccess();
        }),
        catchError((error) => of(otherObjectActions.handleError(error)))
      )
    })
  ));

  removeLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
    ofType(laboratoryExaminationActions.remove),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
    exhaustMap(([{id}, projectId]) => {
      return this.apiService.remove(id, projectId!).pipe(
        map((baseResponse) => {
          if (!baseResponse.success) {
            return otherObjectActions.handleError({errorMessage: baseResponse.error});
          }

					this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.REMOVE_SUCCESS`));
					return laboratoryExaminationActions.removeSuccess();
        }),
        catchError((error) => of(otherObjectActions.handleError(error)))
      )
    })
  ));

  moveLaboratoryExamination$ = createEffect(() => this.actions$.pipe(
    ofType(laboratoryExaminationActions.move),
    withLatestFrom(this.store.select(otherObjectSelectors.getLaboratoryExaminationsSelector)),
    map(([{id, gps}, items]) => {
      const movedItem = items.find(x => x.itemId === id.toString());
      return laboratoryExaminationActions.edit({...movedItem!, gps});
    })
  ));
}
