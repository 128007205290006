import {ActionContext, feature, FeatureName} from "../../admin/types";

export const isAccessToApplicationFeaturePermitted = (featureName: FeatureName, features: feature[]): boolean => features.some(({context, name}) => name === featureName && context === ActionContext.Application);

export const isAccessToOrganizationFeaturePermitted = (organizationId: number, featureName: FeatureName, features: feature[]): boolean => features.some(f => {
	if (f.context === ActionContext.Application) {
		return f.name === featureName;
	}

	return f.context === ActionContext.Organization && f.organizationId === organizationId && f.name === featureName;
});

export const isAccessToProjectFeaturePermitted = (projectId: number, organizationId: number, featureName: FeatureName, features: feature[]): boolean => {
	return features.some(f => {
		if (f.context === ActionContext.Application) {
			return f.name === featureName;
		}

		return f.context === ActionContext.Project && (f.projectId === projectId || !f.projectId) && f.organizationId === organizationId && f.name === featureName;
	});
};

export const getFeaturesForRole = (roleContext: ActionContext, allFeatures: feature[]): feature[] => {
	switch(roleContext) {
		case ActionContext.Application: {
			return allFeatures;
		}
		case ActionContext.Organization: {
			return allFeatures.filter(({context}) => context !== ActionContext.Application)
		}
		default: {
			return allFeatures.filter(({context}) => context === ActionContext.Project)
		}
	}
}
