import {inject, Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {
	ActionContext,
	defaultRole,
	LicenseType,
	manageOrganizationUser,
	organizationDetails,
	organizationUserGridViewModel, organizationUserListItem, organizationUserRoleListItem,
	projectListItem,
	role
} from "./types";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {baseResponse} from "../grass-parameters-presentation/DTO/base-response";

@Injectable({
	providedIn: 'root'
})
export class AdminOrganizationsApiService {
	private http = inject(HttpClient);

	getManagedOrganization = (organizationId: number): Observable<organizationAdminResponse> => this.http.get<organizationAdminResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}`);

	getAvailableUsers = (organizationId: number): Observable<getAvailableOrganizationsResponse> => {
		const endpoint = `${environment.projectApiUrl}organizations/users/${organizationId}`;
		return this.http.get<getAvailableOrganizationsResponse>(endpoint);
	} 

	addOrganization = (name: string, accountId: string, productCode: string): Observable<addOrganizationResponse> => this.http.post<addOrganizationResponse>(`${environment.projectApiUrl}authorization/organizations`, {name, accountId, productCode});

	editOrganization = (organizationId: number, name: string, accountId: string, productCode: string): Observable<baseResponse> => this.http.patch<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}`, {name, accountId, productCode});

	deleteOrganization = (organizationId: number): Observable<baseResponse> => this.http.delete<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}`);

	lockOrganizationUser = (organizationId: number, userId: number): Observable<baseResponse> => this.http.patch<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/users/${userId}/lock`, {});

	unlockOrganizationUser = (organizationId: number, userId: number): Observable<baseResponse> => this.http.patch<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/users/${userId}/unlock`, {});

	getDefaultOrganizationRoles = (organizationId: number): Observable<getDefaultRolesResponse> => this.http.get<getDefaultRolesResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/default-roles`);

	resetToDefaultOrganizationRole = (organizationId: number, roleId: number, defaultRoleId: number) => this.http.post<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/roles/${roleId}/features/reset`, defaultRoleId);

	addOrganizationRole = (organizationId: number, addRoleModel: addOrganizationRoleModel) => this.http.post<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/roles`, addRoleModel);

	removeOrganizationRole = (organizationId: number, roleId: number) => this.http.delete<baseResponse>(`${environment.projectApiUrl}authorization/organizations/${organizationId}/roles/${roleId}`);

	transformUserOrganizationsApiResponseToViewModel = (apiResponse: organizationAdminResponse): organizationUserGridViewModel[] =>
		apiResponse.organizationUsers.map(user => ({
			...user,
			roles: apiResponse.organizationUserRoles.filter(x => x.userId === user.id).map(r => {
				return {
					...r,
					roleName: apiResponse.availableOrganizationRoles.find(x => x.id === r.roleId)?.name || 'unknown',
					projectName: apiResponse.availableOrganizationProjects.find(x => x.id === r.projectId)?.name || ''
				}
			})
		}))
}

export interface getAvailableOrganizationsResponse extends baseResponse {
	users: manageOrganizationUser[];
}

export interface organizationAdminResponse extends baseResponse {
	availableOrganizationRoles: role[],
	availableOrganizationProjects: projectListItem[],
	organizationUsers: organizationUserListItem[],
	organizationUserRoles: organizationUserRoleListItem[],
	subscription?: awsSubscriptionDetails
	name: string,
	accountId: string;
}

export interface awsSubscriptionDetails {
	activationDate: Date,
	automaticRenewal: boolean,
	subscriptionStatus: string
}

export interface addOrganizationResponse extends baseResponse {
	organizationId: number;
}

export interface getDefaultRolesResponse extends baseResponse {
	roles: defaultRole[];
}

export interface addOrganizationRoleModel {
	roleName: string,
	features: number[],
	context: ActionContext
}
