import {inject, Injectable} from "@angular/core";
import {AnyLayer, CircleLayer, FillLayer, GeoJSONSource, LineLayer, Map} from "mapbox-gl";
import {IncidentPriority, ItemDto, LaboratoryExaminationDto, mapIncidentDto, sprinklerDto} from "../DTO/item-dto";
import {ColorService} from "./color.service";
import {FeatureCollection, GeoJsonProperties, Geometry} from "geojson";
import * as turf from "@turf/turf";
import {OtherObjectDataSource, OtherObjectLayer} from "../models/mapbox/types";

@Injectable({
    providedIn: 'root'
})
export class MapboxItemsService {
  private colorService = inject(ColorService);

  loadItemsDataSources = (map: Map, items: ItemDto[], dataSourceName: OtherObjectDataSource) => {
		const features: turf.Feature<Geometry, GeoJsonProperties>[] = items.map(item => {
			switch(item.itemType) {
				case "Incident":
					return this.createIncidentFeature(item as mapIncidentDto);
				case "Sprinkler":
					return this.createSprinklerFeature(item as sprinklerDto);
				case "LaboratoryExamination":
					return this.createLaboratoryExaminationFeature(item as LaboratoryExaminationDto);
				default:
					throw `Unrecognized item type ${item.itemType}`;
			}
		});

		const data:FeatureCollection = {type: 'FeatureCollection', features};
		const source: GeoJSONSource = map.getSource(dataSourceName) as GeoJSONSource;

		if (source === undefined) {
			map.addSource(dataSourceName, { type: 'geojson', data });
		} else {
			source.setData(data);
		}
  }

	createLayers = (map: Map, layerNames: OtherObjectLayer[], isVisible: boolean = true) => {
		layerNames.forEach((layerName) => {
			let layer: AnyLayer;

			switch(layerName) {
				case OtherObjectLayer.LaboratoryExaminations:
					layer = this.createLaboratoryExaminationLayer();
					break;
				case OtherObjectLayer.SprinklersFill:
					layer = this.createSprinklerFillLayer();
					break;
				case OtherObjectLayer.SprinklersLine:
					layer = this.createSprinklerLineLayer();
					break;
				default:
					layer = this.createWorkItemLayer();
			}

			if (!map.getLayer(layerName)) {
				map.addLayer(layer);
			}

			if(!isVisible) {
				this.hideLayer(map, layerName);
			}
		});
	}

  showLayer = (map: Map, layerName: OtherObjectLayer) => map.setLayoutProperty(layerName, 'visibility', 'visible')

	hideLayer = (map: Map, layerName: OtherObjectLayer) => map.setLayoutProperty(layerName, 'visibility', 'none')

  private createIncidentFeature = (item: mapIncidentDto): turf.Feature<Geometry, GeoJsonProperties> => {
    const properties = {
      color: this.getColorByPriority(item.priority)
    }

    return this.createItemPointFeature(item, properties);
  }

  private getColorByPriority(priority: IncidentPriority | null | undefined): string {
    switch (priority) {
      case IncidentPriority.Yellow: return '#fdde61';
      case IncidentPriority.Orange: return '#F57C00';
      case IncidentPriority.Red: return '#EB431F';
      default: return '#42A5F5'
    }
  }

  private createSprinklerFeature = (item: sprinklerDto): turf.Feature<Geometry, GeoJsonProperties> => {
    const {itemId, itemType, radius, gps} = item;
    const center = [+gps!.x, +gps!.y];
    const properties = {itemId, itemType}
    const generateCircleOptions: any = { steps: 40, units: "meters", properties }
    return turf.circle(center, radius!, generateCircleOptions);
  }

  private createLaboratoryExaminationFeature = (item: LaboratoryExaminationDto): turf.Feature<Geometry, GeoJsonProperties> => this.createItemPointFeature(item)

  private createItemPointFeature = (item: mapIncidentDto | LaboratoryExaminationDto, extraProperties: any = {}): turf.Feature<Geometry, GeoJsonProperties> => {
    const {itemId, itemType} = item;
    const properties = {
      itemId,
      itemType
    }

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [Number(item.gps?.x), Number(item.gps?.y)],
      },
      properties: {
        ...properties,
        ...extraProperties
      }
    }
  }

  private createWorkItemLayer = (): CircleLayer => ({
    id: OtherObjectLayer.ObservationPoints,
    type: 'circle',
    source: OtherObjectDataSource.ObservationPoints,
    paint: {
      'circle-color': ['get', 'color'],
      'circle-radius': 10,
      'circle-opacity': 1,
      'circle-stroke-width': 0
    }
  })

  private createSprinklerLineLayer = (): LineLayer => ({
    id: OtherObjectLayer.SprinklersLine,
    type: 'line',
    source: OtherObjectDataSource.Sprinklers,
    paint: {'line-color': '#FFF', 'line-width': 2}
  })

  private createSprinklerFillLayer = (): FillLayer => ({
    id: OtherObjectLayer.SprinklersFill,
    type: 'fill',
    source: OtherObjectDataSource.Sprinklers,
    paint: { 'fill-color': '#FFF', 'fill-opacity': 0.15 }
  })

  private createLaboratoryExaminationLayer = (): CircleLayer => ({
    id: OtherObjectLayer.LaboratoryExaminations,
    type: 'circle',
    source: OtherObjectDataSource.LaboratoryExaminations,
    paint: {
      'circle-color': '#000',
      'circle-radius': 12,
      'circle-opacity': 1,
      'circle-stroke-color': '#FFBF00',
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1
    },
  })
}


