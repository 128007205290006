import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {dateOfPhotoResponse} from "../../models/date-of-photo/date-of-photo-response";
import {FeatureCollection} from "geojson";

@Injectable({
  providedIn: 'root'
})
export class PhotosService {
  private baseUrl:string = environment.projectApiUrl;
  constructor(private http: HttpClient) { }
  getProjectDateOfPhotos = (projectId: number):Observable<dateOfPhotoResponse> => this.http.get<dateOfPhotoResponse>(`${this.baseUrl}photos/${projectId}`);
}
