import {PhotoDataSourceType} from "../../models/photo-data-source-type";
import {DiseaseValue, GrassQualityParameter, ParameterCategory} from "../../constants/config";
import {dateOfPhoto} from "../../models/date-of-photo/date-of-photo";
import {PhotoInfoType} from "../../models/photo-info/photo-info-type";
import {sectorInfo} from "../../models/filtered-objects/sector-info";
import {areaEnds, photoInfo, tilesFilesDto} from "../../models/types";
import {hexTrends} from "../../../parameter-trend-api.service";
import {HexesDataSourceType} from "../../models/mapbox/types";
import {
	grassParamNormGridViewModel,
	mineralIngredientNorm,
	vegetationIndicatorNorm,
	visualAssessmentNorm
} from "../../services/api/project-grass-params-norms-api.service";
import {
	measurementDto
} from "../../components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";
import {observedHexesSnapshot} from "../../services/api/observed-hexes-snapshots.api.service";
import {TilesFiles} from "../../models/mapbox/tiles-layers.config";

export enum PresentationMode {
  Parameters,
  OtherObjects
}

export enum ParametersMapComposition {
	Single,
	SplitScreen,
	InteractiveCompare
}

export enum HexGridDataMode {
  Parameters,
  Deviations,
  Diseases,
  Vegetation
}

export interface selectableParam extends GrassQualityParameter {
  formula?: string,
  isSelected: boolean,
  disabled: boolean;
  filterMinValue: number | undefined;
  filterMaxValue: number | undefined;
}

export interface projectGrassParamsNorms {
	mineralIngredientsNorms: mineralIngredientNorm[];
	visualAssessmentNorms: visualAssessmentNorm[];
	vegetationIndicatorNorms: vegetationIndicatorNorm[];
}

export interface grassParamsState {
  selectableParameters: selectableParam[];
  mainSelectedParam: string;
  selectedDiseases: DiseaseValue[];
  datesOfPhotos: dateOfPhoto[];
  photoInfoTypes: PhotoInfoType[];
  hexGridDataMode: HexGridDataMode;
  isLoadingCount: number;
  expandedCategories: ParameterCategory[];
  selectedSectorIds?: number[];
  sectorInfos: sectorInfo[];
  errorMessage: string;
  parametersMaps: parameterMap[];
  hexGridLayerFilters: mapHexLayerFilters;
  zoomValue: number;
  trends: hexTrends[];
  areasEnds: areaEnds[];
  trendLoadingMapIds: string[];
	projectGrassParamsNorms: projectGrassParamsNorms;
	projectDefaultAndLatestGrassParamsNorms: grassParamNormGridViewModel[],
	hexId?: number,
	debugModeValues: debugModeSelectedParametersActualValues,
	measurementsForMaps: dateOfPhotoMeasurements,
	observedHexesSnapshots: observedHexesSnapshot[],
}

export interface debugModeSelectedParametersActualValues {
	[paramName: string]: number | string
}

export interface parameterMap {
	selectedDate: Date;
	mapId: string;
	dataSourceType: PhotoDataSourceType;
	dataSources: hexesDataSource[];
	photoInfos: photoInfo[],
	bbox: number[],
	tilesLinks: TilesFiles[]
}

export interface hexesDataSource {
	kind: HexesDataSourceType;
	zoom: number;
	dataSourceUrl: string;
}

export interface mapHexLayerFilters {
  sectorIds: any;
  type: any;
  diseases: any;
	min: any,
	max: any,
	hexIds: any
  // zoom: any;
}

export interface propsForMapChange {
  selectedDate: Date;
  photoType: PhotoDataSourceType;
  photoId: number;
}

export interface dateOfPhotoMeasurements {
	[mapId: string]: measurementDto[]
}
