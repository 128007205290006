import {Injectable} from '@angular/core';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState} from '@microsoft/signalr';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubUrl = environment.hubUrl;
  public notificationCounter$ = new BehaviorSubject<number>(0);
  public notificationCompletedFileProcessing$ = new BehaviorSubject<boolean>(false);
  private notificationCounter: Observable<number> = this.notificationCounter$.asObservable();
  private notificationProcessingFileCompleted: Observable<boolean> = this.notificationCompletedFileProcessing$.asObservable()
  private hubConnection: HubConnection | undefined;

  constructor() {
  }

  startConnection(): void {
    if (!this.hubConnection) {
      this.hubConnection = this.buildHubConnection();
    }

    this.hubConnection
      .start()
      .catch((err) => {
        console.error(`Error while starting connection: ${err}`);
      });
  }

  stopConnection(): void {
    if(this.hubConnection) {
      this.hubConnection
        .stop()
        .catch((err) => {
          console.error(`Error while stopping connection: ${err}`);
        });
    }
  }

  public setNotificationCounter(newValue: number): void {
    this.notificationCounter$.next(newValue);
  }

  public getNotificationCounter(): Observable<number> {
    return this.notificationCounter;
  }

  public getCompletedFileProcessing(): Observable<boolean> {
    return this.notificationProcessingFileCompleted;
  }

  public getConnectionState(): Observable<HubConnectionState> {
    return of(this.hubConnection?.state || HubConnectionState.Disconnected);
  }

  private buildHubConnection(): HubConnection {
    const idToken = localStorage.getItem('agro');
    if(!idToken) {
      throw new Error('Cannot establish a connection with the SignalR server, no token in localStorage.');
    }

    const hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubUrl + 'notification', {
        accessTokenFactory: () => idToken,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build()

      hubConnection.on('OnNotify', (counter: number) => {
        this.notificationCounter$.next(counter);
        localStorage.setItem('notificationCounter', counter.toString());
      })
      hubConnection.on('CompletedFileProcessing', () => {
        this.notificationCompletedFileProcessing$.next(true);
    })

    return hubConnection;
  }
}