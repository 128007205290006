import {inject, Injectable} from "@angular/core";
import {AppState} from "../../../types/app-state";
import {Store} from "@ngrx/store";
import {getUploadedPhotoFilePresignedUrlResponse, UploadFileService} from "../../services/upload-file.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {uploadedPhotoFilesActions} from "./index";
import {catchError, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {selectedProjectIdSelector} from "../../project-store/selectors";
import {HistoryUploadItem} from "../../model/upload.interface";
import {baseResponse} from "../../../grass-parameters-presentation/DTO/base-response";
import {AgroMessageService} from "../../../shared/services/agro-message.service";
import {adminActions} from "../../../admin/store";
import {HttpErrorService} from "../../../shared/services/http-error.service";
import { ProjectService } from "../../services/project.service";
import { TranslateService } from "@ngx-translate/core";
import { DialogService } from "primeng/dynamicdialog";

@Injectable()
export class UploadedPhotoFilesEffects {
	private store = inject(Store<AppState>);
	private uploadFileService = inject(UploadFileService);
	private projectService = inject(ProjectService);
	private actions$ = inject(Actions);
	private messageService = inject(AgroMessageService);
	private httpErrorService = inject(HttpErrorService);
    private translateService = inject(TranslateService);
	
	getUploadedPhotos$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.getUploadedPhotos),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		mergeMap(([_, projectId]) =>
			this.uploadFileService.getUploadFiles(projectId!).pipe(map((uploadedPhotos: HistoryUploadItem[]) =>
				uploadedPhotoFilesActions.getUploadedPhotosSuccess({uploadedPhotos})
			)),
		)
	));

	updatePhotoFileInformation$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.updateUploadedPhotoInformation),
		mergeMap(({uploadFileId, updatedPhoto}) =>
			this.uploadFileService.updateUploadFileInformation(uploadFileId, updatedPhoto).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						return uploadedPhotoFilesActions.handleError();
					}

					return uploadedPhotoFilesActions.updateUploadedPhotoInformationSuccess({uploadFileId, updatedPhoto});
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(adminActions.handleError());
				})
			)
		)
	));

	processPhotoFile$ = createEffect(() =>
		this.actions$.pipe(
		  ofType(uploadedPhotoFilesActions.processUploadedPhoto),
		  withLatestFrom(this.store.select(selectedProjectIdSelector)),
		  mergeMap(([action, projectId]) => 
			this.uploadFileService.processUploadedFile(projectId!, action.uploadFileId).pipe(
			  map((response: baseResponse) => {
				if (!response.success) {
				  this.messageService.displayErrorMessage('Error', response.error);
				  return uploadedPhotoFilesActions.handleError();
				}
	  
				return uploadedPhotoFilesActions.processUploadedPhotoSuccess({ uploadFileId: action.uploadFileId });
			  }),
			  catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(adminActions.handleError());
			  })
			)
		  )
		)
	  );

	generateHexGrid$ = createEffect(() =>
		this.actions$.pipe(
		  ofType(uploadedPhotoFilesActions.generateHexGrid),
		  withLatestFrom(this.store.select(selectedProjectIdSelector)),
		  mergeMap(([{uploadFileId}, projectId]) =>
			this.projectService.generateHexGrid(projectId!, uploadFileId).pipe(
			  map((response: baseResponse) => {
				if (!response.success) {
				  return uploadedPhotoFilesActions.handleError();
				}
				return uploadedPhotoFilesActions.initProcessingUploadedPhotoSuccess();
			  }),
			  catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(adminActions.handleError());
			  })
			)
		  )
		)
	  );

	repeatProcessingPhotoFile$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.repeatProcessingUploadedPhoto),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		mergeMap(([{uploadFileId}, projectId]) =>
			this.uploadFileService.repeatProcessingUploadFile(projectId!, uploadFileId).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						return uploadedPhotoFilesActions.handleError();
					}

					return uploadedPhotoFilesActions.initProcessingUploadedPhotoSuccess();
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(adminActions.handleError());
				})
			)
		)
	));

	initProcessingUploadedPhoto$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.processUploadedPhotoSuccess, uploadedPhotoFilesActions.repeatProcessingUploadedPhoto),
		tap(() =>
			this.messageService.displayInfoMessage('Info', 'Photo processing has been started. This may take several time.')
		)
	), {dispatch: false});

	initGenerateHexGrid$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.generateHexGrid),
		tap(() =>
			this.messageService.displayInfoMessage('Info', 'Hex grid generation has started. This may take some time.')
		)
	), {dispatch: false});

	downloadUploadedPhoto$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.downloadUploadedPhoto),
		mergeMap(({uploadFileId}) =>
			this.uploadFileService.getPresignedDownloadUrl(uploadFileId).pipe(
				map((response: getUploadedPhotoFilePresignedUrlResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error', response.error);
						return uploadedPhotoFilesActions.handleError();
					}

					return uploadedPhotoFilesActions.downloadUploadedPhotoSuccess({presignedUrl: response.url})
				}),
				catchError((error) => {
					this.httpErrorService.handleError(error);
					return of(adminActions.handleError());
				})
			))
	));

	downloadUploadedPhotoSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(uploadedPhotoFilesActions.downloadUploadedPhotoSuccess),
		tap(({presignedUrl}) => {
			const link = document.createElement('a');
			link.href = presignedUrl;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
	), {dispatch: false});

	removeUploadFile$ = createEffect(() =>
		this.actions$.pipe(
			ofType(uploadedPhotoFilesActions.removeUploadFile),
			mergeMap(({projectId, projectVersionId}) => {
				return this.uploadFileService.removeUploadFile(projectId!, projectVersionId).pipe(
					map((response: baseResponse) => {
						if (!response.success) {
                            this.messageService.displayErrorMessage(this.translateService.instant("PROJECT.UPLOAD_PHOTOS.UPLOAD_PHOTO_INFO.REMOVE_UPLOAD_FILE.SUMMARY_ERROR"), response.error);
							return uploadedPhotoFilesActions.handleError();
						}

						this.messageService.displaySuccessMessage(
							this.translateService.instant("PROJECT.UPLOAD_PHOTOS.UPLOAD_PHOTO_INFO.REMOVE_UPLOAD_FILE.SUMMARY_INFO"),
						 	this.translateService.instant("PROJECT.UPLOAD_PHOTOS.UPLOAD_PHOTO_INFO.REMOVE_UPLOAD_FILE.REMOVE_FILE_SUCCESS"));
						
						return uploadedPhotoFilesActions.getUploadedPhotos();
					}),
					catchError((error) => {
						this.httpErrorService.handleError(error);
						return of(uploadedPhotoFilesActions.handleError());
					})
				)
			})
		)
	);
}
