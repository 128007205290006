import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {commonActions} from "./actions";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {selectedProjectIdSelector} from "../../../project/project-store/selectors";
import {
	getWeatherForTaskResponse, WeatherApiService
} from "../../../grass-parameters-presentation/components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../types/app-state";

@Injectable()
export class CommonEffects {
	private actions$ = inject(Actions);
	private store = inject(Store<AppState>);
	private weatherApiService = inject(WeatherApiService);

	getWeather$ = createEffect(() => this.actions$.pipe(
		ofType(commonActions.getWeather),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{pastDaysCount, futureDaysCount}, projectId]) => this.weatherApiService.getWeather(projectId!, pastDaysCount, futureDaysCount).pipe(
				map((response: getWeatherForTaskResponse) => {
					if (!response.success) {
						return commonActions.handleError({ errorMessage: response.error });
					}

					return commonActions.getWeatherSuccess({measurements: response.measurements.map((m) => ({...m, measurementDateTime: new Date(m.measurementDateTime)}))});
				}),
				catchError(error => of(commonActions.handleError(error)))
			)
		)
	));
}
