import { Component, inject } from "@angular/core";
import { taskActions } from "../store/actions";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { scheduleTaskCommand } from "../models/ScheduleTaskCommand";
import { FormBuilder, Validators } from "@angular/forms";

@Component({
    templateUrl: './task-schedule.component.html',
    styleUrls: ['./task-schedule.component.css']
})
export class TaskScheduleComponent {
    private store = inject(Store<AppState>);
    private dynamicDialogConfig = inject(DynamicDialogConfig);
    taskId: string = this.dynamicDialogConfig.data;
    private formBuilder = inject(FormBuilder);
    minDate: Date;

    constructor() {
        this.minDate = new Date();
    }

    form = this.formBuilder.group({
        dueDate: [new Date(), Validators.required],
    });

    cancelClick = () => {
        this.store.dispatch(taskActions.hideSchedulePopup({ saveExecuted: false }));
    }

    submit = () => {
        if (this.form.invalid)
            return;
        const formVal = this.form.value;

        const scheduleCommand: scheduleTaskCommand = {
            taskId: this.taskId,
            dueDate: formVal.dueDate!,
        }
        this.store.dispatch(taskActions.scheduleTask(scheduleCommand));
    }

}
