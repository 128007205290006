import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AuthService} from "../../shared/services/auth.service";
import {LoadPanelService} from "../../shared/services/load-panel.service";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import {catchError, exhaustMap, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {selectIsHelpDeskAdmin, selectUserPermittedFeatures, userActions} from "./index";
import * as projectActions from "../../project/project-store/actions";
import {HttpErrorService} from "../../shared/services/http-error.service";
import {UserDataInitApiService, userInfoResponse} from "../../shared/services/user-data-init.api.service";
import {adminActions} from "../../admin/store";
import { Router } from "@angular/router";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {ActionContext, FeatureName} from "../../admin/types";
import { GetUserResponse } from "../types/user-types";
import { updateUserSettingsResponse } from "../update-current-user-settings/update-current-user-settings-types";
import { MeasurementSystem } from "src/app/types/MeasurementSystem";

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  private authService = inject(AuthService);
  private httpErrorService = inject(HttpErrorService);
  private userInitService = inject(UserDataInitApiService);
	private messageService = inject(AgroMessageService);
	private loadPanelService = inject(LoadPanelService);
	private store = inject(Store<AppState>);
  private router = inject(Router);

  getUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUserSettings),
      exhaustMap(() =>
        this.userInitService.getLoggedInUser().pipe(
          map((response: GetUserResponse) => {
              if (!response.success) {
                this.httpErrorService.handleErrorOkResponse(response);
                return userActions.handleError({errorMessage: response.error});
              }
              const settings = response.userSettings;

              return userActions.getCurrentUserSettingsSuccess({ measurementSystem: settings.measurementSystem, acceptNotifications: settings.acceptNotifications});
            }
          ),
          catchError((error) => of(userActions.handleError({errorMessage: error.message})))
        )
      )
    )
  );

  updateUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateCurrentUserSettings),
      exhaustMap((action) =>
        this.userInitService.updateCurrentUserSettings(action.measurementSystem, action.acceptNotifications).pipe(
          map((response: updateUserSettingsResponse) => {
            if (!response.success) {
              this.httpErrorService.handleErrorOkResponse(response);
              return userActions.handleError({errorMessage: response.error});
            }

            return userActions.updateCurrentUserSettingsSuccess({measurementSystem: action.measurementSystem, acceptNotifications: action.acceptNotifications});
          }),
          catchError((error) => of(userActions.handleError({errorMessage: error.message})))
        ))
    )
  );

  initUserData$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.getCurrentUserData),
    exhaustMap(() =>
      this.userInitService.getLoggedInUserData().pipe(
        map((response: userInfoResponse) => {
          if (!response.success) {
            this.httpErrorService.handleErrorOkResponse(response);
            return [userActions.handleError({errorMessage: response.error})];
          }

					const {userRoles, userFullName, isHelpDeskAdmin, active} = response;

          return [
            userActions.loadCurrentUserInfo({roles: userRoles, userFullName, isHelpDeskAdmin, active}),
            projectActions.setOrganizationAndProjects({organizations: response.userOrganizations, projects: response.userProjects, organizationId: response.organizationId})
          ];
        })
      ),
    ),
    mergeMap(actions => actions)
  ));

  changeOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(userActions.changeCurrentUserOrganization),
    exhaustMap(({organizationId}) => {
        return this.userInitService.getLoggedInUserData(organizationId).pipe(
          map((response) => {
            if (!response.success) {
              this.httpErrorService.handleErrorOkResponse(response);
              return [userActions.handleError({errorMessage: response.error})];
            }

						const { userRoles, userFullName, isHelpDeskAdmin, active } = response;

            return [
              userActions.loadCurrentUserInfo({roles: userRoles, userFullName, isHelpDeskAdmin, active}),
              userActions.changeUserOrganizationSuccess({organizationId}),
              projectActions.setOrganizationAndProjects({
                organizations: response.userOrganizations,
                projects: response.userProjects,
                organizationId
              })
            ];
          })
        );
      },
    ),
    mergeMap(actions => actions)
  ));

  redirectAfterOrganizationChange$ = createEffect(() =>
    this.actions$.pipe(
			ofType(userActions.changeUserOrganizationSuccess),
			withLatestFrom(
				this.store.select(selectUserPermittedFeatures),
				this.store.select(selectIsHelpDeskAdmin),
			),
			tap(([{organizationId}, features, isHelpDeskAdmin]) => {
				if (isHelpDeskAdmin || features.some(f => f.context === ActionContext.Organization && f.organizationId === organizationId && f.name === FeatureName.ReadManageOrganizationData)) {
					void this.router.navigate(['/admin/organizations', organizationId, 'details']);
					return;
				}

				void this.router.navigate(['/project']);
			})
    ), { dispatch: false }
  );

  handleError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.handleError),
      tap(({errorMessage}) => this.messageService.displayErrorMessage(errorMessage, ""))
    ), {dispatch: false}
  );

  showLoadingPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUserSettings, userActions.updateCurrentUserSettings, userActions.getCurrentUserData),
      tap(() => this.loadPanelService.showLoadPanel())
    ), {dispatch: false}
  );

  hideLoadingPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getCurrentUserSettingsSuccess, userActions.updateCurrentUserSettingsSuccess, userActions.loadCurrentUserInfo),
      tap(() => this.loadPanelService.hideLoadPanel())
    ), {dispatch: false}
  );
}
