import {inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
	getGolfClubByIdResponse,
	getGolfClubMembersResponse,
	GolfClubMembersApiService, importGolfClubMembersFromExcelResponse
} from "../api/golf-club-members.api.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {golfClubMemberActions, selectFilter} from "./index";
import {catchError, exhaustMap, map, mergeMap, of, tap, withLatestFrom} from "rxjs";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import {HttpErrorService} from "../../shared/services/http-error.service";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {organizationIdSelector, selectedProjectIdSelector} from "../../project/project-store/selectors";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class GolfClubMemberEffects {
	private actions$ = inject(Actions);
	private golfClubMembersApiService = inject(GolfClubMembersApiService);
	private router = inject(Router);
	private messageService = inject(AgroMessageService);
	private httpErrorService = inject(HttpErrorService);
	private store = inject(Store<AppState>);
	private translateService = inject(TranslateService);

	getGolfClubMembers$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.getGolfClubMembers),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([args, projectId]) => this.golfClubMembersApiService.getGolfClubMembers(projectId!, args.filter).pipe(
			map((response: getGolfClubMembersResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.getGolfClubMembersSuccess({golfClubMembers: response.golfClubMembers, totalRecords: response.totalRecords});
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	));

	filterGolfClubMembers$ = createEffect(() => this.actions$.pipe(
		ofType(
			golfClubMemberActions.filterGolfClubMembersByDate,
			golfClubMemberActions.filterGolfClubMembersByLazyLoad),
		withLatestFrom(this.store.select(selectFilter)),
		map(([_, filter]) => {
			return golfClubMemberActions.getGolfClubMembers({filter});
		})
	));

	getMembersFromOtherProjects$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.getMembersFromOtherProjects),
		withLatestFrom(
			this.store.select(organizationIdSelector),
			this.store.select(selectedProjectIdSelector),
		),
		exhaustMap(([_, organizationId, projectId]) => this.golfClubMembersApiService.getMembersFromOtherProjects(organizationId!, projectId!).pipe(
			map((response: getGolfClubMembersResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.getMembersFromOtherProjectsSuccess({golfClubMembers: response.golfClubMembers});
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	));

	addGolfClubMember$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.addGolfClubMember),
		exhaustMap(({projectId, command}) => this.golfClubMembersApiService.addGolfClubMember(projectId, command).pipe(
			map((response: baseResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.addGolfClubMemberSuccess();
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		)),
	));

	addGolfClubMemberSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.addGolfClubMemberSuccess, golfClubMemberActions.assignMemberToProjectSuccess),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		tap(([_, projectId]) => {
			this.router.navigate(['golf-club-members', projectId]).then(() => {
				this.messageService.displaySuccessMessage('Success', this.translateService.instant("GOLF_CLUB_MEMBERS.MESSAGES.ADD_SUCCESS"));
			})
		})
	), {dispatch: false});

	assignMemberToProject$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.assignMemberToProject),
		exhaustMap(({projectId, memberId}) => this.golfClubMembersApiService.assignMemberToProject(projectId, memberId).pipe(
			map((response: baseResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.assignMemberToProjectSuccess();
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	));

	unassignMemberFromProject$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.unassignMemberFromProject),
		exhaustMap(({projectId, memberId}) => this.golfClubMembersApiService.unassignMemberFromProject(projectId, memberId).pipe(
			map((response: baseResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				this.messageService.displaySuccessMessage('Success', this.translateService.instant('GOLF_CLUB_MEMBERS.MESSAGES.UNASSIGN_SUCCESS'));
				return golfClubMemberActions.unassignMemberFromProjectSuccess({memberId});
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	));

	getMemberById$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.getMemberById),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{memberId}, projectId]) => this.golfClubMembersApiService.getGolfClubMemberById(projectId!, memberId).pipe(
			map((response: getGolfClubByIdResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.getMemberByIdSuccess({member: ({...response, allIncidentsCount: 0, acceptedIncidentsCount: 0 })});
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	));

	updateMember$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.updateGolfClubMember),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{memberId, command}, projectId]) => this.golfClubMembersApiService.updateGolfClubMember(projectId!, memberId, command).pipe(
			map((response: baseResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return golfClubMemberActions.handleError();
				}

				return golfClubMemberActions.updateGolfClubMemberSuccess();
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	))

	downloadTemplate$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.downloadTemplate),
		withLatestFrom(this.store.select(selectedProjectIdSelector)),
		exhaustMap(([_, projectId]) => this.golfClubMembersApiService.downloadTemplate(projectId!).pipe(
			map((response: Blob) => {
				return golfClubMemberActions.downloadTemplateSuccess({ bytes: response });
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of(golfClubMemberActions.handleError());
			})
		))
	))

	importGolfClubMembers$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.uploadGolfClubMembers),
		withLatestFrom(this.store.select(selectFilter), this.store.select(selectedProjectIdSelector)),
		exhaustMap(([{file}, filter, projectId]) => this.golfClubMembersApiService.importFromExcel(projectId!, file).pipe(
			map((response: importGolfClubMembersFromExcelResponse) => {
				if (!response.success) {
					this.messageService.displayErrorMessage('Error', response.error);
					return [golfClubMemberActions.handleError()];
				}

				const successMessage = response.invalidPhoneNumbersCount === 0 ?
						this.translateService.instant("GOLF_CLUB_MEMBERS.MESSAGES.IMPORT_SUCCESS") :
						this.translateService.instant("GOLF_CLUB_MEMBERS.MESSAGES.IMPORT_SUCCESS_INVALID_NUMBERS");

				this.messageService.displaySuccessMessage('', successMessage);

				return [golfClubMemberActions.updateGolfClubMemberSuccess(), golfClubMemberActions.getGolfClubMembers({filter})];
			}),
			catchError((error) => {
				this.httpErrorService.handleError(error);
				return of([golfClubMemberActions.handleError()]);
			}),
			mergeMap(actions => actions)
		))
	));

	updateGolfClubMemberSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.updateGolfClubMemberSuccess),
		withLatestFrom(this.store.select(selectFilter)),
		map(([_, filter]) => {
			this.messageService.displaySuccessMessage('', this.translateService.instant('GOLF_CLUB_MEMBERS.MESSAGES.UPDATE_SUCCESS'));
			return golfClubMemberActions.getGolfClubMembers({filter});
		})
	));

	downloadTemplateSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(golfClubMemberActions.downloadTemplateSuccess),
		tap(({ bytes }) => {
			const blob = new Blob([bytes], { type: 'application/octet-stream' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.href = url;
			a.download = 'template.xlsx';
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		})
	), {dispatch: false})

}
