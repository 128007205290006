import {inject, Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {
  addLaboratoryExaminationDto, editLaboratoryExaminationDto
} from "../../components/grass-parameters-presentation/laboratory-examination-popup/types";
import {Observable} from "rxjs";
import {LaboratoryExaminationDto} from "../../DTO/item-dto";
import {baseResponse} from "../../DTO/base-response";

@Injectable({
  providedIn: 'root'
})
export class LaboratoryExaminationsApiService {
  private baseUrl = environment.projectApiUrl;
  private http = inject(HttpClient);

  add = (model: addLaboratoryExaminationDto, projectId: number): Observable<addLaboratoryExaminationResponse> => {
    const endpointUrl = `${this.baseUrl}projects/${projectId}/items/laboratory-Examinations`;
    return this.http.post<addLaboratoryExaminationResponse>(endpointUrl, model);
  }

  get = (id: number, projectId: number): Observable<getLaboratoryExaminationResponse> => {
    const endpointUrl = `${this.baseUrl}projects/${projectId}/items/laboratory-Examinations/${id}`;
    return this.http.get<getLaboratoryExaminationResponse>(endpointUrl);
  }

  edit = (model: editLaboratoryExaminationDto, id: number, projectId: number): Observable<editLaboratoryExaminationResponse> => {
    const endpointUrl = `${this.baseUrl}projects/${projectId}/items/laboratory-Examinations/${id}`;
    return this.http.post<editLaboratoryExaminationResponse>(endpointUrl, model);
  }

  remove = (id: number, projectId: number): Observable<baseResponse> => {
    const endpointUrl = `${this.baseUrl}projects/${projectId}/items/laboratory-Examinations/${id}`;
    return this.http.delete<baseResponse>(endpointUrl);
  }
}

export interface addLaboratoryExaminationResponse extends baseResponse {
  id: number;
}

export interface editLaboratoryExaminationResponse extends baseResponse {
  id: number;
}

export interface getLaboratoryExaminationResponse extends baseResponse {
  laboratoryExamination: LaboratoryExaminationDto;
  projectId: number;
}
