import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { AreaMapboxModel } from 'src/app/project/model/area-mapbox-model';
import { AreaFormPopupModel } from 'src/app/project/model/area-form-popup-model';
import { ElementaryObjectTypes, IElementaryObjectTypes } from 'src/app/project/_helpers/elementary-objects';
import {FormBuilder, Validators} from "@angular/forms";
import * as projectValidators from "../../../utils/validators";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../types/app-state";
import {getType} from "@turf/turf";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-area-form-popup',
  templateUrl: './area-form-popup.component.html',
  styleUrls: ['./area-form-popup.component.css', '../../choose-area.component.scss']
})
export class AreaFormPopupComponent {
  @Input() isAreaFormVisible: boolean = false;
  @Input() isAreaValid: boolean = false;
  @Input() title: string = '';
  private _areaFormData: AreaFormPopupModel = {};
  @Input()
  set areaFormData(value: AreaFormPopupModel) {
    this._areaFormData = value;

    let type = value.type;

    if (value.type === undefined && value.parentUuid) {
      type = ElementaryObjectTypes.Fairway;
    }

    this.areaPopupForm.patchValue({
      name: value.name,
      type,
      uuid: value.uuid,
      parentUuid: value.parentUuid
    });
  }
  get areaFormData(): AreaFormPopupModel {
    return this._areaFormData;
  }
  @Input() currentAreas!: AreaMapboxModel[];
  @Input() isElementaryObjectEnabled: boolean = false;
  @Input() action: string = '';
  @Output() formSubmitted = new EventEmitter<AreaFormPopupModel>();
  @Output() areaDeleted = new EventEmitter<AreaFormPopupModel>();
  @Output() canceled = new EventEmitter();

  // typeId?: string = this.areaFormData.type;
  types: IElementaryObjectTypes[] = [
    { id: 1, name: ElementaryObjectTypes.Fairway },
    { id: 2, name: ElementaryObjectTypes.Green },
    { id: 3, name: ElementaryObjectTypes.Teebox }
  ];

  areaPopupForm = this.formBuilder.group({
    uuid: ['', Validators.required],
    parentUuid: [''],
    name: [
      '',
      [Validators.required],
      [projectValidators.areaNameUnique(this.store)]
    ],
    type: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder, private store: Store<AppState>, private translate: TranslateService) {}

  submit = () => {
    this.areaFormData = this.mapFormToModel();
    this.formSubmitted.emit(this.areaFormData);
  }

  delete = () => {
    this.areaFormData = this.mapFormToModel();
    this.areaDeleted.emit(this.areaFormData);
  }

  cancel = () => this.canceled.emit();

  private mapFormToModel = (): AreaFormPopupModel => {
    return {
      uuid: this.areaPopupForm.get('uuid')?.value ?? undefined,
      type: this.areaPopupForm.get('type')?.value ?? undefined,
      parentUuid: this.areaPopupForm.get('parentUuid')?.value ?? undefined,
      name: this.areaPopupForm.get('name')?.value ?? undefined
    };
  }

  getTranslatedAction(): string {
    return this.translate.instant(`COMMON.${this.action.toUpperCase()}`);
  }

  getTranslatedTitle(): string {
    return this.translate.instant(`PROJECT.AREA_POPUP.${this.title.toUpperCase()}`);
  }
}
