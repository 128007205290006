import {ChangeDetectionStrategy, Component} from "@angular/core";

@Component({
    selector: 'app-unauthorized-page',
    template: `
        <section class="not-authorized">
            <h2>ACCESS FORBIDDEN</h2>
            <h5>You are not authorized to perform this action</h5>
            <p-button label="Go to homepage" [routerLink]="['/project']"></p-button>
        </section>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: { 'class': 'content-container padding-container' }
})
export class UnauthorizedPageComponent {}
