<div class="sprinkler-popup-wrapper point-form-popup-wrapper">
	<form [formGroup]="form" class="panel-container" (ngSubmit)="onSubmit()">
		<div class="panel-left">
			<div class="form-group h-100 justify-space-between">
				<div class="form-group">
					<h3 i18n class="pb-25">{{ 'GRASS_PARAMETERS.SPRINKLER.SPRINKLER_DESCR' | translate }}</h3>
					<h1>{{ this.form.controls['number'].value }}</h1>
					<div class="control-label label-info font-size-10" i18n>* {{ 'COMMON.REQUIRED_FIELDS' | translate }}</div>
				</div>
				<div class="control center">
					<p-button [disabled]="form.invalid" label="{{submitButtonTitle()}}" styleClass="p-button-success w-100" type="submit"></p-button>
				</div>
			</div>
		</div>

		<div class="panel-right">
			<div class="panel-header">
				<div class="control right">
					<a href="#" (click)="onClose($event)">
						<i class="pi pi-times"></i>
					</a>
				</div>
			</div>
			<div class="panel-content">
				<div class="control-separator">&nbsp;</div>
				<div class="form-group row gap-25">
					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.SPRINKLERTYPE' | translate }} *</div>
						<div class="control">
							<p-dropdown formControlName="sprinklerType" [options]="sprinklerTypes" optionLabel="name" optionValue="id" [placeholder]="'GRASS_PARAMETERS.SPRINKLER.SPRINKLERTYPE_PLACEHOLDER' | translate" />
						</div>
						<app-error-message propertyName="sprinklerType" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'GRASS_PARAMETERS.SPRINKLER.SPRINKLERTYPE' | translate}" [form]="form" validatorRule="required" />
					</div>

					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.PRODUCER' | translate }}</div>
						<div class="control">
							<input pInputText i18n-text formControlName="producer" class="input style-2" type="text" />
						</div>						
						<app-error-message propertyName="producer" errorMessage="Producer length must not exceed 100 characters" [form]="form" validatorRule="maxlength" />
						<hr />
					</div>
				</div>

				<div *ngIf="shouldShowAreaName()" class="control-separator">&nbsp;</div>
				<div *ngIf="shouldShowAreaName()" class="form-group row gap-25">
					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.PROJECTAREANAME' | translate }} *</div>
						<div class="control">
							<input pInputText i18n-text formControlName="projectAreaName" class="input style-2" type="text" />
						</div>
						<hr />
					</div>

					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.ELEMENTARYOBJECTNAME' | translate }}</div>
						<div class="control">
							<input pInputText i18n-text formControlName="elementaryObjectName" class="input style-2" type="text" />
						</div>						
						<hr />
					</div>
				</div>

				<div class="control-separator">&nbsp;</div>
				<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.NAME' | translate }}</div>
				<div class="control">
					<input pInputText i18n-text formControlName="name" class="input style-2" type="text" />
				</div>
				<app-error-message propertyName="name" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'GRASS_PARAMETERS.SPRINKLER.NAME' | translate}" [form]="form" validatorRule="required" />
				<app-error-message propertyName="name" errorMessage="Name length must not exceed 200 characters" [form]="form" validatorRule="maxlength" />
				<hr />
				<div class="control-separator">&nbsp;</div>

				<div class="form-group row gap-25">
					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.RADIUS' | translate }} ({{measurementUnit$ | async }}) *</div>
						<div class="control">
							<p-inputNumber formControlName="radius" class="input style-2" [maxFractionDigits]="2" />
						</div>
						<app-error-message propertyName="radius" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'GRASS_PARAMETERS.SPRINKLER.RADIUS' | translate}" [form]="form" validatorRule="required" />
						<hr />
					</div>
					<div class="w-50">
						<div class="control-label font-size-10" i18n>{{ 'GRASS_PARAMETERS.SPRINKLER.IRRIGATION' | translate }} ({{irrigationUnit$ | async }}) *</div>
						<div class="control">
							<p-inputNumber formControlName="irrigation" class="input style-2" [maxFractionDigits]="2" />
						</div>
						<app-error-message propertyName="irrigation" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'GRASS_PARAMETERS.SPRINKLER.IRRIGATION' | translate}" [form]="form" validatorRule="required" />
						<hr />
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
