import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class DevGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(): boolean {
    if(environment.production){
      this.router.navigateByUrl('/');
      return false;
    }

    return true
  }
}
