import {createReducer, on} from "@ngrx/store";
import {reportState} from "./types";
import * as reportActions from './actions';

export const initialState: reportState = {
  reports: [],
  isLoading: false
};

export const reportReducer = createReducer(
    initialState,
    on(reportActions.getProjectReport, (state) => ({...state, isLoading: true})),
    on(reportActions.getProjectReportSuccess, (state, action) => {
        let reports;

        if (!state.reports.find(x => x.projectId === action.projectId)) {
            reports = [...state.reports, action];
        } else {
            reports = state.reports.map(r => r.projectId === action.projectId ? action : r);
        }

        return ({...state, reports, isLoading: false});
    }),
    on(reportActions.refreshProjectReport, (state) => ({...state, isLoading: true})),
    on(reportActions.refreshProjectReportSuccess, (state, action) => {
      return {
          ...state,
          reports: state.reports.map(report => {
              if (report.projectId === action.projectId) {
                  return { ...report, config: action.config };
              }
              return report;
          }),
          isLoading: false
      };
  }),
    on(reportActions.handleError, (state) => ({...state, isLoading: false})),
    on(reportActions.clearState, (state) => ({
        ...state,
        reports: []
      }))
);