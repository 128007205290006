import {inject, Injectable, OnDestroy} from '@angular/core';
import {EventData, Map, MapboxGeoJSONFeature, MapMouseEvent, Marker, Popup, RasterSourceImpl} from 'mapbox-gl';
import {environment} from "../../../environments/environment";
import {ContextMenuPopupService} from "./context-menu-popup.service";
import {ParametersMapComposition, PresentationMode, selectableParam} from '../store/grass-params/types';
import {
	deviationsFillLayers,
	parametersFillLayers,
	vegetationFillLayers
} from "../models/mapbox/types";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {selectComposition, selectPresentationMode} from "../store";
import {ItemsPopupService} from "./items-popup.service";
import {grassParamsActions} from "../store/grass-params/actions";
import {PhotoDataSourceType} from "../models/photo-data-source-type";
import {defaultPopupConfig} from "../constants/config";
import {tilesLayersConfig} from "../models/mapbox/tiles-layers.config";
import { mapIncidentActions } from '../store/other-objects/actions';

@Injectable({
  providedIn: 'root'
})
export class GrassParametersMapsService implements OnDestroy {
  private contextMenuPopupService = inject(ContextMenuPopupService);
  private store = inject(Store<AppState>);
  private itemsPopupService = inject(ItemsPopupService);
  private destroy$ = new Subject<void>();

  maps: photoParameterMapDictionary[] = [];
  currentParams: selectableParam[] = [];
  presentationMode: PresentationMode = PresentationMode.Parameters;
	mapComposition: ParametersMapComposition = ParametersMapComposition.Single;

  constructor() {
    this.store.select(selectPresentationMode).pipe(takeUntil(this.destroy$)).subscribe(x => this.presentationMode = x);
		this.store.select(selectComposition).pipe(takeUntil(this.destroy$)).subscribe(x => this.mapComposition = x);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

	removeMapById = (mapId: string) => {
		let map = this.getMapById(mapId)?.map
		map?.remove()
		this.maps = this.maps.filter((obj) => obj.mapId !== mapId) || [];
	}
  getMapById = (mapId: string): photoParameterMapDictionary | undefined => this.maps.find(x => x.mapId === mapId);

  addMap = (mapId: string): Map => {
		const {minZoom, maxZoom, styles, accessToken} = environment.mapbox;

    const map = new Map({
      accessToken,
      style: styles.satelliteV9,
      container: mapId,
      minZoom,
      maxZoom,
      dragRotate: false,
      touchZoomRotate: false
    });

    map.on('click', mouseEvent => {
      this.bindMouseClickEvents(mouseEvent, map);
    })

    const grassTrendsPopup = new Popup(defaultPopupConfig);
    const contextMenuPopup = new Popup({ closeButton: false, closeOnClick: true });

		const loaderMarker = new Marker({
			element: document.createElement('app-map-loader'),
			anchor: 'center'
		});


    this.maps.push({
      mapId,
      map,
      popup: grassTrendsPopup,
      contextMenuPopup,
      synced: false,
      handleZoomend: () => { },
      previousZoom: map.getZoom(),
			loaderMarker
    });

    map.on('contextmenu', mouseEvent => {
      this.bindContextMenuEvents(mouseEvent, map, contextMenuPopup, mapId);
    });

    return map;
  }

  handleZoomGranulations(dataSourceType: PhotoDataSourceType) {
		if (dataSourceType === PhotoDataSourceType.Satellite && environment.disableSatelliteHighZoom) {
			return;
		}

    this.maps.forEach(mapInfo => {
      mapInfo.previousZoom = mapInfo.map.getZoom();
      mapInfo.map.off('zoomend', mapInfo.handleZoomend);

      mapInfo.handleZoomend = () => {

        const currentZoom = mapInfo.map.getZoom();
        if (currentZoom >= 15 && currentZoom < 19 && (mapInfo.previousZoom < 15 || mapInfo.previousZoom >= 19)) {
          this.store.dispatch(grassParamsActions.changeZoom({ mapId: mapInfo.mapId, zoomValue: environment.hexZoomLow }));
        } else if (currentZoom >= 19 && currentZoom <= 22.5 && (mapInfo.previousZoom < 19 || mapInfo.previousZoom > 22.5)) {
          this.store.dispatch(grassParamsActions.changeZoom({ mapId: mapInfo.mapId, zoomValue: environment.hexZoomHigh }));
        }

        mapInfo.previousZoom = currentZoom;
      };

      mapInfo.map.on('zoomend', mapInfo.handleZoomend);
    });
  }

  hideContextMenu = (mapId: string) => {
    const contextMenu = this.maps.find(x => x.mapId === mapId)?.contextMenuPopup;
    contextMenu?.remove();
  }

  bindParameterPopupEventsAllMaps = (selectedParams: selectableParam[]) => {
    this.currentParams = selectedParams;

		[...parametersFillLayers, ...vegetationFillLayers, ...deviationsFillLayers].forEach(layer => {
			this.maps.forEach(({ map, mapId }) => {
				map.on('mousemove', layer, mouseEvent => {
					const {features} = mouseEvent;

					map.getCanvas().style.cursor = 'pointer';

					if (!features || features.length === 0) {
						return;
					}

					const geoJsonProperties = features[0].properties;
					const hexId = geoJsonProperties === null ? 0 : Number(geoJsonProperties['id']);

					this.store.dispatch(grassParamsActions.selectHexOnMap({hexId}));
					this.store.dispatch(grassParamsActions.setDebugModeValues({geoJsonProperties}));
				});
				map.on('mouseleave', layer, () => {
					map.getCanvas().style.cursor = '';
					this.store.dispatch(grassParamsActions.resetSelectedHex());
					this.store.dispatch(grassParamsActions.resetDebugModeValues());
				});
			});
		});
  }

  resetSyncedProperty = () => {
    this.maps.forEach(map => {
			if (map.syncedCleanupFunction) {
				map.syncedCleanupFunction();
			}

      map.synced = false;
			map.syncedCleanupFunction = undefined;
    });
  };

  setDate = (mapId: string, date: Date) => this.getMapById(mapId)!.date = date;

  private bindContextMenuEvents(mouseEvent: MapMouseEvent & EventData, map: Map, contextMenuPopup: Popup, mapId: string) {
    const features = this.contextMenuPopupService.getItemFeaturesInPoint(mouseEvent.point, map);

    if (features.length === 0) {
      this.showContextMenu({ mouseEvent, contextMenuPopup, mapId, map });
    } else {
      //// TODO: Tu jest zgłoszony bug kiedy itemy nachodzą na siebie nie da sie wybrac konkretnego
      const properties = features[0].properties;
      const item = { itemType: properties!['itemType'], itemId: properties!['itemId'] };
      if (item.itemType === 'Sprinkler' || this.presentationMode === PresentationMode.OtherObjects) {
        this.showContextMenu({ mouseEvent, contextMenuPopup, mapId, map, item });
      }
    }
  }

  private bindMouseClickEvents(mouseEvent: MapMouseEvent & EventData, map: Map) {
    const features = this.contextMenuPopupService.getItemFeaturesInPoint(mouseEvent.point, map);

    if (features.length === 0 || features[0].properties!['movingState']) {
      return;
    }

    const properties = features[0].properties;
    const itemId = properties!['itemId'];
    const itemType = properties!['itemType'];

    if (itemType === 'LaboratoryExamination') {
      this.itemsPopupService.showLaboratoryExaminationDetailsPopup({ itemId });
    }

    if (itemType === 'Incident') {
      this.store.dispatch(mapIncidentActions.showEditIncidentPage({ itemId }));
    }
  }

  syncWithOtherMaps(mapId: string) {
		const map = this.getMapById(mapId)?.map;
    const syncMaps = require('@mapbox/mapbox-gl-sync-move');

    if (this.maps.length > 1) {
      const filteredMaps = this.maps.filter((currentMap) => currentMap.map !== map && !currentMap.synced);
      filteredMaps.forEach((currentMap) => {
        currentMap.syncedCleanupFunction = syncMaps(map, currentMap.map);
        currentMap.synced = true;
      });

      const firstMap = this.maps[0].map;
      const zoom = firstMap.getZoom();
      const center = firstMap.getCenter();
      this.maps.forEach(({ map }) => {
        map.setCenter(center);
        map.setZoom(zoom);
        setTimeout(() => {
          map.resize();
        }, 100);
      });
    }
  }

  private showContextMenu({ mouseEvent, contextMenuPopup, mapId, map, item }: ShowContextMenuPopup) {
    if (!contextMenuPopup) {
      throw 'Argument contextMenuPopup cannot be null';
    }

    this.contextMenuPopupService.showContextMenu(contextMenuPopup, mouseEvent.lngLat, mapId, item);
    contextMenuPopup.addTo(map);
  }

	setBackgroundStyle(map: Map, bbox: number[], tilesUrl: string, config: tilesLayersConfig) {
		const {minZoom, maxZoom} = environment.mapbox;
		const {dataSourceName, layerName, afterLayerId} = config;

		const existingDataSource = map.getSource(dataSourceName) as RasterSourceImpl;



		if(existingDataSource) {
			const tilesExist = existingDataSource?.tiles?.includes(tilesUrl) ?? false
			if(!tilesExist) {
				existingDataSource.setTiles([tilesUrl])
			}
			return;
		}

		map.addSource(dataSourceName, {
			type: 'raster',
			tiles: [
				tilesUrl
			],
			bounds: bbox,
			tileSize: 256,
			minzoom: minZoom,
			maxzoom: 20,
			scheme: "tms"
		});

		const loaderLayer = map.getStyle().layers.find(l => l.id == afterLayerId)

		if(loaderLayer){

			map.addLayer({
				'id': layerName,
				'source': dataSourceName,
				'type': 'raster'
			}, loaderLayer.id);
			//because moveAfter doesn't exist
			map.moveLayer(loaderLayer.id, layerName)
		}else {

			map.addLayer({
				'id': layerName,
				'source': dataSourceName,
				'type': 'raster'
			});

		}
	}

}

interface ShowContextMenuPopup {
  mouseEvent: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData;
  contextMenuPopup: Popup | null;
  mapId: string;
  map: Map;
  item?: { itemType?: string, itemId?: number };
}

interface ShowParamValuePopupParams {
  mouseEvent: MapMouseEvent & { features?: MapboxGeoJSONFeature[] | undefined } & EventData;
  selectedParams: selectableParam[];
}
interface photoParameterMapDictionary {
  mapId: string,
  map: Map,
  popup: Popup | null,
  contextMenuPopup: Popup | null,
  date?: Date,
  synced: boolean,
	syncedCleanupFunction?: any,
  previousZoom: number,
  handleZoomend: () => void;
	loaderMarker: Marker | null;
}
