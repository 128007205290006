import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private baseUrl: string = environment.projectApiUrl;

    constructor(private http: HttpClient) {
    }

    public getEmbeddedReportConfig = (projectId: number): Observable<any> =>
        this.http.get(`${this.baseUrl}projects/${projectId}/power-bi/report`);
}