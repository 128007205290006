import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {taskActions, taskBoardActions, taskCalendarActions} from "./actions";
import {catchError, exhaustMap, map, of, tap, withLatestFrom} from "rxjs";
import {selectAvailableTaskUsers, selectEditedTaskBoardItemId, selectStatusSwitchConfig} from "./selectors";
import {TaskStatusChangeStrategyService} from "../task-board/services/task-status-change-strategy.service";
import {LoadPanelService} from "../../shared/services/load-panel.service";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import { TaskService } from "../task.service";

@Injectable()
export class TaskBoardEffects {
	private actions$ = inject(Actions);
	private store = inject(Store<AppState>);
	private taskStatusChangeStrategy = inject(TaskStatusChangeStrategyService);
	private loadPanelService = inject(LoadPanelService);
	private messageService = inject(AgroMessageService);
	private taskService = inject(TaskService);

	completeStatusChange$ = createEffect(() => this.actions$.pipe(
		ofType(taskBoardActions.completeStatusChange),
		withLatestFrom(
			this.store.select(selectStatusSwitchConfig),
			this.store.select(selectEditedTaskBoardItemId),
			this.store.select(selectAvailableTaskUsers)
		),
		exhaustMap(([{dueDate, assignedUserId}, switchConfig, taskId, availableUsers]) =>
			this.taskStatusChangeStrategy.completeStatusChange(switchConfig!.action, taskId!, dueDate, assignedUserId).pipe(
				map((response: baseResponse) => {
					if (!response.success) {
						this.messageService.displayErrorMessage('Error changing status', response.error);
						return taskBoardActions.cancelStatusChange();
					}

					let assignedUser;

					if (assignedUserId) {
						const {name} =  availableUsers.find(x => x.id === assignedUserId)!;
						assignedUser = {id: assignedUserId, name};
					}

					return taskBoardActions.completeStatusChangeSuccess({dueDate, assignedUser});
				}),
				catchError(() => of(taskBoardActions.cancelStatusChange()))
			)
		)
	));

	rescheduleTask$ = createEffect(() => this.actions$.pipe(
		ofType(taskCalendarActions.rescheduleTask),
		exhaustMap(({taskId, dueDate}) =>
			this.taskService.rescheduleTask(taskId, dueDate).pipe(
				map(response => {
					if (response.success) {
						return taskCalendarActions.rescheduleTaskSuccess({taskId, dueDate});
					}

					return taskCalendarActions.rescheduleTaskFailure({errorMessage: response.error});
				})
			)
		)
	));

	rescheduleTaskFailure$ = createEffect(() => this.actions$.pipe(
		ofType(taskCalendarActions.rescheduleTaskFailure),
		tap(({errorMessage}) => this.messageService.displayErrorMessage(errorMessage, errorMessage))
	), {dispatch: false})

	showLoadPanel$ = createEffect(() => this.actions$.pipe(
		ofType(taskBoardActions.completeStatusChange, taskBoardActions.getTaskBoardTasks, taskCalendarActions.rescheduleTask),
		tap(() => this.loadPanelService.showLoadPanel())
	), {dispatch: false});

	hideLoadPanel$ = createEffect(() => this.actions$.pipe(
		ofType(taskBoardActions.completeStatusChangeSuccess, taskBoardActions.cancelStatusChange, taskActions.loadTasksSuccess, taskCalendarActions.rescheduleTaskSuccess, taskCalendarActions.rescheduleTaskFailure),
		tap(() => this.loadPanelService.hideLoadPanel())
	), { dispatch: false });
}
