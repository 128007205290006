import { Component, Input, inject } from "@angular/core";
import { ATaskHeaderDTO } from "src/app/task/models/ATaskHeaderDTO";
import { ConfirmationService } from "primeng/api";
import { AppState } from "src/app/types/app-state";
import { Store } from "@ngrx/store";
import { incidentActions } from "../../../../incident/store/actions";
import { selectedProjectIdSelector } from "src/app/project/project-store/selectors";
import { TaskService } from "src/app/task/task.service";

@Component({
    selector: 'app-related-tasks',
    templateUrl: './related-tasks.component.html',
})
export class RelatedTasksComponent {
    private store = inject(Store<AppState>);
    private confirmationService = inject(ConfirmationService);
    @Input()
    incidentId?: string;
    @Input()
    taskHeaders: ATaskHeaderDTO[] = [];
    projectId$ = this.store.select(selectedProjectIdSelector);
    taskService = inject(TaskService);

    relatedTaskDeleteClick(task: ATaskHeaderDTO) {
        this.confirmationService.confirm({
            header: 'Confirmation',
            message: 'Are you sure to delete this related task?',
            // icon: 'pi pi-exclamation-triangle',
						acceptButtonStyleClass: 'p-button-success hide-icon',
						rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
            accept: () => this.store.dispatch(incidentActions.removeTaskFromIncident({ incidentId: this.incidentId!, taskId: task.id })),
            reject: () => this.confirmationService.close()
        })
    }

}
