import { Injectable } from '@angular/core';
import {Message, MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class AgroMessageService {
  constructor(private messageService: MessageService) { }

  displaySuccessMessage = (summary: string, detail: string) => {
    const message: Message = {
      summary: summary,
      detail: detail,
      severity: 'success',
			icon: 'check-circle-icon',
    };

    this.messageService.add(message);
  }

  displayErrorMessage = (summary: string, detail: string) => {
    const message: Message = {
      summary: summary,
      detail: detail,
      severity: 'error'
    };

    this.messageService.add(message);
  }

  displayWarningMessage = (summary: string, detail: string) => {
    const message: Message = {
      summary: summary,
      detail: detail,
      severity: 'warn'
    };

    this.messageService.add(message);
  }

  displayInfoMessage = (summary: string, detail: string) => {
    const message: Message = {
      summary: summary,
      detail: detail,
      severity: 'info'
    };

    this.messageService.add(message);
  }

  displayErrorMessages = (errors: string[]) => {
    const messages: Message[] = errors.map(error => {
      return {
        detail: error,
        summary: 'Error',
        severity: 'error'
      }
    });

    this.messageService.addAll(messages);
  }
}
