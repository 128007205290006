import {
	BackgroundTilesDataLayer,
	BackgroundTilesDataSource,
	MapboxSatelliteLayerId,
	TilesDataSourceType
} from "./types";
import {tilesFilesDto} from "../types";

export interface tilesLayersConfig  {
	type: TilesDataSourceType;
	dataSourceName: BackgroundTilesDataSource;
	layerName: BackgroundTilesDataLayer;
	afterLayerId: string;
}

export const tilesLayersConfig: tilesLayersConfig[] = [
	{
		type: TilesDataSourceType.HighResolutionRgb,
		dataSourceName: BackgroundTilesDataSource.HighResolutionRgb,
		layerName: BackgroundTilesDataLayer.HighResolution,
		afterLayerId: MapboxSatelliteLayerId,
	}
];

export interface TilesFiles {
	kind: TilesDataSourceType,
	url: string;
}
