import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-examination-date-form',
  template: `
		<section class="laboratory-examination-popup-date-step-wrapper no-paddings">
			<p-calendar [inline]="true" (onSelect)="onDateSelected()" [(ngModel)]="selectedDate" [maxDate]="today"
									class="dynamicdialog-calendary"/>
		</section>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExaminationDateFormComponent {
  @Output() examinationDateSelected = new EventEmitter<Date>();
  @Input() selectedDate?: Date;

	today: Date = new Date();

  onDateSelected = () => this.examinationDateSelected.emit(this.selectedDate);
}
