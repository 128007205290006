import {Component, inject, Input} from "@angular/core";
import {AppState} from "../../../../../types/app-state";
import {createSelector, Store} from "@ngrx/store";
import {chatGtpMessage} from "./ask-ai-api.service";
import {
	selectAiAllQuestionAsked, selectAiClarificationNeeded,
	selectAiFirstQuestionAsked, selectAiInteractionFinished,
	selectAiNoneQuestionsAsked,
	selectChatGptAnswerLoading,
	selectChatGptMessages, selectStreamedChatMessage
} from "../../../../../incident/store/selectors";
import {askAiActions, incidentActions} from "../../../../../incident/store/actions";
import {selectUserFullName} from "../../../../../profile/store";
import {AuthService} from "../../../../services/auth.service";
import {environment} from "../../../../../../environments/environment";

@Component({
	selector: 'app-ask-ai',
	template: `
		<ng-container *ngIf="vm$ | async as vm">
			<app-ask-ai-welcome-page *ngIf="vm.noneQuestionAsked" (startAiInteractionClicked)="startAiInteraction(vm.currentAuthor)"/>
			<app-ask-ai-chat *ngIf="!vm.noneQuestionAsked" [messages]="vm.messages" [chatGptAnswerLoading]="vm.chatGptAnswerLoading" [currentAuthor]="vm.currentAuthor" [aiInteractionFinished]="vm.interactionFinished" [allQuestionAsked]="vm.allQuestionAsked" [clarificationNeeded]="vm.clarificationNeeded" [firstQuestionAsked]="vm.firstQuestionAsked"
											 (chatGptQuestionAsked)="askChatGpt($event)" (aiInteractionFinishedClicked)="finishAiInteraction()" (clarificationNeededClick)="clarificationNeeded()" [streamedChatMessage]="vm.streamedChatMessage" />
		</ng-container>
	`
})
export class AskAiComponent {
	private store = inject(Store<AppState>);
	readonly vm$ = this.store.select(askAiComponentVmSelector);

	private authService = inject(AuthService);

	@Input() incidentId!: string;

	startAiInteraction = (author: string) => {
		this.store.dispatch(askAiActions.startAiInteraction({author}));
		this.loadStreamedAiResponse('');
	};

	clarificationNeeded = () => this.store.dispatch(askAiActions.clarificationNeeded());

	finishAiInteraction = () => this.store.dispatch(askAiActions.finishAiInteraction());

	askChatGpt = (message: { question: string, author: string }) => {
		this.store.dispatch(askAiActions.askAI({question: message.question, author: message.author}));
		this.loadStreamedAiResponse(message.question);
	};

	loadStreamedAiResponse = (question: string) => {
		const token = this.authService.getIdToken();
		const that = this;

		const payload = {
			question
		};

		fetch(`${environment.projectApiUrl}incidents/${this.incidentId}/askAI`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/stream+json',
				Authorization: `Bearer ${token}`
			},
			body: JSON.stringify(payload)
		}).then(response => {
			if (response.body === null) {
				throw new Error('Something went wrong');
			}

			const reader = response.body.getReader();
			const decoder = new TextDecoder();

			return new ReadableStream({
				async start(controller) {
					while (true) {
						const { done, value } = await reader.read();
						if (done) {
							that.store.dispatch(askAiActions.askAISuccess({}));
							break;
						}
						controller.enqueue(value);
						const chunk = (decoder.decode(value)).slice(2, -1).replace(/\\n/g, '\n');
						that.store.dispatch(incidentActions.updateStreamedMessage({chunk}));
					}
					controller.close();
					reader.releaseLock();
				}
			});
		})
	}
}

interface askAiComponentVm {
	noneQuestionAsked: boolean;
	firstQuestionAsked: boolean;
	allQuestionAsked: boolean;
	clarificationNeeded: boolean;
	interactionFinished: boolean;
	messages: chatGtpMessage[];
	chatGptAnswerLoading: boolean;
	currentAuthor: string;
	streamedChatMessage: string;
}

const askAiComponentVmSelector = createSelector(selectChatGptMessages, selectAiNoneQuestionsAsked, selectAiFirstQuestionAsked, selectAiAllQuestionAsked, selectAiClarificationNeeded, selectAiInteractionFinished, selectChatGptAnswerLoading, selectUserFullName, selectStreamedChatMessage,
	(messages, noneQuestionAsked, firstQuestionAsked, allQuestionAsked, clarificationNeeded, interactionFinished, chatGptAnswerLoading, currentAuthor, streamedChatMessage): askAiComponentVm => ({
	noneQuestionAsked,
	firstQuestionAsked,
	allQuestionAsked,
	clarificationNeeded,
	interactionFinished,
	messages,
	chatGptAnswerLoading,
	currentAuthor,
	streamedChatMessage
}));

