import {environment} from '../../../environments/environment';
import {OrganizationInterface} from '../../profile/types/user-types';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserOrganizationService {
  private baseUrl:string = environment.userApiUrl;
  constructor(private http: HttpClient) { }
  getOrganizationsForOwner = (email?: string): Observable<OrganizationInterface[]> => this.http.get<OrganizationInterface[]>(`${this.baseUrl}organizationsForOwner/${email}`);
  getOrganizationsForUser = (email?: string): Observable<OrganizationInterface[]> => this.http.get<OrganizationInterface[]>(`${this.baseUrl}organizationsForUser/${email}`);
}


