import {Injectable} from '@angular/core';
import {AreaFormPopupModel} from "../model/area-form-popup-model";
import {AreaMapboxModel} from "../model/area-mapbox-model";
import {Geometry} from "geojson";
import * as projectUtils from "../utils/project-utils";
import * as geometryUtils from "../../shared/utils/geometry-utils";
import {MapboxDrawService} from "../../shared/services/mapbox-draw.service";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import {AreaSelectionState} from "../enums/area-selection-state";

@Injectable({
  providedIn: 'root'
})
export class ManageAreasService {

  constructor(private mapboxDrawService: MapboxDrawService, private store: Store<AppState>, private messageService: AgroMessageService) { }

  validateElementaryObjectPolygon = (elementaryObjectForm: AreaFormPopupModel, drawnAreas: AreaMapboxModel[], drawnElementaryObjectGeometry: Geometry) => {
    const isElementaryObject = elementaryObjectForm.parentUuid || (elementaryObjectForm.type && projectUtils.isKnownElementaryObjectType(elementaryObjectForm.type));

    if (!isElementaryObject) {
      return true;
    }

    const parentArea = drawnAreas.find(x => x.uuid === elementaryObjectForm.parentUuid || x.name === elementaryObjectForm.name);

    if (!parentArea) {
      return false;
    }

    return geometryUtils.isGeometryContained(parentArea.polygon, drawnElementaryObjectGeometry);
  }

  validateAreaPolygon = (areaFormPopupModel: AreaFormPopupModel, drawnAreas: AreaMapboxModel[], drawnAreaGeometry: Geometry) => {
    const validatedArea = drawnAreas.find(x => x.uuid === areaFormPopupModel.uuid);
    return validatedArea && !validatedArea.elementaryObjects?.some(eo => !geometryUtils.isGeometryContained(drawnAreaGeometry, eo.polygon));
  }

  setFormPropertiesFromSelectedPolygon = (areaForm: AreaFormPopupModel): AreaFormPopupModel => {
    return {
      ...areaForm,
      uuid: this.mapboxDrawService.getSelectedPolygonUuidProperty(),
      name: this.mapboxDrawService.getSelectedPolygonNameProperty(),
      type: this.mapboxDrawService.getSelectedPolygonTypeProperty()
    };
  }

  getSelectionState = (features: any, isAddAreaPopupVisible: boolean, isEditAreaPopupVisible: boolean): AreaSelectionState => {
    if (features.length > 0 && !isAddAreaPopupVisible) {
      return AreaSelectionState.Selected;
    }

    if (features.length === 0 && isEditAreaPopupVisible) {
      return AreaSelectionState.EditingFinished;
    }

    if (features.length === 0 && isAddAreaPopupVisible) {
      return AreaSelectionState.AddingFinished;
    }

    if (features.length > 0 && isAddAreaPopupVisible) {
      return AreaSelectionState.AddingStarted;
    }

    return AreaSelectionState.Unknown;
  }
  setSelectedPolygonProperties = (form: AreaFormPopupModel) => {
    this.mapboxDrawService.setSelectedPolygonUuidProperty(form.uuid!);
    this.mapboxDrawService.setSelectedPolygonNameProperty(form.name!);
    this.mapboxDrawService.setSelectedPolygonTypeProperty(form.type!);
  }
}
