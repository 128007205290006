import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from "../../../types/app-state";
import * as projectSelectors from "../../../project/project-store/selectors";
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-selected-project',
  // <p-button icon="pi pi-angle-left" styleClass="p-button-outlined p-button-secondary" class="square-46">Back</p-button>
  template:
  `
    <div class="selected-project w-100">
      <div class="title w-100" *ngIf="selectedProjectName$ | async as projectName">
        <div class="form-group row gap-15 align-center">
          <p-button [label]="'COMMON.BACK' | translate" icon="pi pi-angle-left" styleClass="p-button-outlined p-button-secondary p-button-back"
              (onClick)="GoBackClick()"></p-button>
          <h2 class="text-dotted">{{ projectName }}</h2>
        </div>
      </div>
    </div>
  `,
  styles: [
   `
     .selected-project {
       height: 100%;
       display: flex;
       align-items: center;
     }
   `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavSelectedProjectComponent {
  private store = inject(Store<AppState>);
  private location = inject(Location);
  readonly selectedProjectName$ = this.store.select(projectSelectors.selectedProjectNameSelector);

  GoBackClick() {
    this.location.back();
  }

}
