import { Injectable, Type } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OneAtlasDownloadComponent } from '../upload-photos/one-atlas-download/one-atlas-download.component';
import { OneAtlasPredictionComponent } from '../upload-photos/one-atlas-prediction/one-atlas-prediction.component';
import { OneAtlasPredictionInterface } from '../one-atlas-store/types';

@Injectable({
    providedIn: 'root'
})
export class OneAtlasPopupService {
    private dialogOptions = { 
        showHeader: true, 
        header: 'AIRBUS OneAtlas satellite imagery',
        modal: true, 
        width: "auto", 
        height: "auto"
    };

    public showOneAtlasPopup = (dialogService: DialogService) : DynamicDialogRef | null => {
        const dialog = dialogService.open(OneAtlasDownloadComponent, { ...this.dialogOptions, header: 'AIRBUS OneAtlas satellite imagery', data: {} });
        let dialogRef = dialogService.dialogComponentRefMap.get(dialog)!;
        dialogRef.changeDetectorRef.detectChanges();
        const instance: OneAtlasDownloadComponent = dialogRef.instance.componentRef.instance;
        instance.selected.subscribe(() => { dialog.close(); });
        return dialog;
    }

    public showOneAtlasPredictionPopup = (dialogService: DialogService, model: OneAtlasPredictionInterface): DynamicDialogRef | null => {
        const dialog = dialogService.open(OneAtlasPredictionComponent, { ...this.dialogOptions, header: 'AIRBUS OneAtlas prediction', data: model });
        return dialog;
    }
}