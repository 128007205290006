import {Component, inject, OnInit} from '@angular/core';
import {MenuItem} from "primeng/api";
import {createSelector, Store} from "@ngrx/store";
import {selectUserPermittedFeatures} from "../../../../profile/store";
import {
	organizationIdSelector,
	organizationsSelector,
	selectedProjectIdSelector
} from "../../../../project/project-store/selectors";
import {
	isAccessToApplicationFeaturePermitted,
	isAccessToOrganizationFeaturePermitted, isAccessToProjectFeaturePermitted
} from "../../../../shared/utils/feature-authorization-utils";
import {FeatureName} from "../../../../admin/types";
import {AppState} from "../../../../types/app-state";
import {Observable, combineLatest, startWith, switchMap, map} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-admin-menu',
  template: `
		<ng-container *ngIf="vm$ | async as vm">
			<div *ngIf="vm.items.length > 0">
				<p-menu #adminActions [popup]="true" [model]="vm.items" />
				<a class="icon-element size-20 relative" (click)="adminActions.toggle($event)">
					<svg-icon src="./assets/images/icons/setting.svg" />
				</a>
			</div>
		</ng-container>
	`
})
export class AdminMenuComponent implements OnInit {
	private store = inject(Store<AppState>);
	private translateService = inject(TranslateService);

	vm$?: Observable<adminMenuComponentVm>;

	ngOnInit() {
		this.vm$ = combineLatest([
			this.store.select(adminMenuComponentVmSelector),
			this.translateService.onLangChange.pipe(
				startWith({lang: this.translateService.currentLang}),
				switchMap(() => this.translateService.get('NAV.ADMIN_ACTIONS'))
			)
		]).pipe(
			map(([vm, translations]) => ({
				...vm,
				items: vm.items.map((item) => ({...item, label: translations[item.state!['translationKey']]}))
			})),
		)
	}
}

interface adminMenuComponentVm {
	items: MenuItem[];
}

const adminMenuComponentVmSelector = createSelector(selectUserPermittedFeatures, organizationIdSelector, selectedProjectIdSelector, organizationsSelector, (features, organizationId, projectId, organizations): adminMenuComponentVm => {
	const items: MenuItem[] = [
		{
			visible: organizations.map(({id}) => id).some(organizationId => isAccessToApplicationFeaturePermitted(FeatureName.ReadManageApplicationData, features)),
			routerLink: ['/admin/application/users'],
			state: { translationKey: "APPLICATION" }
		},
		{
			visible: organizations.map(({id}) => id).some(organizationId => isAccessToOrganizationFeaturePermitted(organizationId!, FeatureName.ManageOrganizationUsers, features)),
			routerLink: ['/admin/organizations'],
			state: { translationKey: "ORGANIZATIONS" }
		},
		{
			// todo make 'Projects' admin page or add 'Manage projects' tab in manageOrganization page
			visible: isAccessToProjectFeaturePermitted(projectId!, organizationId!, FeatureName.ManageGrassNorms, features),
			disabled: !projectId,
			routerLink: ['/photoParameters', projectId!, 'editNorms'],
			state: { translationKey: "PARAMETER_NORMS" }
		}
	];

	return { items };
})
