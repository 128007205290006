import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { SignalrService } from "../../../shared/services/signalr.service";
import { FeatureName } from "../../../admin/types";
import { PrimeIcons, PrimeNGConfig } from "primeng/api";
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';

@Component({
  selector: 'app-nav-user-panel',
  template:
    `
      <div class="app-nav-user">
				<div class="form-group row gap-30 align-center">
<!--					<a class="icon-element size-20 relative" *appCheckOrganizationListPermission="FeatureName.ManageOrganizationUsers" [routerLink]="['/admin/organizations']">-->
<!--						<svg-icon src="./assets/images/icons/setting.svg" />-->
<!--					</a>-->
					<app-admin-menu />

          <select #langSelect (change)="onLanguageChange(langSelect.value)">
            <option *ngFor="let lang of translate.getLangs()" [value]="lang" [selected]="lang === currentLang">
              {{ lang }}
            </option>
          </select>

					<!-- <app-user-notifications *appCheckProjectFeaturePermission="FeatureName.UploadPhoto" [notificationCounter]="(notificationCounter$ | async)!" /> -->
					<app-user-actions (userLoggedOut)="logOut()" />
				</div>
      </div>

  `,
  styleUrls: ['./nav-user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavUserPanelComponent implements OnInit {
  private authService = inject(AuthService);
  private signalrService = inject(SignalrService);
  translate = inject(TranslateService);
  private config = inject(PrimeNGConfig);

  notificationCounter$ = this.signalrService.notificationCounter$;
  currentLang: string = this.translate.currentLang;

  logOut = () => this.authService.logout();

  ngOnInit(): void {
    const langFromStorage = localStorage.getItem('lang');
    if (langFromStorage && this.translate.getLangs().includes(langFromStorage)) {
      this.setCurrentLanguage(langFromStorage);
    } else {
      const defaultLang = environment.defaultLang;
      this.translate.setDefaultLang(defaultLang);
      this.setCurrentLanguage(defaultLang);
    }
  }

  onLanguageChange(selectedLang: string): void {
    this.setCurrentLanguage(selectedLang);

    localStorage.setItem('lang', selectedLang);
  }

  setCurrentLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLang = lang;

    // translations from: https://github.com/primefaces/primelocale
    this.translate.get('PRIMENG').pipe(take(1)).subscribe(res => {
      this.config.setTranslation(res);
    });
  }

  protected readonly FeatureName = FeatureName;
  protected readonly PrimeIcons = PrimeIcons;
}
