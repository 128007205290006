import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  LaboratoryExaminationPopupComponentStore
} from "./laboratory-examination-popup-component.store";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {
  selectLaboratoryExaminationParameterEvent,
} from "./laboratory-examination-parameters-form/laboratory-examination-parameters-form.component";
import {
	changeUnitProps,
	laboratoryExaminationPopupStep,
	selectParameterProps,
} from "./types";
import {ItemsPopupService} from "../../../services/items-popup.service";

@Component({
  template: `
    <p-button class="laboratory-object-popup-close-button" styleClass="p-button-text" icon="pi pi-times" (onClick)="closePopup()"></p-button>
    <div class="laboratory-examination-popup-wrapper" *ngIf="vm$ | async as vm">
      <h3 class="laboratory-examination-popup-header">{{vm.currentStepTitle}}</h3>
      <app-laboratory-examination-type-form 
        *ngIf="vm.currentStep === laboratoryExaminationPopupStep.LaboratoryExaminationType && !vm.editMode"
        (examinationTypeSelected)="selectExaminationType($event)"
        [selectType]="vm.examinationType"
      />
      <app-laboratory-examination-parameters-form
       *ngIf="vm.currentStep === laboratoryExaminationPopupStep.ExaminationParameters || vm.editMode"
        [parameters]="vm.chemicalParametersList"
        [selectedParameter]="vm.selectedParameterVm"
        (examinationValueUpdated)="updateExaminationValue($event)"
				(unitChanged)="updateUnit($event)"
        (parameterSelected)="selectParameter($event)"
      />
      <app-examination-date-form
        *ngIf="vm.currentStep === laboratoryExaminationPopupStep.DateOfExamination && !vm.editMode"
        (examinationDateSelected)="selectExaminationDate($event)"
        [selectedDate]="vm.examinationDate"
      />
      <app-laboratory-examination-popup-footer
        [editMode]="vm.editMode"
        [currentStep]="vm.currentStep"
        [canFormBeSubmitted]="vm.canFormBeSubmitted"
        (backClicked)="goToPreviousStep(vm.currentStep)"
        (nextClicked)="goToNextStep(vm.currentStep)"
        (submitClicked)="onSubmitForm()"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LaboratoryExaminationPopupComponentStore]
})
export class LaboratoryExaminationPopupComponent implements OnInit {
  ngOnInit(): void {
    if (this.itemId && this.itemId > 0) {
      this.componentStore.initEditForm(this.itemId);
    }
  }

  private componentStore = inject(LaboratoryExaminationPopupComponentStore);
  private config = inject(DynamicDialogConfig);
  private itemsPopupService = inject(ItemsPopupService);

  readonly vm$ = this.componentStore.vm$;
  private itemId?: number = this.config.data.editedItemId;
  private gps = this.config.data.gps;

  selectParameter = (event: selectLaboratoryExaminationParameterEvent) => this.componentStore.selectParameter(event as selectParameterProps);

  updateExaminationValue = (value: number | null) => this.componentStore.updateSelectedParameterPropertyAction(value);

	updateUnit = (event: changeUnitProps) => this.componentStore.changeUnit(event);

  selectExaminationDate = (date: Date) => this.componentStore.changeDateAction(date);

  selectExaminationType = (type: number) => this.componentStore.changeType(type);

  closePopup = () => this.itemsPopupService.destroyDialogIfOpen();

  goToPreviousStep = (step: laboratoryExaminationPopupStep) => {
    if (step === laboratoryExaminationPopupStep.DateOfExamination) {
      this.componentStore.changeStepAction(laboratoryExaminationPopupStep.ExaminationParameters);
      return;
    }

    if (step === laboratoryExaminationPopupStep.ExaminationParameters) {
      this.componentStore.changeStepAction(laboratoryExaminationPopupStep.LaboratoryExaminationType);
      return;
    }
  };

  goToNextStep = (step: laboratoryExaminationPopupStep) => {
      if (step === laboratoryExaminationPopupStep.LaboratoryExaminationType) {
        this.componentStore.changeStepAction(laboratoryExaminationPopupStep.ExaminationParameters);
        return;
      }
  
      if (step === laboratoryExaminationPopupStep.ExaminationParameters) {
        this.componentStore.changeStepAction(laboratoryExaminationPopupStep.DateOfExamination);
        return;
      }
  }

  onSubmitForm = () => {
    if (this.itemId && this.itemId > 0) {
      this.componentStore.submitEditForm(this.itemId);
    } else {
      this.componentStore.submitAddForm(this.gps);
    }

    this.itemsPopupService.destroyDialogIfOpen();
  }

  protected readonly laboratoryExaminationPopupStep = laboratoryExaminationPopupStep;
}
