import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ProjectService} from "../services/project.service";
import * as projectActions from './actions'
import * as projectSelectors from "../project-store/selectors";
import {catchError, exhaustMap, map, mergeMap, of, switchMap, tap, withLatestFrom} from "rxjs";
import {AddProjectInterface, EditProjectInterface, GetAreaInfoByGpsInterface, ProjectInterface} from "../model/project.interface";
import {UserOrganizationService} from "../../shared/services/userOrganization.service";
import {IGolfService} from "../../shared/services/i-golf.service";
import {AddProjectDto} from "../DTO/add-project-dto";
import {Router} from "@angular/router";
import {LoadPanelService} from "../../shared/services/load-panel.service";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import * as chooseAreaSelectors from "../choose-area/choose-area-store/selectors";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {manageProjectAreaDto} from "../DTO/manage-project-area-dto";
import {EditProjectDto} from "../DTO/edit-project-dto";
import { baseResponse } from "src/app/grass-parameters-presentation/DTO/base-response";
import { incidentActions } from "src/app/incident/store/actions";

@Injectable()
export class ProjectEffects {
  constructor(private actions$: Actions,
              private projectService: ProjectService,
              private organizationsService: UserOrganizationService,
              private iGolfService: IGolfService,
              private router: Router,
              private loadPanelService: LoadPanelService,
              private messageService: AgroMessageService,
              private store: Store<AppState>) {}

  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectActions.getProjects),
      map(action => action.organizationId),
      switchMap(organizationId => {
        return this.projectService.getProjects(organizationId).pipe(
          map((projects: ProjectInterface[]) => projectActions.getProjectsSuccess({projects: projects})),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(projectActions.handleError({error: error.message}));
          })
        )
      })
    )
  );

  createProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(projectActions.createNewProject),
      withLatestFrom(this.store.select(chooseAreaSelectors.drawnAreasSelector)),
      mergeMap(([props, drawnAreas]) => {
        const newAreasDtos: manageProjectAreaDto[] = drawnAreas.map(x => ({
          state: x.state!,
          name: x.name,
          areaId: 0,
          polygon: x.polygon,
          elementaryObjects: x.elementaryObjects
        }));

        const newProjectDto: AddProjectDto = {
          ...props.createdProject,
          areas: newAreasDtos,
          currency: 'PLN'
        };

        return this.projectService.addProject(newProjectDto).pipe(
          map((response: AddProjectInterface) => {
            if (!response.success) {
              this.messageService.displayErrorMessage("Error", response.error);
              return projectActions.handleError({error: response.error});
            }

            const newProject: ProjectInterface = {
              id: response.projectId,
              provider: 0,
              providerId: undefined,
              projectName: newProjectDto.projectName,
              area: newProjectDto.area,
              organizationId: newProjectDto.organizationId,
              gps: newProjectDto.gps,
              projectCategoryId: newProjectDto.projectCategoryId,
              grassSpeciesId: newProjectDto.grassSpeciesId!,
              areasCount: newProjectDto.areas?.length,
              currency: newProjectDto.currency,
              messagingPhoneNumber: newProjectDto.messagingPhoneNumber,
              messagingServiceSid: newProjectDto.messagingServiceSid,
            };

            return projectActions.createNewProjectSuccess({newProject: newProject})
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(projectActions.handleError({error: error.message}));
          })
        )
      })
    );
  });

  initProjectFromIGolf$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.initProjectFromIGolf),
    switchMap(action =>
      this.iGolfService.getNewProjectFromIGolf(action.courseId, action.organizationId).pipe(
        tap((response: AddProjectDto) => {
          response.grassSpeciesId = action.grassSpeciesId;
        }),
        map((response: AddProjectDto) => projectActions.initProjectFromIGolfSuccess({ importedProject: response })),
        catchError((error) => {
          this.messageService.displayErrorMessage("Error", error);
          return of(projectActions.handleError({ error: error.message }))
        })
      )
    )
  ));

  changeOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.getOrganizationById),
    map(action => action.organizationId),
    map(organizationId => projectActions.getProjects({organizationId: organizationId!}))
  ));

  showProjectSettingsPopup$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.showProjectSettingsPopup),
    tap(() => {
        this.projectService.showProjectSettingsPopup();
    })
  ), { dispatch: false });

  removeProject$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.removeProject),
    map(action => action.projectId),
    exhaustMap((projectId) => {
      return this.projectService.removeProject(projectId).pipe(
        map((response: baseResponse) => {
          if (!response.success) {
            this.messageService.displayErrorMessage('Error', response.error);
            return projectActions.handleError({error: response.error});
          }

          return projectActions.removeProjectSuccess({ projectId });
        }),
        catchError((error) => {
          this.messageService.displayErrorMessage("Error", error);
          return of(projectActions.handleError(error));
        })
      )
    })
  ));

  removeProjectSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.removeProjectSuccess),
    tap(() => {
      this.projectService.closeProjectSettingsPopup();
      this.router.navigate(['/project']).then(() => this.messageService.displayInfoMessage("", "Project was successfully removed"));
      this.store.dispatch(projectActions.getProjectById({projectId: -1}));
    })
  ), {dispatch: false})

  editProject$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.updateProject),
    map(action => action.editProject),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectSelector)),
    exhaustMap(([editProject, currentProject]) => {
      const editProjectDto: EditProjectDto = {
        projectName: editProject.projectName,
        projectId: currentProject.id,
        provider: currentProject.provider!,
        providerId: currentProject.providerId,
        gps: currentProject.gps,
        organizationId: currentProject.organizationId,
        projectCategoryId: currentProject.projectCategoryId,
				grassSpeciesId: editProject.grassSpeciesId,
        area: currentProject.area,
        vegetativeMonths: editProject.vegetativeMonths,
        currency: editProject.currency,
        messagingServiceSid: editProject.messagingServiceSid,
        messagingPhoneNumber: editProject.messagingPhoneNumber,
      };

      return this.projectService.editProject(currentProject.id!, editProjectDto).pipe(
        map((response: EditProjectInterface) => {
          if (!response.success) {
            this.messageService.displayErrorMessage("Error", response.error);
            return projectActions.handleError({error: response.error});
          }

          return projectActions.updateProjectSuccess({editProject});
        }),
        catchError((error) => {
          this.messageService.displayErrorMessage("Error", error);
          return of(projectActions.handleError(error));
        })
      )
    })
  ));

  getAreaInfoByGps$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.getAreaInfoByGps),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
    exhaustMap(([props, projectId]) => {
      return this.projectService.getAreaInfoByGps(projectId!, props.lat, props.lng).pipe(
        map((response: GetAreaInfoByGpsInterface) => {
          if (!response.success) {
            this.messageService.displayErrorMessage("Error", response.error);
            return projectActions.handleError({error: response.error});
          }

          return projectActions.getAreaInfoByGpsSuccess({dto: response});
        }),
        catchError((error) => {
          this.messageService.displayErrorMessage("Error", error);
          return of(projectActions.handleError(error));
        })
      )
    })
  ));

  getCategories$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.getCategories),
    exhaustMap(() => this.projectService.getProjectCategories().pipe(
      map(categories => projectActions.getCategoriesSuccess({categories})),
      catchError((error) => of(projectActions.handleError(error)))
    ))
  ));

  getGrassSpecies$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.getGrassSpecies),
    exhaustMap(() => this.projectService.getGrassSpecies().pipe(
      map(grassSpecies => projectActions.getGrassSpeciesSuccess({grassSpecies})),
      catchError((error) => of(projectActions.handleError(error)))
    ))
  ));

  getCurrencies$ = createEffect(() => this.actions$.pipe(
    ofType(projectActions.getCurrencies),
    exhaustMap(() => this.projectService.getCurrencies().pipe(
      map(currencies => projectActions.getCurrenciesSuccess({currencies})),
      catchError((error) => of(projectActions.handleError(error)))
    ))
  ));

  showLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(
			projectActions.getProjects,
      projectActions.createNewProject,
      projectActions.initProjectFromIGolf,
      projectActions.removeProject,
      projectActions.updateProject,
      projectActions.getCategories,
      projectActions.getGrassSpecies,
			projectActions.getAreaInfoByGps
		),
    tap(() => this.loadPanelService.showLoadPanel())
  ), {dispatch: false});

  hideLoadingPanel$ = createEffect(() => this.actions$.pipe(
    ofType(
			projectActions.getProjectsSuccess,
      projectActions.createNewProjectSuccess,
      projectActions.initProjectFromIGolfSuccess,
      projectActions.handleError,
      projectActions.removeProjectSuccess,
      projectActions.updateProjectSuccess,
      projectActions.getCategoriesSuccess,
      projectActions.getGrassSpecies,
			projectActions.getAreaInfoByGpsSuccess
		),
    tap(() => this.loadPanelService.hideLoadPanel())
  ),{dispatch: false});
}
