import {inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {baseResponse} from "../../DTO/base-response";
import {mineralIngredientUnit} from "../../components/grass-parameters-presentation/laboratory-examination-popup/types";
import {environment} from "../../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class ProjectGrassParamsNormsApiService {
	private http = inject(HttpClient)

	getLatestNorms = (projectId: number): Observable<getProjectGrassParamsLatestNormsResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/norms/latest`;
		return this.http.get<getProjectGrassParamsLatestNormsResponse>(endpoint);
	}

	getDefaultAndLatestNorms = (projectId: number): Observable<getDefaultAndLatestMineralIngredientNormsResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/norms/default-and-latest`;
		return this.http.get<getDefaultAndLatestMineralIngredientNormsResponse>(endpoint);
	}

	editNorm = (projectId: number, command: editGrassNormCommand): Observable<baseResponse> => {
		const endpoint = `${environment.projectApiUrl}projects/${projectId}/norms`;
		return this.http.post<baseResponse>(endpoint, command);
	}
}

export interface mineralIngredientNorm {
	paramName: string;
	normDownBoundary: number;
	normUpBoundary: number;
	unit?: mineralIngredientUnit;
}

export interface visualAssessmentNorm {
	paramName: string;
	badValue: number;
	goodValue: number;
}

export interface vegetationIndicatorNorm {
	paramName: string;
	badValue: number;
	goodValue: number;
}

export interface getProjectGrassParamsLatestNormsResponse extends baseResponse {
	norms: mineralIngredientNorm[];
}

export interface editGrassNormCommand {
	minNitrogen: number
	maxNitrogen: number
	minPhosphorus: number
	maxPhosphorus: number
	minPotassium: number
	maxPotassium: number
	minCalcium: number
	maxCalcium: number
	minMagnesium: number
	maxMagnesium: number
	minSodium: number
	maxSodium: number
	minCopper: number
	maxCopper: number
	minManganese: number
	maxManganese: number
	minZinc: number
	maxZinc: number
	minIron: number
	maxIron: number
	minHumid: number
	maxHumid: number
}

export interface getDefaultAndLatestMineralIngredientNormsResponse extends baseResponse {
	items: grassParamNormGridViewModel[]
}

export interface grassParamNormGridViewModel {
	paramName: string;
	defaultMin: number;
	defaultMax: number;
	actualMin: number;
	actualMax: number;
	unit: string;
}
