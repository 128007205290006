import {AppState} from "../../../types/app-state";
import {createSelector} from "@ngrx/store";
import {selectPresentationMode} from "../index";
import {LaboratoryExaminationDto, mapIncidentDto, sprinklerDto} from "../../DTO/item-dto";
import {organizationIdSelector, selectedProjectIdSelector} from "src/app/project/project-store/selectors";
import {selectUserPermittedFeatures} from "src/app/profile/store";

export const selectFeatureOtherObjects = (state: AppState) => state.otherObjects;
export const selectedItemsSelector = createSelector(selectFeatureOtherObjects, selectPresentationMode, (state, presentationMode) => ({ presentationMode, items: state.items }));
export const getItemsSelector = createSelector(selectedItemsSelector, (state) => {
  return state.items
});
export const getWorkItemsSelector = createSelector(getItemsSelector, (items) => items.filter(i => i.itemType === 'Incident').map(i => i as mapIncidentDto));
export const getSprinklersSelector = createSelector(getItemsSelector, (items) => items.filter(i => i.itemType === 'Sprinkler').map(i => i as sprinklerDto));
export const getLaboratoryExaminationsSelector = createSelector(getItemsSelector, (items) => items.filter(i => i.itemType === 'LaboratoryExamination').map(i => i as LaboratoryExaminationDto));
export const getEditedItemSelector = createSelector(selectFeatureOtherObjects, (state) => state.editedItem);
export const otherObjectLayersSelector = createSelector(selectFeatureOtherObjects, (state) => state.otherObjectLayers);
export const getLaboratoryExaminationByIdSelector = (itemId: number) => createSelector(getLaboratoryExaminationsSelector, items => items.find(x => x.itemId === itemId.toString()));
export const getWorkItemByIdSelector = (itemId: number) => createSelector(getWorkItemsSelector, items => items.find(x => x.itemId === itemId.toString()));
export const contextMenuPermissionsSelector = createSelector(organizationIdSelector, selectedProjectIdSelector, selectUserPermittedFeatures, (organizationId, projectId, userFeatures) => ({ organizationId, projectId, userFeatures }));
export const isItemsDrawRequiredSelector = (mapId: string) => createSelector(selectFeatureOtherObjects, (state) => state.otherObjectMaps[mapId].drawItemsRequired ?? false)
