import {point} from "../../types/point";

export interface ItemDto {
  itemId: string;
  itemType: string | undefined;
  projectId: number | undefined;
  gps?: point;
}

export interface mapIncidentDto extends ItemDto {
  priority?: IncidentPriority | null;
}

export enum IncidentPriority {
	Yellow = 1,
	Orange = 2,
	Red = 3
}

export interface sprinklerDto extends ItemDto {
  sprinklerId?: number;
  isArchive?: boolean;
  actionUser?: string;
  actionUserId?: number;
  actionDate?: Date;
  radius?: number | undefined;
  name?: string | null;
  producer?: string | null;
  number?: string | null;
  irrigation?: number | null;
  projectAreaId?: number | null;
  projectAreaName?:  string | null;
  elementaryObjectId?: number | null;
  elementaryObjectName?:  string | null;
}

export interface LaboratoryExaminationDto extends ItemDto {
  id: number;
  examinationDate: Date;
  laboratoryExaminationType: number;

  // dryBiomassAverage?: number;
  // dryBiomassStandardDeviation?: number;
  // dryBiomassVariation?: number;

  nitrogenAverage?: number;
  // nitrogenStandardDeviation?: number;
  // nitrogenVariation?: number;

  potassiumAverage?: number;
  // potassiumStandardDeviation?: number;
  // potassiumVariation?: number;

  sodiumAverage?: number;
  // sodiumStandardDeviation?: number;
  // SodiumVariation?: number;

  calciumAverage?: number;
  // calciumStandardDeviation?: number;
  // calciumVariation?: number;

  magnesiumAverage?: number;
  // magnesiumStandardDeviation?: number;
  // magnesiumVariation?: number;

  manganeseAverage?: number;
  // manganeseStandardDeviation?: number;
  // manganeseVariation?: number;

  ironAverage?: number;
  // ironStandardDeviation?: number;
  // ironVariation?: number;

  zincAverage?: number;
  // zincStandardDeviation?: number;
  // zincVariation?: number;

  copperAverage?: number;
  // copperStandardDeviation?: number;
  // copperVariation?: number;

  phosphorusAverage?: number;
  // phosphorusStandardDeviation?: number;
  // phosphorusVariation?: number;
}
