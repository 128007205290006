import {Injectable} from '@angular/core';
import {Map, MapboxGeoJSONFeature} from 'mapbox-gl';
import {GrassParametersLayer} from "../models/mapbox/types";

@Injectable({providedIn: 'root'})
export class ObservedHexesService {
  getFilteredHexesIds = (map: Map, layer: GrassParametersLayer): number[] => {
		const features: MapboxGeoJSONFeature[] = map.queryRenderedFeatures(undefined, {layers: [layer]});
		return features.map(feature => feature.properties?.['id']) ?? [];
	}
}
