import {ParametersMapComposition, PresentationMode} from "./grass-params/types";
import {
	createActionGroup,
	createFeature,
	createReducer,
	createSelector,
	emptyProps,
	on,
	props,
	Store
} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {inject} from "@angular/core";
import {tap, withLatestFrom} from "rxjs";
import {
  presentationModePickerVm
} from "../components/grass-parameters-presentation/grass-params-sidebar/presentation-mode-picker/presentation-mode-picker.component";
import {AppState} from "../../types/app-state";
import {MapboxParamsDisplayService} from "../services/mapbox-params-display.service";
import * as grassParamsSelectors from "./grass-params/selectors";
import {GrassParametersMapsService} from "../services/grass-parameters-maps.service";
import {MapsInteractiveCompareService} from "../services/maps-interactive-compare.service";
import {selectSelectedParams} from "./grass-params/selectors";

export interface photoMapViewState {
  presentationMode: PresentationMode,
	composition: ParametersMapComposition,
	debugMode: boolean
}

export const initialState: photoMapViewState = {
  presentationMode: PresentationMode.Parameters,
	composition: ParametersMapComposition.Single,
	debugMode: false
}

export const photoMapViewActions = createActionGroup({
  source: 'PhotoMapView',
  events: {
    'Change Presentation Mode': props<{presentationMode: PresentationMode}>(),
		'Change Composition Mode': props<{composition: ParametersMapComposition}>(),
		'Init Composition': props<{composition: ParametersMapComposition}>(),
		'Handle Maps Sync': props<{mapId: string}>(),
		'Toggle Debug Mode': emptyProps(),
		'Reset': emptyProps()
  }
});

export const photoMapViewFeature = createFeature({
  name: 'photoMapView',
  reducer: createReducer(
    initialState,
    on(photoMapViewActions.changePresentationMode, (state, {presentationMode}) => ({...state, presentationMode})),
		on(photoMapViewActions.changeCompositionMode, (state, {composition}) => ({...state, composition})),
		on(photoMapViewActions.initComposition, (state, {composition}) => ({...state, composition})),
		on(photoMapViewActions.toggleDebugMode, (state) => ({...state, debugMode: !state.debugMode})),
		on(photoMapViewActions.reset, (state) => initialState)
  ),
  extraSelectors: ({ selectPresentationMode }) => ({
    selectPresentationModePickerVm: createSelector(selectPresentationMode, (presentationMode): presentationModePickerVm => {
      const items = [
        {
          id: PresentationMode.Parameters.toString(),
          state: {
            'iconSelectedPath': './assets/images/quadrocopter-white.svg',
            'iconPath': './assets/images/quadrocopter.svg'
          }
        },
        {
          id: PresentationMode.OtherObjects.toString(),
          state: {
            'iconSelectedPath': './assets/images/layer-white.svg',
            'iconPath': './assets/images/layer.svg'
          }
        }
      ];

      const activeItem = items.find(x => x.id === presentationMode.toString())!;

      return {
        items,
        activeItem
      };
    })
  })},
);

export const { selectPresentationMode, selectPresentationModePickerVm, selectComposition, selectDebugMode } = photoMapViewFeature;

export const changePresentationMode$ = createEffect(
  (
    actions$ = inject(Actions),
    layersDisplayService = inject(MapboxParamsDisplayService),
    store = inject(Store<AppState>)
  ) =>
  actions$.pipe(
    ofType(photoMapViewActions.changePresentationMode),
    withLatestFrom(store.select(grassParamsSelectors.selectHexesLayersConfig)),
    tap(([{presentationMode}, hexesLayersConfig]) => {
      layersDisplayService.changePresentationMode(presentationMode, hexesLayersConfig);
    })
  ), {dispatch: false, functional: true}
);

export const handleMapSync$ = createEffect((
	actions$ = inject(Actions),
	store = inject(Store<AppState>),
	mapsService = inject(GrassParametersMapsService),
	interactiveCompareService = inject(MapsInteractiveCompareService)
) =>
	actions$.pipe(
		ofType(photoMapViewActions.handleMapsSync),
		withLatestFrom(store.select(selectComposition)),
		tap(([{mapId}, composition]) => {
			if (mapId !== 'map-1') {
				return;
			}

			if (composition === ParametersMapComposition.SplitScreen) {
				mapsService.syncWithOtherMaps(mapId);
			}

			if (composition === ParametersMapComposition.InteractiveCompare) {
				interactiveCompareService.addInteractiveCompare();
			}
		})
	), {dispatch: false, functional: true}
);

export const handleCompositionChange$ = createEffect((
		actions$ = inject(Actions),
		mapsService = inject(GrassParametersMapsService),
		interactiveCompareService = inject(MapsInteractiveCompareService),
		store = inject(Store<AppState>)
	) =>
		actions$.pipe(
			ofType(photoMapViewActions.changeCompositionMode),
			withLatestFrom(store.select(selectSelectedParams)),
			tap(([{composition}, selectedParams]) => {
				mapsService.resetSyncedProperty();
				interactiveCompareService.clearCompare();

				if (composition === ParametersMapComposition.SplitScreen) {
					mapsService.syncWithOtherMaps('map-1');
					return;
				}

				if (composition === ParametersMapComposition.InteractiveCompare) {
					interactiveCompareService.addInteractiveCompare();
				}
			})
		), {dispatch: false, functional: true}
);
