import {Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {UserForRegisterDto} from 'src/app/register/DTO/user-for-register-dto';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {
  changeTemporaryPasswordResponse,
  confirmPasswordResponse,
  forgotPasswordResponse,
  LoginResponse, refreshTokenResponse
} from '../../login/models/login-types';
import {forgotPasswordNewPasswordDto} from '../../login/DTO/forgot-password-new-password-dto';
import {UserForLoginDto} from 'src/app/login/DTO/user-for-login-dto';
import {changeTemporaryPasswordDto} from '../../login/DTO/change-temporary-password-dto';
import {UserPasswordForgotDto} from 'src/app/login/DTO/user-password-forgot-dto';
import {ChangePersonalDataDto} from 'src/app/profile/DTO/change-personal-data-dto';
import {ChangePasswordDto} from 'src/app/profile/DTO/change-password-dto';
import {PersonalDataInterface} from '../../profile/types/user-types';
import {SignalrService} from './signalr.service';
import {Store} from '@ngrx/store';
import {
  ConfirmEmailChangeRequest,
  ConfirmEmailChangeResponse,
  EmailChangeRequest,
  EmailChangeResponse
} from '../../profile/change-user-email/change-user-email-types'
import {AppState} from "../../types/app-state";
import * as rootActions from "../../actions";
import * as forge from 'node-forge';
import { ApplicationService, appPublicKeyResponse } from './application.service';

//todo split to more smaller services
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl:string = environment.userApiUrl;
  constructor(private http: HttpClient, private router: Router, private signalr: SignalrService, private store: Store<AppState>, private jwtHelper: JwtHelperService, private appService: ApplicationService) { }
  login = (model: UserForLoginDto): Observable<LoginResponse> => {
    return this.appService.getPublicKey().pipe(
      switchMap((response: appPublicKeyResponse) => {
        const publicKey = response.publicKey;
        const encryptedPassword = this.encryptWithPublicKey(publicKey, model.password!);

        return this.http.post<LoginResponse>(`${this.baseUrl}signin`, { email: model.email, encryptedPassword: encryptedPassword });
      })
    );
  }
  register = (requestBody: UserForRegisterDto): Observable<any> => this.http.post(this.baseUrl + 'register', requestBody);
  changeTemporaryPassword = (model: changeTemporaryPasswordDto): Observable<changeTemporaryPasswordResponse> => {
    return this.appService.getPublicKey().pipe(
      switchMap((response: appPublicKeyResponse) => {
        const publicKey = response.publicKey;
        const encryptedPassword = this.encryptWithPublicKey(publicKey, model.password!);

        return this.http.post<changeTemporaryPasswordResponse>(`${this.baseUrl}changePassword`, { email: model.email, encryptedPassword: encryptedPassword , session: model.session });
      })
    );
  }
  forgotPassword = (requestBody: UserPasswordForgotDto): Observable<forgotPasswordResponse> => this.http.post<forgotPasswordResponse>(this.baseUrl + 'forgotPassword', requestBody);
  confirmPassword = (model: forgotPasswordNewPasswordDto): Observable<confirmPasswordResponse> => {
    return this.appService.getPublicKey().pipe(
      switchMap((response: appPublicKeyResponse) => {
        const publicKey = response.publicKey;
        const encryptedPassword = this.encryptWithPublicKey(publicKey, model.password!);

        return this.http.post<confirmPasswordResponse>(`${this.baseUrl}confirmPassword`, { email: model.email, encryptedPassword: encryptedPassword , code: model.code });
      })
    );
  }
  changePersonalData = (requestBody: ChangePersonalDataDto): Observable<PersonalDataInterface> => this.http.post<PersonalDataInterface>(this.baseUrl + 'updateAttribiuteUser', requestBody);
  changeUserPassword = (model: ChangePasswordDto): Observable<changeTemporaryPasswordResponse> => {
    return this.appService.getPublicKey().pipe(
      switchMap((response: appPublicKeyResponse) => {
        const publicKey = response.publicKey;
        const encryptedCurrentPassword = this.encryptWithPublicKey(publicKey, model.currentPassword!);
        const encryptedNewPassword = this.encryptWithPublicKey(publicKey, model.newPassword!);

        return this.http.post<confirmPasswordResponse>(`${this.baseUrl}changePasswordUser`, { email: model.email, encryptedNewPassword: encryptedNewPassword , encryptedCurrentPassword: encryptedCurrentPassword });
      })
    );
  }
  ChangeEmail = (requestBody: EmailChangeRequest): Observable<EmailChangeResponse> => this.http.post<EmailChangeResponse>(`${this.baseUrl}updateUserEmail`, requestBody);
  confirmUserEmailChange = (requestBody: ConfirmEmailChangeRequest): Observable<ConfirmEmailChangeResponse> => this.http.post<ConfirmEmailChangeResponse>(`${this.baseUrl}confirmEmailChange`, requestBody);
  refreshToken = (refreshToken: string): Observable<refreshTokenResponse> => {
    return this.http.post<refreshTokenResponse>(`${this.baseUrl}refreshToken`, {refreshToken: refreshToken});
  }

  loggedIn = (): boolean => {
    const token = localStorage?.getItem('agro');
    return (token && token !== 'null') ? !this.jwtHelper.isTokenExpired(token) : false;
  };

  getRefreshToken = () => localStorage.getItem('refreshToken')

  getIdToken = () => localStorage.getItem('agro');

  getUserEmail = () => localStorage.getItem('userAgro');

  setRefreshToken = (value: string) => localStorage.setItem('refreshToken', value);

  setIdToken = (value: string) => localStorage.setItem('agro', value);

  setUserEmail = (value: string) => localStorage.setItem('userAgro', value);

  logout = (): void => {
    this.store.dispatch(rootActions.resetStore());
    localStorage?.removeItem('agro');
    localStorage?.removeItem('userAgro');
    localStorage?.removeItem('refreshToken');
    this.signalr.stopConnection();
    this.router.navigate(['/login']);
  };

  encryptWithPublicKey(publicKey: string, password: string): string {
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
    const encryptedPassword = publicKeyObj.encrypt(password, 'RSA-OAEP',{
			md: forge.md.sha1.create() // Use SHA-1 as the hash function for OAEP
		});

    return forge.util.encode64(encryptedPassword);
	}
}
