import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {LaboratoryExaminationPopupDetailsComponentStore} from "./laboratory-examination-popup-details.component.store";
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {ItemsPopupService} from "../../../services/items-popup.service";
import { grassQualityParameterNames } from 'src/app/grass-parameters-presentation/constants/config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: `
    <p-button class="p-dialog-content-close-button laboratory-object-popup-close-button" styleClass="p-button-text" icon="pi pi-times" (onClick)="closePopup()"></p-button>
    <div class="p-dialog-content-container laboratory-examination-details-popup-wrapper" *ngIf="vm$ | async as vm">
			<div class="p-dialog-content-header-container">
      	<h3 class="laboratory-examination-details-popup-title">{{ vm.popupTitle }}</h3>
			</div>
			<div class="p-dialog-content-body-container">
				<p-table styleClass="p-datatable-sm p-datatable-striped" class="table table-secondary laboratory-examination-details-popup-list" [value]="vm.chemicalParameterListItems">
					<ng-template pTemplate="header">
						<tr>
							<th>{{ 'COMMON.NAME' | translate }}</th>
							<th>{{ 'GRASS_PARAMETERS.LAB_EXAM.VALUE' | translate }}</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-parameter>
						<tr>
							<td>{{getParamTranslationName(parameter['realName'], parameter['name'])}}</td>
							<td>{{parameter['average']}}</td>
						</tr>
					</ng-template>
				</p-table>
			</div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LaboratoryExaminationPopupDetailsComponentStore]
})
export class LaboratoryExaminationPopupDetailsComponent implements OnInit {
  private translate = inject(TranslateService);

  ngOnInit(): void {
    this.componentStore.init(this.itemId);
  }

  private componentStore = inject(LaboratoryExaminationPopupDetailsComponentStore);
  private config = inject(DynamicDialogConfig);
  private itemsPopupService = inject(ItemsPopupService);

  readonly vm$ = this.componentStore.vm$;
  itemId = this.config.data.itemId;

  closePopup = () => this.itemsPopupService.destroyDialogIfOpen();

  getParamTranslationName(parameterName: string, defaultName: string): string {
    const qualityParName = grassQualityParameterNames.find(x => x.name === parameterName);
    if (qualityParName)
      return this.translate.instant(qualityParName.translationKey);
    return defaultName;
  }
}
