import {
	Directive,
	ElementRef,
	inject,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges
} from "@angular/core";
import Chart from 'chart.js/auto';

@Directive({
  selector: '[appTrendsLineChart]',
  standalone: true
})
export class TrendsLineChartDirective implements OnChanges, OnDestroy {
  private element = inject(ElementRef);

  @Input() trendData!: number[];
  @Input() downBoundary!: number;
  @Input() upBoundary!: number;

  private readonly boundaryLineColor: string = '#a5a4a4';
  private readonly boundaryLineWidth: number = 1.5;
  private readonly boundaryLineDash: number[] = [5, 5];

  private readonly trendLineColor: string = '#4EC571';
  private readonly trendLineTension: number = 0.6;
  private timerDataChange: any = undefined;
  private chart?: Chart;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trendData'] && this.trendData) {
      if (!this.timerDataChange) {
        this.timerDataChange = setTimeout(() => {
          this.createChart();
          this.timerDataChange = undefined;
        }, 300);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.timerDataChange) {
      clearTimeout(this.timerDataChange);
    }
    this.chart?.destroy();
  }

  private createChart = () => {
			this.chart = new Chart(this.element.nativeElement.getContext('2d'), {
				type: 'line',
				data: {
					labels: this.trendData.map((_, index) => `Label ${index}`),
					datasets: [
						{
							data: [...this.trendData],
							cubicInterpolationMode: 'default',
							tension: this.trendLineTension,
							borderColor: this.trendLineColor
						},
						this.getBoundaryDataset(this.downBoundary),
						this.getBoundaryDataset(this.upBoundary)
					]
				},
				options: {
					plugins: {
						legend: { display: false }
					},
					elements: { point: { radius: 0 } },
					scales: {
						y: { display: false, },
						x: { display: false }
					}
				}
			}
    );
  }

  private getBoundaryDataset = (boundaryValue: number) => ({
    data: new Array(this.trendData.length).fill(boundaryValue),
    borderColor: this.boundaryLineColor,
    borderWidth: this.boundaryLineWidth,
    borderDash: this.boundaryLineDash
  })
}
