export enum MeasurementSystem {
  Metric = 0,
  Imperial = 1
}

export enum Units {
  MilesPerHour = 'mph',
  KilometersPerHour = 'km/h',
  Celsius = '°C',
  Fahrenheit = '°F',
  Percent = '%',
  Meters = 'meters',
  Feet = 'feet',
  Millimeters = 'millimeters',
	MillimetersShort = 'mm',
  Inches = 'inches',
	InchesShort = 'in',
  GalonPerSquareMeter = 'G/F2',
  LiterPerSquareMeter = 'L/m2',
  Acidity = 'pH',
  FeetPerSecond = '(ft/s)',
  Liter = 'L',
  Gallon = 'G'
}
