import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, startWith, switchMap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: 'translateIncidentCategoryAsync' })
export class TranslateIncidentCategoryAsyncPipe implements PipeTransform {
	private translate = inject(TranslateService);

  transform(value: string): Observable<string> {
		return this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(`INCIDENT.CATEGORY.${value.toUpperCase()}`))
		)
  }
}
