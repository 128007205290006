import { NgModule } from '@angular/core';
import {CommonModule, DatePipe, NgOptimizedImage} from '@angular/common';
import { NeedHelpComponent } from './components/need-help/need-help.component';
import { LoginBackgroundComponent } from './components/login-background/login-background.component';
import { LoadPanelComponent } from "./components/load-panel/load-panel.component";
import { CopyrightInfoComponent } from './components/copyright-info/copyright-info.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { MessageModule } from "primeng/message";
import { CollapsableOverlayComponent } from './components/collapsable-overlay/collapsable-overlay.component';
import { InputSwitchModule } from "primeng/inputswitch";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { CheckProjectFeaturePermissionDirective } from "./directives/check-project-feature-permission.directive";
import { UserDataInitApiService } from "./services/user-data-init.api.service";
import { UnauthorizedPageComponent } from "./components/unauthorized-page.component";
import { ButtonModule } from "primeng/button";
import { RouterLink } from "@angular/router";
import { LogoComponent } from "./components/logo/logo.component";
import { PanelModule } from 'primeng/panel';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { CommentsSectionComponent } from "./components/comments-section/comments-section.component";
import { InputTextareaModule } from "primeng/inputtextarea";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ChangesHistoryComponent } from "./components/changes-history/changes-history.component";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { CheckOrganizationFeaturePermissionDirective } from "./directives/check-organization-feature-permission.directive";
import { CheckOrganizationListPermissionDirective } from "./directives/check-organization-list-permission.directive";
import { CheckApplicationFeaturePermissionDirective } from "./directives/check-application-feature-permission.directive";
import { HelpContextComponent } from './components/help-context/help-context.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceInformationComponent } from './components/service-information/service-information.component';
import { TranslateTaskStatusAsyncPipe } from './pipes/translate-task-status-async.pipe';
import { TranslateGrassParamPipe } from './pipes/translate-grass-param.pipe';
import {WeatherChartComponent} from "./components/weather-chart/weather-chart.component";
import {WeatherChartDirective} from "./components/weather-chart/weather-chart.directive";
import {InputMaskModule} from "primeng/inputmask";
import {DropdownModule} from "primeng/dropdown";
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { TranslateCountryPipe } from './pipes/translate-country.pipe';
import { TranslateExaminationTypeAsyncPipe } from './pipes/translate-examination-type-async.pipe';
import {LocalizedDatePipe} from "./pipes/localized-date.pipe";
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { TranslateTaskTypeAsyncPipe } from './pipes/translate-task-type-async.pipe';
import { TranslateIncidentCategoryAsyncPipe } from './pipes/translate-incident-category-async.pipe';
import {EditIncidentComponent} from "./components/edit-incident/edit-incident.component";
import {EditIncidentBaseComponent} from "./components/edit-incident/edit-incident-base.component";
import {EditIncidentPopupComponent} from "./components/edit-incident/edit-incident-popup.component";
import {IncidentTabViewComponent} from "./components/edit-incident/tab-view/incident-tab-view.component";
import {AskAiComponent} from "./components/edit-incident/tab-view/ai/ask-ai.component";
import {AskAiChatComponent} from "./components/edit-incident/tab-view/ai/ask-ai-chat.component";
import {AskAiWelcomePageComponent} from "./components/edit-incident/tab-view/ai/ask-ai-welcome-page.component";
import {
	IncidentCommentsSectionComponent
} from "./components/edit-incident/tab-view/comments/incident-comments-section.component";
import {AvatarModule} from "primeng/avatar";
import {TranslateIncidentStateAsyncPipePipe} from "./pipes/translate-incident-state-async.pipe";
import {TabViewModule} from "primeng/tabview";
import {InputTextModule} from "primeng/inputtext";
import {IncidentEvaluationComponent} from "./components/edit-incident/evaluation/evaluation.component";
import {RatingModule} from "primeng/rating";
import {RelatedTasksComponent} from "./components/edit-incident/related-tasks/related-tasks.component";
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";

@NgModule({
	providers: [
		UserDataInitApiService,
		DatePipe
	],
	declarations: [
		NeedHelpComponent,
		HelpContextComponent,
		CopyrightInfoComponent,
		LoginBackgroundComponent,
		LogoComponent,
    	LoadPanelComponent,
    	ErrorMessageComponent,
    	CollapsableOverlayComponent,
    	CheckProjectFeaturePermissionDirective,
		CheckOrganizationFeaturePermissionDirective,
		CheckOrganizationListPermissionDirective,
		CheckApplicationFeaturePermissionDirective,
    	UnauthorizedPageComponent,
    	AttachmentsComponent,
		CommentsSectionComponent,
		ChangesHistoryComponent,
		YesNoPipe,
		HelpDialogComponent,
		ServiceInformationComponent,
		TranslateTaskStatusAsyncPipe,
  		TranslateGrassParamPipe,
		TranslateExaminationTypeAsyncPipe,
		WeatherChartComponent,
        PhoneMaskPipe,
        TranslateCountryPipe,
        LocalizedDatePipe,
        PhoneNumberComponent,
        TranslateTaskTypeAsyncPipe,
        TranslateIncidentCategoryAsyncPipe,
		EditIncidentComponent,
		EditIncidentBaseComponent,
		EditIncidentPopupComponent,
		IncidentTabViewComponent,
		AskAiComponent,
		AskAiChatComponent,
		AskAiWelcomePageComponent,
		IncidentCommentsSectionComponent,
		TranslateIncidentStateAsyncPipePipe,
		IncidentEvaluationComponent,
		RelatedTasksComponent
	],
	exports: [
		NeedHelpComponent,
		HelpContextComponent,
		CopyrightInfoComponent,
		LoginBackgroundComponent,
		LogoComponent,
		LoadPanelComponent,
		ErrorMessageComponent,
		CollapsableOverlayComponent,
		CheckProjectFeaturePermissionDirective,
		CheckOrganizationFeaturePermissionDirective,
		CheckOrganizationListPermissionDirective,
		CheckApplicationFeaturePermissionDirective,
		UnauthorizedPageComponent,
		AttachmentsComponent,
		CommentsSectionComponent,
		ChangesHistoryComponent,
		YesNoPipe,
		ServiceInformationComponent,
		TranslateTaskStatusAsyncPipe,
		TranslateGrassParamPipe,
		WeatherChartComponent,
		PhoneMaskPipe,
		TranslateCountryPipe,
		TranslateExaminationTypeAsyncPipe,
		LocalizedDatePipe,
		PhoneNumberComponent,
		TranslateTaskTypeAsyncPipe,
		TranslateIncidentCategoryAsyncPipe,
		TranslateIncidentStateAsyncPipePipe
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		MessageModule,
		InputSwitchModule,
		ProgressSpinnerModule,
		DialogModule,
		ButtonModule,
		RouterLink,
		PanelModule,
		FileUploadModule,
		ScrollPanelModule,
		TooltipModule,
		InputTextareaModule,
		AngularSvgIconModule,
		TranslateModule,
		WeatherChartDirective,
		InputMaskModule,
		DropdownModule,
		NgOptimizedImage,
		AvatarModule,
		TabViewModule,
		InputTextModule,
		RatingModule,
		RippleModule,
		TableModule
	]
})
export class SharedModule { }
