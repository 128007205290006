import {createSelector} from "@ngrx/store";
import {AppState} from "../../../types/app-state";
import {State} from "../../_helpers/state";
import {uuid} from "aws-sdk/clients/customerprofiles";

export const selectFeatureChooseArea = (state: AppState) => state.chooseArea;
export const selectedAreaSelector = createSelector(selectFeatureChooseArea, (state) => state.selectedArea);
export const expandedAreaSelector = createSelector(selectFeatureChooseArea, (state) => state.expandedArea);
export const sidebarAreas = createSelector(selectFeatureChooseArea, (state) => {
  return state.drawnAreas.filter(x => x.state !== State.Delete)
    .map(area => {
      const nonDeletedElementaryObjects = area.elementaryObjects?.filter(eo => eo.state !== State.Delete);
      return {...area, elementaryObjects: nonDeletedElementaryObjects};
    });
});
export const drawnAreasSelector = createSelector(selectFeatureChooseArea, (state) => state.drawnAreas);

export const validateAreaSelector = createSelector(selectFeatureChooseArea, (state) => ({drawnAreas: state.drawnAreas, isElementaryObject: state.isElementaryObjectEnabled}))

export const idsToDelete = (areaId: uuid) => createSelector(selectFeatureChooseArea, (state) => {
  const result = state.drawnAreas.find(area => area.uuid === areaId)?.elementaryObjects?.map(x => x.uuid) ?? [];
  result.push(areaId);
  return result;
});

export const isAddAreaPopupVisibleSelector = createSelector(selectFeatureChooseArea, (state) => state.isAddAreaPopupVisible);

export const isEditAreaPopupVisibleSelector = createSelector(selectFeatureChooseArea, (state) => state.isEditAreaPopupVisible);

export const isElementaryObjectEnabledSelector = createSelector(selectFeatureChooseArea, (state) => state.isElementaryObjectEnabled);

export const isAnyElementChangedSelector = createSelector(selectFeatureChooseArea, (state) => state.isChanged);

export const areaInvalidSelector = createSelector(selectFeatureChooseArea, (state) => state.areaInvalid);

export const selectionChangedSelector = createSelector(selectFeatureChooseArea, (state) => {
  return {
    isAddAreaPopupVisible: state.isAddAreaPopupVisible,
    isEditAreaPopupVisible: state.isEditAreaPopupVisible,
    areaInvalid: state.areaInvalid
  }
});

export const areaFormPopupTitle = createSelector(selectFeatureChooseArea, (state) => {
  return `${state.isEditAreaPopupVisible ? "Edit" : "Add"} ${state.isElementaryObjectEnabled ? "Type" : "Hole"}`
})

export const areaFormPopupAction = createSelector(selectFeatureChooseArea, (state) => {
  return state.isEditAreaPopupVisible ? "Edit" : "Add";
})
