import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {AskAiApiService, askAiResponse} from "../../shared/components/edit-incident/tab-view/ai/ask-ai-api.service";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import {askAiActions, incidentActions} from "./actions";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {selectEditedIncidentId} from "./selectors";
import {response} from "express";

@Injectable()
export class AskAiEffects {
	private actions$ = inject(Actions);
	private askAIApiService = inject(AskAiApiService);
	private store = inject(Store<AppState>);

	getChatHistory$ = createEffect(() => this.actions$.pipe(
		ofType(askAiActions.getChatHistory),
		exhaustMap(({incidentId}) =>
			this.askAIApiService.getIncidentChatMessages(incidentId).pipe(
				map(messages => askAiActions.getChatHistorySuccess({messages})),
				catchError(error => of(incidentActions.handleError(error)))
			))
	));

	// startAiInteraction$ = createEffect(() => this.actions$.pipe(
	// 	ofType(askAiActions.startAiInteraction),
	// 	withLatestFrom(this.store.select(selectEditedIncidentId)),
	// 	exhaustMap(([_, incidentId]) => {
	// 		return this.askAIApiService.askDefaultQuestion(incidentId!).pipe(
	// 			map((response: askAiResponse) => {
	// 				if (!response.success) {
	// 					return incidentActions.handleError({errorMessage: response.error});
	// 				}
	//
	// 				return askAiActions.askAISuccess({message: response.message})
	// 			})
	// 		)
	// 	})
	// ));

	// askAi$ = createEffect(() => this.actions$.pipe(
	// 	ofType(askAiActions.askAI),
	// 	withLatestFrom(this.store.select(selectEditedIncidentId)),
	// 	exhaustMap(([{question}, incidentId]) => {
	// 		return this.askAIApiService.askAi(incidentId!, question).pipe(
	// 			map((response: askAiResponse) => {
	// 				if (!response.success) {
	// 					return incidentActions.handleError({errorMessage: response.error});
	// 				}
	//
	// 				return askAiActions.askAISuccess({message: response.message});
	// 			}),
	// 			catchError(error => of(incidentActions.handleError(error)))
	// 		)
	// 	})
	// ))
}
