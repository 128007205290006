import {createAction, props} from "@ngrx/store";
import {OneAtlasOrderInterface, OneAtlasPredictionInterface, OneAtlasSearchInterface} from "./types";

export const getOneAtlasPhotoInfos = createAction('[OneAtlas] Get One Atlas project infos', props<{projectId: number, searchRange: { year: number, month: number }}>());
export const getOneAtlasPhotoInfosSuccess = createAction('[OneAtlas] Get One Atlas project infos Success', props<{results: OneAtlasSearchInterface}>());
export const prepareOneAtlasOrder = createAction('[OneAtlas] Prepare One Atlas Order', props<{projectId: number, day: Date}>());
export const prepareOneAtlasOrderSuccess = createAction('[OneAtlas] Prepare One Atlas Order Success', props<{projectId: number, processId: string}>());
export const acceptOneAtlasOrderPrice = createAction('[OneAtlas] Accept One Atlas Order Price', props<{projectId: number, processId: string}>());
export const acceptOneAtlasOrderPriceSuccess = createAction('[OneAtlas] Accept One Atlas Order Price Success', props<{projectId: number, processId: string}>());
export const rejectOneAtlasOrderPrice = createAction('[OneAtlas] Reject One Atlas Order Price', props<{projectId: number, processId: string}>());
export const rejectOneAtlasOrderPriceSuccess = createAction('[OneAtlas] Reject One Atlas Order Price Success', props<{projectId: number, processId: string}>());
export const findOneAtlasCreatedOrders = createAction('[OneAtlas] Find One Atlas Created Orders', props<{projectId: number}>());
export const findOneAtlasCreatedOrdersSuccess = createAction('[OneAtlas] Find One Atlas Created Orders Success', props<{orders: OneAtlasOrderInterface[]}>());
export const handleError = createAction('[OneAtlas] Error Occurred');
