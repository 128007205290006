import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";

import { getItemsResponse } from "../DTO/get-items-response";

import { getSprinklerResponse } from "../DTO/items/sprinklers/get-sprinkler-response";
import { addSprinklerCommand } from "../DTO/items/sprinklers/add-sprinkler-command";
import { addSprinklerResponse } from "../DTO/items/sprinklers/add-sprinkler-response";
import { editSprinklerCommand } from "../DTO/items/sprinklers/edit-sprinkler-command";
import { baseResponse } from '../DTO/base-response';
// TODO: ZMienic nazwe na SprinklerService a getItems pobierac z osobnego
@Injectable({
    providedIn: 'root'
})
export class ItemsService {
    private baseUrl: string = environment.projectApiUrl;

    constructor(private http: HttpClient) {
    }

    public getItems = (projectId: number, dateTime: Date | null | undefined): Observable<getItemsResponse> => {
        let params = new HttpParams();

        if (dateTime != null) {
            params = params.append('dateTime', dateTime?.toISOString());
        }

        return this.http.get<getItemsResponse>(`${this.baseUrl}projects/${projectId}/items`, { params: params });
    }

    public addSprinkler = (command: addSprinklerCommand): Observable<addSprinklerResponse> => this.http.post<addSprinklerResponse>(`${this.baseUrl}projects/${command.projectId}/items/sprinklers`, command);
    public getSprinkler = (projectId: number, sprinklerId: number): Observable<getSprinklerResponse> => this.http.get<getSprinklerResponse>(`${this.baseUrl}projects/${projectId}/items/sprinklers/${sprinklerId}`);
    public editSprinkler = (command: editSprinklerCommand): Observable<baseResponse> => this.http.put<baseResponse>(`${this.baseUrl}projects/${command.projectId}/items/sprinklers/${command.sprinklerId}`, command);
    public removeSprinkler = (projectId: number, sprinklerId: number): Observable<baseResponse> => this.http.delete<baseResponse>(`${this.baseUrl}projects/${projectId}/items/sprinklers/${sprinklerId}`);
}