import {Component, OnInit, inject, OnDestroy} from "@angular/core";
import { ElementaryObject } from "src/app/project/model/elementary-object";
import { AppState } from "src/app/types/app-state";
import {createSelector, Store} from "@ngrx/store";
import { FormBuilder, Validators } from "@angular/forms";
import {askAiActions, incidentActions} from "../../../incident/store/actions";
import {Observable, Subject, combineLatest, map, startWith, switchMap, takeUntil} from "rxjs";
import {
	selectEditedIncident, selectIncidentArea, selectIncidentCalculatedArea,
	selectorDiseases,
} from "../../../incident/store/selectors";
import { selectAreas } from "src/app/task/store/selectors";
import {taskActions} from "../../../task/store/actions";
import {IncidentWithDetailsDTO} from "../../../incident/models/IncidentWithDetailsDTO";
import {manageProjectAreaDto} from "../../../project/DTO/manage-project-area-dto";
import {DiseaseDTO} from "../../../incident/models/DiseaseDTO";
import { IncidentState } from "../../../incident/models/IncidentState";
import { TranslateService } from "@ngx-translate/core";
import { IncidentPriority } from "../../../incident/models/IncidentPriority";

@Component({
	selector: 'app-edit-incident',
	templateUrl: './edit-incident-base.component.html',
	styleUrls: ['./edit-incident-base.component.scss'],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {'class': 'content-container padding-container'}
})
export class EditIncidentBaseComponent implements OnInit, OnDestroy {
	private formBuilder = inject(FormBuilder);
	protected store = inject(Store<AppState>);
	private destroy$ = new Subject<void>();
	private translate = inject(TranslateService);

	incidentStates: IncidentState[] = [
		IncidentState.New,
		IncidentState.Suspended,
		IncidentState.InProgress,
		IncidentState.Rejected,
		IncidentState.Closed,
	];

	readonly vm$ = this.store.select(editIncidentComponentVmSelector);

	incidentId?: string;
	isPopup: boolean = false;

	form = this.formBuilder.group({
			number: [{ value: '', disabled: true }],
			state: [{ value: '', disabled: false }],
			name: [null as string | null | undefined],
			description: [''],
			source: [{ value: '', disabled: true }],
			diseaseId: [null as number | null | undefined],
			elementaryObjectId: null as number | null | undefined,
			priority: null as number | null | undefined
	});

	private readonly priorityYellowTranslationKey: string = 'INCIDENT.PRIORITY.YELLOW'
	private readonly priorityOrangeTranslationKey: string = 'INCIDENT.PRIORITY.ORANGE'
	private readonly priorityRedTranslationKey: string = 'INCIDENT.PRIORITY.RED'
	private readonly translationKeys: string[] = [this.priorityYellowTranslationKey, this.priorityOrangeTranslationKey, this.priorityRedTranslationKey];

	activeOptions$: Observable<{name: string | null, id: IncidentPriority | null}[]> = combineLatest([
		this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(this.translationKeys))
		)]
	).pipe(
		map(([translations]) => [
			{ name: translations[this.priorityYellowTranslationKey], id: IncidentPriority.Yellow },
			{ name: translations[this.priorityOrangeTranslationKey], id: IncidentPriority.Orange },
			{ name: translations[this.priorityRedTranslationKey], id: IncidentPriority.Red },
			{ name: '', id: null }
		])
	);

	ngOnInit(): void {
		this.store.dispatch(taskActions.loadAreas());
		this.store.dispatch(incidentActions.loadDiseases());

		if (!this.incidentId) {
			return;
		}

		this.store.dispatch(incidentActions.getIncidentById({ incidentId: this.incidentId }));
		this.store.dispatch(incidentActions.getChangesHistory({ incidentId: this.incidentId }));
		this.store.dispatch(askAiActions.getChatHistory({incidentId: this.incidentId}));
		this.store.select(selectEditedIncident).pipe(takeUntil(this.destroy$)).subscribe(incident => {
			if (!incident) {
				return;
			}

			this.form.patchValue({...incident});

			this.store.dispatch(incidentActions.changeIncidentArea({areaId: incident.projectAreaId, elementaryObjectId: incident.elementaryObjectId}));

			if (incident.elementaryObjectId) {
				this.form.get('elementaryObjectId')?.disable();
			}
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	submit = () => {}

	onStateChange(event: any) {
		this.store.dispatch(incidentActions.changeState({incidentId: this.incidentId!, state: event.value}));
	}

	onElementaryObjectChanged = (e: any) => this.store.dispatch(incidentActions.changeIncidentElementaryObject({elementaryObjectId: e.value}));
}

interface editIncidentComponentVm {
	incident?: IncidentWithDetailsDTO,
	areas: manageProjectAreaDto[],
	diseases: DiseaseDTO[],
	elementaryObjects: ElementaryObject[],
	incidentArea?: manageProjectAreaDto;
	calculatedArea: string | undefined;
}

const editIncidentComponentVmSelector = createSelector(selectEditedIncident, selectorDiseases, selectAreas, selectIncidentArea, selectIncidentCalculatedArea, (incident, diseases, areas, incidentArea, calculatedArea): editIncidentComponentVm => {
	const elementaryObjects = incidentArea?.elementaryObjects ?? [];
	return {incident, diseases, areas, elementaryObjects, incidentArea, calculatedArea}
})

