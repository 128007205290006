import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {ProjectInterface} from "../../model/project.interface";
import * as projectSelectors from "../../project-store/selectors";
import {createSelector, Store} from "@ngrx/store";
import {AppState} from "../../../types/app-state";

@Component({
  selector: 'app-edit-areas-popup',
  template: `
    <p-dialog *ngIf="vm$ | async as vm" header="Edit holes on map" [(visible)]="visible" [modal]="true" (onHide)="popupClosed.emit()" class="dialog-full-page" [resizable]="false" [draggable]="false">
      <ng-template pTemplate="header">
        <h2>{{ 'PROJECT.EDIT_HOLES.TITLE' | translate }}</h2>
      </ng-template>

      <app-edit-areas *ngIf="visible" [project]="vm.selectedProject" />
    </p-dialog>
  `,
  styles: [
  ]
})
export class EditAreasPopupComponent {
  private store = inject(Store<AppState>);

  readonly vm$ = this.store.select(editAreasPopupSelector);

  @Input() visible = false;
  @Output() popupClosed = new EventEmitter();
}

interface editAreasPopupVm {
  selectedProject: ProjectInterface
}

const editAreasPopupSelector = createSelector(projectSelectors.selectedProjectSelector, (selectedProject): editAreasPopupVm => {
  return {selectedProject};
})
