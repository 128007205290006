import { createReducer, on } from "@ngrx/store";
import { attachmentState } from "./attachmentState";
import { attachmentActions } from "./actions";

const initialState: attachmentState = {
    isLoading: false,
    attachments: [],
    urlBeginning: '',
    objectId: '',
};

export const attachmentReducer = createReducer(
    initialState,

    on(attachmentActions.loadAttachments, (state, {urlBeginning, objectId}) => {
        return ({ ...state, isLoading: true, urlBeginning: urlBeginning, objectId: objectId });
    }),

    on(attachmentActions.loadAttachmentsEnd, (state, {attachments}) => {
        return ({ ...state, isLoading: false, attachments: attachments });
    }),

    on(attachmentActions.handleError, (state) => {
        return ({ ...state, isLoading: false });
    }),

    on(attachmentActions.reset, (state) => {
        return ({ ...state, isLoading: false, attachments: [] });
    }),

);
