import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {loadPanelConfig} from "../config/load-panel-default.config";

@Injectable({
  providedIn: 'root'
})
export class LoadPanelService {
  isVisible$ = new BehaviorSubject<boolean>(false);
  message$ = new Subject<string>();

  showLoadPanel = (message: string = loadPanelConfig.message) => {
		this.isVisible$.next(true);
		this.message$.next(message);
  }

  hideLoadPanel = () => this.isVisible$.next(false)
}
