import {PopupOptions} from "mapbox-gl";

export interface GrassQualityParameter {
  name: string,
  displayName: string,
  formula?: string,
  category: ParameterCategory,
  translationKey: string
}

export enum ParameterCategory {
  'VisualAssessment' = 'Visual assessment',
  'VegetationIndicators' = 'Vegetation',
  'MineralIngredients' = 'Nutrients',
  'Diseases' = 'Diseases',
	'Others' = 'Others'
}

export enum ParameterCategoryTranslationKey {
  'VisualAssessment' = 'GRASS_PARAMETERS.PARAMETERS_CATEGORY.VISUAL_ASSESSMENT',
  'VegetationIndicators' = 'GRASS_PARAMETERS.PARAMETERS_CATEGORY.VEGETATION',
  'Others' = 'GRASS_PARAMETERS.PARAMETERS_CATEGORY.OTHERS',
  'MineralIngredients' = 'GRASS_PARAMETERS.PARAMETERS_CATEGORY.NUTRIENTS',
  'Diseases' = 'GRASS_PARAMETERS.PARAMETERS_CATEGORY.DISEASE'
}

export enum DiseaseType {
  AbsenceOfDisease = 'AbsenceOfDisease',
  SnowMold = 'SnowMold',
  YellowStain = 'YellowStain'
}

export enum DiseaseColor {
  AbsenceOfDisease = '#01B072',
  SnowMold = '#BFBFBF',
  YellowStain = '#F9D923'
}

export enum DiseaseValue {
  AbsenceOfDisease = 1,
  SnowMold = 2,
  YellowStain = 3
}

export const demoProjectId = 1;

export const getParamCategoryByKey = (key: string): ParameterCategory => ParameterCategory[key as keyof typeof ParameterCategory];

export const getParamCategoryTranslationKeyByKey = (key: string): ParameterCategoryTranslationKey => ParameterCategoryTranslationKey[key as keyof typeof ParameterCategory];

// INFO: The display order is determined by the position in the table.
export const grassQualityParameterNames: GrassQualityParameter[] = [
  // Classification
  // { name: "sod", displayName: 'Sod', category: ParameterCategory.VisualAssessment },
  // { name: "general", displayName: 'General', category: ParameterCategory.VisualAssessment },
  // { name: "color", displayName: 'Color', category: ParameterCategory.VisualAssessment },
  // { name: "slender", displayName: 'Slender', category: ParameterCategory.VisualAssessment },
  // Regression
  // { name: "fall", displayName: 'SPAD', category: ParameterCategory.VegetationIndicators },
  // { name: "normalizedDifferenceVegetationIndex", displayName: 'NDVI', category: ParameterCategory.VegetationIndicators },
  // { name: "leafAreaIndexMean", displayName: 'LAI', category: ParameterCategory.VegetationIndicators },

  { name: "nitrogen", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.NITROGEN', displayName: 'Nitrogen', formula: 'N', category: ParameterCategory.MineralIngredients },
  { name: "potassium", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.POTASSIUM', displayName: 'Potassium', formula: 'K', category: ParameterCategory.MineralIngredients },
  { name: "phosphorus", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.PHOSPHORUS', displayName: 'Phosphorus', formula: 'P', category: ParameterCategory.MineralIngredients },
  { name: "magnesium", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.MAGNESIUM', displayName: 'Magnesium', formula: 'Mg', category: ParameterCategory.MineralIngredients },
  { name: "calcium", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.CALCIUM', displayName: 'Calcium', formula: 'Ca', category: ParameterCategory.MineralIngredients },
  { name: "sodium", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.SODIUM', displayName: 'Sodium', formula: 'Na', category: ParameterCategory.MineralIngredients },
  // { name: "iron", displayName: 'Iron', formula: 'Fe', category: ParameterCategory.MineralIngredients },
  { name: "zinc", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.ZINC', displayName: 'Zinc', formula: 'Zn', category: ParameterCategory.MineralIngredients },
  { name: "copper", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.COPPER', displayName: 'Copper', formula: 'Cu', category: ParameterCategory.MineralIngredients },
  { name: "manganese", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.MANGANESE', displayName: 'Manganese', formula: 'Mn', category: ParameterCategory.MineralIngredients },

  { name: "ndviClass", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.BIOMASS', displayName: 'Biomass (NDVI)', formula: 'NDVI', category: ParameterCategory.VegetationIndicators },
  { name: "ndreClass", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.CHLOROPHYLL', displayName: 'Chlorophyll (NDRE)', formula: 'NDRE', category: ParameterCategory.VegetationIndicators },
  { name: "pvrClass", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.TURFGRASS_QUALITY', displayName: 'Turfgrass Quality (PVR)', formula: 'PVR', category: ParameterCategory.VegetationIndicators },
  { name: "gliClass", translationKey: 'GRASS_PARAMETERS.PARAMETERS_TREE.TURFGRASS_DENSITY', displayName: 'Turfgrass Density (GLI)', formula: 'GLI', category: ParameterCategory.VegetationIndicators },
  /*
  { name: "lrClass", displayName: 'LR', category: ParameterCategory.VegetationIndicators },
  { name: "ariClass", displayName: 'ARI', category: ParameterCategory.VegetationIndicators },
  { name: "mcaClass", displayName: 'MCA', category: ParameterCategory.VegetationIndicators },
  { name: "msrClass", displayName: 'MSR', category: ParameterCategory.VegetationIndicators },
  { name: "bdrviClass", displayName: 'BDRVI', category: ParameterCategory.VegetationIndicators },
  */

  // { name: "dryBiomass", displayName: 'Dry biomass', category: ParameterCategory.Biomass },
	{ name: "humid", translationKey: "GRASS_PARAMETERS.PARAMETERS_TREE.HUMID", displayName: 'Humid', category: ParameterCategory.Others },
  // { name: "freshBiomass", displayName: 'Fresh biomass', category: ParameterCategory.Biomass },
  // { name: DiseaseType.SnowMold, displayName: 'Snow mold', category: ParameterCategory.Diseases },
  // { name: DiseaseType.YellowStain, displayName: 'Yellow stain', category: ParameterCategory.Diseases },
  // { name: DiseaseType.AbsenceOfDisease, displayName: 'Healthy', category: ParameterCategory.Diseases }
]

export const initialParameters = grassQualityParameterNames.map(({name, displayName, formula, category, translationKey}) =>
    ({name, displayName, formula, category, isSelected: false, disabled: false, translationKey,
      filterMinValue: undefined, filterMaxValue: undefined}));

export const selectedParametersLimit = 5;

export const defaultPopupConfig: PopupOptions = {
	closeButton: false,
	closeOnClick: false,
	offset: { top: [0, 1] }
}
