import * as mapboxgl from "mapbox-gl";
import {point} from "../../types/point";
import {LngLat} from "mapbox-gl";
import * as turf from "@turf/turf";
import {AllGeoJSON} from "@turf/turf";
import {FeatureCollection} from "geojson";

export module MapboxUtils {
  export function hasIntersectionBetweenLines(p0: mapboxgl.LngLat, p1: mapboxgl.LngLat, p2: mapboxgl.LngLat, p3: mapboxgl.LngLat) : boolean {
    let denominator = (p3.lng - p2.lng) * (p1.lat - p0.lat) - (p3.lat - p2.lat) * (p1.lng - p0.lng);
    let ua = (p3.lat - p2.lat) * (p0.lng - p2.lng) - (p3.lng - p2.lng) * (p0.lat - p2.lat);
    let ub = (p1.lat - p0.lat) * (p0.lng - p2.lng) - (p1.lng - p0.lng) * (p0.lat - p2.lat);

    if (denominator < 0) {
      ua = -ua;
      ub = -ub;
      denominator = -denominator;
    }

    if (ua >= 0 && ua < denominator && ub >= 0 && ub < denominator && denominator !== 0) {
      return true;
    }
    return false;
  }

  export function getCoordinates(gps: point | null | undefined): mapboxgl.LngLat {
    const longitude: number = gps?.x ? +gps.x : 0;
    const latitude: number = gps?.y ? +gps.y : 0;
    return new mapboxgl.LngLat(longitude, latitude);
  }

  export function lngLatToPoint(lngLat: LngLat): point {
    return {
      x: lngLat.lng.toString(),
      y: lngLat.lat.toString(),
    }
  }

  export function getCenterFromFeatureCollection(featureCollection: FeatureCollection): LngLat {
    const centerPoint = turf.center(featureCollection as AllGeoJSON);
    const centerPointCoordinates = turf.getCoords(centerPoint);
    return new mapboxgl.LngLat(centerPointCoordinates[0], centerPointCoordinates[1]);
  }
}
