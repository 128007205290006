import { Component, EventEmitter, Input, OnInit, Output, inject } from "@angular/core";
import { getTaskIconPathByName } from "../../../shared/config/task-icons.config";
import { taskStatus } from "../../models/TaskStatus";
import { TranslateService } from "@ngx-translate/core";

@Component({
	template:
		`
			<p-card class="task-board-item {{styleClass}}" pDraggable (onDragStart)="dragStarted.emit(taskId)">
				<h5 class="task-board-item-title" [routerLink]="['/tasks', projectId, 'editTask', taskId]">
					{{ title }}
				</h5>

				<div class="flex justify-content-between align-items-center gap-15">
					<p-tag [style]="statusColor">
						<span class="task-status-text">{{ state | translateTaskStatusAsync | async }}</span>
					</p-tag>

					<div *ngIf="size !== ''" class="tasks-sizes">
						<p>{{ size }}</p>
						<svg-icon src="./assets/images/icons/tasks/resize.svg"/>
					</div>
				</div>

				<!-- <p-tag class="task-importance-tag" value="MEDIUM" severity="warning" />-->
				<ng-template pTemplate="footer">
					<div class="task-board-footer">
						<div class="footer-assignee">
							<p-avatar *ngIf="author" [label]="getInitials(author)" shape="circle"/>
							<p>{{ author }}</p>
						</div>
						<div class="task-board-footer-base-data">
							<p *ngIf="date" class="footer-date">{{ date | localizedDate: 'short' }}</p>
							<div class="footer-svg">
								<svg-icon [src]="getIconPath()"/>
							</div>
						</div>
					</div>
				</ng-template>
			</p-card>
		`,
	selector: 'app-task-board-item',
	styleUrls: ['./task-board-item.component.scss'],
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { 'class': 'task-board-item-wrapper' }
})
export class TaskBoardItemComponent implements OnInit {
	translate = inject(TranslateService);
	@Input() title = '';
	@Input() date?: Date;
	@Input() author: string = '';
	@Input() taskId?: string;
	@Input() projectId!: number;
	@Input() size?: string;
	@Input() icon!: string;
	@Input() state!: taskStatus;
	@Output() dragStarted = new EventEmitter<string>();
	styleClass!: string;
	statusColor!: any;

	ngOnInit() {
		let color: string;
		switch (this.state) {
			case taskStatus.New:
				color = '#06D143';
				break;
			case taskStatus.Scheduled:
				color = '#D071F8';
				break;
			case taskStatus.InProgress:
				color = '#7AA178';
				break;
			case taskStatus.Canceled:
				color = '#FC5353';
				break;

			case taskStatus.Suspended:
				color = '#535353';
				break;

			case taskStatus.Closed:
				color = '#858585';
				this.styleClass = 'task-closed';
				break;
		}
		this.statusColor = { 'background': color};
	}

	getInitials = (name: string): string => {
		return name.split(' ').map(word => word.substring(0, 1).toUpperCase()).join('');
	}

	getIconPath = (): string => getTaskIconPathByName(this.icon);
}
