import {ActionReducer, MetaReducer} from "@ngrx/store";

export function reset(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    if (action.type === '[All] Reset Store') {
      return reducer({}, action);
    }

    return reducer(state, action);
  }
}

export const metaReducers: MetaReducer<any>[] = [reset];
