import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { OneAtlasPredictionInterface } from '../../one-atlas-store/types';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-one-atlas-prediction',
    templateUrl: './one-atlas-prediction.component.html',
    imports: [NgIf],
    standalone: true,
})
export class OneAtlasPredictionComponent {
    yearSum: number = 0;
    monthAvg: number = 0;

    constructor(private config: DynamicDialogConfig) {
        const data: OneAtlasPredictionInterface = config.data;
        this.yearSum = data.months.reduce((sum, a) => sum + a.photosCount, 0);
        this.monthAvg = Math.floor(this.yearSum / 12);
    }

    public shouldShowMonthInfo(): boolean {
        return this.monthAvg > 0;
    }

    public shouldShowYearInfo(): boolean {
        return this.monthAvg == 0 && this.yearSum > 0;
    }

    public shouldShowNoDataInfo(): boolean {
        return this.monthAvg == 0 && this.yearSum == 0;
    }
}