import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {BaseResponse} from "../../../shared/model/api-responses";
import {areaEnds, geoJsonFileSourceLink, photoInfo, tilesFilesDto} from "../../models/types";
import {environment} from "../../../../environments/environment";
import {point} from "../../../types/point";

@Injectable({
  providedIn: "root"
})
export class PhotoParametersApiService {
  private http = inject(HttpClient);
  getProjectPhotoHexParameters = (processingFileVersionId: number): Observable<getProjectPhotoHexParametersResponse> => {
		const endpoint = `${environment.projectApiUrl}photos/hex-parameters/${processingFileVersionId}`;
		return this.http.get<getProjectPhotoHexParametersResponse>(endpoint);
  }
}

interface getProjectPhotoHexParametersResponse extends BaseResponse {
  hexZooms: number[];
  photoInfos: photoInfo[];
  fileLinks: geoJsonFileSourceLink[];
	gps: point;
  areasEnds: areaEnds[];
	bbox: number[],
	tilesLinks: tilesFilesDto[];
}


