import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {EditIncidentBaseComponent} from "./edit-incident-base.component";
import {UpdateIncidentCommand} from "../../../incident/models/UpdateIncidentCommand";
import {incidentActions} from "../../../incident/store/actions";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-edit-incident',
  templateUrl: './edit-incident-base.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditIncidentComponent extends EditIncidentBaseComponent implements OnInit {
	private activatedRoute = inject(ActivatedRoute);

	override ngOnInit() {
		this.incidentId = this.activatedRoute.snapshot.paramMap.get('incidentId') ?? undefined;
		super.ngOnInit();
	}

	override submit = () => {
		if(!this.form.valid) {
			return;
		}

		const formValue = this.form.value;

		const updateCommand: UpdateIncidentCommand = {
			incidentId: this.incidentId!,
			diseaseId: formValue.diseaseId ?? undefined,
			name: formValue.name ?? undefined,
			description: formValue.description ?? undefined,
			elementaryObjectId: formValue.elementaryObjectId ?? undefined,
			priority: formValue.priority ?? undefined,
		}

		this.store.dispatch(incidentActions.updateIncident({command: updateCommand, editedFromPopup: false}));
	}
}
