import {
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import {AppState} from "../../types/app-state";
import {createSelector, Store} from "@ngrx/store";
import {Subject, takeUntil} from "rxjs";
import {selectUserPermittedFeatures} from "../../profile/store";
import {FeatureName} from "../../admin/types";
import {organizationIdSelector, selectedProjectIdSelector} from "../../project/project-store/selectors";
import {isAccessToProjectFeaturePermitted} from "../utils/feature-authorization-utils";

@Directive({
  selector: '[appCheckProjectFeaturePermission]'
})
export class CheckProjectFeaturePermissionDirective implements OnInit, OnDestroy {
	// todo verify if getting projectId from selector is always possible

  private store = inject(Store<AppState>);
  private templateRef = inject(TemplateRef<any>);
  private viewContainer = inject(ViewContainerRef);
  private changeDetectorRef = inject(ChangeDetectorRef)

  private requiredFeature!: FeatureName;

  @Input() set appCheckProjectFeaturePermission(featureName: FeatureName) {
    this.requiredFeature = featureName;
  }

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.store.select(checkProjectFeatureDirectiveSelector).pipe(takeUntil(this.destroy$))
      .subscribe(({features, organizationId, projectId}) => {
        this.viewContainer.clear();

				const accessPermitted = isAccessToProjectFeaturePermitted(projectId!, organizationId!, this.requiredFeature, features)

        if (accessPermitted) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

const checkProjectFeatureDirectiveSelector = createSelector(selectUserPermittedFeatures, organizationIdSelector, selectedProjectIdSelector, (features, organizationId, projectId) =>({
	features,
	organizationId,
	projectId
}));
