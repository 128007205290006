import {inject, Injectable} from "@angular/core";
import {BaseResponse} from "../model/api-responses";
import {ProjectInterface} from "../../project/model/project.interface";
import {GetUserResponse, OrganizationInterface} from "../../profile/types/user-types";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {role} from "../../admin/types";
import { PersonalData } from "src/app/profile/personal-data/personal-data.interface";
import { MeasurementSystem } from "src/app/types/MeasurementSystem";
import { baseResponse } from "src/app/grass-parameters-presentation/DTO/base-response";

@Injectable({
  providedIn: "root"
})
export class UserDataInitApiService {
  private readonly baseUrl: string = environment.projectApiUrl;
  private http = inject(HttpClient);

  getLoggedInUserData = (organizationId?: number): Observable<userInfoResponse> => {
    let params = new HttpParams();

    if (organizationId) {
      params = params.append('organizationId', organizationId);
    }

    return this.http.get<userInfoResponse>(`${this.baseUrl}logged-in-user-data`, { params });
  };

  getLoggedInUserInfo = (): Observable<PersonalData> => {
    const email = localStorage?.getItem('userAgro');

    if (email === null) {
      throw new Error("User not logged in");
    }

    const options = { params: new HttpParams().set('email', email) }
    return this.http.get<PersonalData>(`${this.baseUrl}get-user-by-email`, options);
  };

  getLoggedInUser = () => this.http.get<GetUserResponse>(`${this.baseUrl}get-user-by-email`, {params: new HttpParams().set('email', localStorage.getItem('userAgro')!)});

  updateCurrentUserSettings (measurementSystem: MeasurementSystem, acceptNotifications: boolean) {
    return this.http.put<baseResponse>(`${this.baseUrl}update-logged-in-user-settings`, {measurementSystem, acceptNotifications});
  }
}

export interface userInfoResponse extends BaseResponse {
  userOrganizations: OrganizationInterface[];
  userRoles: role[];
  userProjects: ProjectInterface[];
  organizationId: number;
	userFullName: string;
	isHelpDeskAdmin: boolean;
	active: boolean;
}
