<p-scrollPanel id="scrollview" class="area-dialog-list">
  <section class="drawn-areas-sidebar mapbox-sidebar">
    <div class="mapbox-sidebar-elements">
      <div class="mapbox-sidebar-title-box fixed-element">
        <h5 class="mapbox-sidebar-title">{{ 'COMMON.HOLES' | translate }}</h5>
        <p-button *ngIf="canEdit" pTooltip="Add hole" tooltipZIndex="9999" tooltipPosition="right" icon="pi pi-plus"
          styleClass="p-button-success p-button-square-20" (onClick)="initNewArea()"></p-button>
      </div>
      <ul>
        <ng-container *ngFor="let area of areas">
          <div class="area-row">
            <li (click)="selectArea(area)" class="area-li"
              [ngClass]="{'element-selected': area.uuid === (selectedArea$ | async)?.uuid || area.uuid === (expandedArea$ | async)?.uuid}">
              {{area.name}}<span class="rows-count"
                *ngIf="area.elementaryObjects?.length"><sup>{{area.elementaryObjects?.length}}</sup></span>
            </li>
            <p-button *ngIf="canEdit" [pTooltip]="'PROJECT.EDIT_HOLES.ADD_TYPE_FOR' | translate:{areaname: area.name}" tooltipZIndex="9999" tooltipPosition="right"
              icon="pi pi-plus" styleClass="p-button-success" (onClick)="initNewElementaryObject(area)"></p-button>
          </div>
          <ul *ngIf="area.uuid === (expandedArea$ | async)?.uuid">
            <li *ngFor="let elementaryObject of area.elementaryObjects" class="element-row"
              [ngClass]="{'element-selected': elementaryObject.uuid === (selectedArea$ | async)?.uuid }"
              (click)="selectElementaryObject(elementaryObject)">
              {{elementaryObject.type}}
            </li>
          </ul>
        </ng-container>
      </ul>
    </div>
  </section>
</p-scrollPanel>
