import { createAction, props } from "@ngrx/store";
import { AttachmentDTO } from "../../model/attachmentDTO";
import { GetAttachmentUrlResponse } from "src/app/project/model/upload.interface";

export const attachmentActions = {
    deleteAttachment: createAction('[Attachment] Delete Attachment', props<{ attachmentId: string }>()),
    handleError: createAction('[Attachment] Error Occurred', props<{ errorMessage: string }>()),
    refreshAttachments: createAction('[Attachment] Refresh Attachments'),
    loadAttachments: createAction('[Attachment] Load Attachments', props<{urlBeginning: string, objectId: string}>()),
    loadAttachmentsEnd: createAction('[Attachment] Load Attachments End', props<{attachments: AttachmentDTO[]}>()),
    getAttachmentUrl: createAction('[Attachment] Get Attachment Url', props<{attachmentId: string}>()),
    getAttachmentUrlSuccess: createAction('[Attachment] Get Attachment Url Success', props<GetAttachmentUrlResponse>()),
    reset: createAction('[Attachment] Reset'),
}
