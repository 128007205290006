import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {chatGtpMessage} from "./ask-ai-api.service";

@Component({
	selector: 'app-ask-ai-chat',
	template: `
		<p-panel class="p-panel-comments">
			<div class="comments-panel">
				<div class="comment-container" *ngFor="let chatGtpMessage of messages">
					<p-avatar shape="circle" [image]="getPostedByUserAvatar(chatGtpMessage.postedBy)!" />
					<div class="comment-content">
						<div class="comment-header">
							<label class="author">{{chatGtpMessage.postedBy}}</label>
							<label class="date">{{chatGtpMessage.postedAt | date:'short'}}</label>
						</div>
						<div class="content">{{chatGtpMessage.message}}</div>
					</div>
				</div>

				<div class="comment-container" *ngIf="streamedChatMessage">
					<p-avatar shape="circle" [image]="getPostedByUserAvatar('')!" />
					<div class="comment-content">
						<div class="comment-header">
							<label class="author">ChatGPT</label>
							<label class="date"></label>
						</div>
						<div class="content">{{streamedChatMessage}}</div>
					</div>
				</div>

				<div class="comment-container-waiting" *ngIf="chatGptAnswerLoading">
					<p-progressSpinner styleClass="chat-progress-spinner" strokeWidth="4" />
					<p>{{ 'INCIDENT.AI.PREPARING_OPINION' | translate }}</p>
				</div>

			</div>

			<ng-template pTemplate="footer" *ngIf="!allQuestionAsked && !aiInteractionFinished">
				<div style="display: flex; flex-direction: column; align-items: center" *ngIf="firstQuestionAsked && !aiInteractionFinished && !clarificationNeeded">
					<p style="padding: 2rem; font-size: 0.7rem; font-weight: 600">{{ 'INCIDENT.AI.IF_YOU_NEED' | translate }}</p>
					<div class="row form-group gap-10">
						<p-button styleClass="p-button-outlined p-button-success" [label]="'INCIDENT.AI.NO' | translate" (onClick)="aiInteractionFinishedClicked.emit()" />
						<p-button styleClass="p-button-success" [label]="'INCIDENT.AI.YES' | translate" (onClick)="clarificationNeededClick.emit()" />
					</div>
				</div>
				<ng-container *ngIf="firstQuestionAsked && clarificationNeeded">
					<h4>{{ 'INCIDENT.AI.SEND_MESSAGE' | translate }}</h4>
					<div class="input-box label-top">
						<label>{{ 'INCIDENT.AI.NEW_MESSAGE' | translate }}</label>
						<textarea pInputTextarea cols="80" [(ngModel)]="question"></textarea>
					</div>
					<div class="comment-action">
						<p-button [label]="'INCIDENT.AI.SEND_MESSAGE' | translate" styleClass="p-button-success" (onClick)="sendChatGptMessage()"/>
					</div>
				</ng-container>
			</ng-template>
		</p-panel>
	`
})
export class AskAiChatComponent {
	@Input() messages: chatGtpMessage[] = [];
	@Input() chatGptAnswerLoading: boolean = false;
	@Input() currentAuthor: string = '';
	@Input() firstQuestionAsked: boolean = false;
	@Input() allQuestionAsked: boolean = false;
	@Input() clarificationNeeded: boolean = false;
	@Input() aiInteractionFinished: boolean = false;

	@Input() streamedChatMessage: string = '';

	@Output() chatGptQuestionAsked = new EventEmitter<{ question: string, author: string }>();
	@Output() clarificationNeededClick = new EventEmitter();
	@Output() aiInteractionFinishedClicked = new EventEmitter();

	question: string = '';

	sendChatGptMessage = () => {
		this.chatGptQuestionAsked.emit({ question: this.question, author: this.currentAuthor });
		this.question = '';
	}

	getPostedByUserAvatar = (postedBy: string | null | undefined) : string | null => {
		if (!postedBy) {
			return './assets/images/skim_logo_sygnet_circle_blue.svg';
		}

		return null;
	}
}
