import {createReducer, on} from "@ngrx/store";
import {oneAtlasState} from "./types";
import * as oneAtlasActions from './actions';

export const initialState: oneAtlasState = {
    oneAtlasSearch: undefined,
    oneAtlasOrders: [],
    errorMessage: "",
    isLoading: false
};

export const oneAtlasReducer = createReducer(
    initialState,
    on(oneAtlasActions.getOneAtlasPhotoInfos, (state) => ({...state, isLoading: true})),
    on(oneAtlasActions.getOneAtlasPhotoInfosSuccess, (state, action) => ({...state, oneAtlasSearch: action.results, isLoading: false})),
    on(oneAtlasActions.findOneAtlasCreatedOrders, (state) => ({...state, isLoading: true})),
    on(oneAtlasActions.findOneAtlasCreatedOrdersSuccess, (state, action) => ({...state, oneAtlasOrders: action.orders, isLoading: false})),
    on(oneAtlasActions.acceptOneAtlasOrderPriceSuccess, (state, action) => ({...state, oneAtlasOrders: state.oneAtlasOrders.filter(o => o.processId != action.processId) })),
    on(oneAtlasActions.rejectOneAtlasOrderPriceSuccess, (state, action) => ({...state, oneAtlasOrders: state.oneAtlasOrders.filter(o => o.processId != action.processId) })),
    on(oneAtlasActions.handleError, (state) => ({...state, isLoading: false})),
);