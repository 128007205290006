import {Pipe, PipeTransform, inject} from "@angular/core";
import {taskStatus} from "../../task/models/TaskStatus";
import { TranslateService } from "@ngx-translate/core";
import {Observable, startWith, switchMap} from "rxjs";

@Pipe({ name: 'translateTaskStatusAsync' })
export class TranslateTaskStatusAsyncPipe implements PipeTransform {
	private translate = inject(TranslateService);

	transform(value: taskStatus): Observable<string> {
		return this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(`TASK.STATUS.${value.toUpperCase()}`))
		)
	}
}
