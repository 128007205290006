import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {baseResponse} from "../../grass-parameters-presentation/DTO/base-response";
import {
	greenSpeedExaminationFormModel,
	changeGreenSpeedExaminationDto,
	greenSpeedNormGridModel,
	holeDropDownModel,
	holeObjectDropDownModel,
	examinationGridModel,
	changeGreenSpeedNormDto,
	addGreenSpeedExaminationDto, 
	addAcidityExaminationDto, 
	acidityExaminationFormModel, 
	biomassExaminationFormModel,
	laboratoryExaminationFormModel,
	changeAcidityExaminationDto,
	addBiomassExaminationDto,
	changeBiomassExaminationDto,
	addLaboratoryExaminationDto,
	changeLaboratoryExaminationDto,
	examinationGridParameters,
} from "../types";
import * as dayjs from "dayjs";

@Injectable({
	providedIn: "root"
})
export class ExaminationsApiService {
	private http = inject(HttpClient);
	private readonly baseUrl = environment.projectApiUrl;

	getHoles = (projectId: number) => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/holes`;
		return this.http.get<getHolesResponse>(url);
	}

	getHoleObjects = (projectId: number, holeId: number): Observable<getHoleObjectsResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/holes/${holeId}/objects`;
		return this.http.get<getHoleObjectsResponse>(url);
	}

	getExaminations = (projectId: number, parameters: examinationGridParameters): Observable<getExaminationsResponse> => {
        let params = new HttpParams({
			fromObject: {
			  	skip: parameters.skip,
				take: parameters.take,
			}
		});

		if (parameters.examinationType != null) {
			params = params.append('examinationType', parameters.examinationType);
		}

		if (parameters.holeIds && parameters.holeIds.length > 0) {
			parameters.holeIds.forEach(holeId => {
			  params = params.append('projectAreaIds', holeId);
			});
		}

		if (parameters.holeObjectTypeIds && parameters.holeObjectTypeIds.length > 0) {
			parameters.holeObjectTypeIds.forEach(holeObjectTypeId => {
			  params = params.append('elementaryObjectTypes', holeObjectTypeId);
			});
		}
		
		if (parameters.dateRange !== null && parameters.dateRange !== undefined) {
			const dateFrom = parameters.dateRange[0];
			const dateTo = parameters.dateRange[1];
			params = params.append('dateFrom', dateFrom.toISOString());
			params = params.append('dateTo', dayjs(dateTo).add(1, 'day').subtract(1, 'second').toISOString());
		}

		if (parameters.sortField && parameters.sortOrder) {
			params = params.append('sortOrder', parameters.sortField + ((parameters.sortOrder > 0) ? '' : ' desc'));
		}

		const url = `${this.baseUrl}projects/${projectId}/examinations`;
		return this.http.get<getExaminationsResponse>(url, { params: params });
	}
	
	getGreenSpeedNorms = (projectId: number): Observable<getGreenSpeedNormsResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/norms`;
		return this.http.get<getGreenSpeedNormsResponse>(url);
	}

	getGreenSpeedNorm = (projectId: number, greenSpeedNormId: number): Observable<getGreenSpeedNormResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/norms/${greenSpeedNormId}`;
		return this.http.get<getGreenSpeedNormResponse>(url);
	}

	changeGreenSpeedNorm = (projectId: number, dto: changeGreenSpeedNormDto): Observable<changeGreenSpeedNormResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/norms`;
		return this.http.post<changeGreenSpeedNormResponse>(url, dto);
	}

	addGreenSpeedExamination = (projectId: number, dto: addGreenSpeedExaminationDto): Observable<addGreenSpeedExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed`;
		return this.http.post<addGreenSpeedExaminationResponse>(url, dto);
	}

	getGreenSpeedExamination = (projectId: number, greenSpeedExaminationId: number): Observable<getGreenSpeedExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/${greenSpeedExaminationId}`;
		return this.http.get<getGreenSpeedExaminationResponse>(url);
	}

	changeGreenSpeedExamination = (projectId: number, greenSpeedExaminationId: number, dto: changeGreenSpeedExaminationDto): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/${greenSpeedExaminationId}`;
		return this.http.patch<baseResponse>(url, dto);
	}

	removeGreenSpeedExamination = (projectId: number, greenSpeedExaminationId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/green-speed/${greenSpeedExaminationId}`;
		return this.http.delete<baseResponse>(url);
	}
	
	addAcidityExamination = (projectId: number, dto: addAcidityExaminationDto): Observable<addAcidityExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/acidity`;
		return this.http.post<addAcidityExaminationResponse>(url, dto);
	}

	getAcidityExamination = (projectId: number, acidityExaminationId: number): Observable<getAcidityExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/acidity/${acidityExaminationId}`;
		return this.http.get<getAcidityExaminationResponse>(url);
	}

	changeAcidityExamination = (projectId: number, acidityExaminationId: number, dto: changeAcidityExaminationDto): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/acidity/${acidityExaminationId}`;
		return this.http.patch<baseResponse>(url, dto);
	}

	removeAcidityExamination = (projectId: number, acidityExaminationId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/acidity/${acidityExaminationId}`;
		return this.http.delete<baseResponse>(url);
	}
	
	addBiomassExamination = (projectId: number, dto: addBiomassExaminationDto): Observable<addBiomassExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/biomass`;
		return this.http.post<addBiomassExaminationResponse>(url, dto);
	}

	getBiomassExamination = (projectId: number, biomassExaminationId: number): Observable<getBiomassExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/biomass/${biomassExaminationId}`;
		return this.http.get<getBiomassExaminationResponse>(url);
	}

	changeBiomassExamination = (projectId: number, biomassExaminationId: number, dto: changeBiomassExaminationDto): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/biomass/${biomassExaminationId}`;
		return this.http.patch<baseResponse>(url, dto);
	}

	removeBiomassExamination = (projectId: number, biomassExaminationId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/biomass/${biomassExaminationId}`;
		return this.http.delete<baseResponse>(url);
	}
	
	addLaboratoryExamination = (projectId: number, dto: addLaboratoryExaminationDto): Observable<addLaboratoryExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/laboratory`;
		return this.http.post<addLaboratoryExaminationResponse>(url, dto);
	}

	getLaboratoryExamination = (projectId: number, laboratoryExaminationId: number): Observable<getLaboratoryExaminationResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/laboratory/${laboratoryExaminationId}`;
		return this.http.get<getLaboratoryExaminationResponse>(url);
	}

	changeLaboratoryExamination = (projectId: number, laboratoryExaminationId: Number, dto: changeLaboratoryExaminationDto): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/laboratory/${laboratoryExaminationId}`;
		return this.http.patch<baseResponse>(url, dto);
	}

	removeLaboratoryExamination = (projectId: number, laboratoryExaminationId: number): Observable<baseResponse> => {
		const url = `${this.baseUrl}projects/${projectId}/examinations/laboratory/${laboratoryExaminationId}`;
		return this.http.delete<baseResponse>(url);
	}
}

export interface getHolesResponse extends baseResponse {
	holes: holeDropDownModel[]
}

export interface getHoleObjectsResponse extends baseResponse {
	objects: holeObjectDropDownModel[]
}

export interface getExaminationsResponse extends baseResponse {
	examinations: examinationGridModel[],
	totalRecords: number
}

export interface getGreenSpeedNormsResponse extends baseResponse {
	norms: greenSpeedNormGridModel[]
}

export interface changeGreenSpeedNormResponse extends baseResponse {
	greenSpeedNormId: number
}

export interface getGreenSpeedNormResponse extends baseResponse {
	norm: greenSpeedNormGridModel
}

export interface getGreenSpeedExaminationResponse extends baseResponse {
	examination: greenSpeedExaminationFormModel
}

export interface addGreenSpeedExaminationResponse extends baseResponse {
	id: number
}

export interface getAcidityExaminationResponse extends baseResponse {
	examination: acidityExaminationFormModel
}

export interface addAcidityExaminationResponse extends baseResponse {
	id: number
}

export interface getBiomassExaminationResponse extends baseResponse {
	examination: biomassExaminationFormModel
}

export interface addBiomassExaminationResponse extends baseResponse {
	id: number
}

export interface getLaboratoryExaminationResponse extends baseResponse {
	laboratoryExamination: laboratoryExaminationFormModel
}

export interface addLaboratoryExaminationResponse extends baseResponse {
	id: number
}
