<p-panel [ngClass]="{'with-scroll-body': !isPopup}" class="app-context-panel" *ngIf="vm$ | async as vm">
	<ng-template pTemplate="header" *ngIf="!isPopup">
		<h2 class="mb-30">{{ 'INCIDENT.INCIDENT_EDIT.TITLE' | translate }}</h2>
	</ng-template>
	<form id="controls-container" class="app-context-box app-context-box-columns" [formGroup]="form" (ngSubmit)="submit()">
		<div id="sectionLeft" class="column column-big">
			<div class="column-content">
				<div class="form-group gap-25 column-big-paddings">
					<div class="form-group row gap-25">
						<div class="input-box input-button label-top w-auto">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.STATUS' | translate }}</label>
							<p-dropdown formControlName="state" [options]="incidentStates"
								(onChange)="onStateChange($event)">
								<ng-template let-item pTemplate="item">
									<span>{{ item | TranslateIncidentStateAsyncPipe | async }}</span>
								</ng-template>
								<ng-template let-selectedItem pTemplate="selectedItem">
									<span>{{ selectedItem | TranslateIncidentStateAsyncPipe | async }}</span>
								</ng-template>
							</p-dropdown>
						</div>
						<div class="input-box secondary label-top w-auto normal-text">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.CREATION_DATE' | translate }}</label>
							<input type="text" pInputText value="{{ vm.incident?.createdAt | date:'mediumDate' }}" />
						</div>
						<div class="input-box secondary label-top w-auto normal-text">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.SOURCE' | translate }}</label>
							<input type="text" pInputText [disabled]="true" [value]="vm.incident?.source" />
						</div>
						<div class="input-box secondary label-top w-auto disease-number">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.NUMBER' | translate }}</label>
							<input [disabled]="true" type="text" pInputText formControlName="number"
								style="width: 100%" />
						</div>
					</div>

					<div class="form-group row gap-25">
						<div class="input-box secondary p-input-secondary label-top w-100">
							<label>{{ 'INCIDENT.INCIDENT_ADD.NAME' | translate }}</label>
							<input type="text" pInputText formControlName="name" style="width: 100%" maxlength="200"/>
						</div>
					</div>

					<div class="form-group row gap-25">
						<div class="input-box label-top w-50">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.CATEGORY' | translate }}</label>
							<p-dropdown [options]="vm.diseases" optionLabel="name" optionValue="id"
								formControlName="diseaseId" [showClear]="true" [autoDisplayFirst]="false"
								class="h-100">
								<ng-template let-diseaseCategory pTemplate="selectedItem">
									<div>{{diseaseCategory.name | translateIncidentCategoryAsync | async}}</div>
								</ng-template>
								<ng-template let-diseaseCategory pTemplate="item">
									<div>{{diseaseCategory.name | translateIncidentCategoryAsync | async}}</div>
								</ng-template>
							</p-dropdown>
						</div>

						<div class="input-box label-top w-50">
							<label>{{ 'INCIDENT.INCIDENT_ADD.PRIORITY' | translate }}</label>
							<p-dropdown *ngIf="this.activeOptions$ | async as activeOptions" formControlName="priority" [options]="activeOptions" optionLabel="name" optionValue="id" [showClear]="true" class="h-100" />
						</div>
					</div>
					<div class="form-group row gap-25">
						<div class="form-group row gap-25 w-60">
							<div class="input-box label-top w-50">
								<label>{{ 'INCIDENT.INCIDENT_EDIT.HOLE' | translate }} *</label>
								<p-dropdown [disabled]="true" [options]="vm.areas" [ngModel]="vm.incidentArea"
									[ngModelOptions]="{standalone: true}" optionLabel="name" [style]="{'width':'100%'}"
								></p-dropdown>
							</div>
							<div class="input-box label-top w-50">
								<label>{{ 'INCIDENT.INCIDENT_EDIT.TYPE' | translate }}</label>
								<p-dropdown [options]="vm.elementaryObjects" formControlName="elementaryObjectId"
									optionLabel="name" optionValue="id" [style]="{'width':'100%'}" [showClear]="true"
									[autoDisplayFirst]="false"
									(onChange)="onElementaryObjectChanged($event)"></p-dropdown>
							</div>
						</div>
						<div class="input-box label-top w-auto w-40">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.AREA' | translate }}</label>
							<p>{{ vm.calculatedArea }}</p>
						</div>
					</div>
					<div class="form-group row gap-25">
						<div class="input-box secondary p-input-secondary label-top w-100">
							<label>{{ 'INCIDENT.INCIDENT_EDIT.DESCRIPTION' | translate }}</label>
							<textarea type="text" pInputTextarea formControlName="description"></textarea>
						</div>
					</div>
					<app-attachments urlBeginning="incidents" [objectId]="incidentId!"></app-attachments>
				</div>
				<div id="tasksSection" class="section related-tasks">
					<h4 class="title">{{ 'INCIDENT.RELATED_TASK.TITLE' | translate }}</h4>
					<app-related-tasks [incidentId]="incidentId" [taskHeaders]="vm.incident?.taskHeaders || []"></app-related-tasks>
				</div>
			</div>
			<div class="column-footer column-big-paddings">
				<p class="absolute left font-size-10 color-fontColor">* {{ 'COMMON.REQUIRED' | translate }}</p>
				<p-button [disabled]="!form.valid" [label]="'COMMON.SAVE' | translate" styleClass="p-button-success" (click)="submit()" />
			</div>
		</div>
		<div class="column column-small no-padding" *ngIf="incidentId">
			<app-incident-tab-view [incidentId]="incidentId" class="h-100" />
		</div>
	</form>
	<app-incident-evaluation />
</p-panel>
