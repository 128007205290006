import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OneAtlasService {
    private baseUrl: string = environment.projectApiUrl;

    constructor(private http: HttpClient) {
    }

    public prediction = (projectId: number): Observable<any> =>
        this.http.get(`${this.baseUrl}projects/${projectId}/one-atlas/prediction`, {});

    public search = (projectId: number, from: any, to: any): Observable<any> => 
        this.http.post(`${this.baseUrl}projects/${projectId}/one-atlas/search`, { from: from, to: to });

    public prepareOrder = (projectId: number, processId: string, day: any): Observable<any> =>
        this.http.post(`${this.baseUrl}projects/${projectId}/one-atlas/orders`, { day: day, processId: processId } );

    public findOrders = (projectId: number, status?: number, day?: any, requestor?: string): Observable<any> => {
        let queryParams = new HttpParams();
        
        if (status != null) {
            queryParams = queryParams.append("status", status);
        }

        if (day != null) {
            queryParams = queryParams.append("day", day);
        }
        
        if (requestor != null) {
            queryParams = queryParams.append("requestor", requestor);
        }
        
        return this.http.get(`${this.baseUrl}projects/${projectId}/one-atlas/orders`, { params: queryParams });
    }

    public findCreatedOrders = (projectId: number): Observable<any> =>
        this.http.get(`${this.baseUrl}projects/${projectId}/one-atlas/orders/created`);

    public acceptOrderPrice = (projectId: number, processId: string): Observable<any> =>
        this.http.post(`${this.baseUrl}projects/${projectId}/one-atlas/orders/${processId}/accept`, {});

    public rejectOrderPrice = (projectId: number, processId: string): Observable<any> =>
        this.http.post(`${this.baseUrl}projects/${projectId}/one-atlas/orders/${processId}/reject`, {});
}