import { createReducer, on } from "@ngrx/store";
import {askAiActions, incidentActions} from "./actions";
import { incidentState } from "./incidentState"
import {manageProjectAreaDto} from "../../project/DTO/manage-project-area-dto";
import {CommentDTO} from "../../shared/model/commentDTO";
import {taskActions} from "../../task/store/actions";
import {chatGtpMessage} from "../../shared/components/edit-incident/tab-view/ai/ask-ai-api.service";

const initialState: incidentState = {
	isLoading: false,
	incidents: [],
	totalRecords: 0,
	diseases: [],
	availableTasks: [],
	areas: [],
	chatGptMessages: [],
	chatGptAnswerLoading: false,
	aiInteractionFinished: false,
	aiClarificationNeeded: false,
	changesHistory: [],
	streamedChatMessage: '',
	isEvaluationPopupVisible: false,
	evaluationPopupModel: null
};

export const incidentReducer = createReducer(
	initialState,

	on(incidentActions.loadIncidents, (state, loadIncidentsParameters) => {
			return ({ ...state, isLoading: true, lastLoadIncidentsParameters: loadIncidentsParameters });
	}),

	on(incidentActions.loadIncidentsSuccess, (state, response) => {
			return ({ ...state, isLoading: false, incidents: response.incidents, totalRecords: response.count });
	}),

	on(incidentActions.loadDiseasesSuccess, (state, response) => {
			return ({ ...state, diseases: response.items });
	}),

	on(incidentActions.handleError, (state) => {
			return ({ ...state, isLoading: false });
	}),

	on(incidentActions.reset, (state) => {
			return ({ ...state, isLoading: false, incidents: [], totalRecords: 0, availableTasks: [] });
	}),

	on(incidentActions.loadAvailableTasksSuccess, (state, response) => {
			return ({ ...state, availableTasks: response.tasks });
	}),

	on(incidentActions.getIncidentById, (state) => {
			return ({ ...state, incidentWithDetails: undefined });
	}),

	on(incidentActions.getIncidentByIdSuccess, (state, {incident}) => {
		const incidentArea = state.areas.find(({areaId}) => areaId === incident.projectAreaId);
		return ({ ...state, incidentWithDetails: incident, incidentArea });
	}),

	on(incidentActions.addComment, (state) => ({...state, isLoading: true})),

	on(incidentActions.addCommentSuccess, (state, {comment, author}) => {
		const incidentWithDetails = state.incidentWithDetails!;
		const newComment: CommentDTO = {content: comment, author, date: new Date()};
		return {...state, incidentWithDetails: ({...incidentWithDetails, comments: [...incidentWithDetails.comments, newComment]})}
	}),

	on(incidentActions.changeStateEnd, (state, {stateToUpdate, errorMessage}) => {
		const incidentWithDetails = state.incidentWithDetails!;
		return {...state, incidentWithDetails: ({...incidentWithDetails, state: stateToUpdate})}
	}),

	on(taskActions.loadAreasSuccess, (state, {areas}) => ({...state, areas})),

	on(incidentActions.changeIncidentArea, (state, {areaId, elementaryObjectId}) => {
		const incidentArea = state.areas.find(x => x.areaId === areaId);
		const incidentElementaryObject = incidentArea?.elementaryObjects?.find(x => x.id === elementaryObjectId);

		return ({...state, incidentArea, incidentElementaryObject});
	}),
	on(incidentActions.changeIncidentElementaryObject, (state, {elementaryObjectId}) => {
		const incidentArea = state.incidentArea!;
		return {...state, incidentElementaryObject: incidentArea.elementaryObjects?.find(({id}) => id === elementaryObjectId) }
	}),

	on(askAiActions.getChatHistorySuccess, (state, {messages}) => ({...state, chatGptMessages: messages})),
	on(askAiActions.askAI, (state, {question, author}) => ({
		...state,
		chatGptAnswerLoading: true,
		chatGptMessages: [...state.chatGptMessages, {message: question, postedBy: author || '', postedAt: new Date()}]
	})),
	on(askAiActions.askAISuccess, (state, {message}) => {
		if (message) {
			return ({...state, chatGptMessages: [...state.chatGptMessages, message], streamedChatMessage: ''});
		}

		const streamedChatGptMessage: chatGtpMessage = {
			message: state.streamedChatMessage,
			postedAt: new Date(),
			postedBy: ''
		}

		return ({...state, chatGptMessages: [...state.chatGptMessages, streamedChatGptMessage], streamedChatMessage: ''});
	}),
	on(askAiActions.startAiInteraction, (state, {author}) => {
		const incident = state.incidentWithDetails!;
		const question = state.diseases!.find(x => x.id === incident.diseaseId)?.questionAI ?? '';
		return ({...state, chatGptAnswerLoading: true, chatGptMessages: [...state.chatGptMessages, {message: question, postedBy: author || '', postedAt: new Date()}]});
	}),
	on(askAiActions.finishAiInteraction, (state) => ({...state, aiInteractionFinished: true})),
	on(askAiActions.clarificationNeeded, (state) => ({...state, aiClarificationNeeded: true})),
	on(incidentActions.updateIncidentSuccess, (state) => ({...state, incidentWithDetails: undefined})),
	on(incidentActions.getChangesHistorySuccess, (state, {changesHistory}) => ({...state, changesHistory})),
	on(incidentActions.updateStreamedMessage, (state, {chunk}) => {
		if (!state.streamedChatMessage) {
			return ({...state, streamedChatMessage: state.streamedChatMessage + chunk, chatGptAnswerLoading: false})
		}

		return ({...state, streamedChatMessage: state.streamedChatMessage + chunk});
	}),
	on(incidentActions.clearIncident, (state) => ({...state, incidentArea: undefined, incidentElementaryObject: undefined})),
	on(incidentActions.showEvaluateIncidentPopup, (state, {model}) => ({...state, evaluationPopupModel: model, isEvaluationPopupVisible: true})),
	on(incidentActions.hideEvaluateIncidentPopupAndChangeState, (state) => ({...state, isEvaluationPopupVisible: false}))
);
