<div class="parameters-multiselect-popup-wrapper">
  <h5 class="parameters-multiselect-popup-title">
    <i class="dx-icon-warning"></i>Deviations from the norm
  </h5>
  <table class="parameters-multiselect-popup-parameters-table">
    <tr *ngFor="let param of selectedParams">
      <td class="selected-param-name">{{param.displayName}} <ng-container *ngIf="param.formula">({{param.formula}})</ng-container></td>
      <td class="selected-param-value">{{getParamDisplayValue(param)}}</td>
      <td class="selected-param-deviation">
        <span class="no-deviation-span" *ngIf="isParamInNorm(param)">
          None
        </span>
        <span class="deviation-span" *ngIf="!isParamInNorm(param)">
          <i [ngClass]="getParamDeviationValuePercentage(param) > 0 ? 'dx-icon-spinup' : 'dx-icon-spindown'"></i>
          {{getParamDeviationDisplayValue(param)}}
        </span>
      </td>
    </tr>
  </table>
</div>
