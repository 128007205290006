import { Component, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import {PrimeIcons} from "primeng/api";
import {AppState} from "../../../types/app-state";
import {Store} from "@ngrx/store";
import {selectHelpContextPath} from "../../../routing-store/selectors";
import {filter, map, Observable} from "rxjs";
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-help-context',
    template: `
			<ng-container *ngIf="(hideHelp$ | async) === false">
				<ng-container *ngIf="helpUrl$ | async as helpUrl">
					<p-button styleClass="p-button-link" [icon]="PrimeIcons.QUESTION_CIRCLE" iconPos="right" (onClick)="openHelpPopup(helpUrl)" label="Demo"></p-button>
				</ng-container>
			</ng-container>
		`
})
export class HelpContextComponent {
	private dialogService = inject(DialogService);
	private store = inject(Store<AppState>);
	private router = inject(Router);

	readonly helpUrl$: Observable<string | null> = this.store.select(selectHelpContextPath);
	readonly hideHelp$: Observable<boolean> = this.router.events.pipe(
		filter(event => event instanceof NavigationEnd),
		map((event) => (event as NavigationEnd).url === '/project')
	)

	openHelpPopup = (helpUrl: string): void => {
		this.dialogService.open(HelpDialogComponent, {
			showHeader: true,
			modal: true,
			width: "90%",
			height: "90%",
			data: { srcUrl: helpUrl }
		});
	}

	protected readonly PrimeIcons = PrimeIcons;
}
