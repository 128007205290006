import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import * as reportActions from './actions'
import { ReportService } from "../services/report.service";
import { AgroMessageService } from "../../shared/services/agro-message.service";

@Injectable()
export class ReportEffects {
  constructor(private actions$: Actions,
    private messageService: AgroMessageService,
    private reportServie: ReportService) {
  }

  getProjectReport$ = createEffect(() => 
    this.actions$.pipe(
      ofType(reportActions.getProjectReport),
      switchMap(props => {
        return this.reportServie.getEmbeddedReportConfig(props.projectId).pipe(
          map((response: any) => {
            return reportActions.getProjectReportSuccess({ projectId: props.projectId, config: response.config });
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
              return of(reportActions.handleError());
            })
        );
      })
    )
  );

  refreshProjectReport$ = createEffect(() => 
    this.actions$.pipe(
      ofType(reportActions.refreshProjectReport),
      switchMap(props => {
        return this.reportServie.getEmbeddedReportConfig(props.projectId).pipe(
          map((response: any) => {
            return reportActions.refreshProjectReportSuccess({ projectId: props.projectId, config: response.config });
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
              return of(reportActions.handleError());
            })
        );
      })
    )
  );
}