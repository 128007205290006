import {mineralIngredientUnit} from "../../components/grass-parameters-presentation/laboratory-examination-popup/types";
import {projectGrassParamsNorms} from "../../store/grass-params/types";

interface mineralIngredientNorm {
  paramName: string;
  normDownBoundary: number;
  normUpBoundary: number;
	unit?: mineralIngredientUnit;
}

interface visualAssessmentNorm {
  paramName: string;
  badValue: number;
  goodValue: number;
}

interface vegetationIndicatorNorm {
  paramName: string;
  badValue: number;
  goodValue: number;
}

export interface parameterMapboxNorm {
  paramName: string;
  legendGradient: string;
  interpolatedValues: (string | number)[];
  breakpoints: string[];
}

export enum HexColor {
	Black = '#000',
	DarkGray = '#424242',
	BaseGreen = '#60b856',
	SlightlyDarkerGreen = '#16642a',
	Red = '#FF5733',
	VeryLightBlue = '#E0F7FA',
	SlightlyLighterBlue = '#B3E5FC',
	BaseBlue = '#42A5F5',
	SlightlyDarkerBlue = '#0D47A1',
	VeryDarkBlue = '#000040',
	Yellow = '#fdde61',
	GreenYellow = '#7dc44a',
	Green = '#76B363',
	DarkGreen = '#007e51',
  White = '#FFFFFF',
}

interface mineralIngredientNormColor {
	belowNorm: HexColor,
	transitionLow: HexColor,
	good: HexColor,
	transitionHigh: HexColor,
	aboveNorm: HexColor
}

interface mineralIngredientNormColorsInterface {
	[key: string]: mineralIngredientNormColor
}

const mineralIngredientNormColors: mineralIngredientNormColorsInterface = {
	'default': {
		belowNorm: HexColor.Red,
		transitionLow: HexColor.Yellow,
		good: HexColor.BaseGreen,
		transitionHigh: HexColor.SlightlyDarkerGreen,
		aboveNorm: HexColor.DarkGray
	},
	'humid': {
		belowNorm: HexColor.White,
		transitionLow: HexColor.SlightlyLighterBlue,
		good: HexColor.BaseBlue,
		transitionHigh: HexColor.SlightlyDarkerBlue,
		aboveNorm: HexColor.VeryDarkBlue
	}
}

export const getNormForMineralIngredient = (mineralIngredientNorm: mineralIngredientNorm): parameterMapboxNorm => {
  const normColors = mineralIngredientNormColors[mineralIngredientNorm.paramName] || mineralIngredientNormColors['default'];

  const {normDownBoundary, normUpBoundary, paramName} = mineralIngredientNorm;

  const calculateInterpolatedArray = (): (string | number)[] => {
    let interpolatedValues = [];
		const normBreakThreshold = (normUpBoundary - normDownBoundary) * 0.05;

    interpolatedValues.push(normDownBoundary - normBreakThreshold, normColors.belowNorm);
    interpolatedValues.push(normDownBoundary, normColors.transitionLow);
    interpolatedValues.push(((normDownBoundary + normUpBoundary) / 2), normColors.good);
    interpolatedValues.push(normUpBoundary, normColors.transitionHigh);
    interpolatedValues.push(normUpBoundary + normBreakThreshold, normColors.aboveNorm);

    return interpolatedValues;
  }

  const calculateLinearGradient = (): string => `linear-gradient(to right, ${normColors.belowNorm} 0%, ${normColors.transitionLow} 2%, ${normColors.good} 50%, ${normColors.transitionHigh} 98%, ${normColors.aboveNorm} 100%)`

  const interpolatedValues = calculateInterpolatedArray();
  const legendGradient = calculateLinearGradient();

  const downBreakpoint = normDownBoundary.toFixed(2);
  const middleBreakpoint = ((normDownBoundary + normUpBoundary) / 2).toFixed(2);
  const upBreakpoint = normUpBoundary.toFixed(2);

  return { paramName, interpolatedValues, legendGradient, breakpoints: [downBreakpoint, middleBreakpoint, upBreakpoint] };
}

export const getNormForVisualAssessment = (visualAssessmentNorm: visualAssessmentNorm): parameterMapboxNorm => {
  const badColor = '#fff';
  const goodColor = '#015039';

  const {paramName, goodValue, badValue} = visualAssessmentNorm

  const interpolatedValues = [badValue, badColor, goodValue, goodColor];
  const legendGradient = `linear-gradient(to right, ${badColor}, ${goodColor})`;

  return {paramName, interpolatedValues, legendGradient, breakpoints: [badValue.toString(), goodValue.toString()]};
}

export const getNormForVisualVegetation = (visualNorm: vegetationIndicatorNorm): parameterMapboxNorm => {
  const gradientColors: HexColor[] = [HexColor.Red, HexColor.Yellow, HexColor.GreenYellow, HexColor.Green, HexColor.DarkGreen];
  const valueInterval = (visualNorm.goodValue - visualNorm.badValue + 1) / gradientColors.length;
  const interpolatedValues = [];
  let legendGradient = 'linear-gradient(to right';
  const breakpoints = [];
  for (let currentGradientColorIndex = 0; currentGradientColorIndex < gradientColors.length; currentGradientColorIndex++) {
    const val = visualNorm.badValue + (valueInterval * currentGradientColorIndex);
    breakpoints.push(val.toString());
    interpolatedValues.push(val, gradientColors[currentGradientColorIndex]);
    legendGradient += ', ' + gradientColors[currentGradientColorIndex];
  }
  legendGradient += ')';

  const {paramName} = visualNorm;
  return {paramName, interpolatedValues, legendGradient, breakpoints};
}

export const visualAssessmentsNorms: visualAssessmentNorm[] = [
  {
    paramName: 'color',
    badValue: 1,
    goodValue: 9
  },
  {
    paramName: 'slender',
    badValue: 1,
    goodValue: 9
  },
  {
    paramName: 'sod',
    badValue: 1,
    goodValue: 9
  },
  {
    paramName: 'general',
    badValue: 1,
    goodValue: 9
  },
  {
    paramName: 'normalizedDifferenceVegetationIndex',
    badValue: -1,
    goodValue: 1
  },
  {
    paramName: 'fall',
    badValue: 30,
    goodValue: 60
  }
]

export const vegetationIndicatorNorms: vegetationIndicatorNorm[] = [
  {
    paramName: 'lrClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'ariClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'gliClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'mcaClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'msrClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'pvrClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'ndreClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'ndviClass',
    badValue: 1,
    goodValue: 5
  },
  {
    paramName: 'bdrviClass',
    badValue: 1,
    goodValue: 5
  }
]

export const getParameterMapboxNorms = (norms: projectGrassParamsNorms): parameterMapboxNorm[] => {
	const {mineralIngredientsNorms, visualAssessmentNorms, vegetationIndicatorNorms} = norms;

	return [
		...mineralIngredientsNorms.map(norm => getNormForMineralIngredient(norm)),
		...visualAssessmentNorms.map(norm => getNormForVisualAssessment(norm)),
		...vegetationIndicatorNorms.map(norm => getNormForVisualVegetation(norm)),
		{
			paramName: 'Deviations',
			legendGradient: `linear-gradient(to right, ${HexColor.Red}, ${HexColor.Yellow}, ${HexColor.Green})`,
			interpolatedValues: [0, HexColor.Green, 0.5, HexColor.Yellow, 1, HexColor.Red],
			breakpoints: ["bad", "average", "good"]
		}
	];
}

