import {Component, EventEmitter, Output} from "@angular/core";

@Component({
	selector: 'app-ask-ai-welcome-page',
	template: `
		<section class="p-tabview-page ask-ai-welcome">
			<div class="p-tabview-page-content">
				<div class="ask-ai-welcome-message">
					<svg-icon src="./assets/images/skim_logo_sygnet.svg" />
					<h3>{{ 'INCIDENT.AI.HOW_CAN_HELP' | translate }}</h3>
					<p-button [label]="'INCIDENT.INCIDENT_EDIT.TAB_VIEW.ASK_AI' | translate" styleClass="p-button-success w-195" (onClick)="startAiInteractionClicked.emit()" />
				</div>
				<div class="p-tabview-page-footer ask-ai-welcome-page-footer">
					<p>{{ 'INCIDENT.AI.MAKE_MISTAKES' | translate }}. <br/> {{ 'INCIDENT.AI.CONSIDER_CHECK' | translate }}.</p>
				</div>
			</div>
		</section>
	`
})
export class AskAiWelcomePageComponent {
	@Output() startAiInteractionClicked = new EventEmitter();
}
