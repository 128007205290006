import {HistoryUploadItem} from "../../model/upload.interface";
import {createActionGroup, createFeature, createReducer, emptyProps, on, props} from "@ngrx/store";
import {VerificationStatus} from "../types";

export interface uploadedPhotoFilesState {
	uploadedPhotos: HistoryUploadItem[],
	isLoading: boolean,
}

export const initialState: uploadedPhotoFilesState = {
	uploadedPhotos: [],
	isLoading: false
}

export const uploadedPhotoFilesActions = createActionGroup({
	source: 'Uploaded Photo Files',
	events: {
		'Get Uploaded Photos': emptyProps(),
		'Get Uploaded Photos Success': props<{uploadedPhotos: HistoryUploadItem[]}>(),
		'Process Uploaded Photo': props<{uploadFileId: number}>(),
		'Process Uploaded Photo Success': props<{uploadFileId: number}>(),
		'Repeat Processing Uploaded Photo': props<{uploadFileId: number}>(),
		'Init Processing Uploaded Photo Success': emptyProps(),
		'Update Uploaded Photo Information': props<{uploadFileId: number, updatedPhoto: HistoryUploadItem}>(),
		'Update Uploaded Photo Information Success': props<{uploadFileId: number, updatedPhoto: HistoryUploadItem}>(),
		'Download Uploaded Photo': props<{uploadFileId: number}>(),
		'Download Uploaded Photo Success': props<{presignedUrl: string}>(),
		'Generate Hex Grid': props<{uploadFileId: number}>(),
		'Remove upload file': props<{projectId: number, projectVersionId: number}>(),
		'Handle Error': emptyProps(),
	}
})

export const uploadedPhotoFilesFeature = createFeature({
	name: 'uploadedPhotoFiles',
	reducer: createReducer(
		initialState,
		on(uploadedPhotoFilesActions.getUploadedPhotos, (state) => ({...state, isLoading: true})),
		on(uploadedPhotoFilesActions.getUploadedPhotosSuccess, (state, {uploadedPhotos}) => ({...state, uploadedPhotos, isLoading: false})),
		on(uploadedPhotoFilesActions.processUploadedPhotoSuccess, (state, {uploadFileId}) => ({
			...state,
			uploadedPhotos: state.uploadedPhotos.map(uploadedPhoto => uploadedPhoto.id === uploadFileId ? {
				...uploadedPhoto,
				status: VerificationStatus.Processing
			}: uploadedPhoto)
		})),
		on(uploadedPhotoFilesActions.generateHexGrid, (state, {uploadFileId}) => ({
			...state,
			uploadedPhotos: state.uploadedPhotos.map(uploadedPhoto => uploadedPhoto.id === uploadFileId ? {
				...uploadedPhoto,
				status: VerificationStatus.Uploaded
			}: uploadedPhoto)
		})),
		on(uploadedPhotoFilesActions.repeatProcessingUploadedPhoto, (state, {uploadFileId}) => ({
			...state,
			uploadedPhotos: state.uploadedPhotos.map(uploadedPhoto => uploadedPhoto.id === uploadFileId ? {
				...uploadedPhoto,
				status: VerificationStatus.Processing
			}: uploadedPhoto)
		})),
		on(uploadedPhotoFilesActions.updateUploadedPhotoInformation, (state) => ({...state, isLoading: true})),
		on(uploadedPhotoFilesActions.updateUploadedPhotoInformationSuccess, (state, {uploadFileId, updatedPhoto}) => ({
			...state,
			uploadedPhotos: state.uploadedPhotos.map(uploadedPhoto => uploadedPhoto.id === uploadFileId ? updatedPhoto: uploadedPhoto),
			isLoading: false
		})),
		on(uploadedPhotoFilesActions.handleError, (state) => ({...state, isLoading: false}))
	)
})

export const { selectUploadedPhotos, selectIsLoading } = uploadedPhotoFilesFeature
