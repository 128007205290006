<p-panel [ngClass]="{'with-scroll-body': !isPopup}" class="app-context-panel" *ngIf="vm$ | async as vm">
	<ng-template pTemplate="header" *ngIf="!isPopup">
		<h2 class="mb-30">{{ 'INCIDENT.INCIDENT_ADD.TITLE' | translate }}</h2>
	</ng-template>
	<form class="app-context-box app-context-box-columns" [formGroup]="form" (ngSubmit)="submit()">
		<div class="column w-100">
			<div class="column-content">
				<div class="form-group gap-25 column-big-paddings">
					<div class="form-group row gap-25">
						<div class="form-group row gap-25 w-60">
							<div class="input-box label-top w-auto">
								<label>{{ 'INCIDENT.INCIDENT_ADD.CREATION_DATE' | translate }}</label>
								<p>{{ today | date: 'dd.MM.yyyy' }}</p>
							</div>
							<div class="input-box label-top w-auto">
								<label>{{ 'INCIDENT.INCIDENT_ADD.SOURCE' | translate }}</label>
								<p>{{ 'INCIDENT.INCIDENT_ADD.USER' | translate }}</p>
							</div>
						</div>
					</div>

					<div class="form-group row gap-25">
						<div class="input-box secondary p-input-secondary label-top w-100">
							<label>{{ 'INCIDENT.INCIDENT_ADD.NAME' | translate }}</label>
							<input type="text" pInputText formControlName="name" style="width: 100%" maxlength="200"/>
						</div>
					</div>

					<div class="form-group row gap-25">
						<div class="input-box label-top w-50">
							<label>{{ 'INCIDENT.INCIDENT_ADD.CATEGORY' | translate }}</label>
							<p-dropdown [options]="vm.diseases" optionLabel="name" optionValue="id" formControlName="diseaseId"
													[showClear]="true" [autoDisplayFirst]="false" class="h-100">
								<ng-template let-diseaseCategory pTemplate="selectedItem">
									<div>{{diseaseCategory.name | translateIncidentCategoryAsync | async}}</div>
								</ng-template>
								<ng-template let-diseaseCategory pTemplate="item">
									<div>{{diseaseCategory.name | translateIncidentCategoryAsync | async}}</div>
								</ng-template>
							</p-dropdown>
						</div>

						<div class="input-box label-top w-50">
							<label>{{ 'INCIDENT.INCIDENT_ADD.PRIORITY' | translate }}</label>
							<p-dropdown *ngIf="this.activeOptions$ | async as activeOptions" formControlName="priority" [options]="activeOptions" optionLabel="name" optionValue="id" [showClear]="true" class="h-100" />
						</div>
					</div>
					<div class="form-group row gap-25">
						<div class="form-group row gap-25 w-60">
							<div class="input-box label-top w-50">
								<label>{{ 'INCIDENT.INCIDENT_ADD.HOLE' | translate }} *</label>
								<p-dropdown [options]="vm.areas" formControlName="projectAreaId" optionLabel="name"
														optionValue="areaId" [style]="{'width':'100%'}" [showClear]="true" [autoDisplayFirst]="false"
														(onChange)="onProjectAreaChanged($event)"></p-dropdown>
								<app-error-message [form]="form"
																	 [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'INCIDENT.INCIDENT_ADD.HOLE' | translate}"
																	 propertyName="projectAreaId" validatorRule="required" />
							</div>
							<div class="input-box label-top w-50">
								<label>{{ 'INCIDENT.INCIDENT_ADD.TYPE' | translate }} *</label>
								<p-dropdown [options]="vm.elementaryObjects" formControlName="elementaryObjectId" optionLabel="name" optionValue="id"
														[style]="{'width':'100%'}" [showClear]="true" [autoDisplayFirst]="false" (onChange)="onElementaryObjectChanged($event)"></p-dropdown>
								<app-error-message [form]="form" [errorMessage]="'COMMON.IS_REQUIRED' | translate:{field: 'INCIDENT.INCIDENT_ADD.ELEMENTARY_OBJECT' | translate}" propertyName="elementaryObjectId" validatorRule="required" />
							</div>
						</div>
						<div class="input-box label-top w-auto w-40">
							<label>{{ 'INCIDENT.INCIDENT_ADD.AREA' | translate }}</label>
							<p>{{ vm.calculatedArea }}</p>
						</div>
					</div>
					<div class="form-group row gap-25">
						<div class="input-box secondary p-input-secondary label-top w-100">
							<label>{{ 'INCIDENT.INCIDENT_ADD.DESCRIPTION' | translate }}</label>
							<textarea type="text" pInputTextarea formControlName="description"></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="column-footer column-big-paddings">
				<p class="absolute left font-size-10 color-fontColor">* {{ 'COMMON.REQUIRED' | translate }}</p>
				<p-button [disabled]="!form.valid" [label]="'COMMON.SAVE' | translate" styleClass="p-button-success" (click)="submit()" />
			</div>
		</div>
	</form>
</p-panel>
