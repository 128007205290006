<div class="task-schedule-wrapper">
    <form class="agro-form" [formGroup]="form" (ngSubmit)="submit()">
        <div class="agro-form-property">
            <p-calendar formControlName="dueDate" [minDate]="minDate" [inline]="true" class="box-calendary"></p-calendar>
        </div>
        <div class="task-add-buttons form-group gap-10 row align-items-center">
            <p-button [label]="'COMMON.CANCEL' | translate" styleClass="w-170 p-button-outlined p-button-success p-button-medium" (click)="cancelClick()" />
            <p-button [label]="'TASK.TASK_LIST.SCHEDULE' | translate" styleClass="w-170 p-button-success p-button-medium" [disabled]="form.invalid" (click)="submit()" />
        </div>
    </form>
</div>
