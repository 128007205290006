import {environment} from "../../../../environments/environment";

export interface helpContextPathsDictionary {
	[contextPathKey: string]: string
}

export const helpContextPaths: helpContextPathsDictionary = {
	'tasks': environment.helpPathTasks,
	'photoParameters': environment.helpPathGrassParams,
	'incidents': environment.helpPathIncidents
}
