import {inject, Injectable} from "@angular/core";
import {laboratoryExaminationListVm, manageLaboratoryExaminationDto} from "../laboratory-examination-popup/types";
import {ComponentStore} from "@ngrx/component-store";
import {exhaustMap, filter, map, Observable} from "rxjs";
import {AppState} from "../../../../types/app-state";
import {Store} from "@ngrx/store";
import * as otherObjectSelectors from "../../../store/other-objects/selectors";
import {LaboratoryExaminationDto} from "../../../DTO/item-dto";
import {
	calculateAverageInChosenUnit,
	convertAverage,
	laboratoryExaminationPopupParameterListInitialValue
} from "../laboratory-examination-popup/constants";
import {parseDateToString} from "../../../helpers/date-utils";
import { TranslateService } from "@ngx-translate/core";
import { LaboratoryExaminationType } from "src/app/examinations/types";

interface LaboratoryObjectPopupDetailsState {
  chemicalParametersList: laboratoryExaminationListVm[];
  examinationDate?: Date;
  examinationType?: number;
}

interface laboratoryExaminationDetailsListVm {
  realName: string,
  name: string,
  average?: string;
}

@Injectable()
export class LaboratoryExaminationPopupDetailsComponentStore extends ComponentStore<LaboratoryObjectPopupDetailsState> {
  private store = inject(Store<AppState>);
  private translate = inject(TranslateService);
  
  constructor() {
    super({
      chemicalParametersList: []
    });
  }

  //selectors
  readonly chemicalParameterListItems$ = this.select((state): laboratoryExaminationDetailsListVm[] => {
    return state.chemicalParametersList.map(x => ({
      realName: x.name,
      name: x.displayName,
      average: this.getExaminationDisplayValue(x.Average, x.unit!),
    }));
  });

  readonly examinationDate$ = this.select((state) => parseDateToString(state.examinationDate!));

  readonly examinationType$ = this.select((state) => state.examinationType!);

  readonly popupTitle$ = this.select((state) => {
    const dateDisplayValue = parseDateToString(state.examinationDate!);

    switch(state.examinationType) {
      case LaboratoryExaminationType.Turf:
        return this.translate.instant("GRASS_PARAMETERS.LAB_EXAM.EXAM_FROM", { date: dateDisplayValue, examinationType: this.translate.instant('EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_TURF') });
      case LaboratoryExaminationType.Soil:
        return this.translate.instant("GRASS_PARAMETERS.LAB_EXAM.EXAM_FROM", { date: dateDisplayValue, examinationType: this.translate.instant('EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_SOIL') });
      default:
        throw 'Unknown type';
    }
  });

  readonly vm$ = this.select({
    chemicalParameterListItems: this.chemicalParameterListItems$,
    examinationDate: this.examinationDate$,
    examinationType: this.examinationType$,
    popupTitle: this.popupTitle$
  });

  
  // actions
  readonly initAction = this.updater((state, laboratoryExaminationDto: LaboratoryExaminationDto) => {
    const chemicalParametersList = laboratoryExaminationPopupParameterListInitialValue.map(vm => {
      const manageLaboratoryExaminationDto = laboratoryExaminationDto as manageLaboratoryExaminationDto;
			const averageInStandardUnit = manageLaboratoryExaminationDto[`${vm.name}Average` as keyof manageLaboratoryExaminationDto];
      const average = calculateAverageInChosenUnit(averageInStandardUnit, vm.unit!);
      return {...vm, Average: average}
    });

    const examinationDate = laboratoryExaminationDto.examinationDate;
    const examinationType = laboratoryExaminationDto.laboratoryExaminationType;

    return {...state, examinationDate, examinationType, chemicalParametersList};
  })

  //effects
  readonly init = this.effect(
    (itemId$: Observable<number>) =>
      itemId$.pipe(
        filter(x => x !== undefined),
        exhaustMap((itemId) =>
          this.store.select(otherObjectSelectors.getLaboratoryExaminationByIdSelector(itemId)).pipe(
            map(laboratoryExamination => this.initAction(laboratoryExamination!))
          ))
      )
  );

  //private methods
  private getExaminationDisplayValue = (value: number | undefined | null, unit: string) => value !== undefined && value !== null ? `${value}${unit}` : ''
}
