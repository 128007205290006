import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { baseResponse } from "../grass-parameters-presentation/DTO/base-response";
import { LoadIncidentsParameters } from "./models/LoadIncidentsParameters";
import { GetIncidentsResponse } from "./models/GetIncidentsResponse";
import { IncidentWithDetailsDTO } from "./models/IncidentWithDetailsDTO";
import { AddIncidentCommand } from "./models/AddIncidentCommand";
import { UpdateIncidentCommand } from "./models/UpdateIncidentCommand";
import { TaskService } from "../task/task.service";
import { GetDiseasesResponse } from "./models/GetDiseasesResponse";
import { AddTaskToIncidentCommand } from "./models/AddTaskToIncidentCommand";
import { AddCommentCommand } from "../shared/model/addCommentCommand";
import { IncidentState } from "./models/IncidentState";
import { ChangeStateCommand } from "./models/ChangeStateCommand";
import * as dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class IncidentAPIService {
  private incidentsUrl = environment.projectApiUrl + 'incidents';  // URL to web api
  private diseasesUrl = environment.projectApiUrl + 'dictionaries/diseases';
  private httpClient = inject(HttpClient);
  private taskService = inject(TaskService);
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  getIncidentList(loadIncidentsParameters: LoadIncidentsParameters): Observable<GetIncidentsResponse> {
    let params = new HttpParams({
      fromObject: {
        ProjectId: loadIncidentsParameters.projectId,
        Closed: true,
        Rejected: true,
        Skip: loadIncidentsParameters.skip,
        Take: loadIncidentsParameters.take,
      }
    });

    if (loadIncidentsParameters.projectAreas) {
      loadIncidentsParameters.projectAreas.forEach(area => {
        params = params.append('ProjectAreaIds', area.areaId);
      });
      if (loadIncidentsParameters.elementaryObject)
        params = params.append('ElementaryObjectId', loadIncidentsParameters.elementaryObject.id!);
    }

    if (loadIncidentsParameters.diseases && loadIncidentsParameters.diseases.length > 0) {
      loadIncidentsParameters.diseases.forEach(disease => {
        params = params.append('DiseaseIds', disease.id);
      });
    }

    if (loadIncidentsParameters.states && loadIncidentsParameters.states.length > 0) {
      loadIncidentsParameters.states.forEach(state => {
        params = params.append('states', state);
      });
    }

    if (loadIncidentsParameters.createdAtFrom && loadIncidentsParameters.createdAtTo) {
      params = params.append('CreatedAtFrom', loadIncidentsParameters.createdAtFrom.toISOString());
      let dateTo = dayjs(loadIncidentsParameters.createdAtTo).add(1, 'day').subtract(1, 'second');
      params = params.append('CreatedAtTo', dateTo.toISOString());
    }

    if (loadIncidentsParameters.sortField && loadIncidentsParameters.sortOrder) {
      params = params.append('sortOrder', loadIncidentsParameters.sortField + ((loadIncidentsParameters.sortOrder > 0) ? '' : ' desc'));
    }

    return this.httpClient.get<GetIncidentsResponse>(this.incidentsUrl, { params: params });
  }

  getIncidentById(incidentId: string): Observable<IncidentWithDetailsDTO> {
    let url: string = `${this.incidentsUrl}/${incidentId}`;
    return this.httpClient.get<IncidentWithDetailsDTO>(url);
  }

  addIncident(addCommand: AddIncidentCommand): Observable<baseResponse> {
    return this.httpClient.post<baseResponse>(this.incidentsUrl, addCommand, this.httpOptions);
  }

  updateIncident(updateCommand: UpdateIncidentCommand): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${updateCommand.incidentId}`;
    return this.httpClient.put<baseResponse>(url, updateCommand, this.httpOptions);
  }

  deleteIncident(id: string): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${id}`;
    return this.httpClient.delete<baseResponse>(url);
  }

  getDiseases(): Observable<GetDiseasesResponse> {
    return this.httpClient.get<GetDiseasesResponse>(this.diseasesUrl);
  }

  addTaskToIncident(command: AddTaskToIncidentCommand): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${command.incidentId}/tasks`;
    return this.httpClient.post<baseResponse>(url, command, this.httpOptions);
  }

  removeTaskFromIncident(incidentId: string, taskId: string): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${incidentId}/tasks/${taskId}`;
    return this.httpClient.delete<baseResponse>(url);
  }

  addComment(incidentId: string, command: AddCommentCommand): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${incidentId}/comments`;
    return this.httpClient.post<baseResponse>(url, command, this.httpOptions);
  }

  changeState(incidentId: string, state: IncidentState, evaluation: number | null): Observable<baseResponse> {
    let url: string = `${this.incidentsUrl}/${incidentId}/`;
    let action;
    switch (state) {
      case IncidentState.InProgress:
        action = 'start';
        break;
      case IncidentState.Closed:
        action = 'close';
        break;
      case IncidentState.Rejected:
        action = 'reject';
        break;
      case IncidentState.Suspended:
        action = 'suspend';
        break;
    }
    url += action;

    const command: ChangeStateCommand = {
      incidentId: incidentId,
      evaluation: evaluation
    };
    return this.httpClient.post<baseResponse>(url, command, this.httpOptions);
  }

}
