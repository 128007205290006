import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {countryDto, countryListDto} from "../../project/DTO/country-list-dto";
import {map, Observable, tap} from "rxjs";
import {courseListDto, golfCourseDto} from "../../project/DTO/course-list-dto";
import {getCourseListRequest} from "../../project/requests/get-courses-list-request";
import {AddProjectDto} from "../../project/DTO/add-project-dto";
import { ProviderType } from 'src/app/project/model/types';
import { GetProjectWithIGolfChangesResponse } from 'src/app/project/DTO/getProjectWithIGolfChangesResponse';

@Injectable({
  providedIn: 'root'
})
export class IGolfService {
  private baseUrl: string = environment.projectApiUrl;

  constructor(private http: HttpClient) { }

  getCountriesList = (organizationId: number):Observable<countryDto[]> => {
    return this.http.get<countryListDto>(`${this.baseUrl}organizations/${organizationId}/igolf/countries`)
      .pipe(
        map(response => response.countries)
      );
  };

  getGolfCourseList = (organizationId: number, requestBody: getCourseListRequest):Observable<golfCourseDto[]> => {
    return this.http.post<courseListDto>(`${this.baseUrl}organizations/${organizationId}/igolf/courses`, requestBody)
      .pipe(
        map(response => response.courses)
      );
  };

  getNewProjectFromIGolf = (courseId: string, organizationId: number) => {
    const url = `${this.baseUrl}organizations/${organizationId}/igolf/courses/${courseId}/vectors`;
    return this.http.get<AddProjectDto>(url).pipe(
      map(response => ({
          ...response,
          provider: this.mapProviderToId(response.provider!)
      }))
  );
}

  getProjectWithIGolfChanges = (organizationId: number, projectId: number) => {
    const url = `${this.baseUrl}organizations/${organizationId}/projects/${projectId}/igolf/refresh`;
    return this.http.get<GetProjectWithIGolfChangesResponse>(url);
  }

  mapProviderToId(providerText: any): number {
    switch (providerText) {
        case ProviderType.Manual:
            return 0;
        case ProviderType.IGolf:
            return 1;
        default:
          throw new Error("Unsupported provider type: " + providerText);
    }
}
}
