import {createAction, props} from "@ngrx/store";
import {dateOfPhoto} from "../../models/date-of-photo/date-of-photo";
import {PhotoDataSourceType} from "../../models/photo-data-source-type";
import {ParameterCategory} from "../../constants/config";
import {hexesDataSource, HexGridDataMode, projectGrassParamsNorms} from "./types";
import {PhotoInfoType} from "../../models/photo-info/photo-info-type";
import {hexTrends} from "../../../parameter-trend-api.service";
import {areaEnds, geoJsonFileSourceLink, photoInfo, tilesFilesDto} from "../../models/types";
import {point} from "../../../types/point";
import {
	editGrassNormCommand,
	grassParamNormGridViewModel
} from "../../services/api/project-grass-params-norms-api.service";
import {GeoJsonProperties} from "geojson";
import {
	measurementDto
} from "../../components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";
import {
	observedHexesSnapshot,
} from "../../services/api/observed-hexes-snapshots.api.service";
import {TilesFiles} from "../../models/mapbox/tiles-layers.config";

export const grassParamsActions = {
  getDatesOfPhotos: createAction('[GrassParams] Get Dates Of Photos', props<{projectId: number}>()),
  getDatesOfPhotosSuccess: createAction('[GrassParams] Get Dates Of Photos Success', props<{datesOfPhotos: dateOfPhoto[]}>()),
  changeDateOfPhoto: createAction('[GrassParams] Change Date Of Photo', props<{mapId: string, date?: Date}>()),
  changePhotoType: createAction('[GrassParams] Change Photo Type', props<{mapId: string, photoType: PhotoDataSourceType}>()),
  changeDateOfPhotoOrPhotoTypeSuccess: createAction('[GrassParams] Change Date Of Photo Success', props<{date: Date, photoType: PhotoDataSourceType,
          mapId: string, fileLinks: geoJsonFileSourceLink[], photoInfos: photoInfo[], gps: point, areasEnds: areaEnds[], bbox: number[], tilesLinks: TilesFiles[]}>()),
  toggleSelectedParam: createAction('[GrassParams] Toggle Selected Param', props<{selectedParam: string}>()),
  selectMainParam: createAction('[GrassParams] Select Main Param', props<{selectedParam: string}>()),
  handleErrorResponse: createAction('[GrassParams] Handle Error', props<{errorMessage: string}>()),
  switchToDeviationsMode: createAction('[GrassParams] Switch To Deviations Mode', props<{paramNames: string[]}>()),
  toggleDisease: createAction('[GrassParams] Select Disease', props<{diseaseName: string}>()),
  removeMap: createAction('[GrassParams] Remove Map', props<{mapId: string}>()),
  reset: createAction('[GrassParams] Reset'),
  expandCategory: createAction('[GrassParams] Expand Category', props<{category: ParameterCategory}>()),
  hideCategory: createAction('[GrassParams] Hide Category', props<{category: ParameterCategory}>()),
  hideAllCategories: createAction('[GrassParams] Hide All Categories'),
  switchHexGridMode: createAction('[GrassParams] Switch Hex Grid Mode', props<{hexGridDataMode: HexGridDataMode}>()),
  filterBySectorIds: createAction('[GrassParams] Filter By Sector Ids', props<{sectorIds: number[]}>()),
  filterByTypes: createAction('[GrassParams] Filter By Type', props<{typeNames: PhotoInfoType[]}>()),
	filterByMinMax: createAction('[GrassParams] Filter By Min Max', props<{filterMinValue?: number, filterMaxValue?: number}>()),
	filterByHexIds: createAction('[GrassParams] Filter By Hex Ids', props<{hexIds: number[], selectedParameter: string, zoom: number}>()),
	clearHexIdsFilter: createAction('[GrassParams] Clear Filter By Hex Ids'),
  // filterByZoomValue: createAction('[GrassParams] Filter By Zoom Value', props<{zoomValue: number}>()),
  getTrendsForHex: createAction('[GrassParams] Get Trends For Hex', props<{hexId: number, mapId: string}>()),
  getTrendsForHexSuccess: createAction('[GrassParams] Get Trends For Hex Success', props<{hexTrends?: hexTrends, mapId: string}>()),
  getDataSource: createAction('[GrassParams] Get Data Source', props<{url: string, mapId: string, modifyMap: boolean, kind: string, zoom: number}>()),
  getDataSourceSuccess: createAction('[GrassParams] Get Data Source Success', props<{mapId: string, dataSource: hexesDataSource, modifyMap: boolean}>()),
  changeZoom: createAction('[GrassParams] Change Zoom', props<{zoomValue: number, mapId: string}>()),
  clearParameters: createAction('[GrassParams] Clear selected parameters'),
	getParametersNorms: createAction('[GrassParams] Get Parameters Norms', props<{projectId: number}>()),
	getParametersNormsSuccess: createAction('[GrassParams] Get Parameters Norms Success', props<{projectGrassParamsNorms: projectGrassParamsNorms}>()),
	editParameterNorms: createAction('[GrassParams] Edit Parameter Norms', props<{command: editGrassNormCommand}>()),
	initSelectableParameters: createAction('[GrassParams] Init Selectable Parameters', props<{projectId: number}>()),
	selectHexOnMap: createAction('[GrassParams] Select Hex On Map', props<{hexId: number}>()),
	resetSelectedHex: createAction('[GrassParams] Reset Selected Hex'),
	setDebugModeValues: createAction('[GrassParams] Select Debug Mode Values', props<{geoJsonProperties: GeoJsonProperties}>()),
	resetDebugModeValues: createAction('[GrassParams] Reset Debug Mode Values'),
	getWeatherAroundDate: createAction('[GrassParams] Get Weather Around Date', props<{date?: Date, mapId: string}>()),
	getWeatherAroundDateSuccess: createAction('[GrassParams] Get Weather Around Date Success', props<{measurements: measurementDto[], mapId: string}>()),
	getObservedHexesSnapshots: createAction('[GrassParams] Get Observed Hexes Snapshots'),
	getObservedHexesSnapshotsSuccess: createAction('[GrassParams] Get Observed Hexes Snapshots Success', props<{observedHexesSnapshots: observedHexesSnapshot[]}>()),
	saveObservedHexesSnapshot: createAction('[GrassParams] Save Observed Hexes Snapshot', props<{title: string}>()),
	saveObservedHexesSnapshotSuccess: createAction('[GrassParams] Save Observed Hexes Snapshot Success', props<{snapshot: observedHexesSnapshot}>()),
	getDefaultAndLatestParametersNorms: createAction('[GrassParams] Get Default And Latest Parameter Norms', props<{projectId: number}>()),
	getDefaultAndLatestParametersNormsSuccess: createAction('[GrassParams] Get Default And Latest Parameter Norms Success', props<{items: grassParamNormGridViewModel[]}>()),
	removeObservedHexesSnapshot: createAction('[GrassParams] Remove Observed Hexes Snapshot', props<{snapshotId: number}>()),
	removeObservedHexesSnapshotSuccess: createAction('[GrassParams] Remove Observed Hexes Snapshot Success', props<{snapshotId: number}>())
}
