import {otherObjectsState} from "./types";
import {OtherObjectDataSource, OtherObjectLayer, OtherObjectType} from "../../models/mapbox/types";
import {createReducer, on} from "@ngrx/store";
import {laboratoryExaminationActions, mapIncidentActions, otherObjectActions, sprinklersActions} from "./actions";
import {photoMapViewActions} from "../index";
import {ParametersMapComposition} from "../grass-params/types";

const initialState: otherObjectsState = {
  items: [],
  otherObjectLayers: [
    {
      selected: false,
			type: OtherObjectType.Incident,
      displayName: "Alerts",
      dataSourceName: OtherObjectDataSource.ObservationPoints,
      layerNames: [OtherObjectLayer.ObservationPoints],
      localizedTextKey: "GRASS_PARAMETERS.OTHER_OBJECTS.OBSERVATION_POINTS",
    },
    {
      selected: false,
			type: OtherObjectType.Sprinkler,
      displayName: "Sprinklers",
      dataSourceName: OtherObjectDataSource.Sprinklers,
      layerNames: [OtherObjectLayer.SprinklersFill, OtherObjectLayer.SprinklersLine],
      localizedTextKey: "GRASS_PARAMETERS.OTHER_OBJECTS.SPRINKLERS",
    },
    {
      selected: false,
			type: OtherObjectType.LaboratoryExamination,
      displayName: "Laboratory examinations",
      dataSourceName: OtherObjectDataSource.LaboratoryExaminations,
      layerNames: [OtherObjectLayer.LaboratoryExaminations],
      localizedTextKey: "GRASS_PARAMETERS.OTHER_OBJECTS.LAB_EXAMINATIONS",
    }
  ],
  isLoading: false,
	otherObjectMaps: {
		'map-0': { drawItemsRequired: false },
		'map-1': { drawItemsRequired: false }
	}
};

export const otherObjectsReducer = createReducer(
  initialState,
  on(otherObjectActions.getItems, (state) => ({...state, isLoading: true})),
  on(otherObjectActions.getItemsSuccess, (state, {items}) => ({...state, items, isLoading: false})),
  on(otherObjectActions.selectOtherObject, (state, action) => ({
    ...state, otherObjectLayers: state.otherObjectLayers.map(x => x.displayName === action.otherObject.displayName ? {
      ...x,
      selected: true
    } : x)
  })),
  on(otherObjectActions.unselectOtherObject, (state, action) => ({
    ...state, otherObjectLayers: state.otherObjectLayers.map(x => x.displayName === action.otherObject.displayName ? {
      ...x,
      selected: false
    } : x)
  })),
  on(otherObjectActions.hideItemPopup, (state) => ({...state, editedItem: undefined})),
	on(mapIncidentActions.removeIncidentSuccess, (state) => ({...state, isLoading: false})),
  on(sprinklersActions.addSprinkler, (state) => ({...state, isLoading: true})),
	on(sprinklersActions.addSprinklerSuccess, (state, item) => ({...state, isLoading: false})),
  on(sprinklersActions.editSprinkler, (state) => ({...state, isLoading: true})),
	on(sprinklersActions.editSprinklerSuccess, (state, dto) => ({...state, isLoading: false})),
  on(sprinklersActions.removeSprinkler, (state) => ({...state, isLoading: true})),
	on(sprinklersActions.removeSprinklerSuccess, (state, dto) => ({...state, isLoading: false})),
  on(sprinklersActions.showEditSprinklerPopup, (state, {sprinklerId}) =>
		({
			...state,
			editedItem: state.items.find((item) => (item.itemType === 'Sprinkler' && 'sprinklerId' in item) && item.sprinklerId === +sprinklerId)
		})),
  on(laboratoryExaminationActions.add, (state) => ({...state, isLoading: true })),
	on(laboratoryExaminationActions.addSuccess, (state) => ({...state, isLoading: false})),
  on(laboratoryExaminationActions.edit, (state) => ({...state, isLoading: true})),
	on(laboratoryExaminationActions.editSuccess, (state) => ({...state, isLoading: false})),
  on(laboratoryExaminationActions.remove, (state) => ({...state, isLoading: true})),
	on(laboratoryExaminationActions.removeSuccess, (state) => ({...state, isLoading: false})),
  on(otherObjectActions.handleError, (state) => ({...state, isLoading: false})),
  on(otherObjectActions.reset, () => ({...initialState})),
	on(photoMapViewActions.changeCompositionMode, (state, {composition}) => {
		if (composition === ParametersMapComposition.Single) {
			return state;
		} else {
			return {...state, otherObjectMaps: {...state.otherObjectMaps, 'map-1': {drawItemsRequired: true}}}
		}
	}),
	on(otherObjectActions.drawItems, (state, {mapId}) => ({...state, otherObjectMaps: {...state.otherObjectMaps, mapId: {drawItemsRequired: false}}}))
);
