import {Component, inject, Input} from "@angular/core";
import {AppState} from "../../../../../types/app-state";
import {Store} from "@ngrx/store";
import {incidentActions} from "../../../../../incident/store/actions";
import {selectIncidentComments} from "../../../../../incident/store/selectors";

@Component({
	selector: 'app-incident-comments-section',
	template: `<app-comments-section [comments]="(comments$ | async)!" (commentAdded)="addComment($event)" />`
})
export class IncidentCommentsSectionComponent {
	private store = inject(Store<AppState>);

	@Input() incidentId!: string

	readonly comments$ = this.store.select(selectIncidentComments);

	addComment = (comment: string) => this.store.dispatch(incidentActions.addComment({incidentId: this.incidentId, comment}));
}
