import {projectsState} from "./project-store-types";
import {createReducer, on} from "@ngrx/store";
import * as projectActions from './actions';
import {ProjectInterface} from "../model/project.interface";
import {adminActions} from "../../admin/store";
import * as chooseAreaActions from '../choose-area/choose-area-store/actions';
import { OrganizationInterface } from "src/app/profile/types/user-types";

export const initialState: projectsState = {
  projects: [],
  selectedProject: {},
  organizations: [],
  selectedOrganization: {},
  newProject: {},
  selectedProjectDetails: {},
  errorMessage: "",
  categories: [],
  grassSpecies: [],
  currencies: [],
  areaInfo: null,
  isLoading: false,
};

export const projectReducer = createReducer(
  initialState,
  on(projectActions.getProjects, (state) => ({...state, isLoading: true})),
  on(projectActions.getProjectsSuccess, (state, action) => ({ ...state, projects: action.projects, isLoading: false })),
  on(projectActions.getProjectById, (state, action) => ({...state, selectedProject: state.projects.find(x => x.id === action.projectId) ?? {}})),
  on(projectActions.getOrganizationById, (state, action) => ({...state, selectedOrganization: state.organizations.find(x => x.id === action.organizationId) ?? {}})),
  on(projectActions.initNewProject, (state, action) => ({...state, newProject: action.newProject})),
  on(projectActions.createNewProject, (state) => ({...state, isLoading: true})),
  on(projectActions.createNewProjectSuccess, (state, action) => ({...state, projects: [...state.projects, action.newProject], newProject: {}})),
  on(projectActions.handleError, (state, action) => ({...state, errorMessage: action.error, isLoading: false})),
  on(projectActions.initProjectFromIGolf, (state) => ({...state, isLoading: true})),
  on(projectActions.initProjectFromIGolfSuccess, (state, action) => ({...state, newProject: action.importedProject, isLoading: false})),
  on(projectActions.removeProject, (state) => ({...state, isLoading: true})),
  on(projectActions.removeProjectSuccess, (state, action) => ({...state, projects: state.projects.filter(x => x.id !== action.projectId), isLoading: false})),
  on(projectActions.updateProject, (state) => ({...state, isLoading: true})),
  on(projectActions.updateProjectSuccess, (state, action) => {
    const projectId = state.selectedProject.id;

    const projectList = state.projects.map(project => {
      return project.id === projectId ? {
        ...project, projectName:
          action.editProject.projectName,
          currency: action.editProject.currency,
				  grassSpeciesId: action.editProject.grassSpeciesId,
          vegetativeMonths: action.editProject.vegetativeMonths,
          messagingPhoneNumber: action.editProject.messagingPhoneNumber,
          messagingServiceSid: action.editProject.messagingServiceSid
      } : project
    });

    const selectedProject : ProjectInterface = {...state.selectedProject,
      projectName: action.editProject.projectName,
			grassSpeciesId: action.editProject.grassSpeciesId,
      vegetativeMonths: action.editProject.vegetativeMonths,
      currency: action.editProject.currency,
      messagingPhoneNumber: action.editProject.messagingPhoneNumber,
      messagingServiceSid: action.editProject.messagingServiceSid
    };

    return {...state, projects: projectList, selectedProject: selectedProject, isLoading: false};
  }),
  on(projectActions.getCategories, (state) => ({...state, isLoading: true})),
  on(projectActions.getCategoriesSuccess, (state, {categories}) => ({...state, categories, isLoading: false})),
  on(projectActions.getGrassSpecies, (state) => ({...state, isLoading: true})),
  on(projectActions.getGrassSpeciesSuccess, (state, {grassSpecies}) => ({...state, grassSpecies, isLoading: false})),
  on(projectActions.getCurrencies, (state) => ({...state, isLoading: true})),
  on(projectActions.getCurrenciesSuccess, (state, {currencies}) => ({...state, currencies, isLoading: false})),
  on(projectActions.setOrganizationAndProjects, (state, {projects, organizations, organizationId}) => ({...state, projects, selectedProject: {}, organizations, selectedOrganization: organizations.find(({id}) => id === organizationId)!})),
  on(projectActions.getAreaInfoByGps, (state) => ({...state, areaInfo: null, isLoading: true})),
  on(projectActions.getAreaInfoByGpsSuccess, (state, results) => ({...state, areaInfo: results.dto, isLoading: false})),
  on(projectActions.createNewProjectSuccess, (state, { newProject }) => ({...state,
    organizations: state.organizations.map((o) => (o.id !== state.selectedOrganization.id) ? o : {...o, removable: false })})),
	on(adminActions.addOrganizationSuccess, (state, {organization}) => ({
		...state,
		organizations: [...state.organizations, organization]
	})),
	on(adminActions.editOrganizationSuccess, (state, {organizationId, name, accountId, productCode}) =>
		({...state, organizations: state.organizations.map(organization => organization.id === organizationId ? {...organization, name, accountId, productCode} : organization)
	})),
	on(adminActions.removeOrganizationSuccess, (state, {organizationId}) => ({
		...state,
		organizations: state.organizations.filter(({id}) => id !== organizationId)
	})),
  on(chooseAreaActions.editProjectSuccess, (state, {projectId, areasCount}) => {
    const projectList = state.projects.map(project => {
      return project.id === projectId ? {
        ...project, areasCount: areasCount
      } : project
    });

    const selectedProject : ProjectInterface = {...state.selectedProject, areasCount: areasCount };

    return {...state, projects: projectList, selectedProject: selectedProject, isLoading: false};
  }),
);
