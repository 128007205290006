interface taskIcon {
	name: string;
	path: string;
}

const basePath = './assets/images/icons/tasks'

export const taskIconsConfig: taskIcon[] = [
	{
		name: 'mowing',
		path: `${basePath}/mowing.svg`
	},
	{
		name: 'watering',
		path: `${basePath}/watering.svg`
	},
	{
		name: 'rolling',
		path: `${basePath}/rolling.svg`
	},
	{
		name: 'grooming',
		path: `${basePath}/grooming.svg`
	},
	{
		name: 'vertigrooming',
		path: `${basePath}/vertigrooming.svg`
	},
	{
		name: 'fertilizing',
		path: `${basePath}/fertilizing.svg`
	},
	{
		name: 'verticutting',
		path: `${basePath}/verticutting.svg`
	},
	{
		name: 'aeration',
		path: `${basePath}/aeration.svg`
	},
	{
		name: 'topdressing',
		path: `${basePath}/topdressing.svg`
	},
	{
		name: 'herbicide',
		path: `${basePath}/herbicide.svg`
	},
	{
		name: 'plant-regulator',
		path: `${basePath}/plant-regulator.svg`
	},
	{
		name: 'seeding',
		path: `${basePath}/seeding.svg`
	},
	{
		name: 'sowing',
		path: `${basePath}/sowing.svg`
	},
	{
		name: 'replanting',
		path: `${basePath}/replanting.svg`
	},
	{
		name: 'removing_excess_moisture',
		path: `${basePath}/removing_excess_moisture.svg`
	},
	{
		name: 'removing_moisture',
		path: `${basePath}/removing-moisture.svg`
	},
	{
		name: 'repair',
		path: `${basePath}/repair.svg`
	},
	{
		name: 'cutting_holes',
		path: `${basePath}/cutting_holes.svg`
	}
];

export const getTaskIconPathByName = (iconName: string): string => taskIconsConfig.find(({name}) => name === iconName)?.path ?? '';
