import {createReducer, on} from "@ngrx/store";
import {TaskPresentationMode, taskState} from "./taskState";
import {taskActions, taskBoardActions, taskCalendarActions, taskListActions} from "./actions";
import {taskStatusSwitchConfig} from "../models/TaskStatus";
import {PhotoInfoType} from "../../grass-parameters-presentation/models/photo-info/photo-info-type";
import {CommentDTO} from "../../shared/model/commentDTO";
import {commonActions} from "../../shared/store/common/actions";

const initialState: taskState = {
	isLoading: false,
	tasks: [],
	totalRecords: 0,
	taskTypes: [],
	areas: [],
	currentTaskBoardFilter: {
		take: 100,
		skip: 0,
		areas: [],
		taskTypes: [],
	},
	currentCalendarFilter: {
		take: 100,
		skip: 0,
		areas: [],
		taskTypes: [],
	},
	currentTaskListFilter: {
		take: 100,
		skip: 0,
		areas: [],
		taskTypes: [],
	},
	taskPresentationMode: TaskPresentationMode.List,
	selectedIncidents: [],
	changesHistory: [],
	availableUsers: [],
	taskMeasurements: []
};

export const taskReducer = createReducer(
	initialState,
	on(taskActions.loadTasks, (state, loadTasksParameters) => ({
		...state,
		isLoading: true,
		lastLoadTasksParameters: loadTasksParameters
	})),
	on(taskActions.loadTasksSuccess, (state, {tasks, totalRecords}) => ({
		...state,
		isLoading: false,
		tasks,
		totalRecords
	})),
	on(taskActions.loadTaskTypesSuccess, (state, {taskTypes}) => ({...state, taskTypes})),
	on(taskActions.loadAreasSuccess, (state, {areas}) => ({...state, areas})),

	on(taskActions.switchToListMode, (state) => ({...state, taskPresentationMode: TaskPresentationMode.List})),
	on(taskActions.switchToKannbannMode, (state) => ({...state, taskPresentationMode: TaskPresentationMode.Kannbann})),
	on(taskActions.switchToCalendarMode, (state) => ({...state, taskPresentationMode: TaskPresentationMode.Calendar})),

	on(taskBoardActions.initStatusChange, (state, {taskId, taskStatus}) => {
		const editedTask = state.tasks.find(({id}) => id === taskId);

		const switchOptions =  taskStatusSwitchConfig.find(x => x.currentStatus === editedTask?.state && x.targetStatus === taskStatus)

		if (!editedTask || !switchOptions) {
			return state;
		}

		return {
			...state,
			editedTaskBoardItemId: taskId,
			taskBoardSwitchConfig: switchOptions
		}
	}),
	on(taskBoardActions.cancelStatusChange, (state) => ({...state, editedTaskBoardItemId: undefined, taskBoardSwitchConfig: undefined })),
	on(taskBoardActions.completeStatusChangeSuccess, (state, {dueDate, assignedUser}) => {
		const tasks = state.tasks.map(task => task.id === state.editedTaskBoardItemId ? {
			...task,
			state: state.taskBoardSwitchConfig!.targetStatus,
			dueDate: dueDate === undefined ? task.dueDate : dueDate,
			assignedUserName: assignedUser === undefined ? task.assignedUserName : assignedUser.name!,
			assignedUserId: assignedUser === undefined ? task.assignedUserId : assignedUser.id!
		}: task);

		return {...state, editedTaskBoardItemId: undefined, taskBoardSwitchConfig: undefined, tasks};
	}),
	on(taskBoardActions.filterByAreas, (state, {areas}) => {
		return {...state, currentTaskBoardFilter: {...state.currentTaskBoardFilter, areas}}
	}),
	on(taskBoardActions.filterByElementaryObjectType, (state, {photoInfoType}) => {
		return {
			...state,
			currentTaskBoardFilter: {
				...state.currentTaskBoardFilter,
				elementaryObjectType: photoInfoType === PhotoInfoType.All ? undefined : photoInfoType
			}
		}
	}),
	on(taskBoardActions.filterByDueDate, (state, {dateRange}) => {
		const dueDateFrom = dateRange !== null ? dateRange[0] : undefined;
		const dueDateTo = dateRange !== null ? dateRange[1] : undefined;

		return {...state, currentTaskBoardFilter: {...state.currentTaskBoardFilter, dueDateFrom, dueDateTo}}
	}),
	on(taskBoardActions.filterByTaskTypeId, (state, {taskTypeId}) => ({...state, currentTaskBoardFilter: {...state.currentTaskBoardFilter, taskTypeId}})),

	on(taskBoardActions.filterByTaskTypes, (state, {taskTypes}) => ({
		...state,
		currentTaskBoardFilter: {...state.currentTaskBoardFilter, taskTypes}
	})),
	on(taskBoardActions.filterByAssignedUserId, (state, {userId}) => ({...state, currentTaskBoardFilter: {...state.currentTaskBoardFilter, assignedUserId: userId}})),
	on(taskCalendarActions.filterByTaskTypes, (state, {taskTypes}) => ({
		...state,
		currentCalendarFilter: {...state.currentCalendarFilter, taskTypes}
	})),

	on(taskCalendarActions.filterByAreas, (state, {areas}) => ({
		...state,
		currentCalendarFilter: {...state.currentCalendarFilter, areas}
	})),
	on(taskCalendarActions.filterByElementaryObjectType, (state, {photoInfoType}) => ({
		...state,
		currentCalendarFilter: {
			...state.currentCalendarFilter,
			elementaryObjectType: photoInfoType === PhotoInfoType.All ? undefined : photoInfoType
		}
	})),
	on(taskCalendarActions.filterByTaskTypeId, (state, {taskTypeId}) => ({...state, currentCalendarFilter: {...state.currentCalendarFilter, taskTypeId}})),
	on(taskListActions.loadTaskList, (state, {lazyLoadEvent}) => {
		if (!lazyLoadEvent) {
			return state;
		}

		return {
			...state,
			currentTaskListFilter: {
				...state.currentTaskListFilter,
				take: lazyLoadEvent.rows!,
				skip: lazyLoadEvent.first!,
				sortField: lazyLoadEvent.sortField,
				sortOrder: lazyLoadEvent.sortOrder
			}
		}
	}),
	on(taskListActions.filterByAreas, (state, {areas}) => ({
		...state,
		currentTaskListFilter: {...state.currentTaskListFilter, areas}
	})),
	on(taskListActions.filterByDueDate, (state, {dateRange}) => {
		const dueDateFrom = dateRange !== null ? dateRange[0] : undefined;
		const dueDateTo = dateRange !== null ? dateRange[1] : undefined;

		return {...state, currentTaskListFilter: {...state.currentTaskListFilter, dueDateFrom, dueDateTo}}
	}),
	on(taskListActions.filterByTaskTypeId, (state, {taskTypeId}) => ({...state, currentTaskListFilter: {...state.currentTaskListFilter, taskTypeId}})),

	on(taskListActions.filterByTaskTypes, (state, {taskTypes}) => ({
		...state,
		currentTaskListFilter: {...state.currentTaskListFilter, taskTypes}
	})),

	on(taskListActions.filterByStatuses, (state, {statuses}) => ({
		...state,
		currentTaskListFilter: {...state.currentTaskListFilter, states: statuses}
	})),
	on(taskListActions.filterByElementaryObjectType, (state, {photoInfoType}) => ({
		...state,
		currentTaskListFilter: {
			...state.currentTaskListFilter,
			elementaryObjectType: photoInfoType === PhotoInfoType.All ? undefined : photoInfoType
		}
	})),

	on(commonActions.handleError, (state) => ({...state, isLoading: false})),
	on(taskActions.reset, (state) => ({ ...state, isLoading: false, tasks: [], totalRecords: 0 })),
	on(taskActions.getTaskByIdSuccess, (state, {task}) => {
		const taskArea = state.areas.find(({areaId}) => areaId === task.projectAreaId);
		return {...state, taskWithDetails: task, taskArea}
	}),
	on(taskActions.addCommentSuccess, (state, {comment, author}) => {
		const taskWithDetails = state.taskWithDetails!;
		const newComment: CommentDTO = {content: comment, author, date: new Date()};
		return {...state, taskWithDetails: ({...taskWithDetails, comments: [...taskWithDetails.comments, newComment]})};
	}),
	on(taskActions.changeTaskArea, (state, {areaId, elementaryObjectId}) => {
		const taskArea = state.areas.find(x => x.areaId === areaId);
		const taskElementaryObject = taskArea?.elementaryObjects?.find(x => x.id === elementaryObjectId);

		return ({...state, taskArea, taskElementaryObject});
	}),
	on(taskActions.changeTaskElementaryObject, (state, {elementaryObjectId}) => {
		const taskArea = state.taskArea!;
		return {...state, taskElementaryObject: taskArea.elementaryObjects?.find(({id}) => id === elementaryObjectId)}
	}),
	on(taskActions.initAddTaskFromIncidents, (state, { incidents }) => ({ ...state, selectedIncidents: incidents })),
	on(taskActions.updateTaskSuccess, (state) => ({ ...state, taskWithDetails: undefined })),

	on(taskCalendarActions.rescheduleTaskSuccess, (state, {taskId, dueDate}) => {
		return {...state, tasks: state.tasks.map(task => task.id === taskId ? {...task, dueDate} : task)};
	}),
	on(taskCalendarActions.rescheduleTaskFailure, (state) => ({...state, tasks: [...state.tasks]})),
	on(taskActions.getChangesHistorySuccess, (state, {changesHistory}) => ({...state, changesHistory})),
	on(taskActions.clearTask, (state) => ({...state, taskArea: undefined, taskElementaryObject: undefined})),
	on(taskActions.getAvailableTaskUsersSuccess, (state, {availableUsers}) => ({...state, availableUsers: availableUsers })),
	on(commonActions.getWeather, (state) => ({...state, isLoading: true})),
	on(commonActions.getWeatherSuccess, (state, {measurements}) => ({...state, taskMeasurements: measurements, isLoading: false}))
);
