import {Map} from 'mapbox-gl';
import {mapHexLayerFilters} from "../store/grass-params/types";
import {PhotoInfoType} from "../models/photo-info/photo-info-type";
import {hexesLayerConfig} from "../models/mapbox/hexes-layers.config";

export class MapboxFilterUtils {
  static filterHexGridLayers = (map: Map, config: hexesLayerConfig, filterDict: mapHexLayerFilters) => {
		const layers = [config.fillLayer, config.bordersLayer];

    const filters: any[] = config.filteredProps.reduce((currentFilters: any[], filterName) => {
			if (filterDict[filterName as keyof mapHexLayerFilters]) {
				currentFilters.push(filterDict[filterName as keyof mapHexLayerFilters]);
			}

      return currentFilters;
    }, []);

    let combinedFilter: any;
    if (filters.length > 1) {
      combinedFilter = ['all', ...filters];
    } else if (filters.length === 1) {
      combinedFilter = filters[0];
    } else {
      combinedFilter = null;
    }

    layers.forEach(layer => {
			if (map.getLayer(layer)) {
				map.setFilter(layer, combinedFilter);
			}
		});
  }

  static getSectorIdsFilter = (sectorIds: number[]) => ["in", ["get", "areaId"], ["literal", sectorIds]];

  static getTypeFilter = (typeNames: PhotoInfoType[]) => {
    const requiredTypesForAll = [PhotoInfoType.Fairway, PhotoInfoType.Green, PhotoInfoType.Teebox];
    if (requiredTypesForAll.every(type => typeNames.includes(type))) {
        return ["in", ["get", "type"], ["literal", [PhotoInfoType.Fairway, PhotoInfoType.Green, PhotoInfoType.Teebox]]];
    }

    return ["in", ["get", "type"], ["literal", typeNames]];
  };

  static getZoomFilter = (zoomValue: number) => ["==", ["get", "hexZoom"], zoomValue];

	static getMinFilter = (minValue: number, paramName: string) => ['>=', ['get', paramName], minValue];

	static getMaxFilter = (maxValue: number, paramName: string) => ['<=', ['get', paramName], maxValue];

	static getHexIdsFilter = (hexIds: number[]) => ["in", ["get", "id"], ["literal", hexIds]];
}
