import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { laboratoryExaminationPopupStep } from '../types';

@Component({
  selector: 'app-laboratory-examination-popup-footer',
  template: `
    <section class="laboratory-examination-popup-footer">
			<div class="form-group row gap-0">
				<p-button class="laboratory-examination-popup-footer-button" styleClass="w-170 p-button-outlined p-button-success p-button-medium" [label]="'COMMON.BACK' | translate"
          *ngIf="shouldShowBack()" (onClick)="backClicked.emit()"/>
				<p-button class="laboratory-examination-popup-footer-button" styleClass="w-170 p-button-success p-button-medium" [label]="'COMMON.NEXT' | translate" 
          *ngIf="shouldShowNext()" (onClick)="nextClicked.emit()" />
        <p-button class="laboratory-examination-popup-footer-button" styleClass="w-170 p-button-success p-button-medium" [label]="'COMMON.SAVE' | translate" 
          *ngIf="shouldShowSubmit()" (onClick)="submitClicked.emit()" [disabled]="!canFormBeSubmitted" />
			</div>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LaboratoryExaminationPopupFooterComponent {
  @Input() editMode: boolean = false;
  @Input() currentStep: laboratoryExaminationPopupStep = this.editMode ? laboratoryExaminationPopupStep.ExaminationParameters : laboratoryExaminationPopupStep.LaboratoryExaminationType;
  @Input() canFormBeSubmitted: boolean = false;

  @Output() nextClicked = new EventEmitter();
  @Output() backClicked = new EventEmitter();
  @Output() submitClicked = new EventEmitter();

  shouldShowBack = (): boolean => (!this.editMode && this.currentStep !== laboratoryExaminationPopupStep.LaboratoryExaminationType);

  shouldShowNext = (): boolean => (!this.editMode && this.currentStep !== laboratoryExaminationPopupStep.DateOfExamination);

  shouldShowSubmit = (): boolean =>
   (this.editMode && this.currentStep === laboratoryExaminationPopupStep.ExaminationParameters) ||
   (!this.editMode && this.currentStep === laboratoryExaminationPopupStep.DateOfExamination);
}