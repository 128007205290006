import {createSelector} from "@ngrx/store";
import {AppState} from "../../types/app-state";

export const selectFeatureProjects = (state: AppState) => state.projects;
export const projectsSelector = createSelector(selectFeatureProjects, (state) => state?.projects);
export const organizationsSelector = createSelector(selectFeatureProjects, (state) => state?.organizations);
export const selectedProjectSelector = createSelector(selectFeatureProjects, (state) => state?.selectedProject);
export const selectedProjectIdSelector = createSelector(selectedProjectSelector, (project) => project?.id);
export const selectedProjectNameSelector = createSelector(selectedProjectSelector, (project) => project?.projectName);
export const selectedOrganizationSelector = createSelector(selectFeatureProjects, (state) => state?.selectedOrganization);
export const newProjectSelector = createSelector(selectFeatureProjects, (state) => state?.newProject);
export const organizationIdSelector = createSelector(selectFeatureProjects, (state) => state.selectedOrganization.id);
export const isLoadingSelector = createSelector(selectFeatureProjects, (state) => state.isLoading);
export const currenciesSelector = createSelector(selectFeatureProjects, (state) => state.currencies);
export const grassSpecies = createSelector(selectFeatureProjects, (state) => state.grassSpecies);
export const areaInfoSelector = createSelector(selectFeatureProjects, (state) => state.areaInfo);
