import {inject, Injectable} from "@angular/core";
import {ComponentStore} from "@ngrx/component-store";
import {
	addLaboratoryExaminationDto,
	changeUnitProps,
	editLaboratoryExaminationDto,
	initEditFormProps,
	laboratoryExaminationListVm,
	laboratoryExaminationPopupStep,
	LaboratoryObjectPopupState,
	manageLaboratoryExaminationDto,
	mineralIngredientUnit,
	selectedParameterVm,
	selectParameterProps
} from "./types";
import {filter, map, Observable, switchMap, tap, withLatestFrom} from "rxjs";
import {AppState} from "../../../../types/app-state";
import {Store} from "@ngrx/store";
import {point} from "../../../../types/point";
import {laboratoryExaminationActions} from "../../../store/other-objects/actions";
import * as otherObjectSelectors from "../../../store/other-objects/selectors";
import {
	calculateAverageInStandardUnit,
	calculateAverageInChosenUnit,
	calculateDeviation,
	laboratoryExaminationPopupParameterListInitialValue,
} from "./constants";
import {parseDateToString} from "../../../helpers/date-utils";
import { TranslateService } from "@ngx-translate/core";
import { grassQualityParameterNames } from "src/app/grass-parameters-presentation/constants/config";
import {selectGrassParamsNorms} from "../../../store/grass-params/selectors";
import {splitAndCapitalize} from "../../../../shared/utils/string.utils";
import { LaboratoryExaminationType } from "src/app/examinations/types";

@Injectable()
export class LaboratoryExaminationPopupComponentStore extends ComponentStore<LaboratoryObjectPopupState> {
  private store = inject(Store<AppState>);
  private translate = inject(TranslateService);

  constructor() {
    super({
      chemicalParametersList: laboratoryExaminationPopupParameterListInitialValue,
      manageLaboratoryExaminationDto: {},
      currentStep: laboratoryExaminationPopupStep.LaboratoryExaminationType,
      editMode: false
    });
  }

  // selectors
  readonly chemicalParametersListDisplay$: Observable<laboratoryExaminationListVm[]> = this.select(state => {
		return state.chemicalParametersList.map(param => {
			return {...param, Average: calculateAverageInChosenUnit(param.Average, param.unit!)}
		});
	});

	readonly chemicalParametersList$: Observable<laboratoryExaminationListVm[]> = this.select(state => state.chemicalParametersList);

  readonly manageLaboratoryExaminationDto$ = this.select(state => state.manageLaboratoryExaminationDto);
  readonly isAnyParameterSelected$ = this.select(state => state.selectedParameter !== undefined);

	readonly selectedParameterVm$: Observable<selectedParameterVm> = this.select(state => state).pipe(
		withLatestFrom(this.store.select(selectGrassParamsNorms)),
		map(([state, grassParametersNorms]) => {
			const selectedParameter = state.selectedParameter;

			if (!selectedParameter) {
				return {
					isFormVisible: false
				};
			}

			let labelPrefix: string;
			const grassQualityPar = grassQualityParameterNames.find(x => x.name === selectedParameter);
			if (grassQualityPar) {
				labelPrefix = this.translate.instant(grassQualityPar.translationKey);
				labelPrefix = labelPrefix.split(' ')[0];
			} else {
				labelPrefix = selectedParameter;
			}

			labelPrefix = splitAndCapitalize(labelPrefix);
			const averageLabel = this.translate.instant("GRASS_PARAMETERS.LAB_EXAM.PAR_AVERAGE", {par: labelPrefix});
			const deviationLabel = this.translate.instant("GRASS_PARAMETERS.LAB_EXAM.PAR_DEVIATION", {par: labelPrefix});

			const selectedParameterListVm = state.chemicalParametersList.find(x => x.name === selectedParameter);
			const { unit, Average } = selectedParameterListVm!;

			let deviation;
			let deviationColor;

			if (state.selectedParameter && Average !== undefined) {
				const deviationVal = calculateDeviation(state.selectedParameter, Average, grassParametersNorms!.mineralIngredientsNorms) * 100;
				deviationColor = deviationVal === 0 ? '#8d8b8b' : '#FF4032';

				deviation = `${deviationVal.toFixed(2)}%`

				if (deviationVal > 0) {
					deviation = `+${deviation}`;
				}
			}

			return {
				averageLabel,
				deviationLabel,
				deviation,
				deviationColor,
				isFormVisible: true,
				unit: ` ${unit}`,
			};
		})
	);

	readonly selectedParameter$: Observable<string | undefined> = this.select((state) => state.selectedParameter);

  readonly currentStep$ = this.select(state => state.currentStep);

  readonly currentStepTitle$ = this.select(state => state).pipe(
    switchMap(state => {
      if (state.editMode) {
        const dateDisplayValue = parseDateToString(state.examinationDate!);
        
        const typeTranslationKey = state.examinationType === LaboratoryExaminationType.Turf
          ? 'EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_TURF'
          : 'EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_SOIL';
  
        return this.translate.get(typeTranslationKey).pipe(
          switchMap(typeTranslation => 
            this.translate.get('GRASS_PARAMETERS.LAB_EXAM.EDIT_FROM', { date: dateDisplayValue, examinationType: typeTranslation })
          )
        );
      } 
  
      return this.getCurrentStepTranslation(state.currentStep);
    })
  );
  
  private getCurrentStepTranslation(currentStep: laboratoryExaminationPopupStep): Observable<string> {
    switch(currentStep) {
      case laboratoryExaminationPopupStep.LaboratoryExaminationType:
        return this.translate.get("GRASS_PARAMETERS.LAB_EXAM.SELECT_TYPE").pipe(
          map(translatedText => `1. ${translatedText}`)
        );
      case laboratoryExaminationPopupStep.ExaminationParameters:
        return this.translate.get("GRASS_PARAMETERS.LAB_EXAM.PICK_PARS").pipe(
          map(translatedText => `2. ${translatedText}`)
        );
      case laboratoryExaminationPopupStep.DateOfExamination:
        return this.translate.get("GRASS_PARAMETERS.LAB_EXAM.SELECT_DATE").pipe(
          map(translatedText => `3. ${translatedText}`)
        );
      default:
        throw new Error(`Unknown laboratory examination popup step '${currentStep}'`);
    }
  }

  readonly canFormBeSubmitted$ = this.select(state => { 
    return state.examinationDate !== undefined && state.examinationType !== undefined 
  });

  readonly examinationDate$ = this.select(state => state.examinationDate);

  readonly examinationType$ = this.select(state => state.examinationType);

  readonly editMode$ = this.select(state => state.editMode);

  readonly vm$ = this.select({
    chemicalParametersList: this.chemicalParametersListDisplay$,
    manageLaboratoryExaminationDto: this.manageLaboratoryExaminationDto$,
    isAnyParameterSelected: this.isAnyParameterSelected$,
    selectedParameterVm: this.selectedParameterVm$,
    currentStep: this.currentStep$,
    currentStepTitle: this.currentStepTitle$,
    canFormBeSubmitted: this.canFormBeSubmitted$,
    examinationDate: this.examinationDate$,
    examinationType: this.examinationType$,
    editMode: this.editMode$
  });

  // actions
  readonly selectParameterAction = this.updater((state, selectedParameter: string): LaboratoryObjectPopupState => {
    return {...state, selectedParameter};
  });

	readonly updateUnitAction = this.updater((state, selectedUnit: mineralIngredientUnit): LaboratoryObjectPopupState => {
		const chemicalParametersList: laboratoryExaminationListVm[] = state.chemicalParametersList.map((param) => {
			return param.name === state.selectedParameter ? {
				...param,
				unit: selectedUnit
			}: param
		});

		return {...state, chemicalParametersList};
	});

  readonly updateSelectedParameterPropertyAction = this.updater((state, value: number | null): LaboratoryObjectPopupState => {
		const paramVm = state.chemicalParametersList.find(x => x.name === state.selectedParameter)!;

		const average = calculateAverageInStandardUnit(value === null ? undefined: value, paramVm.unit!);

    const manageLaboratoryExaminationDto = state.manageLaboratoryExaminationDto;
    manageLaboratoryExaminationDto[`${state.selectedParameter}Average` as keyof manageLaboratoryExaminationDto] = average;

    const chemicalParametersList = state.chemicalParametersList.map(vm =>
      vm.name === state.selectedParameter ? {
        ...vm,
        Average: average
      } : vm);

    return {
      ...state,
      manageLaboratoryExaminationDto,
      chemicalParametersList
    };
  });

  readonly changeStepAction = this.updater((state, step: laboratoryExaminationPopupStep): LaboratoryObjectPopupState => ({
    ...state,
    currentStep: step
  }));

  readonly changeDateAction = this.updater((state, examinationDate: Date): LaboratoryObjectPopupState => ({...state, examinationDate}));

  readonly changeType = this.updater((state, examinationType: number): LaboratoryObjectPopupState => ({...state, examinationType}));

  readonly initEditFormAction = this.updater((state, props: initEditFormProps): LaboratoryObjectPopupState => {
    const {editedLaboratoryExamination, examinationDate, examinationType} = props;

    const chemicalParametersList = state.chemicalParametersList.map(listVm => {
      return {...listVm, Average: editedLaboratoryExamination[`${listVm.name}Average` as keyof manageLaboratoryExaminationDto]}
    });

    return {...state, examinationDate, examinationType, manageLaboratoryExaminationDto: editedLaboratoryExamination, editMode: true, currentStep: laboratoryExaminationPopupStep.ExaminationParameters, chemicalParametersList};
  });

  // effects
  readonly selectParameter = this.effect((props$: Observable<selectParameterProps>) =>
    props$.pipe(
      filter(props => props.selectedParameter !== undefined),
      withLatestFrom(this.chemicalParametersList$),
      tap(([props, params]) => {
        const {selectedParameter, form} = props;
				const { Average, unit } = params.find(({name}) => name === selectedParameter)!;

        form.patchValue({
          average: calculateAverageInChosenUnit(Average, unit!),
					unit: unit
        }, {emitEvent: false});
      }),
      map(([{selectedParameter}]) => this.selectParameterAction(selectedParameter))
    ));

	readonly changeUnit = this.effect((props$: Observable<changeUnitProps>) =>
		props$.pipe(
			withLatestFrom(this.chemicalParametersList$, this.selectedParameter$),
			tap(([props, params, selectedParam]) => {
				const {unit, form} = props;
				const { Average } = params.find(({name}) => name === selectedParam)!;

				form.patchValue({
					average: calculateAverageInChosenUnit(Average, unit)
				}, {emitEvent: false});
			}),
			map(([{unit}]) => this.updateUnitAction(unit))
		)
	);


  readonly submitAddForm = this.effect((props$: Observable<point>) =>
    props$.pipe(
      withLatestFrom(this.vm$),
      map(([gps, vm]) => {
        const {examinationDate, examinationType, manageLaboratoryExaminationDto} = vm
        return {
          gps,
          examinationDate,
          examinationType,
          manageLaboratoryExaminationDto
        };
      }),
      map((props) => {
        const {gps, manageLaboratoryExaminationDto, examinationDate, examinationType } = props;
        const addedLaboratoryExamination: addLaboratoryExaminationDto = {
          ...manageLaboratoryExaminationDto,
          gps,
          examinationDate: examinationDate!,
          laboratoryExaminationType: examinationType!,
        };

        return this.store.dispatch(laboratoryExaminationActions.add(addedLaboratoryExamination));
      })
    ));

  readonly initEditForm = this.effect((itemId$: Observable<number>) =>
    itemId$.pipe(
      withLatestFrom(this.store.select(otherObjectSelectors.getLaboratoryExaminationsSelector)),
      map(([itemId, examinations]) => {
        const currentExamination = examinations.find(x => x.itemId === itemId.toString());
        const editedLaboratoryExamination: manageLaboratoryExaminationDto = {...currentExamination};
        const examinationDate = currentExamination!.examinationDate;
        const examinationType = currentExamination!.laboratoryExaminationType!;
        return this.initEditFormAction({editedLaboratoryExamination, examinationDate, examinationType});
      })
    )
  );

  readonly submitEditForm = this.effect((itemId$: Observable<number>) =>
    itemId$.pipe(
      withLatestFrom(this.vm$),
      map(([itemId, vm]) => {
        const dto = vm.manageLaboratoryExaminationDto;
        const editedObjectDto: editLaboratoryExaminationDto = {...dto, id: itemId};
        return this.store.dispatch(laboratoryExaminationActions.edit(editedObjectDto));
      })
    )
  );
}



