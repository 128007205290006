import { Component, Input, ViewEncapsulation } from "@angular/core";
import { taskStatus } from "../../models/TaskStatus";

@Component({
	template:
		`
		<app-task-board-item *ngIf="!monthMode" [projectId]="projectId" [taskId]="taskId" [title]="title" [date]="date"
				[author]="author" [icon]="icon" [size]="size" [state]="state!" />
		<section *ngIf="monthMode" class="task-event-month-mode">
			<p [routerLink]="['/tasks', projectId, 'editTask', taskId]">{{title}}</p>
		</section>
	`,
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { 'class': 'task-calendar-event' },
	styles: [
		`.task-calendar-event { width: 100%; }`
	],
	encapsulation: ViewEncapsulation.None
})
export class TaskCalendarEventComponent {
	@Input() title = '';
	@Input() date?: Date;
	@Input() author: string = '';
	@Input() weekMode?: boolean;
	@Input() monthMode?: boolean;
	@Input() dayMode?: boolean;
	@Input() projectId!: number;
	@Input() taskId!: string;
	@Input() icon!: string;
	@Input() size?: string;
	@Input() state?: taskStatus;
}
