import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, mergeMap, of, switchMap, tap, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {v4 as uuid} from 'uuid';
import * as dayjs from 'dayjs'
import * as objectSupport from 'dayjs/plugin/objectSupport';
import * as utc from 'dayjs/plugin/utc';
import {AppState} from "../../types/app-state";
import * as oneAtlasActions from './actions'
import * as oneAtlasSelectors from "./selectors";
import { OneAtlasService } from "../services/one-atlas.service";
import { OneAtlasOrderInterface, OneAtlasPredictionInterface, OneAtlasSearchInterface } from "./types";
import {AgroMessageService} from "../../shared/services/agro-message.service";
import * as projectActions from "../../project/project-store/actions";
import { OneAtlasPopupService } from "../services/one-atlas-popup.service";
import { DialogService } from "primeng/dynamicdialog";

@Injectable()
export class OneAtlasEffects {
  constructor(private actions$: Actions,
    private messageService: AgroMessageService,
    private oneAtlasServie: OneAtlasService,
    private oneAtlasPopupService: OneAtlasPopupService,
    private dialogService: DialogService,
    private store: Store<AppState>) {
  }

  getOneAtlasPhotoInfos$ = createEffect(() => 
    this.actions$.pipe(
      ofType(oneAtlasActions.getOneAtlasPhotoInfos),
      switchMap(props => {
        dayjs.extend(objectSupport);
        dayjs.extend(utc);
            
        const monthIndex = props.searchRange.month - 1;
        const from = dayjs.utc({ year: props.searchRange.year, month: monthIndex, day: 1 }).toISOString();
        const to = dayjs.utc(from).endOf('month').toISOString();
        return this.oneAtlasServie.search(props.projectId, from, to).pipe(
          map((response: any) => {
            if (response.success !== true) {
              this.messageService.displayErrorMessage("Error", response.error);
              return oneAtlasActions.handleError();
            }

            const results: OneAtlasSearchInterface = {
              projectId: response.projectId,
              fromDate: dayjs(response.fromDate).toDate(),
              toDate: dayjs(response.toDate).toDate(),
              images: response.images!.map((i: any) => ({ imageId: i.imageId, date: dayjs(i.date).toDate() })),
              orders: response.orders!.map((o: any) => ({ day: dayjs(o.day).toDate(), processId: o.processId })),
            }

            return oneAtlasActions.getOneAtlasPhotoInfosSuccess({results: results});
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          })
        );
      })
    )
  );
    
  prepareOneAtlasOrder$ = createEffect(() => 
    this.actions$.pipe(
      ofType(oneAtlasActions.prepareOneAtlasOrder),
      switchMap(props => {
        dayjs.extend(objectSupport);
        dayjs.extend(utc);
    
        const processId = uuid();
        const day = dayjs(props.day);
        const date = dayjs.utc({ year: day.year(), month: day.month(), day: day.date() }).toISOString();
        return this.oneAtlasServie.prepareOrder(props.projectId, processId, date).pipe(
          map((response: any) => { 
            if (response.success !== true) {
              this.messageService.displayErrorMessage("Error", response.error);
              return oneAtlasActions.handleError();
            }

            return oneAtlasActions.prepareOneAtlasOrderSuccess({ projectId: props.projectId, processId: processId });
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          })
        )
      })
    )
  );
    
  prepareOneAtlasOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oneAtlasActions.prepareOneAtlasOrderSuccess),
      withLatestFrom(this.store.select(oneAtlasSelectors.oneAtlasSearchSelector)),
      mergeMap(([props, search]) => {
        const day = dayjs(search!.fromDate);
        return of(oneAtlasActions.getOneAtlasPhotoInfos({ projectId: props.projectId, searchRange: { year: day.year(), month: day.month() }}));
      })
    )
  );
    
  findOneAtlasCreatedOrders$ = createEffect(() => 
    this.actions$.pipe(
      ofType(oneAtlasActions.findOneAtlasCreatedOrders, oneAtlasActions.prepareOneAtlasOrderSuccess),
      switchMap(props => {
        return this.oneAtlasServie.findCreatedOrders(props.projectId).pipe(
          map((response) => {
            if (response.success !== true) {
              this.messageService.displayErrorMessage("Error", response.error);
              return oneAtlasActions.handleError();
            }
            
            let orders: OneAtlasOrderInterface[] = response?.orders || [];
            return oneAtlasActions.findOneAtlasCreatedOrdersSuccess({orders: orders});
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          })
        )
      })
    )
  );
    
  acceptOneAtlasOrderPrice$ = createEffect(() => 
    this.actions$.pipe(
      ofType(oneAtlasActions.acceptOneAtlasOrderPrice),
      switchMap(props => {
        return this.oneAtlasServie.acceptOrderPrice(props.projectId, props.processId).pipe(
          map(() => oneAtlasActions.acceptOneAtlasOrderPriceSuccess({projectId: props.projectId, processId: props.processId})),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          })
        )
      })
    )
  );
    
  rejectOneAtlasOrderPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(oneAtlasActions.rejectOneAtlasOrderPrice),
      switchMap(props => {
        return this.oneAtlasServie.rejectOrderPrice(props.projectId, props.processId).pipe(
          map(() => oneAtlasActions.rejectOneAtlasOrderPriceSuccess({projectId: props.projectId, processId: props.processId})),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          })
        )
      })
    )
  );

  createNewProjectSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(projectActions.createNewProjectSuccess),
      switchMap(props => {
        return this.oneAtlasServie.prediction(props.newProject.id!).pipe(
          tap((data) => {
            this.oneAtlasPopupService.showOneAtlasPredictionPopup(this.dialogService, data);
            return of();
          }),
          catchError((error) => {
            this.messageService.displayErrorMessage("Error", error);
            return of(oneAtlasActions.handleError());
          }))
        })
    )
  );
}