import {inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AttachmentDTO} from "../model/attachmentDTO";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BaseResponse} from "../model/api-responses";
import {GetAttachmentUrlResponse} from "../../project/model/upload.interface";

@Injectable({
	providedIn: 'root'
})
export class AttachmentsService {
	private http = inject(HttpClient)

	private baseUrl: string = environment.projectApiUrl;
	getAttachmentsFiles(url: string): Observable<AttachmentDTO[]> {
		url = this.baseUrl + url;
		return this.http.get<AttachmentDTO[]>(url);
	}

	deleteAttachment(id: string): Observable<BaseResponse> {
		const url = this.baseUrl + 'Attachments/' + id;
		return this.http.delete<BaseResponse>(url);
	}

	getAttachmentUrl(id: string): Observable<GetAttachmentUrlResponse> {
		return this.http.get<GetAttachmentUrlResponse>(`${this.baseUrl}Attachments/${id}/url`, {});
	}
}
