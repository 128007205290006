import {routingState} from "./routing-reducer-types";
import {createReducer, on} from "@ngrx/store";
import * as routingActions from './actions';
import { getBooleanFromLocalStorage } from "../shared/utils/storage-utils";
import {userActions} from "../profile/store";

const initialState: routingState = {
  navSidebarRoutingSegments: [],
  sidebarExpanded: getBooleanFromLocalStorage('sidebarExpanded', true),
  loggedIn: false
}

export const routingReducer = createReducer(
  initialState,
  on(routingActions.setNavSidebarRoutingSegments, (state, action) => ({...state, navSidebarRoutingSegments: action.segments})),
  on(routingActions.login, (state) => ({...state, loggedIn: true})),
  on(routingActions.toggleSidebar, (state) => ({...state, sidebarExpanded: !state.sidebarExpanded})),
	on(userActions.changeCurrentUserOrganization, (state) => ({...state, navSidebarRoutingSegments: []}))
)
