export const squareMetersToHectares = (input: number):number => input * 0.0001;

export const squareFeetToAcres = (input: number):number => input * 2.295684113865932e-5;

export const feetToMeters = (input: number) => input * 0.3048;

export const metersToFeet = (input: number) => input * 3.2808399;

export const squareMetersToAcres = (input: number) =>  (input * 0.000247105);

export const squareMetersToSquareFeet = (input: number) =>  input * 10.7639;

