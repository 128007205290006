import {inject, Injectable} from "@angular/core";
import {GrassParametersMapsService} from "./grass-parameters-maps.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../types/app-state";
import {GeoJSONSource, LngLat} from "mapbox-gl";
import {point} from "../../types/point";
import {laboratoryExaminationActions, sprinklersActions} from "../store/other-objects/actions";
import {OtherObjectDataSource} from "../models/mapbox/types";
import {ConfirmationService} from "primeng/api";
import {GeoJsonProperties} from "geojson";
import {MapboxUtils} from "../../shared/utils/mapbox-utils";
import lngLatToPoint = MapboxUtils.lngLatToPoint;
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class MapboxMoveItemsService {
  private mapsService = inject(GrassParametersMapsService);
  private store = inject(Store<AppState>);
  private confirmationService = inject(ConfirmationService);
  private translate = inject(TranslateService);

  moveSprinkler = (originalLngLat: LngLat, mapId: string, itemId: number) => {
    const successCallback: moveItemSuccessCallback = (gps: point) => {
      this.store.dispatch(sprinklersActions.moveSprinkler({sprinklerId: itemId, gps}))
    }

    this.moveItem(originalLngLat, mapId, itemId, OtherObjectDataSource.Sprinklers, successCallback);
  }

  moveLaboratoryExamination = (originalLngLat: LngLat, mapId: string, itemId: number) => {
    const successCallback: moveItemSuccessCallback = (gps: point) => {
      this.store.dispatch(laboratoryExaminationActions.move({id: itemId, gps}));
    }

    this.moveItem(originalLngLat, mapId, itemId, OtherObjectDataSource.LaboratoryExaminations, successCallback);
  }

  moveItem = (originalLngLat: LngLat, mapId: string, itemId: number, dataSourceName: OtherObjectDataSource, successCallback: moveItemSuccessCallback) => {
    let isMoving = true;
    const map = this.mapsService.getMapById(mapId)!.map;

    const dataSource = map.getSource(dataSourceName) as GeoJSONSource;

    const features: any = this.getUniqueFeatures(map.querySourceFeatures(dataSourceName), 'itemId');
    const movedFeatureIndex = features.findIndex((f: { properties: GeoJsonProperties }) => f.properties!['itemId'] === itemId);

    const isPoint = features[movedFeatureIndex].geometry.type === 'Point';

    const originalCoordinates = isPoint ? [originalLngLat.lng, originalLngLat.lat] : JSON.parse(JSON.stringify(features[movedFeatureIndex].geometry.coordinates));

    const mouseMoveListener = (e: any) => {
      if (isMoving) {
        features[movedFeatureIndex].properties['movingState'] = isMoving;
        if (isPoint) {
          features[movedFeatureIndex].geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
        } else {
          const offset = this.calculateOffset(originalLngLat, e.lngLat);
          for (let i = 0; i < originalCoordinates.length; i++) {
            for (let j = 0; j < originalCoordinates[i].length; j++) {
              features[movedFeatureIndex].geometry.coordinates[i][j] = [
                originalCoordinates[i][j][0] + offset.offsetLng,
                originalCoordinates[i][j][1] + offset.offsetLat
              ];
            }
          }
        }
        dataSource.setData({
          type: 'FeatureCollection',
          features
        });
      }
    }

    const mouseUpListener = (e: any) => {
      if (isMoving) {
        isMoving = false;
        features[movedFeatureIndex].properties['movingState'] = isMoving;

        this.confirmationService.confirm({
          header: 'Confirmation',
          // icon: 'pi pi-exclamation-triangle',
          message: this.translate.instant("GRASS_PARAMETERS.OTHER_OBJECTS.SURE_MOVE"),
					acceptButtonStyleClass: 'p-button-success hide-icon',
					rejectButtonStyleClass: 'p-button-outlined p-button-success hide-icon',
          accept: () => {
            const gps: point = lngLatToPoint(e.lngLat);
            successCallback(gps);
          },
          reject: () => {
            features[movedFeatureIndex].geometry.coordinates = originalCoordinates;
            dataSource.setData({
              type: 'FeatureCollection',
              features
            });
          }
        })

        map.off('mousemove', mouseMoveListener);
        map.off('mouseup', mouseUpListener);
      }
    }

    map.on('mousemove', mouseMoveListener);
    map.on('mouseup', mouseUpListener);
  }

  // Because features come from tiled vector data,
  // feature geometries may be split
  // or duplicated across tile boundaries.
  // As a result, features may appear
  // multiple times in query results.
  private getUniqueFeatures = (features: any, comparatorProperty: string) => {
    const uniqueIds = new Set();
    const uniqueFeatures = [];
    for (const feature of features) {
      const id = feature.properties[comparatorProperty];
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id);
        uniqueFeatures.push(feature);
      }
    }
    return uniqueFeatures;
  }

  private calculateOffset = (original: LngLat, current: LngLat): { offsetLng: number, offsetLat: number } => {
    return {
      offsetLng: current.lng - original.lng,
      offsetLat: current.lat - original.lat
    };
  }
}

type moveItemSuccessCallback = (gps: point) => void;
