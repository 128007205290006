<p-table class="table table-main" [value]="taskHeaders" [tableStyle]="{ 'min-width': '50rem' }" [rows]="5" dataKey="id">
    <ng-template pTemplate="header">
        <tr>
            <th>{{ 'INCIDENT.RELATED_TASK.NUMBER' | translate }}</th>
            <th>{{ 'INCIDENT.RELATED_TASK.STATE' | translate }}</th>
            <th>{{ 'INCIDENT.RELATED_TASK.TITLE_GRID' | translate }}</th>
            <th>{{ 'INCIDENT.RELATED_TASK.TASK_TYPE' | translate }}</th>
            <th>{{ 'INCIDENT.RELATED_TASK.DUE_DATE' | translate }}</th>
            <th class="text-right">{{ 'INCIDENT.RELATED_TASK.ACTIONS' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task>
        <tr>
            <td><a [routerLink]="['/tasks', projectId$ | async, 'editTask', task.id]">{{ task.number }}</a></td>
            <td class="{{ task.state | lowercase }}">{{ task.state | translateTaskStatusAsync | async }}</td>
            <td class="grid-column-long-text">{{ task.title }}</td>
            <td class="grid-column-long-text">{{ task.type | translateTaskTypeAsync | async }}</td>
            <td [ngClass]="{'expired-task': taskService.isExpiredTask(task.dueDate)}">{{ task.dueDate | date }}</td>
            <td class="actions">
                <div class="flex align-items-center justify-content-end gap-2">
                    <button pButton pRipple type="button" icon="pi pi-trash" (click)="relatedTaskDeleteClick(task)"
                        class="p-button-rounded p-button-text" pTooltip="Delete" tooltipPosition="top"></button>
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4">{{ 'INCIDENT.RELATED_TASK.NO_RELATED_TASK' | translate }}</td>
        </tr>
    </ng-template>
</p-table>
