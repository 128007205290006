import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, of, startWith, switchMap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'translateTaskTypeAsync'
})
export class TranslateTaskTypeAsyncPipe implements PipeTransform {
	private translate = inject(TranslateService);

  transform(taskName: string): Observable<string> {
    return this.translate.onLangChange.pipe(
			startWith({lang: this.translate.currentLang}),
			switchMap(() => this.translate.get(`TASK.TYPES.${taskName.toUpperCase()}`))
		);
  }
}
