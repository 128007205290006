import {Injectable} from "@angular/core";
import {GrassParametersMapsService} from "../grass-parameters-presentation/services/grass-parameters-maps.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as routingActions from "./actions";
import {tap} from "rxjs";

@Injectable()
export class RoutingEffects {
  constructor(private actions$: Actions, private mapsService: GrassParametersMapsService) {}

  toggleSidebar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routingActions.toggleSidebar),
      tap(() => {
        setTimeout(() => {
            this.mapsService.maps.forEach(x => x.map.resize());
          }, 10);
      })
    )
  }, {dispatch: false})
}
