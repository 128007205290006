import {AbstractControl, AsyncValidatorFn, ValidationErrors} from "@angular/forms";
import {AreaMapboxModel} from "../model/area-mapbox-model";
import {State} from "../_helpers/state";
import {map, Observable, take} from "rxjs";
import {AppState} from "../../types/app-state";
import {Store} from "@ngrx/store";
import * as chooseAreaSelectors from "../choose-area/choose-area-store/selectors";
import {validateAreaSelector} from "../choose-area/choose-area-store/selectors";

export const areaNameUnique = (store: Store<AppState>): AsyncValidatorFn => {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return store.select(chooseAreaSelectors.validateAreaSelector)
      .pipe(
        take(1),
        map(({drawnAreas, isElementaryObject}) => {
          if (isElementaryObject) {
            return null;
          }

          const uuid = control.parent?.get('uuid')?.value;
          const nameAlreadyExists = drawnAreas.some(area => area.uuid !== uuid && area.name === control.value && area.state !== State.Delete);
          return nameAlreadyExists ? {nameUnique: true} : null;
        })
      )
  }
}
