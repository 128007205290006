import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'class': 'app-root'}
})
export class AppComponent {
  title = 'AgroWeb';

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['en', 'pl']);

    const availableLangs = this.translate.getLangs();
    const defaultLang = environment.defaultLang;

    let browserLang = localStorage.getItem('lang') || this.translate.getBrowserLang() || defaultLang;

    if (!availableLangs.includes(browserLang)) {
      browserLang = defaultLang;
    }

    this.translate.setDefaultLang(browserLang);

    localStorage.setItem('lang', browserLang);
  }
}
