import {createAction, props} from "@ngrx/store";
import {
	measurementDto
} from "../../../grass-parameters-presentation/components/grass-parameters-presentation/photo-general-information/general-info-weather-panel/services/weather-api.service";


export const commonActions = {
	getWeather: createAction('[Common] Get Weather For Task', props<{pastDaysCount?: number, futureDaysCount?: number}>()),
	getWeatherSuccess: createAction('[Common] Get Weather For Task Success', props<{measurements: measurementDto[]}>()),
	handleError: createAction('[Common] Handle Error', props<{errorMessage: string}>()),
}
