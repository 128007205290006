import {Injectable} from '@angular/core';
import {AnyLayer, GeoJSONSource, Map, Marker} from 'mapbox-gl'
import {parameterMapboxNorm} from "../models/parameter-norms/parameter-mapbox-norm";
import {
	GrassParametersDataSource,
	GrassParametersLayer,
	LoaderLayerId,
	OtherObjectLayer
} from "../models/mapbox/types";
import {ParametersMapComposition, selectableParam} from '../store/grass-params/types';
import {hexesLayerConfig} from "../models/mapbox/hexes-layers.config";

type SourceDataEventListener = (e: any) => void;
type DataSourceLoadedListeners = Record<GrassParametersDataSource, SourceDataEventListener>

@Injectable({
  providedIn: 'root'
})
export class MapboxHexGridService {
	// @ts-ignore
	dataSourceLoadedListeners: DataSourceLoadedListeners = {};

  setHexesLayer = (map: Map, dataSourceLink: string, config: hexesLayerConfig, isVisible: boolean, loaderMarker: Marker, mapComposition: ParametersMapComposition) => {
    const { dataSourceName, fillLayer, bordersLayer } = config;
		this.handleDataSourceLoadingEvents(dataSourceName, map, isVisible, loaderMarker, mapComposition);
		const existingDataSource = map.getSource(dataSourceName);

    if (existingDataSource !== undefined) {
      (existingDataSource as GeoJSONSource).setData(dataSourceLink);
      return;
    }

    const visibility = isVisible ? 'visible' : 'none';

    map.addSource(dataSourceName, {
      type: "geojson",
      data: dataSourceLink,
      buffer: 0.5
    });

		const addFillLayerConfig: AnyLayer = {
			id: fillLayer,
			source: dataSourceName,
			type: "fill",
			paint: { 'fill-color': 'transparent' },
			layout: { visibility }
		};

		const addBorderLayerConfig: AnyLayer = {
			id: bordersLayer,
			source: dataSourceName,
			type: "line",
			layout: { visibility },
			paint: {
				"line-color": "#000",
				"line-width": 0.5
			}
		}


		if (map.getLayer(OtherObjectLayer.ObservationPoints)) {
			map.addLayer(addFillLayerConfig, OtherObjectLayer.ObservationPoints);
			map.addLayer(addBorderLayerConfig, OtherObjectLayer.ObservationPoints);

		} else {
			map.addLayer(addFillLayerConfig);
			map.addLayer(addBorderLayerConfig);
		}
  }

	paintDiseasesLayer = (map: Map, layerName: GrassParametersLayer) => {
    // map.setPaintProperty(GrassParametersLayer.HexGridDiseasesFillLayerLowZoom, "fill-color", [
    //   "match",
    //   ["get", "disease"],
    //   1, DiseaseColor.AbsenceOfDisease,
    //   2, DiseaseColor.SnowMold,
    //   3, DiseaseColor.YellowStain,
    //   "red"
    // ]);
  }

  paintHexesLayerSingleParam = (paramName: string, fillLayerName: GrassParametersLayer, map: Map, selectedGrassParam: selectableParam, parameterNorms: parameterMapboxNorm[]) => {
    if (!paramName) {
      map.setPaintProperty(fillLayerName, "fill-color", "transparent");
      return;
    }

    const norm = parameterNorms.find(x => x.paramName === paramName);

    if (!norm) {
      return;
    }

		map.setPaintProperty(fillLayerName, 'fill-color', [
			'case',
			['==', ['get', paramName], null],
			"transparent",// Checks if the value of paramName is null
			['interpolate', ['linear'], ['coalesce', ['get', paramName], 0], ...norm.interpolatedValues]
		]);
  }

  paintHexesLayerMultiParams = (params: string[], fillLayer: GrassParametersLayer, map: Map, parameterNorms: parameterMapboxNorm[]) => {
    const deviations: unknown[] = [];

    params.forEach(param => {
      deviations.push(['abs', ["get", `${param}Deviation`]]);
    });

    const interpolatedValues = parameterNorms.find(({ paramName }) => paramName === 'Deviations')!.interpolatedValues

    map.setPaintProperty(fillLayer, "fill-color", [
      "interpolate",
      ["linear"],
      ["/", ["+", ...deviations], params.length],
      ...interpolatedValues
    ]);
  }

  removeHexesParamsPaint = (fillLayerName: GrassParametersLayer, map: Map) => {
    map.setPaintProperty(fillLayerName, "fill-color", "transparent");
  }

	private handleDataSourceLoadingEvents(dataSourceName: GrassParametersDataSource, map: Map, isVisible: boolean, loaderMarker: Marker, mapComposition: ParametersMapComposition) {
		if (this.dataSourceLoadedListeners[dataSourceName]) {
			map.off('sourcedata', this.dataSourceLoadedListeners[dataSourceName]);
		}

		if (isVisible) {
			if (mapComposition !== ParametersMapComposition.InteractiveCompare) {
				loaderMarker.setLngLat(map.getCenter()).addTo(map);
			}

			map.setLayoutProperty(LoaderLayerId, 'visibility', 'visible');
		}

		const dataLoadedListener = (e: any) => {
			if (e.dataType === 'source' && e.sourceId === dataSourceName && e.isSourceLoaded) {
				map.setLayoutProperty(LoaderLayerId, 'visibility', 'none');
				if (mapComposition !== ParametersMapComposition.InteractiveCompare) {
					loaderMarker.remove();
				}
			}
		};

		map.on('sourcedata', dataLoadedListener);
		this.dataSourceLoadedListeners[dataSourceName] = dataLoadedListener;
	}
}
