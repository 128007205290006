import { PhotoInfoType } from "../grass-parameters-presentation/models/photo-info/photo-info-type";

export enum ExaminationType {
	Acidity = 'Acidity',
	Biomass = 'Biomass',
	GreenSpeed = 'GreenSpeed',
	Laboratory = 'Laboratory'
}

export enum LaboratoryExaminationType {
	Turf = 1,
	Soil = 2
}

export interface examinationTypeDropDownModel {
	id: string;
	name: string;
}

export interface holeObjectTypeDropDownModel {
	id: PhotoInfoType;
	name: string;
}

export interface holeDropDownModel {
	id: number;
	name: string;
}

export interface holeObjectDropDownModel {
	id: number;
	type: string;
	name: string;
}

export interface examinationGridParameters {
    take: number;
    skip: number;
	examinationType: ExaminationType | null;
    holeIds: number[] | null;
    holeObjectTypeIds: string[] | null;
	dateRange?: Date[] | null;
    sortField?: string;
    sortOrder?: number;
}

export interface examinationGridModel {
	id: number;
	itemType: ExaminationType;
	projectAreaId: number | null;
	projectAreaName: string | null;
	elementaryObjectId: number | null;
	elementaryObjectName: string | null;
	examinerName: string;
	examinationDate: Date;
}

export interface greenSpeedNormGridModel {
	id: number;
	userFullName: string;
	dateTime: Date;
	speedMin: number | null;
	speedMax: number | null;
	remarks: string | null;
}

export interface examinationFormModel {
	id?: number;
	projectAreaId: number | null;
	projectAreaName: string | null | undefined;
	elementaryObjectId: number | null | undefined;
	elementaryObjectName: string | null | undefined;
	examinationDate: Date;
	remarks: string | null;
}

export interface laboratoryExaminationTypeDropDownModel {
	id: number;
	name: string;
}

export interface greenSpeedExaminationFormModel extends examinationFormModel {
	speed: number;
	speedMin: number | null;
	speedMax: number | null;
	temperature: number | null;
	windSpeed: number | null
	precipitation: number | null;
	precipitationType: string | null;
	solarEnergy: number | null;
}

export interface biomassExaminationFormModel extends examinationFormModel {
	biomass: number | null | undefined;
}

export interface acidityExaminationFormModel extends examinationFormModel {
	acidity: number;
}

export interface laboratoryExaminationFormModel extends examinationFormModel {
	laboratoryExaminationType: number | null | undefined;
	nitrogenAverage: number | null | undefined;
	potassiumAverage: number | null | undefined;
	phosphorusAverage: number | null | undefined;
	magnesiumAverage: number | null | undefined;
	calciumAverage: number | null | undefined;
	sodiumAverage: number | null | undefined;
	zincAverage: number | null | undefined;
	copperAverage: number | null | undefined;
	manganeseAverage: number | null | undefined;
	gps: any | null | undefined;
}

export interface changeGreenSpeedNormDto {
	speedMin: number;
	speedMax: number;
	remarks: string | null | undefined;
}

export interface addGreenSpeedExaminationDto {
	holeId: number;
    greenId: number;
	examinationDate: Date;
	speed: number;
	remarks: string | null | undefined;
}

export interface changeGreenSpeedExaminationDto {
    examinationDate: Date;
	speed: number;
	remarks: string | null | undefined;
}

export interface addAcidityExaminationDto {
	projectAreaId: number;
	elementaryObjectId: number | null | undefined;
	examinationDate: Date;
	acidity: number;
	remarks: string | null | undefined;
}

export interface changeAcidityExaminationDto {
    examinationDate: Date;
	acidity: number;
	remarks: string | null | undefined;
}

export interface addBiomassExaminationDto {
	projectAreaId: number;
	elementaryObjectId: number | null | undefined;
	examinationDate: Date;
	biomass: number;
	remarks: string | null | undefined;
}

export interface changeBiomassExaminationDto {
    examinationDate: Date;
	biomass: number;
	remarks: string | null | undefined;
}

export interface addLaboratoryExaminationDto {
	examinationDate: Date;
	projectAreaId: number;
	elementaryObjectId: number | null | undefined;
	laboratoryExaminationType: number | null | undefined;
	nitrogenAverage: number | null | undefined;
	potassiumAverage: number | null | undefined;
	phosphorusAverage: number | null | undefined;
	magnesiumAverage: number | null | undefined;
	calciumAverage: number | null | undefined;
	sodiumAverage: number | null | undefined;
	zincAverage: number | null | undefined;
	copperAverage: number | null | undefined;
	manganeseAverage: number | null | undefined;
	remarks: string | null | undefined;
	gps: any | null;
	day: any;
}

export interface changeLaboratoryExaminationDto {
    examinationDate: Date;
	projectAreaId: number;
	elementaryObjectId: number | null | undefined;
	laboratoryExaminationType: number | null | undefined;
	nitrogenAverage: number | null | undefined;
	potassiumAverage: number | null | undefined;
	phosphorusAverage: number | null | undefined;
	magnesiumAverage: number | null | undefined;
	calciumAverage: number | null | undefined;
	sodiumAverage: number | null | undefined;
	zincAverage: number | null | undefined;
	copperAverage: number | null | undefined;
	manganeseAverage: number | null | undefined;
	remarks: string | null | undefined;
	gps?: any | null;
}