import {createAction, props} from "@ngrx/store";
import {CurrencyInterface, GetAreaInfoByGpsInterface, GrassSpeciesInterface, ProjectCategoryInterface, ProjectInterface} from "../model/project.interface";
import {OrganizationInterface} from "../../profile/types/user-types";
import {AddProjectDto} from "../DTO/add-project-dto";
import { EditProjectDto } from "../DTO/edit-project-dto";

export const getProjects = createAction('[Projects] Get Projects', props<{organizationId: number}>());
export const getProjectsSuccess = createAction('[Projects] Get Projects Success', props<{projects: ProjectInterface[]}>());
export const getProjectById = createAction('[Projects] Get Project By Id', props<{projectId: number}>());
export const getOrganizationById = createAction('[Projects] Get Organization By Id', props<{organizationId?: number}>());
export const initNewProject = createAction('[Projects] Init New Project', props<{newProject: AddProjectDto}>());
export const createNewProject = createAction('[Projects] Create New Project', props<{createdProject: AddProjectDto}>());
export const createNewProjectSuccess = createAction('[Projects] Create New Project Success', props<{newProject: ProjectInterface}>());
export const handleError = createAction('[Projects] Error occurred', props<{error: string}>());
export const initProjectFromIGolf = createAction('[Projects] Init Project From IGolf', props<{organizationId: number, courseId: string, grassSpeciesId: number}>());
export const initProjectFromIGolfSuccess = createAction('[Projects] Init Project From IGolf Success', props<{importedProject: AddProjectDto}>());
export const showProjectSettingsPopup = createAction('[Projects] Show Project Settings Popup');
export const removeProject = createAction('[Projects] Remove Project', props<{projectId: number}>());
export const removeProjectSuccess = createAction('[Projects] Remove Project Success', props<{projectId: number}>());
export const updateProject = createAction('[Projects] Update Project', props<{editProject: EditProjectDto}>());
export const updateProjectSuccess = createAction('[Projects] Update Project Success', props<{editProject: EditProjectDto}>());
export const getCategories = createAction('[Projects] Get Categories');
export const getCategoriesSuccess = createAction('[Projects] Get Categories Success', props<{categories: ProjectCategoryInterface[]}>());
export const getGrassSpecies = createAction('[Projects] Get Grass Species');
export const getGrassSpeciesSuccess = createAction('[Projects] Get Grass Species Success', props<{grassSpecies: GrassSpeciesInterface[]}>());
export const setOrganizationAndProjects = createAction('[Projects] Set Organization And Projects', props<{organizations: OrganizationInterface[], projects: ProjectInterface[], organizationId: number}>());
export const getCurrencies = createAction('[Projects] Get Currencies');
export const getCurrenciesSuccess = createAction('[Projects] Get Currencies Success', props<{currencies: CurrencyInterface[]}>());
export const getAreaInfoByGps = createAction('[Projects] Get Area Info By Gps', props<{lat: number, lng: number}>());
export const getAreaInfoByGpsSuccess = createAction('[Projects] Get Area Info By Gps Success', props<{dto: GetAreaInfoByGpsInterface}>());