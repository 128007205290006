import {inject, Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../../../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import { baseResponse } from '../../../../../../grass-parameters-presentation/DTO/base-response';

@Injectable({
  providedIn: 'root'
})
export class WeatherApiService {
  private readonly baseUrl = environment.weatherApiUrl;
  private http = inject(HttpClient);

  getActualConditions = (projectId: number): Observable<getActualConditionResponse> => {
    const endpoint = `${this.baseUrl}projects/${projectId}/measurements/actuals-conditions`;
    return this.http.get<getActualConditionResponse>(endpoint);
  };

	getWeather = (projectId: number, pastDaysCount?: number, futureDaysCount?: number): Observable<getWeatherForTaskResponse> => {
		const endpoint = `${this.baseUrl}projects/${projectId}/visual-crossing/weather`;
    let params = new HttpParams({fromObject: {day: new Date().toISOString()}});

		if (pastDaysCount) {
			params = params.append('PastDaysCount', pastDaysCount);
		}

		if(futureDaysCount) {
			params = params.append('FutureDaysCount', futureDaysCount);
		}

		return this.http.get<getWeatherForTaskResponse>(endpoint, { params });
	}

	getWeatherAroundDate = (projectId: number, date: Date): Observable<getWeatherForTaskResponse> => {
		const endpoint = `${this.baseUrl}projects/${projectId}/visual-crossing/weather`;
		const params = new HttpParams({fromObject: {day: date.toISOString(), pastDaysCount: 7, futureDaysCount: 7}});
		return this.http.get<getWeatherForTaskResponse>(endpoint, { params });
	}
}

export interface getActualConditionResponse extends baseResponse {
  measurement?: measurementDto;
}

export interface getWeatherForTaskResponse extends baseResponse {
	measurements: measurementDto[];
}

export interface measurementDto {
	measurementDateTime: Date;
  maximumTemperature: number;
  minimumTemperature: number;
  temperature: number;
  dewPoint?: number;
  humidity?: number;
  precipitation: number;
  precipitationEstimated?: number;
  precipitationType?: string;
  windDirection?: number;
  windSpeed?: number;
  solarEnergy?: number | null;
  solarRadiation?: number | null;
  uvIndex?: number | null;
  icon?: string;
}
