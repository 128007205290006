import {inject, Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {otherObjectActions, sprinklersActions} from "../actions";
import {catchError, map, mergeMap, of, switchMap, tap, withLatestFrom} from "rxjs";
import * as otherObjectsSelectors from "../selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../types/app-state";
import {ItemsService} from "../../../services/items.service";
import {sprinklerDto} from "../../../DTO/item-dto";
import {ItemsPopupService} from "../../../services/items-popup.service";
import * as projectSelectors from "../../../../project/project-store/selectors";
import {selectUserSettings} from "../../../../profile/store";
import {MeasurementSystem} from "../../../../types/MeasurementSystem";
import {feetToMeters, metersToFeet} from "../../../../shared/utils/measurement-system-utils";
import {editSprinklerCommand} from "../../../DTO/items/sprinklers/edit-sprinkler-command";
import {AgroMessageService} from "../../../../shared/services/agro-message.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class SprinklerEffects {
  private actions$ = inject(Actions);
  private store = inject(Store<AppState>);
  private itemsService = inject(ItemsService);
  private itemsPopupService = inject(ItemsPopupService);
	private messageService = inject(AgroMessageService);
	private translateService = inject(TranslateService);

	private readonly messagesTranslationKey = 'GRASS_PARAMETERS.OTHER_OBJECTS.MESSAGES.SPRINKLERS';

  getSprinkler$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.getSprinkler),
    mergeMap(action => this.itemsService.getSprinkler(action.projectId, action.sprinklerId).pipe(
      map((getSprinklerResponse) => {
        if (!getSprinklerResponse.success) {
          return otherObjectActions.handleError({errorMessage: getSprinklerResponse.error});
        }

        return sprinklersActions.getSprinklerSuccess(getSprinklerResponse.sprinkler);
      }),
      catchError((error) => of(otherObjectActions.handleError(error)))
    ))
  ));

  addSprinkler$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.addSprinkler),
    withLatestFrom(
      this.store.select(projectSelectors.selectedProjectIdSelector),
      this.store.select(selectUserSettings)
    ),
    switchMap(([command, projectId, {measurementSystem}]) => {
      const radius = measurementSystem === MeasurementSystem.Metric ? command.radius : feetToMeters(command.radius);
      const addedSprinkler = {...command, radius, projectId};

      return this.itemsService.addSprinkler(addedSprinkler).pipe(
        map((addSprinklerResponse) => {
          if (!addSprinklerResponse.success) {
            return otherObjectActions.handleError({errorMessage: addSprinklerResponse.error});
          }

					this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.ADD_SUCCESS`));
					return sprinklersActions.addSprinklerSuccess();
        }),
        catchError((error) => of(otherObjectActions.handleError(error)))
      );
    })
  ));

  editSprinkler$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.editSprinkler),
    withLatestFrom(
      this.store.select(projectSelectors.selectedProjectIdSelector),
      this.store.select(selectUserSettings)
    ),
    switchMap(([command, projectId, {measurementSystem}]) => {
      const radius = measurementSystem === MeasurementSystem.Metric ? command.radius : feetToMeters(command.radius);
      const editSprinklerCommand: editSprinklerCommand = {...command, radius, projectId};

      return this.itemsService.editSprinkler(editSprinklerCommand).pipe(
        map((baseResponse) => {
          if (!baseResponse.success) {
            return otherObjectActions.handleError({errorMessage: baseResponse.error});
          }

					const editedSprinkler: sprinklerDto = {
						...editSprinklerCommand,
						itemId: editSprinklerCommand.sprinklerId.toString(),
						itemType: 'Sprinkler',
						projectId
					};

					this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.UPDATE_SUCCESS`));
					return sprinklersActions.editSprinklerSuccess();
        })
      );
    })
  ));

  moveSprinkler$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.moveSprinkler),
    withLatestFrom(this.store.select(otherObjectsSelectors.getSprinklersSelector)),
    map(([{sprinklerId, gps}, items]) => {
      const sprinkler: sprinklerDto = items.find(c => c.sprinklerId == sprinklerId)!;
      const {projectId, radius, name, irrigation } = sprinkler;
      return sprinklersActions.editSprinkler({projectId, sprinklerId, name: name!, radius: radius!, gps, irrigation: irrigation});
    }),
    catchError((error) => of(otherObjectActions.handleError(error)))
  ));

  removeSprinkler$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.removeSprinkler),
    withLatestFrom(this.store.select(projectSelectors.selectedProjectIdSelector)),
    mergeMap(([action, projectId]) => this.itemsService.removeSprinkler(projectId!, action.sprinklerId).pipe(
      map((baseResponse) => {
        if (!baseResponse.success) {
          return otherObjectActions.handleError({errorMessage: baseResponse.error});
        }

				this.messageService.displaySuccessMessage('', this.translateService.instant(`${this.messagesTranslationKey}.REMOVE_SUCCESS`));
				return sprinklersActions.removeSprinklerSuccess();
      }),
      catchError((error) => of(otherObjectActions.handleError(error)))
    ))
  ));

  showEditWorkItemPopup$ = createEffect(() => this.actions$.pipe(
    ofType(sprinklersActions.showEditSprinklerPopup),
    withLatestFrom(
      this.store.select(otherObjectsSelectors.getEditedItemSelector),
      this.store.select(selectUserSettings)
    ),
    tap(([_, editedItem, {measurementSystem}]) => {
      this.itemsPopupService.destroyDialogIfOpen();

      const editedSprinkler = editedItem as sprinklerDto;
      const radius = measurementSystem === MeasurementSystem.Metric ? editedSprinkler.radius : metersToFeet(editedSprinkler.radius!);

      this.itemsPopupService.showEditSprinklerPopup({...editedSprinkler, radius});
    })
  ), {dispatch: false});
}
