import { Component, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-help-dialog',
    templateUrl: './help-dialog.component.html',
})
export class HelpDialogComponent implements OnInit {
    private dialogConfig = inject(DynamicDialogConfig);
    domSanitizer = inject(DomSanitizer);
    url?: SafeResourceUrl;

    ngOnInit() {
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dialogConfig.data.srcUrl);      
    }
}
