import { Injectable, inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, mergeMap, of, tap, withLatestFrom } from "rxjs";
import { attachmentActions } from "./actions";
import { AgroMessageService } from "../../services/agro-message.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/types/app-state";
import { selectorObjectId, selectorUrlBeginning } from "./selectors";
import { AttachmentDTO } from "../../model/attachmentDTO";
import { GetAttachmentUrlResponse } from "src/app/project/model/upload.interface";
import {AttachmentsService} from "../../services/attachments.service";

@Injectable()
export class AttachmentsEffects {
    private actions$ = inject(Actions);
    private messageService = inject(AgroMessageService);
		private attachmentsService = inject(AttachmentsService);
    private store = inject(Store<AppState>);

    deleteAttachment$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.deleteAttachment),
        exhaustMap(({ attachmentId }) => {
            return this.attachmentsService.deleteAttachment(attachmentId).pipe(
                map(response => {
                    if (response.success) {
                        return attachmentActions.refreshAttachments();
                    }
                    return attachmentActions.handleError({ errorMessage: response.error });
                }),
                catchError(error => of(attachmentActions.handleError(error)))
            )
        })
    ));

    handleError$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.handleError),
        tap(({ errorMessage }) => {
            this.messageService.displayErrorMessage("Error", errorMessage);
        })
    ), { dispatch: false });

    refreshAttachments$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.refreshAttachments),
        withLatestFrom(this.store.select(selectorUrlBeginning), this.store.select(selectorObjectId)),
        map(([_, urlBeginning, objectId]) => {
            return attachmentActions.loadAttachments({ urlBeginning: urlBeginning, objectId: objectId });
        })
    ));

    loadAttachments$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.loadAttachments),
        exhaustMap(({ urlBeginning, objectId }) => {
            const getUrl = `${urlBeginning}/${objectId}/attachments`;
            return this.attachmentsService.getAttachmentsFiles(getUrl)
                .pipe(
                    map(response => {
                        const attachments = response as AttachmentDTO[];
                        return attachmentActions.loadAttachmentsEnd({ attachments: attachments });
                    }),
                    catchError(error => of(attachmentActions.handleError(error)))
                )
        })
    ));

    getAttachmentUrl$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.getAttachmentUrl),
        mergeMap(({attachmentId}) => {
            return this.attachmentsService.getAttachmentUrl(attachmentId).pipe(
                map((response: GetAttachmentUrlResponse) => attachmentActions.getAttachmentUrlSuccess(response)),
                catchError(error => of(attachmentActions.handleError(error)))
            )
        })));

    getAttachmentUrlSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(attachmentActions.getAttachmentUrlSuccess),
        tap((response: GetAttachmentUrlResponse) => {
            window.open(response.url, '_blank');
        })
      ), {dispatch: false});
}
