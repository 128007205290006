import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LaboratoryExaminationType } from 'src/app/examinations/types';

@Component({
    selector: 'app-laboratory-examination-type-form',
    template: `
          <section class="laboratory-examination-popup-type-step-wrapper no-paddings">
          <div class="agro-form-property">
            <label>{{ 'EXAMINATION.FORM.LABORATORY_EXAMINATION_TYPE' | translate }}</label>
            <div class="agro-form-control">
              <p-dropdown 
                [ng-model]="this.selectType" 
                [options]="laboratoryExaminationTypes"
                (onChange)="onTypeSelected($event)"
                optionLabel="name" 
                optionValue="id"
                [placeholder]="'EXAMINATION.FORM.LABORATORY_EXAMINATION_TYPE_PLACEHOLDER' | translate" />
            </div>
          </div>
          </section>
      `,
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class LaboratoryExaminationTypeFormComponent {
    private translate = inject(TranslateService);
    
    @Output() examinationTypeSelected = new EventEmitter<number>();
    @Input() selectType: number | null | undefined;
    
    laboratoryExaminationTypes: any[] = [
      { id: LaboratoryExaminationType.Turf, name: this.translate.instant('EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_TURF') }, 
      { id: LaboratoryExaminationType.Soil, name: this.translate.instant('EXAMINATION.COMMON.LABORATORYEXAMINATIONTYPE_SOIL') }
    ];
    
    onTypeSelected(e: any) {
      this.selectType = e.value;
      this.examinationTypeSelected.emit(this.selectType!);
    }
  }